export var PAYMENT_GATEWAY = {
    "STRIPE":"stripe",
    "CASH":"cash"
}

export var CONFIG = {
    API_URL: 'https://api.deliveryangel.com',
    IMAGE_URL: 'https://api.deliveryangel.com/',
    //API_URL: 'http://localhost:8000',
    //IMAGE_URL: 'http://localhost:8000/',
    IS_USE_AWS : false,
    GOOGLE_KEY: 'AIzaSyDD0ysJ1Hidhr2x6IqhsKEUNHa0-RGA1Ws',
    STRIPE_KEY: 'pk_test_51IaXPZIUvxJzsGa77vgr8DTGNKqerXppKEzUX7a7J6Hii4Uvjh3EwIhpZgdI59B5Relxqx99lpkquR1jwaBY9xJY00nMNpNHFE',
    FIREBASE_CONFIG : {
        apiKey: "AIzaSyCLRAJRyv5421PbLgolNmL8BueMMOTRJU0",
        authDomain: "store-157305.firebaseapp.com",
        databaseURL: "https://store-157305.firebaseio.com",
        projectId: "store-157305",
        storageBucket: "store-157305.appspot.com",
        messagingSenderId: "1062107977876",
        appId: "1:1062107977876:web:ecaa423e96aa78dd36fc81"
    }
}

export var ORDER_STATE = {
    WAITING_FOR_ACCEPT_STORE: 1,
    STORE_ACCEPTED: 3,
    ORDER_READY: 7,
    DELIVERY_MAN_STARTED_DELIVERY: 19,
    DELIVERY_MAN_ARRIVED_AT_DESTINATION: 21,
    ORDER_COMPLETED: 25,
    DELIVERY_MAN_PICKED_ORDER: 17,

    // CANCELED_BY_USER: 101,
    // STORE_REJECTED: 103,
    // STORE_CANCELLED: 104,
    // STORE_CANCELLED_REQUEST: 105,
    // STORE_PREPARING_ORDER: 5,
    // WAITING_FOR_DELIVERY_MAN: 9,
    // NO_DELIVERY_MAN_FOUND: 109,
    // DELIVERY_MAN_ACCEPTED: 11,
    // DELIVERY_MAN_REJECTED: 111,
    // DELIVERY_MAN_CANCELLED: 112,
    // DELIVERY_MAN_COMING: 13,
    // DELIVERY_MAN_ARRIVED: 15,
    // DELIVERY_MAN_COMPLETE_DELIVERY: 23,
};

export var DEFAULT_IMAGE  = {
    USER_PROFILE: 'assets/default_images/profile.png',
    STORE_PROFILE: 'assets/default_images/store.png',
    DELIVERY: 'assets/default_images/delivery.png',
    PRODUCT: 'assets/default_images/default_item_product.png',
    ADS: 'assets/default_images/ads.png',
}
