import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {  Subscription } from 'rxjs';
import { CountryModel } from 'src/app/models/country.model';
import { UserModel } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { CommonService } from 'src/app/services/common.service';
import { LocationModel, LocationService } from 'src/app/services/location.service';
import { Helper } from 'src/app/shared/helper';
import { environment } from 'src/environments/environment';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { SetttingModel } from 'src/app/models/setting.model';
import { CONFIG, DEFAULT_IMAGE } from 'src/app/constants/constants';
import { NotifiyService } from 'src/app/services/notifier.service';

declare var $:any;
declare var google:any;

export enum VERIFICATION_TYPE {REGISTER,AUTH}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit,OnDestroy {
  loginForm :FormGroup;
  signupForm :FormGroup;
  forgotPasswordForm :FormGroup;
  otpForm :FormGroup;
  resetPasswordForm :FormGroup;
  verificationForm :FormGroup = new FormGroup({
    sms_otp:new FormControl(null),
    email_otp:new FormControl(null)
  }); 

  IMAGE_URL = CONFIG.IMAGE_URL;
  DEFAULT_USER_PROFILE = DEFAULT_IMAGE.USER_PROFILE;
  DEFAULT_ITEM_IMAGE = DEFAULT_IMAGE.PRODUCT;
  
  userSubscription:Subscription;
  cartSubscription:Subscription;
  verificationSubscription:Subscription;
  user:UserModel;
  
  countries:Array<CountryModel> = [];
  total_items_in_user_cart: number = 0;
  items_in_user_cart = [];  
  is_referral_applied:boolean = false;
  referral_error = "";
  password_error = "";
  verification_error = "";
  resetPasswordID = "";
  resetPasswordToken = "";
  setting_data:SetttingModel = new SetttingModel();
  verification_type = null;
  verificationData = {
    email_otp : null,
    sms_otp : null
  }
  
  @ViewChild('address', { static: true }) address: ElementRef;

  constructor(public helper:Helper,
    public _cartService:CartService,
    public _locationService: LocationService,
    private _socialAuthService: SocialAuthService,
    public _commanService:CommonService,
    private _notifierService:NotifiyService,
    public _authService:AuthService) { }


  // Init App Data

  ngOnInit(): void {
    
    
    this._initForms();
    this.setting_data = this._commanService.settings;
    
    this._commanService.get_country_list().then(data=>{
      this.countries = data;
      if(this.countries.length){
        this.signupForm.patchValue({
          country_code:this.countries[0].country_code,
          country_phone_code:this.countries[0].country_phone_code,
          currency:this.countries[0].currency_code,
          cart_unique_token:this.helper.cart_unique_token
        })
      }
    })

    this.userSubscription = this._authService.loginObservable.subscribe((user)=>{
        this.user = user;
        if(user){
          this._authService.check_verification(this.user,this._commanService.settings);
        }
        this._cartService.update_local_cart();
  }); 
    
    this.cartSubscription = this._cartService.cartObservable.subscribe((cart)=>{
      this.total_items_in_user_cart = 0;
      this.items_in_user_cart = [];
      if(cart && cart.cart_data.cart.length){
        cart.cart_data.cart.forEach(element => {
          this.total_items_in_user_cart = this.total_items_in_user_cart + element.items.length
          this.items_in_user_cart.push(element)
        });
      }else{
        this.total_items_in_user_cart = 0
        this.items_in_user_cart = []
      }
   });

   this.verificationSubscription = this._authService.verificationObservable.subscribe((is_required_verification)=>{
      if(is_required_verification){
        this._authService.user_generate_otp({
          email:this._authService.user.email,
          phone:this._authService.user.phone,
          country_phone_code:this._authService.user.country_code
        }).then(otpData=>{
          if(otpData.success){
            this.verificationData = {email_otp: otpData.otp_for_email,sms_otp:otpData.otp_for_sms}
            this.verification_type = VERIFICATION_TYPE.AUTH;
            this.verificationForm.reset();
            $(".custom-model-main").removeClass('model-open');
            $("body").addClass('model-open');
            $('#verification-popup').addClass('model-open');
          }
        })

      }
   })
    
    this._initAutoComplete();
  }


  _initForms(){

    this.signupForm  = new FormGroup({
      first_name:new FormControl(null,Validators.required),
      last_name:new FormControl(null,Validators.required),
      email:new FormControl(null,Validators.required),
      phone:new FormControl(null,Validators.required),
      country_phone_code:new FormControl(null,Validators.required),
      country_code:new FormControl(null,Validators.required),
      currency:new FormControl(null,Validators.required),
      password:new FormControl(null,Validators.required),
      cart_unique_token:new FormControl(null,Validators.required),
      is_phone_number_verified:new FormControl(false,Validators.required),
      is_email_verified:new FormControl(false,Validators.required),
      is_term_checked:new FormControl(true,Validators.required),
      is_collection_checked:new FormControl(true,Validators.required),
      login_by:new FormControl('manual'),
      referral_code:new FormControl(null),
      city:new FormControl(null),
      social_id:new FormControl(null),
      address:new FormControl(null),
    })

    this.loginForm = new FormGroup({
      username:new FormControl(null,[Validators.required]),
      password:new FormControl(null,Validators.required)
    })

    this.forgotPasswordForm = new FormGroup({
      phone:new FormControl(null,[Validators.required])
    })

    this.otpForm =  new FormGroup({
      phone:new FormControl(null,[Validators.required]),
      otp:new FormControl(null,[Validators.required])
    })

    this.resetPasswordForm = new FormGroup({
      password:new FormControl(null,[Validators.required]),
      confirm_password:new FormControl(null,[Validators.required])
    })  

    this.verificationForm =  new FormGroup({
      sms_otp:new FormControl(null,Validators.required),
      email_otp:new FormControl(null,Validators.required)
    })
  }

  // Header and Cart Flow

  _initAutoComplete(){
      var autocompleteElm = this.address.nativeElement;
      let autocomplete = new google.maps.places.Autocomplete((autocompleteElm), {
        types: [],
        // componentRestrictions: { country: country_code }
      });
      autocomplete.addListener('place_changed', () => {
        this.helper._loader.isLoading = true;
        var place = autocomplete.getPlace();
        var curlocation:LocationModel = new LocationModel();
        curlocation.latitude = place.geometry.location.lat();
        curlocation.longitude = place.geometry.location.lng();
        curlocation.address = place['formatted_address'];
        place['address_components'].forEach(element => {
            var type = element.types[0]
            switch (type) {
                case 'country':
                    curlocation.country_name = element.long_name;
                    curlocation.country_code = element.short_name;
                    break;
                case 'administrative_area_level_1':
                    curlocation.state_code = element.short_name;
                    curlocation.state_name = element.long_name;
                    break;
                case 'administrative_area_level_2':
                    curlocation.city_name = element.short_name;
                    break;
                case 'postal_code':
                    break;
                default:
                    break;
            }
        });
        this._locationService.current_location  = curlocation;
      });
  }
  
  increaseValue(product_id,item_id): void{
    this._cartService.increase_qty(product_id,item_id)
  }

  decreaseValue(product_id,item_id): void{
    this._cartService.decrease_qty(product_id,item_id)
  }
 
  set_current_location(){
    localStorage.removeItem('current_location')
    this._locationService.set_current_location().then(is_set_location=>{
      if(!is_set_location){
        this._notifierService.showNotification('error','Please Enable Location');
      }
    })
  }

  goToCheckout(){
    this.helper._route.navigate(['/checkout']);
  }


  // Auth Flow 

  signIn(){
    if(this.loginForm.invalid){
      this.loginForm.markAllAsTouched();
      return;
    }
    this._authService.user_login({username:this.loginForm.value.username,password:this.loginForm.value.password,uuid:this.helper.cart_unique_token}).then(islogin=>{
      if(islogin){
        $(".signinclose").click();
        this.loginForm.reset();
      }
    });
  }

  signOut(){
    this._authService.user_logout();
    this.helper._route.navigate(['/'])
  }

  openModel(id){
    $(".custom-model-main").removeClass('model-open');
    $("body").addClass('model-open');
    $('#'+id).addClass('model-open');
  }

  signUp(){

      if(this.signupForm.invalid){
          this.signupForm.markAllAsTouched();
          return;
      }    
      
      if(this.setting_data.is_user_mail_verification || this.setting_data.is_user_sms_verification){
        this._authService.user_generate_otp({
          email:this.signupForm.value.email,
          phone:this.signupForm.value.phone,
          country_phone_code:this.signupForm.value.country_code
        }).then(otpData=>{
          if(otpData.success){
            this.verificationData = {email_otp: otpData.otp_for_email,sms_otp:otpData.otp_for_sms}
            this.verificationForm.reset();
            $(".custom-model-main").removeClass('model-open');
            $("body").addClass('model-open');
            $('#verification-popup').addClass('model-open');
          }
        })
      }else{
        this.register();
      }
      
  }

  resendOtp(){
    if(this.setting_data.is_user_mail_verification || this.setting_data.is_user_sms_verification){
      this._authService.user_generate_otp({email:this.signupForm.value.email,phone:this.signupForm.value.phone,country_phone_code:this.signupForm.value.country_code}).then(otpData=>{
        if(otpData.success){
          this.verificationData = {email_otp: otpData.otp_for_email,sms_otp:otpData.otp_for_sms}
        }
      })
    }
  }

  verifyUser(){
    if(!this.verification_type){
        return;
    }

    this.verification_error = ""
    var is_sms_verified = false;
    var is_email_verified = false;

    if(!this.setting_data.is_user_sms_verification || this._authService.user.is_email_verified){
      this.verificationForm.patchValue({sms_otp:"123456"})
      is_sms_verified = true;
    }
    
    if(!this.setting_data.is_user_mail_verification || this._authService.user.is_email_verified){
      this.verificationForm.patchValue({email_otp:"123456"})
      is_email_verified = true;      
    }
    
    if(this.verificationForm.invalid){
      this.verificationForm.markAllAsTouched();
      return;
    }
    
    if(!is_sms_verified){
      is_sms_verified = this.verificationData.sms_otp === this.verificationForm.value.sms_otp;
    }
    if(!is_email_verified){
      is_email_verified = this.verificationData.email_otp === this.verificationForm.value.email_otp;
    }

    if(is_sms_verified && is_email_verified){
      // $('#verificationclose').click()
      $(".custom-model-main").removeClass('model-open');
      $("body").removeClass('model-open');
      $("body").removeClass('model-open');
      $('#verification-popup').removeClass('model-open');
      switch (this.verification_type) {
        case VERIFICATION_TYPE.REGISTER:
          this.register();
          this.verification_type = null;
          break;
        case VERIFICATION_TYPE.AUTH:
          var json = {};
            json['user_id'] = this._authService.user._id;
            json['server_token'] = this._authService.user.server_token;
            
            if(this.setting_data.is_user_mail_verification){
            json['email'] = this._authService.user.email
            json['is_email_verified'] = true;
          }

          if(this.setting_data.is_user_sms_verification){
            json['phone'] = this._authService.user.phone
            json['is_phone_number_verified'] = true
          }
          this._authService.user_verify_otp(json);
          
          this.verification_type = null;
          break;  
        default:
          this.verification_type = null;
          break;
      }
    }else{
      this.verification_error = "title.verification-error";
    }

  }

  private register(){

      const signUpForm = new FormData();
      signUpForm.append('first_name',this.signupForm.value.first_name);
      signUpForm.append('last_name',this.signupForm.value.last_name);
      signUpForm.append('email',this.signupForm.value.email);
      signUpForm.append('phone',this.signupForm.value.phone);
      signUpForm.append('country_phone_code',this.signupForm.value.country_phone_code);
      signUpForm.append('country_code',this.signupForm.value.country_code);
      signUpForm.append('currency',this.signupForm.value.currency);
      signUpForm.append('password',this.signupForm.value.password);
      signUpForm.append('cart_unique_token',this.signupForm.value.cart_unique_token);
      signUpForm.append('referral_code',this.signupForm.value.referral_code);
      signUpForm.append('city',this.signupForm.value.city);
      signUpForm.append('login_by',this.signupForm.value.login_by);
      signUpForm.append('address',this.signupForm.value.address);
      signUpForm.append('is_phone_number_verified','false');
      signUpForm.append('is_email_verified','false');
      
      if(this.signupForm.value.social_id){
        signUpForm.append('social_id',this.signupForm.value.social_id);
      }

      this._authService.user_register(signUpForm).then(is_register=>{
        if(is_register){
          $('#signup-close').click()
          this.signupForm.reset();          
        }
      })
  }

  changeCountry(event){
    const idx = this.countries.findIndex(_c=>_c.country_code === event)
    if(idx !== -1){
      this.signupForm.patchValue({
        country_code:this.countries[idx].country_code,
        country_phone_code:this.countries[idx].country_phone_code,
        currency:this.countries[idx].currency_code,
      })
    }

  }

  checkReferral(){
    this.referral_error = "";
    var referral_code = this.signupForm.value.referral_code;
    var country_code = this.signupForm.value.country_code;
    this._commanService.check_referral(country_code,referral_code).then(referral_applied=>{
      if(referral_applied){
        this.is_referral_applied = true;        
      }else{
        this.referral_error = "title.referral-is-not-valid";
      }
    });
  }

  // Social Login / Register

  signInWithGoogle(): void {
    this._socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(user=>{
      if(user){
        this._authService.user_login({
          social_id:user.id,
          password:'',
          username:'',
          login_by:'social',
          uuid:this._cartService.cart_unique_token
        }).then(islogin=>{
          if(islogin){
            $(".signinclose").click();
            this.loginForm.reset();
          }
        });
      }
    })
  }

  signInWithFB(): void {
    this._socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(user=>{
      if(user){
        this._authService.user_login({
          social_id:user.id,
          login_by:'social',
          password:'',
          username:'',
          uuid:this._cartService.cart_unique_token
        }).then(islogin=>{
          if(islogin){
            $(".signinclose").click();
            this.loginForm.reset();
          }
        });
      }
    })
  }
  
  registerWithGoogle() {
    this._socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(user=>{
      if(user){
        this.signupForm.patchValue({
          first_name: user.firstName,
          last_name:user.lastName,
          social_id: user.id,
          login_by: 'social',
          email: user.email
        })
      }
    })
  }
  
  registerWithFB(){
    this._socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(user=>{
      if(user){
        this.signupForm.patchValue({
          first_name: user.firstName,
          last_name:user.lastName,
          social_id: user.id,
          login_by: 'social',
          email: user.email
        })
      }
    })
  }

  // Forgot Password Flow

  forgotPassword(){
    if(this.forgotPasswordForm.invalid){
      this.forgotPasswordForm.markAllAsTouched();
      return;
    }
    var phone = this.forgotPasswordForm.value.phone;
    this.helper._loader.isLoading = true;
    this._authService.user_forgot_password({phone:this.forgotPasswordForm.value.phone}).then(isForgot=>{
      $(".forgotclose").click();
      this.forgotPasswordForm.reset();
      if(isForgot){
        this.otpForm.patchValue({
          phone:phone
        })
        $(".custom-model-main").removeClass('model-open');
        $("body").addClass('model-open');
        $('#otp-verification-popup').addClass('model-open');
      }
      this.helper._loader.isLoading = false;
    });
  }

  forgotPasswordVerify(){
    if(this.otpForm.invalid){
      this.otpForm.markAllAsTouched();
      return;
    }
    this.helper._loader.isLoading = true;
    this._authService.user_forgot_password_verify({phone:this.otpForm.value.phone,otp:this.otpForm.value.otp.toString()}).then(verifyResponse=>{
      if(verifyResponse.success){
        $("#otpclose").click();
        this.otpForm.reset();
        this.resetPasswordID = verifyResponse.id
        this.resetPasswordToken = verifyResponse.server_token
        $(".custom-model-main").removeClass('model-open');
        $("body").addClass('model-open');
        $('#set-password-popup').addClass('model-open');
      }
      this.helper._loader.isLoading = false;
    });
  }

  resetPassword(){
    if(this.resetPasswordForm.invalid){
      this.resetPasswordForm.markAllAsTouched();
      return;
    }
    this.password_error = '';
    if(this.resetPasswordForm.value.password !== this.resetPasswordForm.value.confirm_password){
      this.password_error = "title.password-not-match";
      return;
    }

    this._authService.user_reset_password({
      password:this.resetPasswordForm.value.password,
      id:this.resetPasswordID,
      server_token:this.resetPasswordToken
    }).then(isSuccess=>{
      $("#set-password-popup-close").click();
      this.resetPasswordForm.reset();      
    });
  }

  resendPassword(){
    this.helper._loader.isLoading = false;
    var phone = this.otpForm.value.phone;
    this._authService.user_forgot_password({phone}).then(()=>{
      this.helper._loader.isLoading = false;
    })
  }

  // Clear App Data

  ngOnDestroy(){
    if(this.userSubscription){
      this.userSubscription.unsubscribe();
    }
    if(this.cartSubscription){
      this.cartSubscription.unsubscribe();
    }
  }

}
