import { Component, OnInit } from '@angular/core';
import { CommonService } from './services/common.service';
import { Helper } from './shared/helper';
import { HeaderComponent } from './views/layout/header/header.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers:[HeaderComponent]
})
export class AppComponent {
  constructor(private _helper: Helper,private _commanService:CommonService){}


  onActivate(){
    this._helper.loadScript('/assets/js/custom.js')
    window.scroll(0,0);
    // this._commanService.is_location_show = this._helper._route.url.includes('/');
    this._commanService.is_location_show = this._helper._route.url === '/';
  }



}
