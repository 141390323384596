export class SetttingModel {
    admin_contact_email: string = null;
    admin_contact_phone_number: string = null;
    app_name: string = null;
    _id: string = null;
    is_user_sms_verification:boolean = false;
    is_user_mail_verification:boolean = false;
    is_allow_contactless_delivery:boolean= false;
    is_user_login_by_social: boolean =false;
    is_use_aws_bucket: boolean =false;
    tip_type:number = 0;

    
    // access_key_id: ""
    // admin_base_url: "https://adminedelivery.appemporio.net/"
    // admin_country: "India"
    // admin_currency: "₹"
    // admin_currency_code: "INR"
    // admin_email: "info@edelivery.com"
    // admin_name: "Admin"
    // admin_panel_timezone: "Asia/Kolkata"
    // admin_phone_number: "9999999999"
    // admin_time_zone: "Asia/Kolkata"
    // auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs"
    // auth_uri: "https://accounts.google.com/o/oauth2/auth"
    // aws_bucket_name: ""
    // client_email: "firebase-adminsdk-xet3o@store-157305.iam.gserviceaccount.com"
    // client_id: "109183974811548927250"
    // client_x509_cert_url: "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-xet3o%40store-157305.iam.gserviceaccount.com"
    // created_at: "2017-06-13T11:50:41.408Z"
    // databaseURL: "https://store-157305.firebaseio.com"
    // default_search_radius: 500000
    // is_allow_pickup_order_verification: false
    // is_allow_user_to_give_tip: true
    // is_apply_image_settings: true
    // is_ask_time_when_accept_order: false
    // is_busy: false
    // is_confirmation_code_required_at_complete_delivery: false
    // is_confirmation_code_required_at_pickup_delivery: false
    // is_email_id_field_required_in_provider_register: true
    // is_email_id_field_required_in_store_register: true
    // is_email_id_field_required_in_user_register: true
    // is_mail_notification: true
    // is_phone_field_required_in_provider_register: true
    // is_phone_field_required_in_store_register: true
    // is_phone_field_required_in_user_register: true
    // is_provider_earning_add_in_wallet_on_cash_payment: false
    // is_provider_earning_add_in_wallet_on_other_payment: false
    // is_provider_login_by_email: true
    // is_provider_login_by_phone: true
    // is_provider_login_by_social: false
    // is_provider_mail_verification: false
    // is_provider_profile_picture_required: true
    // is_provider_sms_verification: false
    // is_push_notification: true
    // is_referral_to_provider: false
    // is_referral_to_store: false
    // is_referral_to_user: true
    // is_show_optional_field_in_provider_register: true
    // is_show_optional_field_in_store_register: true
    // is_show_optional_field_in_user_register: false
    // is_sms_notification: true
    // is_store_earning_add_in_wallet_on_cash_payment: false
    // is_store_earning_add_in_wallet_on_other_payment: false
    // is_store_login_by_email: true
    // is_store_login_by_phone: true
    // is_store_login_by_social: false
    // is_store_mail_verification: false
    // is_store_profile_picture_required: true
    // is_store_sms_verification: false
    // is_upload_provider_documents: false
    // is_upload_store_documents: false
    // is_upload_user_documents: false
    // is_user_login_by_email: true
    // is_user_login_by_phone: true
    // is_user_profile_picture_required: false
    // lang: [{name: "English", code: "en", string_file_path: "./uploads/i18n/en.json"},…]
    // no_of_loop_for_send_request: 0
    // number_of_try_for_scheduled_request: 0
    // private_key: "-----BEGIN PRIVATE KEY-----↵MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDKLJKMXLoZqht6↵2iBmTiw2F9N1t0GpiSQH3/3O3M2nl+VQc7djOUPdASykphN5au2f6j7C6rjW/mvF↵2jh5TRd74kCnG6e1JsHL+XmVq/d99dY9uWcsPBpMANoeyV/762RIh3XEU96UGslM↵xtiTDcZfR101NOWc9Bo3n5vTImNaVTSXOLuB6bHnBzn+yQNxl08todRTHHu8lMJU↵k3ioi8uiOOKnrGWNLqyXNc1k6LXVrLWfvgFPmLmblBdU59RaIvJc7z9Djq14/ps2↵3jdTxeO1tGzrmbQyWwI0yylT6s1FkJNs8rUb12sXxC79nHCis9D48rSAjv8pqYCS↵VM1nd+O1AgMBAAECggEAAOJB/8AWBi+ysA5NDvVvhwsCnySecl3JnQkzJFjdw0pM↵M9ZVqmbhL5GmPtAFDMZVZJoTDSoWhcYV9Yb3MTyPijiafMCHsqiJN1mPWQKn8dKF↵zb5GP7DBkqx8R58svr6wMQr6jlGpiu81CxNhdf8c62S1+RoSmOWOScj2Vu1zDr2H↵oFu7BNvAerVJOwxX+QD4XNYPC+ozRNgiKeXommPd8JFkc+M90cJ8TW/84K/Sc4Rn↵KZd88FIJJErleFC+ZaLt5heI736w/WDDVvEuiXlzPZk3zX0Z3h5ppBV2J2oj7olb↵JUJCWTeH6UIW/J89DQHp+G5wlj76lnc+PWFX86GtoQKBgQDq2tLb+++FZNSwY63m↵/YFW6rTWbrIKoyFKHfzGcGhWSuM3t2fNmlyvgryIDXThIGTFdZoeyNSH5q9pS+YJ↵jvzx8QZ2cC3NAIZF42Fr8J7JIdY3chMOuYc+ccOipc2XlW5JXpP/I/BnbSBS/dCU↵UiJo8vWU8O+WgperlsegsSXL5QKBgQDcYH2pYnC2mL7g39ehR+gSWONEgXVyZmIJ↵1SC/WwNxYN3qIT1CnTbnQcj2G2gXa7GplFZXwGw2xkiFovPAn/eBhPzdWb5ECeRB↵7n9+Zy9mfDnioylmR+DvjG6EjpO9y5ObRBFSznXd4SU0RUqW2GsA9Rd3aLHf/XKZ↵AUj4A/FbkQKBgFAKxFK1Sqzr8JEtZ9eSDsLQu1FZ6NQ3ROE91yjKpsTCQS58E6S/↵N1eZ9ydnbq5LnAywSBa127l1pdL9Syl20j6jJtC26uUziI/JLyral0N1hMzg1B3w↵nWdq3oJQgAFprGzVDxul5Alb5+hhGPV0CMY8B/b7JqzlVUvl16OsvHUBAoGBAMaT↵s+fjqkj/DlC3oyfTEY0mx3M9bEUveHv/rkh/jHSnoo+McBUMcSKlJp6NR5iDFE4I↵Ol1MKLaaqVKx6Q7TlkUBPpF+gnHwr9uxOvT9qbqhic9s0qs1bbytQBD2IK7POCya↵gzmojJDohYu6PGH8lT8Bmqmgj1eUm6S/ga7qbpvhAoGBAJyig6B8U7TDQI2Cf+sQ↵gz2n+BFWiVRBPnq1PqWNySrM8etR+5Pv3UVsK6b6GIzpp23MdiMfAnnUDgTG06Wu↵nthop31pHtxoMmbvsDGNK4/L+pwwPXvLBl2NxLmEaiq74HXH+ihpOGrH6CoCAIWe↵Zf0fZ9/UPoBEnV5DQMbDNjqa↵-----END PRIVATE KEY-----↵"
    // private_key_id: "f23eaee1502600bca836a76a535df372850c02bb"
    // project_id: "store-157305"
    // provider_timeout: 50
    // scheduled_request_pre_start_minute: 30
    // secret_key_id: ""
    // sms_gateway_id: "58760b3c2427bcc6ca779e46"
    // store_base_url: "https://storeedelivery.appemporio.net/"
    // store_timeout: 0
    // tip_type: 1
    // token_uri: "https://oauth2.googleapis.com/token"
    // type: "service_account"
    // updated_at: "2021-03-05T12:10:56.691Z"
    // user_base_url: "https://webappedelivery.appemporio.net/"
}