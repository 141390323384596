import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { environment } from 'src/environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FooterComponent} from './views/layout/footer/footer.component';
import {HeaderComponent} from './views/layout/header/header.component';
import { LandingComponent } from './views/landing/landing.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CheckoutComponent } from './views/checkout/checkout.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AccountDetailComponent } from './views/account-detail/account-detail.component';
import { OrderDetailComponent } from './views/order-detail/order-detail.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomePageComponent } from './views/home-page/home-page.component';
import { WalletHistoryComponent } from './views/wallet-history/wallet-history.component';
import { FavouritesListComponent } from './views/favourites-list/favourites-list.component';
import { HelpComponent } from './views/help/help.component';
import { ConfigService } from './services/config.service';
import { LoaderComponent } from './views/layout/loader/loader.component';
import { PipeModule } from './pipes/pipe.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ResInterceptInterceptor } from './interceptor/res-intercept.interceptor';
import { Cart } from './models/cart.model';
import { CourierOrderComponent } from './views/courier-order/courier-order.component';
import {TabsModule} from 'ngx-tabset';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {GoogleLoginProvider,FacebookLoginProvider} from 'angularx-social-login';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireModule } from '@angular/fire';
import { CONFIG } from './constants/constants';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ToastrModule} from 'ngx-toastr';

function load(config: ConfigService): (() => Promise<boolean>) {
	return (): Promise<boolean> => {
	  return new Promise<boolean>((resolve: (a: boolean) => void): void => {
		config._initApp().then(()=>{
			resolve(true)
		})
	  });
	};
}


@NgModule({
	declarations:[
		AppComponent,
		HeaderComponent,
		FooterComponent,
		LandingComponent,
		LoaderComponent,
		CheckoutComponent,
		AccountDetailComponent,
		OrderDetailComponent,
		HomePageComponent,
		WalletHistoryComponent,
		FavouritesListComponent,
		HelpComponent,
		CourierOrderComponent,
	],
	imports:[
		HttpClientModule,
		BrowserModule,
		ReactiveFormsModule,
		InfiniteScrollModule,
		AppRoutingModule,
		NgbModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 1000,
      progressBar: true,
      progressAnimation: 'increasing',
    }),
		SocialLoginModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (http: HttpClient) => new TranslateHttpLoader(http, 'assets/languages/', '.json'),
				deps: [HttpClient]
			}
		}),
		PipeModule,
		AngularFireMessagingModule,
		AngularFireDatabaseModule,
		AngularFireModule.initializeApp(CONFIG.FIREBASE_CONFIG),
    	TabsModule.forRoot(),
		NgSelectModule,
    	FormsModule
	],
	providers:[
		{
		provide: 'SocialAuthServiceConfig',
		useValue: {
			autoLogin: false,
			providers: [
			{
				id: GoogleLoginProvider.PROVIDER_ID,
				provider: new GoogleLoginProvider('1062107977876-2ch5ulrgsv7p38qinc68v7rl73kv57fd.apps.googleusercontent.com')
			},
			{
				id: FacebookLoginProvider.PROVIDER_ID,
				provider: new FacebookLoginProvider('601526146700337')
			}
			]
		} as SocialAuthServiceConfig,
		},
		Cart,
		TranslateService,
		{
			provide: APP_INITIALIZER,
			useFactory: load,
			deps: [ConfigService],
			multi: true
		},
		{
			provide:HTTP_INTERCEPTORS,
			useClass:ResInterceptInterceptor,
			multi:true
		}
	],
	bootstrap:[AppComponent]
})
export class AppModule {
}
