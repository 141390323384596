import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ALERT_TYPE, Helper } from 'src/app/shared/helper';
import { LocationService } from 'src/app/services/location.service'
import { environment } from 'src/environments/environment';
import { CartService, DELIVERY_TYPE } from 'src/app/services/cart.service';
import { AuthService } from 'src/app/services/auth.service';
import { OrderPayment } from 'src/app/models/order_payment';
import { cartAddress } from 'src/app/models/cart.model';
import { Subscription } from 'rxjs';
import { PaymentService } from 'src/app/services/payment.service';
import { Card } from 'src/app/models/card.model';
import { CONFIG, DEFAULT_IMAGE, PAYMENT_GATEWAY } from 'src/app/constants/constants';
import { CommonService } from 'src/app/services/common.service';
import * as moment from 'moment-timezone';
import { StoreModel } from 'src/app/models/store.model';
import { rejects } from 'assert';
import { NotifiyService } from 'src/app/services/notifier.service';

export var WEEK_DAY = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday"
};

declare var $: any;
declare var google: any;
declare var stripe: any;
declare var elements: any;


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit, OnDestroy {

  userDetailForm: FormGroup;
  IMAGE_BASE_URL: any = CONFIG.IMAGE_URL

  // Order Payment Data
  cartSubscription: Subscription;
  loginSubscription: Subscription;
  order_payment_details: OrderPayment = new OrderPayment();
  total_items_in_user_cart = 0;
  address_error = this.helper.trans.instant("label-title.select-address");
  items_in_user_cart = [];
  map: any = null;
  delivery_location: any[] = [];
  store_marker: any = null;
  tip_amount = 0;
  tip: any = null;
  google_distance = 0;
  google_time = 0;

  // Promo Data
  promo_applied = false;
  promocode = '';
  promo_codes = [];
  promo_bonus: number = 0;
  busy_period_amount: number = 0;
  delivery_time: number = 0;

  // Payment Data
  wallet = 0;
  wallet_currency_code: string = '';
  cards: Array<Card> = [];
  selectedcard: Card = null;
  selected_payment_gateway = 'cash';
  is_cash_payment_mode = false;
  is_card_payment_mode = false;
  isCutlery = false;
  is_use_wallet = true;
  is_login = false;
  card;
  cardHandler = this.onChange.bind(this);
  card_error: string;
  amount_error: string;
  isLoading = false;
  payment_id;

  // Schedule Order Data
  schedule_date: any = '';
  date_array: any[] = [];
  dateIndex: number = 0;
  store_time: any[] = [];
  time_array: any[] = [];
  slot1: Boolean = false;
  slot2: Boolean = false;
  slot3: Boolean = false;
  slot4: Boolean = false;
  schedule_time_error: Boolean = false;
  is_taking_schedule_order: Boolean = false;
  store_close: Boolean = false;
  current_close: Boolean = true;
  currentDate = new Date();
  clicked_date: any = null;
  selected_time: any = null;
  schedule_time: string = '';
  store: StoreModel = new StoreModel();
  store_open_day: string = '';
  open_at = "";
  timezone = 'Asia/Kolkata';
  moment = moment();
  is_schedule_order: Boolean = false;
  DEFAULT_PROMO_IMAGE = DEFAULT_IMAGE.ADS;
  delivery_note:string = '';
  is_allow_contactless_delivery = false;
  tip_type = 0;

  @ViewChild('address', { static: true }) address: ElementRef;
  @ViewChild('cardInfo') cardInfo: ElementRef;

  constructor(public helper: Helper,
    private _notifierService:NotifiyService,
    private _authService: AuthService,private paymentService: PaymentService,public _commonService: CommonService,private cd: ChangeDetectorRef,public _locationService: LocationService,private _paymentService: PaymentService,public _cartService: CartService) { }

  ngOnDestroy(): void {
    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }
  }

  onChange({ error }) {
    if (error) {
      this.card_error = error.message;
    } else {
      this.card_error = null;
    }
    this.cd.detectChanges();
  }

  ngOnInit(): void {
    this.tip_type = this._commonService.settings.tip_type;
    this._initAutoComplete()
    this._initForm();
    this.validateUserData()
    this._initPromocodes();

    this.loginSubscription = this._authService.loginObservable.subscribe((user) => {
      this.validateUserData();
      if (user !== null) {
        // Init Payment Gateway
        this.paymentService.get_payment_gateway(this._locationService.current_location, this._authService.user._id, this._authService.user.server_token, this._cartService.user_cart.cart_data.cart_id).then(paymentGatewayData => {
          this.is_cash_payment_mode = paymentGatewayData.is_cash_payment_mode;
          const idx = paymentGatewayData.payment_gateway.findIndex(_p => _p.name.toLowerCase() === PAYMENT_GATEWAY.STRIPE)
          this.is_card_payment_mode = idx !== -1
          this.wallet = paymentGatewayData.wallet
          this.wallet_currency_code = paymentGatewayData.wallet_currency_code
          if (this.is_card_payment_mode) {
            this.payment_id = paymentGatewayData.payment_gateway[idx]._id;
            this._initCards();
          }
        })

        // Update Destination Address
        if (this._cartService.user_cart.cart_data.destination_addresses.length) {
          this.setMap();
        }

      } else {
        this.resetCheckout()
      }


    })



    this.cartSubscription = this._cartService.cartObservable.subscribe(async (cart) => {

      if (cart && cart.cart_data.cart.length) {
        this.store.busy_premium_time = cart.busy_premium_time;
        this.timezone = cart.time_zone;
        this.store.store_delivery_time = cart.store_delivery_time;
        this.store.store_time = cart.store_time;
        this.store.is_busy_period = cart.is_busy_period;
        this.delivery_note = cart.cart_data.destination_addresses[0].note
        if (this.google_time === 0 && this.google_distance === 0) {
          await this.get_distnce_time()
        }
        this.get_order_cart_invoice(this.google_distance, this.google_time);
        this.total_items_in_user_cart = 0;
        this.items_in_user_cart = [];
        cart.cart_data.cart.forEach(element => {
          this.total_items_in_user_cart = this.total_items_in_user_cart + element.items.length
          this.items_in_user_cart.push(element)
        });
        this.userDetailForm.patchValue({
          user_name: this._cartService.user_cart.cart_data.destination_addresses[0].user_details.name,
          user_phone_number: this._cartService.user_cart.cart_data.destination_addresses[0].user_details.phone,
          apartment: this._cartService.user_cart.cart_data.apartment,
          building_name: this._cartService.user_cart.cart_data.building_name,
          business_name: this._cartService.user_cart.cart_data.business_name,
          option_for_delivery: this._cartService.user_cart.cart_data.option_for_delivery,
        })
      } else {
        this.helper._route.navigate(['/']);
        // this.order_payment_details = new OrderPayment();
      }
    })

  }

  _initPromocodes() {
    var store_id = localStorage.getItem('cartStoreId');
    if (store_id) {
      this._commonService.get_store_detail(this._cartService.user_cart.cart_data.selectedStoreId).then(response => {
        if (response.success) {
          this.store = response.store_detail;
          this.is_taking_schedule_order = response.store_detail.is_taking_schedule_order;
          this.promo_codes = response.promo_codes;
          this.timezone = response.timezone;
          this.lang_change_month_day();
          this.delivery_time = this.store.default_prep_time;
          let date: any = this._cartService.user_cart.server_time;
          if (!this._cartService.user_cart.is_schedule_order) {
            date = new Date(date).toLocaleString("en-US", { timeZone: this.timezone })
            date = new Date(date);
          }
          if (this._cartService.user_cart.is_schedule_order) {
            this.check_prepataion_period()
          }else{
            this.check_prepataion_open(date, true);
          }
          console.log(this.delivery_time);
        }
      })
    }
  }

  _initCards() {
    return new Promise((resolve,rejects)=>{
      this.paymentService.get_card_list({ user_id: this._authService.user._id, server_token: this._authService.user.server_token }).then(cards => {
        this.helper.ngZone.run(()=>{
          this.cards = cards;
        if (this.cards.length) {
          const idx = this.cards.findIndex(_c => _c.is_default);
          if (idx !== -1) {
            this.selectedcard = cards[idx];
            resolve(true)
          }else{
            this.selectDefault(this.cards[0]._id)
            this.selectedcard = cards[0];
          }
        }
        })
      })
    })
  }

  validateUserData() {
    this.is_use_wallet = this._authService.user !== null ? this._authService.user.is_use_wallet : false;
    this.is_login = this._authService.user !== null;
  }

  _initForm() {
    this.userDetailForm = new FormGroup({
      user_name: new FormControl('', Validators.required),
      building_name: new FormControl(''),
      business_name: new FormControl(''),
      apartment: new FormControl(''),
      option_for_delivery: new FormControl(0),
      user_phone_number: new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(12)])
    })
  }

  removeCard(card_id) {
    this._paymentService.delete_card({ card_id }).then(is_deleted => {
      if (is_deleted) {
        this._initCards()
      }
    })
  }

  onClickAddCard() {
    this.helper._loader.isLoading = true;
    setTimeout(() => {
      this.card = elements.create('card');
      this.card.mount(this.cardInfo.nativeElement);
      this.card.addEventListener('change', this.cardHandler);
      $(".custom-model-main").removeClass('model-open');
    $("body").addClass('model-open');
      $('#add-new-card').addClass('model-open')
      this.helper._loader.isLoading = false;
    }, 1000);
  }

  openModel(model_id:string){
    $(".custom-model-main").removeClass('model-open');
    $("body").addClass('model-open');
    $('#'+model_id).addClass('model-open')
  }

  closeModel(id:string){
    $('#'+id).click()
  }

  async addCard() {
    this.isLoading = true;
    const { token, error } = await stripe.createToken(this.card)
    if (error) {
      alert(error);
    } else {
      var expiry_month = token.card.exp_month;
      var expiry_year = token.card.exp_year;

      if (this.payment_id) {
        this._paymentService.get_stripe_add_card_intent({ payment_id: this.payment_id }).then(client_secret => {
          if (client_secret) {
            stripe.handleCardSetup(client_secret, this.card, {
              payment_method_data: {
                billing_details: {}
              }
            }
            ).then((result) => {
              if (result.error) {
                this.card.clear();
                this.card.removeEventListener('change', this.cardHandler);
                this.card.destroy();
                this.card_error = result.error.message;
                this._notifierService.showNotification('error', this.card_error);
                this.isLoading = false;
              } else {
                this._paymentService.add_card({
                  payment_id: this.payment_id,
                  payment_method: result.setupIntent.payment_method,
                  user_id: this._authService.user._id,
                  server_token: this._authService.user.server_token,
                  expiry_month: expiry_month,
                  expiry_year: expiry_year
                }).then(is_card_added => {
                  this.closeAddCardModel()
                  if (is_card_added) {
                    this._initCards()
                  } else {
                  }
                })
              }
            });
          }
        })
      }
    }
  }

  get_order_cart_invoice(total_distance = 0, total_time = 0) {
    if(this.store && !this.store.is_provide_pickup_delivery && this._cartService.user_cart.is_user_pick_up_order){
      this.helper.open_dialog(ALERT_TYPE.WARNING,"Alert","Store Not Provide Pickup Delivery..");
      setTimeout(() => {
        this._cartService.user_cart.is_user_pick_up_order = false;
      }, 1000);
    }
    let start_time= Number(Date.now());
    let end_time= Number(Date.now());
    let date: any = this._cartService.user_cart.server_time;
      if (this._cartService.user_cart.is_schedule_order) {
        this.check_valid_time()
      } else {
        date = new Date(date).toLocaleString("en-US", { timeZone: this.timezone })
        date = new Date(date);
        this.check_open(date, true);
      }
    this.busy_period_amount = 0;

    if(this.store.is_busy_period){
      if (this._cartService.user_cart.is_schedule_order) {
        this.check_busy_period()
      }else{
        this.check_busy_open(date, true);
      }
    }

    this._cartService.get_order_cart_invoice(total_distance, total_time, this.tip_amount,null,null,this.busy_period_amount).then(res_data => {
      if(res_data.success){        
        this._cartService.user_cart.cart_data.selectedStoreId = res_data['data']['store']._id;
        this.order_payment_details = res_data.data.order_payment;
      }else{
        this.order_payment_details =  new OrderPayment();
      }
    })
  }
  check_busy_open(selected_date, today){
    var date: any = JSON.parse(JSON.stringify(selected_date));
    date = new Date(date)

    let weekday = date.getDay();
    let current_time = date.getTime();
    
    if(!this.store.busy_premium_time.length){
      return;
    }
    let week_index = this.store.busy_premium_time.findIndex((x) => x.day == weekday)
    let day_time = this.store.busy_premium_time[week_index].day_time;
   
              day_time.forEach((store_time, index) => {

                  let x = date.setHours(Math.floor( store_time.store_open_time / 60), store_time.store_open_time % 60, 0, 0)
                  let x1 = new Date(x);
                  let x2 = x1.getTime();

                  let y = date.setHours(Math.floor( store_time.store_close_time / 60), store_time.store_close_time % 60, 0, 0)
                  let y1 = new Date(y);
                  let y2 = y1.getTime();
                  
                  if (current_time > x2 && current_time < y2) {
                     this.busy_period_amount = store_time.value;
                  }
              });
  }
  check_busy_period(){
   
    let server_date: any = new Date(this._cartService.user_cart.server_time);
    server_date = new Date(server_date).toLocaleString("en-US", { timeZone: this.timezone })
    server_date = new Date(server_date);

    let selected_date: any = this._cartService.user_cart.schedule_date

    let day_diff = selected_date.getDay() - server_date.getDay();
    let timeDiff = Math.round(selected_date.getTime() - server_date.getTime());

    if (timeDiff / 60000 >= 30) {
      
        if (day_diff > 0) {
            this.check_busy_open(selected_date, false);
        } else {
            this.check_busy_open(selected_date, true);
        }

    }
  }
  check_prepataion_open(selected_date, today){
    var date: any = JSON.parse(JSON.stringify(selected_date));
    date = new Date(date)

    let weekday = date.getDay();
    let current_time = date.getTime();
    
    if(!this.store.prep_time.length){
      return;
    }
    let week_index = this.store.prep_time.findIndex((x) => x.day == weekday)
    let day_time = this.store.prep_time[week_index].day_time;
   
              day_time.forEach((store_time, index) => {

                  let x = date.setHours(Math.floor( store_time.store_open_time / 60), store_time.store_open_time % 60, 0, 0)
                  let x1 = new Date(x);
                  let x2 = x1.getTime();

                  let y = date.setHours(Math.floor( store_time.store_close_time / 60), store_time.store_close_time % 60, 0, 0)
                  let y1 = new Date(y);
                  let y2 = y1.getTime();
                   
                  if (current_time > x2 && current_time < y2) {
                     this.delivery_time = store_time.value;
                  }
              });
  }
  check_prepataion_period(){
   
    let server_date: any = new Date(this._cartService.user_cart.server_time);
    server_date = new Date(server_date).toLocaleString("en-US", { timeZone: this.timezone })
    server_date = new Date(server_date);

    let selected_date: any = this._cartService.user_cart.schedule_date

    let day_diff = selected_date.getDay() - server_date.getDay();
    let timeDiff = Math.round(selected_date.getTime() - server_date.getTime());

    if (timeDiff / 60000 >= 30) {
      
        if (day_diff > 0) {
            this.check_prepataion_open(selected_date, false);
        } else {
            this.check_prepataion_open(selected_date, true);
        }

    }
  }
  closeAddCardModel() {
    $('.addcardclose').click()
    this.isLoading = false;
  }

  setMap(lat=null,lng=null) {

    if(lat===null){
      lat  = this._cartService.user_cart.cart_data.destination_addresses[0].location[0];
      lat = lat === 0 ? this._locationService.current_location.latitude : lat
    }
    if(lng===null){
      lng = this._cartService.user_cart.cart_data.destination_addresses[0].location[1];
      lng = lng === 0 ? this._locationService.current_location.longitude : lat
    }

    this.map = new google.maps.Map(document.getElementById('map'), {
      zoom: 15,
      center: { lat, lng },
      draggable: true,
      zoomControl: true,
      scrollwheel: false,
      disableDoubleClickZoom: false,
      fullscreenControl: true
    });
    if (lat && lng) {
      this.store_marker = new google.maps.Marker({
        position: { lat, lng },
        map: this.map,
        draggable: true,
        icon: this.IMAGE_BASE_URL + 'map_pin_images/Admin/user_pin.png'
      });
      var user_location = new google.maps.LatLng(lat, lng);
      this.map.setCenter(user_location);
      // google.maps.event.addListener(this.store_marker, 'dragend', (marker) => {
      //   lat = marker.latLng.lat();
      //   lng = marker.latLng.lng();
      //   this.geocodePosition(marker.latLng.lat(), marker.latLng.lng());
      // });
    } else {
      // if (!this.store_marker) {
      //   this.store_marker.setMap(null);
      // }
    }
  }

  resetCheckout() {
    this.order_payment_details = new OrderPayment();
    this.total_items_in_user_cart = 0;
    this.delivery_location = [];
    this.store_marker = null;
    this.tip_amount = 0;
    this.tip = null;
    this.google_distance = 0;
    this.google_time = 0;
    this.promo_applied = false;
    this.promocode = '';
    this.wallet = 0;
    this.map = null;
    this.wallet_currency_code = '';
    this.selectedcard = null;
    this.selected_payment_gateway = 'cash';
    this.is_cash_payment_mode = false;
    this.is_card_payment_mode = false;
    this.is_use_wallet = true;
    this.is_login = false;
    this.items_in_user_cart = []
  }

  _initAutoComplete() {
    var autocompleteElm = this.address.nativeElement;
    let autocomplete = new google.maps.places.Autocomplete((autocompleteElm), { types: [] });
    autocomplete.addListener('place_changed', () => {
      var place = autocomplete.getPlace();

      var lat = place.geometry.location.lat();
      var lng = place.geometry.location.lng();
      var address = place['formatted_address'];
      this.setMap(lat,lng)

      this._cartService.update_address(lat, lng, address).then(is_update => {
        this.helper.ngZone.run(() => {
          if (is_update) {
            // Display
              this.address_error = ""
          }else{
            this.address_error = this.helper.trans.instant("label-title.delivery-not-available");
          }
        },);
      })



    });
  }

  closeupdateaddress() {
    $('#close-update-address').click()
  }

  geocodePosition(lat, lng) {
    var geocoder = new google.maps.Geocoder();
    let request = { latLng: new google.maps.LatLng(lat, lng) };
    geocoder.geocode(request, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        // this.order_delivery_address.address = results[0].formatted_address;
        // this.order_delivery_address.location = [lat, lng];
      }
      else {
      }
    });
  }

  apply_promo_code(promo_name = this.promocode, is_close = false) {
    if ((promo_name !== '' && this.promo_applied == false)) {
      this._cartService.check_promo(promo_name, this.order_payment_details._id).then(res_data => {
        if (res_data.success) {
          console.log(res_data)
          this.promo_applied = true;
          this.promo_bonus = res_data.data.order_payment.promo_payment
          if (is_close) {
            $('#view-offers-close').click();
          }
          this.order_payment_details = res_data.data.order_payment;
        } else {
          promo_name = '';
        }
      })
    }
  }

  openUserChange() {
    $(".custom-model-main").removeClass('model-open');
    $("body").addClass('model-open');
    $('#user-details').addClass('model-open');
    this.userDetailForm.patchValue({
      user_name: this._cartService.user_cart.cart_data.destination_addresses[0].user_details.name,
      user_phone_number: this._cartService.user_cart.cart_data.destination_addresses[0].user_details.phone,
      apartment: this._cartService.user_cart.cart_data.apartment,
      building_name: this._cartService.user_cart.cart_data.building_name,
      business_name: this._cartService.user_cart.cart_data.business_name,
      option_for_delivery: this._cartService.user_cart.cart_data.option_for_delivery,
    })
  }

  onUserDetail() {
    this.userDetailForm.markAsTouched();
    if (this.userDetailForm.valid && this._cartService.user_cart.cart_data.pickup_addresses.length) {
      this._cartService.user_cart.cart_data.destination_addresses[0].user_details.name = this.userDetailForm.value.user_name
      this._cartService.user_cart.cart_data.destination_addresses[0].user_details.phone = this.userDetailForm.value.user_phone_number
      this._cartService.user_cart.cart_data.apartment = this.userDetailForm.value.apartment;
      this._cartService.user_cart.cart_data.building_name = this.userDetailForm.value.building_name;
      this._cartService.user_cart.cart_data.business_name = this.userDetailForm.value.business_name;
      this._cartService.user_cart.cart_data.option_for_delivery = this.userDetailForm.value.option_for_delivery;
      this._cartService.update_address()
    }
    $(".custom-model-main").removeClass('model-open');
    $("body").removeClass('model-open');
  }

  increaseValue(product_id, item_id): void {
    this._cartService.increase_qty(product_id, item_id)
  }

  decreaseValue(product_id, item_id): void {
    this._cartService.decrease_qty(product_id, item_id)
  }

  get_distnce_time() {
    return new Promise((resolve,rejects)=>{
      let origin = { lat: parseFloat(this._cartService.user_cart.cart_data.pickup_addresses[0].location[0].toString()), lng: parseFloat(this._cartService.user_cart.cart_data.pickup_addresses[0].location[1].toString()) };
      let destination = { lat: parseFloat(this._cartService.user_cart.cart_data.destination_addresses[0].location[0].toString()), lng: parseFloat(this._cartService.user_cart.cart_data.destination_addresses[0].location[1].toString()) };
      let service = new google.maps.DistanceMatrixService;
      service.getDistanceMatrix({
        origins: [origin],
        destinations: [destination],
        unitSystem: google.maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false,
        travelMode: google.maps.TravelMode.DRIVING,
      }, (response, status) => {
        if (status == google.maps.DistanceMatrixStatus.OK) {
          this.google_distance = response.rows[0].elements[0].distance != undefined ? response.rows[0].elements[0].distance.value : 0;
          this.google_time = response.rows[0].elements[0].duration != undefined ? response.rows[0].elements[0].duration.value : 0;
        }
        resolve(true)
      });
    })
  }

  selectDefault(card_id) {
    this._paymentService.select_card({ card_id })
  }

  add_tip(amount) {
    if (this.tip_amount == amount) {
      amount = 0;
    }
    this.tip_amount = amount;
    this.tip = null;
    this.get_order_cart_invoice(this.google_distance, this.google_time);
  }

  add_tip1(amount) {
    this.tip_amount = amount;
    this.tip = amount;
    this.get_order_cart_invoice(this.google_distance, this.google_time);
  }

  change_user_wallet_status(event) {
    this._cartService.change_user_wallet_status(this.is_use_wallet)
  }

  place_order() {
    this.helper._loader.isLoading = true;
    var payment_mode_cash = this.selected_payment_gateway === PAYMENT_GATEWAY.CASH;
    var payment_id = payment_mode_cash ? 'cash' : this.payment_id;
    this._cartService.pay_order_payment(payment_mode_cash, this.order_payment_details._id, payment_id).then((res_data) => {
      if (res_data.success) {
        var is_payment_paid = res_data.data.is_payment_paid;
        if (is_payment_paid) {
          this.create_order_service();
        } else {
          var client_secret = res_data.data.client_secret;
          var payment_method = res_data.data.payment_method;
          stripe.confirmCardPayment(client_secret, { payment_method: payment_method }).then((result) => {
            if (result.error) {
            } else {
              this.create_order_service();
            }
          });
        }
      }else{
        this.helper._loader.isLoading = false;
      }
    })

  }

  create_order_service() {
    this.delivery_time = this.store.default_prep_time;
    let date: any = this._cartService.user_cart.server_time;
      if (!this._cartService.user_cart.is_schedule_order) {
        date = new Date(date).toLocaleString("en-US", { timeZone: this.timezone })
        date = new Date(date);
      }
    if (this._cartService.user_cart.is_schedule_order) {
        this.check_prepataion_period()
      }else{
        this.check_prepataion_open(date, true);
      }
    let selected_date = this._cartService.user_cart.schedule_date;
    let milisecond = 0;
    let milisecond1 = 0;
    if (this._cartService.user_cart.is_schedule_order) {
        milisecond = selected_date.getTime();
        // milisecond = (selected_date.getTime() - this.moment.tz(this.timezone).utcOffset() * 60000);
        if (this._cartService.user_cart.schedule_date1) {
            milisecond1 = this._cartService.user_cart.schedule_date1.getTime();
            // milisecond1 = (this._cartService.user_cart.schedule_date1.getTime() - this.moment.tz(this.timezone).utcOffset() * 60000);
        }
    }
    this._cartService.create_order(this.order_payment_details._id,milisecond,milisecond1,[],DELIVERY_TYPE.DELIVERY,this.delivery_note,this.is_allow_contactless_delivery,null,this.delivery_time,this.isCutlery).then(is_created => {
      if (is_created) {
        this.helper._loader.isLoading = false;
        // this.openModel('thankyou-popup');
        this.helper._route.navigate(['/']);
      }
    })
  }

  // Schedule Order

  set_schedule(bool) {
    if (bool) {
      if (this._cartService.user_cart.schedule_date) {
        this._cartService.user_cart.is_schedule_order = true;
        this._notifierService.showNotification('success', 'schedule-time-select-successfully');
        $('.schedule-form').css('display', 'none');
        setTimeout(() => {
          $('.schedule-form').css('display', '');
        }, 100);
      } else {
        this._notifierService.showNotification('error', 'please-select-time-for-schedule-order');
      }
    } else {
      this._cartService.user_cart.is_schedule_order = false;
      this._cartService.user_cart.schedule_date = null;
      this.selected_time = null;
    }
    this.get_order_cart_invoice(this.google_distance, this.google_time);
  }

  lang_change_month_day() {
    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let time_date = new Date();
    time_date.setHours(0, 0, 0, 0);
    for (var i = 0; i < 7; i++) {
      let date = new Date();
      date.setDate(date.getDate() + i);
      date = new Date(date);
      var day = this.helper.trans.instant(days[date.getDay()]);
      var month = this.helper.trans.instant(months[date.getMonth()]);
      var date_format = day + ', ' + month + ' ' + date.getDate();
      this.date_array[i] = { day: date.getDay(), date_format: date_format, isActive: false, date: date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() };
    }
    if (this.date_array.length > 0) {
      this.set_date(this.date_array[0]);
    }
    this.open_check();
  }

  set_date(date) {
    this.slot1 = false;
    this.slot2 = false;
    this.slot3 = false;
    this.slot4 = false;
    var diff1 = String(parseInt(moment().tz(this.timezone).format("ZZ")) - parseInt(moment(new Date()).format("ZZ")));
    var hour = "0";
    var minute = "0";
    if (diff1[0]) {
      hour = diff1[0];
    }
    if (diff1[1]) {
      if(diff1[1] == '7'){
        minute = 3 + diff1[2];
      }else{
        minute = diff1[1] + diff1[2];
      }      
    }
    var total_minute = ((Number(hour) * 60) + Number(minute)) * 60000;
    if (diff1[0] == "-") {
      total_minute = total_minute * -1;
    }
    var current_store_time = new Date(Number(new Date()) + total_minute);

    if (date == this.date_array[0]) {
      this.schedule_date = date.date;
    }
    this.clicked_date = date.date_format;
    let date_with_Delivery_time = new Date(new Date(Number(new Date()) + total_minute).getTime() + this.store.schedule_order_create_after_minute * 60000)
    this.date_array.forEach((date_detail) => {
      date_detail.isActive = false;
      if (date_detail.date == date.date) {
        date_detail.isActive = true;
      }
    });
    let checkSelectedDate = new Date(date.date);
    checkSelectedDate.setHours(23, 59, 59, 0);
    var diff = (checkSelectedDate.getTime() - new Date(Number(new Date()) + total_minute).getTime()) / 1000;
    diff /= 60;
    diff = Math.abs(Math.round(diff));

    var n = checkSelectedDate.getDay();

    let time_date = new Date(date.date);
    let time_date_close = new Date(date.date);
    var date1 = new Date(date.date);
    this.time_array = [];
    var i = 0;

    if(this._cartService.user_cart.is_user_pick_up_order == false){

      if (this.store.is_store_set_schedule_delivery_time == true && this.store.store_delivery_time[date.day].is_store_open == true && this.store.store_delivery_time[date.day].is_store_open_full_time == false) {
        date1.setHours(0, 0, 0, 0);
        
          this.store.store_delivery_time[n].day_time.forEach((store_time, index) => {


            let x = date1.setHours(Math.floor(store_time.store_open_time / 60), store_time.store_open_time % 60, 0, 0)

            let x1 = new Date(x);
            let x2 = x1.getTime();

            time_date_close.setHours(Math.floor(store_time.store_open_time / 60), store_time.store_open_time % 60, 0, 0);
            time_date_close = new Date(time_date_close.setMinutes(time_date_close.getMinutes() + 30));
            time_date.setHours(Math.floor(store_time.store_open_time / 60), store_time.store_open_time % 60, 0, 0);
            time_date = new Date(time_date);

            let y = date1.setHours(Math.floor(store_time.store_close_time / 60), store_time.store_close_time % 60, 0, 0)
            let y1 = new Date(y);
           
            for (; time_date < y1;) {
             
              var hours = time_date.getHours();
              var am_pm = "AM";
              if (hours > 12) {
                hours -= 12;
                am_pm = "PM";
              } else if (hours === 0) {
                hours = 12;
              }
              var hours_close = time_date_close.getHours();
              var am_pm_close = "AM";
              if (hours_close > 12) {
                hours_close -= 12;
                am_pm_close = "PM";
              } else if (hours_close === 0) {
                hours_close = 12;
              }

              if (date_with_Delivery_time < time_date) {
                if (time_date.getHours() < 6) {
                  var type = 1;
                  this.slot1 = true;
                } else if (time_date.getHours() < 12) {
                  var type = 2;
                  this.slot2 = true;
                } else if (time_date.getHours() < 18) {
                  var type = 3;
                  this.slot3 = true;
                } else {
                  var type = 4;
                  this.slot4 = true;
                }

                this.time_array[i] = { type: type, date: date, time_format: this.helper.pad2(time_date.getHours()) + ':' + this.helper.pad2(time_date.getMinutes()) + '-' + this.helper.pad2(time_date_close.getHours()) + ':' + this.helper.pad2(time_date_close.getMinutes()), time: this.helper.pad2(hours) + ':' + this.helper.pad2(time_date.getMinutes()) + " " + am_pm + ' - ' + this.helper.pad2(hours_close) + ':' + this.helper.pad2(time_date_close.getMinutes()) + " " + am_pm_close }
                i++;
              }
              
              time_date.setMinutes(time_date.getMinutes() + 30);
              time_date_close.setMinutes(time_date_close.getMinutes() + 30);
            }
          });
      } else {

        if(this.store.store_delivery_time[date.day].is_store_open_full_time){
          let x = date1.setHours(0, 0, 0, 0)
          let x1 = new Date(x);
          let x2 = x1.getTime();

          time_date_close.setHours(0, 0, 0, 0);
          time_date_close = new Date(time_date_close.setMinutes(time_date_close.getMinutes() + 30));
          time_date.setHours(0, 0, 0, 0);
          time_date = new Date(time_date);

          let y = date1.setHours(23, 59, 0, 0)
          let y1 = new Date(y);

          for (; time_date < y1;) {
            var hours = time_date.getHours();
            var am_pm = "AM";
            if (hours > 12) {
              hours -= 12;
              am_pm = "PM";
            } else if (hours === 0) {
              hours = 12;
            }
            var hours_close = time_date_close.getHours();
            var am_pm_close = "AM";
            if (hours_close > 12) {
              hours_close -= 12;
              am_pm_close = "PM";
            } else if (hours_close === 0) {
              hours_close = 12;
            }

            if (date_with_Delivery_time < time_date) {
              if (time_date.getHours() < 6) {
                var type = 1;
                this.slot1 = true;
              } else if (time_date.getHours() < 12) {
                var type = 2;
                this.slot2 = true;
              } else if (time_date.getHours() < 18) {
                var type = 3;
                this.slot3 = true;
              } else {
                var type = 4;
                this.slot4 = true;
              }
              this.time_array[i] = { type: type, date: date, time_format: this.helper.pad2(time_date.getHours()) + ':' + this.helper.pad2(time_date.getMinutes()) + '-' + this.helper.pad2(time_date_close.getHours()) + ':' + this.helper.pad2(time_date_close.getMinutes()), time: this.helper.pad2(hours) + ':' + this.helper.pad2(time_date.getMinutes()) + " " + am_pm + ' - ' + this.helper.pad2(hours_close) + ':' + this.helper.pad2(time_date_close.getMinutes()) + " " + am_pm_close }
              i++;
            }
            time_date.setMinutes(time_date.getMinutes() + 30);
            time_date_close.setMinutes(time_date_close.getMinutes() + 30);
          }
        }else{
          this.slot1 = undefined
          this.slot2 = undefined
          this.slot3 = undefined
          this.slot4 = undefined
          this.schedule_date = '';
          this.time_array = [];
        }


      }
    }else{


      if (this.store.store_time[n].is_store_open && this.store.store_time[n].is_store_open_full_time) {
        let x = date1.setHours(0, 0, 0, 0)
        let x1 = new Date(x);
        let x2 = x1.getTime();

        time_date_close.setHours(0, 0, 0, 0);
        time_date_close = new Date(time_date_close.setMinutes(time_date_close.getMinutes() + 30));
        time_date.setHours(0, 0, 0, 0);
        time_date = new Date(time_date);

        let y = date1.setHours(23, 59, 0, 0)
        let y1 = new Date(y);

        for (; time_date < y1;) {
          var hours = time_date.getHours();
          var am_pm = "AM";
          if (hours > 12) {
            hours -= 12;
            am_pm = "PM";
          } else if (hours === 0) {
            hours = 12;
          }
          var hours_close = time_date_close.getHours();
          var am_pm_close = "AM";
          if (hours_close > 12) {
            hours_close -= 12;
            am_pm_close = "PM";
          } else if (hours_close === 0) {
            hours_close = 12;
          }

          if (date_with_Delivery_time < time_date) {
            if (time_date.getHours() < 6) {
              var type = 1;
              this.slot1 = true;
            } else if (time_date.getHours() < 12) {
              var type = 2;
              this.slot2 = true;
            } else if (time_date.getHours() < 18) {
              var type = 3;
              this.slot3 = true;
            } else {
              var type = 4;
              this.slot4 = true;
            }
            this.time_array[i] = { type: type, date: date, time_format: this.helper.pad2(time_date.getHours()) + ':' + this.helper.pad2(time_date.getMinutes()) + '-' + this.helper.pad2(time_date_close.getHours()) + ':' + this.helper.pad2(time_date_close.getMinutes()), time: this.helper.pad2(hours) + ':' + this.helper.pad2(time_date.getMinutes()) + " " + am_pm + ' - ' + this.helper.pad2(hours_close) + ':' + this.helper.pad2(time_date_close.getMinutes()) + " " + am_pm_close }
            i++;
          }
          time_date.setMinutes(time_date.getMinutes() + 30);
          time_date_close.setMinutes(time_date_close.getMinutes() + 30);
        }
      } else if (this.store.store_time[n].is_store_open && !this.store.store_time[n].is_store_open_full_time) {
        this.store.store_time[n].day_time.forEach((store_time, index) => {


          let x = date1.setHours(Math.floor(store_time.store_open_time / 60), store_time.store_open_time % 60, 0, 0)
          let x1 = new Date(x);
          let x2 = x1.getTime();

          time_date_close.setHours(Math.floor(store_time.store_open_time / 60), store_time.store_open_time % 60, 0, 0);
          time_date_close = new Date(time_date_close.setMinutes(time_date_close.getMinutes() + 30));
          time_date.setHours(Math.floor(store_time.store_open_time / 60), store_time.store_open_time % 60, 0, 0);
          time_date = new Date(time_date);

          let y = date1.setHours(Math.floor(store_time.store_close_time / 60), store_time.store_close_time % 60, 0, 0)
          let y1 = new Date(y);

          for (; time_date < y1;) {
            var hours = time_date.getHours();
            var am_pm = "AM";
            if (hours > 12) {
              hours -= 12;
              am_pm = "PM";
            } else if (hours === 0) {
              hours = 12;
            }
            var hours_close = time_date_close.getHours();
            var am_pm_close = "AM";
            if (hours_close > 12) {
              hours_close -= 12;
              am_pm_close = "PM";
            } else if (hours_close === 0) {
              hours_close = 12;
            }

            if (date_with_Delivery_time < time_date) {
              if (time_date.getHours() < 6) {
                var type = 1;
                this.slot1 = true;
              } else if (time_date.getHours() < 12) {
                var type = 2;
                this.slot2 = true;
              } else if (time_date.getHours() < 18) {
                var type = 3;
                this.slot3 = true;
              } else {
                var type = 4;
                this.slot4 = true;
              }
              this.time_array[i] = { type: type, date: date, time_format: this.helper.pad2(time_date.getHours()) + ':' + this.helper.pad2(time_date.getMinutes()) + '-' + this.helper.pad2(time_date_close.getHours()) + ':' + this.helper.pad2(time_date_close.getMinutes()), time: this.helper.pad2(hours) + ':' + this.helper.pad2(time_date.getMinutes()) + " " + am_pm + ' - ' + this.helper.pad2(hours_close) + ':' + this.helper.pad2(time_date_close.getMinutes()) + " " + am_pm_close }
              i++;
            }
            time_date.setMinutes(time_date.getMinutes() + 30);
            time_date_close.setMinutes(time_date_close.getMinutes() + 30);
          }
        });
      } else{
        this.slot1 = undefined
        this.slot2 = undefined
        this.slot3 = undefined
        this.slot4 = undefined
        this.schedule_date = '';
        this.time_array = [];
      }
    };
  }

  open_check() {
    var diff1 = String(parseInt(moment().tz(this.timezone).format("ZZ")) - parseInt(moment(new Date()).format("ZZ")));
    var hour = "0";
    var minute = "0";
    if (diff1[0]) {
      hour = diff1[0];
    }
    if (diff1[1]) {
      if(diff1[1] == '7'){
        minute = 3 + diff1[2];
      }else{
        minute = diff1[1] + diff1[2];
      }
      
    }
    var total_minute = ((Number(hour) * 60) + Number(minute)) * 60000;
    if (diff1[0] == "-") {
      total_minute = total_minute * -1;
    }
    var date = new Date(Number(new Date()) + total_minute);

    let weekday = date.getDay();
    let current_time = date.getTime();
    this.open_at = '';
    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    var day;
    let week_index = this.store.store_time.findIndex((x) => x.day == weekday)
    let day_time = this.store.store_time[week_index].day_time;

    if (this.store.store_time[week_index].is_store_open) {

      if (this.store.store_time[week_index].is_store_open_full_time) {
        this.open_at = this.helper.trans.instant('open_for_full_day');
      } else {

        if (day_time.length == 0) {
        } else {
          day_time.forEach((store_time, index) => {
            let x = date.setHours(Math.floor(store_time.store_open_time / 60), store_time.store_open_time % 60, 0, 0)
            let x1 = new Date(x);
            let x2 = x1.getTime();

            let y = date.setHours(Math.floor(store_time.store_close_time / 60), store_time.store_close_time % 60, 0, 0)
            let y1 = new Date(y);
            let y2 = y1.getTime();

            if (current_time > x2 && current_time < y2) {
              this.current_close = false;
              let y = date.setHours(Math.floor(store_time.store_close_time / 60), store_time.store_close_time % 60, 0, 0)
              let y1 = new Date(y);
              let y2 = y1.getTime();

              if (((y2 - current_time) / 60000) < 30) {
                this.open_at = this.helper.trans.instant('close_in') + ' ' + Math.floor((y2 - current_time) / 60000) + ' ' + this.helper.trans.instant('mins');
              } else {
                var am_pm = 'AM';
                var hour_12 = Math.floor(store_time.store_close_time / 60);
                if (hour_12 > 12) {
                  hour_12 = hour_12 - 12;
                  am_pm = 'PM';
                }
                this.open_at = this.helper.trans.instant('open_till') + ' ' + this.helper.pad2(hour_12) + ':' + this.helper.pad2(store_time.store_close_time % 60) + ' ' + am_pm;
              }
            }

            if (current_time < x2 && this.open_at == '') {
              var am_pm = 'AM';
              var hour_12 = Math.floor(store_time.store_open_time / 60);
              if (hour_12 > 12) {
                hour_12 = hour_12 - 12;
                am_pm = 'PM';
              }
              if (((x2 - current_time) / 60000) < 30) {
                this.open_at = this.helper.trans.instant('open_in') + ' ' + Math.floor((x2 - current_time) / 60000) + ' ' + this.helper.trans.instant('mins');;
              } else {
                this.open_at = this.helper.trans.instant('open_at') + ' ' + this.helper.pad2(hour_12) + ':' + this.helper.pad2(store_time.store_open_time % 60) + ' ' + am_pm;
              }
            }
          });
          if (this.open_at == '') {

            if (this.current_close == false) {

            } else {
              for (let i = 1; i < 7; i++) {
                day = week_index + i;
                if (day > 6) {
                  day = day - 7;
                }

                if (this.store.store_time[day].is_store_open) {

                  if (this.store.store_time[day].is_store_open_full_time || this.store.store_time[day].day_time.length != 0) {

                    if (day == (week_index + 1)) {
                      this.open_at = this.helper.trans.instant('open_at') + ' ' + this.helper.trans.instant('tomorrow');
                    } else {

                      this.open_at = this.helper.trans.instant('open_at') + ' ' + this.helper.trans.instant(days[day]);
                    }
                    break;

                  }
                }
              }
            }
          }
        }
      }
    } else {

      for (let i = 1; i < 7; i++) {
        day = week_index + i;
        if (day > 6) {
          day = day - 7;
        }

        if (this.store.store_time[day].is_store_open) {

          if (this.store.store_time[day].is_store_open_full_time || this.store.store_time[day].day_time.length != 0) {

            if (day == (week_index + 1)) {
              this.open_at = this.helper.trans.instant('open_at') + ' ' + this.helper.trans.instant('tomorrow');
            } else {
              this.open_at = this.helper.trans.instant('open_at') + ' ' + this.helper.trans.instant(days[day]);
            }
            break;

          }
        }
      }
    }

    if (this.open_at == '' && !this.current_close) {
      let open_index = this.store.store_time.findIndex((x) => (x.is_store_open_full_time == true) || (x.day_time.length > 0 && x.is_store_open == true))
      if (open_index == -1) {
        this.open_at = this.helper.trans.instant('open_business_off');
      }
    }

  }

  onSlide(e) {
    this.dateIndex = parseInt(e.current.replace("slideId_", ""), 10);
    this.set_date(this.date_array[this.dateIndex]);
  }

  set_time(time) {
    this.selected_time = time;
    this.schedule_time = time.time_format;
    this.schedule_date = time.date.date;
    this.clicked_date = time.date.date_format;
    this.set_order_time(true);
  }

  set_order_time(boolean) {
    if (boolean) {
        if (this.schedule_date !== '' && this.schedule_time !== '') {

            let server_date: any = new Date(this._cartService.user_cart.server_time);
            server_date = new Date(server_date).toLocaleString("en-US", { timeZone: this.timezone })
            server_date = new Date(server_date);

            let date = JSON.parse(JSON.stringify(this.schedule_date.split('-')));
            var schedule_time = this.schedule_time.split('-');
            let time = schedule_time[0].split(':')
            var selected_date1 = null;

            if (schedule_time[1] && schedule_time[1] != "") {
                let time1 = schedule_time[1].split(':')
                selected_date1 = new Date(Date.now());
                selected_date1 = new Date(selected_date1).toLocaleString("en-US", { timeZone: this.timezone })
                selected_date1 = new Date(selected_date1);
                selected_date1.setDate(date[2])
                selected_date1.setMonth(date[1] - 1)
                selected_date1.setFullYear(date[0])
                selected_date1.setHours(time1[0], time1[1], 0, 0);
            }
            let selected_date: any = new Date(Date.now());
            selected_date = new Date(selected_date).toLocaleString("en-US", { timeZone: this.timezone })
            selected_date = new Date(selected_date);
            selected_date.setDate(date[2])
            selected_date.setMonth(date[1] - 1)
            selected_date.setFullYear(date[0])
            selected_date.setHours(time[0], time[1], 0, 0);
            let timeDiff = Math.round(selected_date.getTime() - server_date.getTime());

            if (timeDiff / 60000 >= 30) {
                this.schedule_time_error = false;
                this._cartService.user_cart.schedule_date = selected_date;
                this._cartService.user_cart.schedule_date1 = selected_date1;
                if(Number(schedule_time[0].split(":")[0]) > 12){
                    schedule_time[0] = this.helper.pad2(Number(schedule_time[0].split(":")[0]) -12)+':'+schedule_time[0].split(":")[1]+' PM';
                }else{
                    schedule_time[0] = schedule_time[0] +' AM';
                }
                if(Number(schedule_time[1].split(":")[0]) > 12){
                    schedule_time[1] = this.helper.pad2(Number(schedule_time[1].split(":")[0]) -12)+':'+schedule_time[1].split(":")[1]+' PM';
                }else{
                    schedule_time[1] = schedule_time[1] +' AM';
                }
                this._cartService.user_cart.clicked_date = this.clicked_date + ' ' + schedule_time;
            } else {
                this.schedule_time_error = true;
                // this.helper.trans.instant('store_not_provide_service_at_this_time')
            }
        } else {
            this.schedule_time_error = true;
        }

    } else {
        this._cartService.user_cart.schedule_date = null;
        this.schedule_time_error = false;
        this.schedule_date = '';
        this.schedule_time = '';
    }
    this.check_valid_time();
  }

  check_open(selected_date, today) {
    var date: any = JSON.parse(JSON.stringify(selected_date));
    date = new Date(date)

    let weekday = date.getDay();
    let current_time = date.getTime();
    this.store.close = true;
    this.store.nextopentime = '';


    if (today) {
      this.store_open_day = 'Today';
    } else {
      this.store_open_day = WEEK_DAY[weekday];
    }
    if(!this.store.store_time.length){
      return;
    }
    let week_index = this.store.store_time.findIndex((x) => x.day == weekday)

    let day_time = this.store.store_time[week_index].day_time;
    if (this.store.store_time[week_index].is_store_open_full_time) {
      this.store.close = false;
    }
    else {
      if (this.store.store_time[week_index].is_store_open) {
          if (day_time.length == 0) {
              this.store.close = true;
          } else {
              day_time.forEach((store_time, index) => {
                  let x = date.setHours(Math.floor( store_time.store_open_time / 60), store_time.store_open_time % 60, 0, 0)
                  let x1 = new Date(x);
                  let x2 = x1.getTime();

                  let y = date.setHours(Math.floor( store_time.store_close_time / 60), store_time.store_close_time % 60, 0, 0)
                  let y1 = new Date(y);
                  let y2 = y1.getTime();

                  if (current_time > x2 && current_time < y2) {
                      this.store.close = false;
                  }

                  if (current_time < x2 && this.store.nextopentime == '') {
                      this.store.nextopentime = this.helper.pad2(Math.floor( store_time.store_open_time / 60)) + ':' +this.helper.pad2(store_time.store_open_time % 60);
                  }
              });
              if(this.store.nextopentime == '' && this.store.close)
              {
                  this.store.nextopentime = day_time[0].store_open_time
              }


          }
      } else {
          this.store.close = true;
      }
    }

    if(!this._cartService.user_cart.is_user_pick_up_order && this._cartService.user_cart.is_schedule_order){
      this.check_delivery_time(selected_date,today);
    }

  }

  check_valid_time() {

    this.is_schedule_order = this._cartService.user_cart.is_schedule_order;

    let server_date: any = new Date(this._cartService.user_cart.server_time);
    server_date = new Date(server_date).toLocaleString("en-US", { timeZone: this.timezone })
    server_date = new Date(server_date);

    let selected_date: any = this._cartService.user_cart.schedule_date

    let day_diff = selected_date.getDay() - server_date.getDay();
    let timeDiff = Math.round(selected_date.getTime() - server_date.getTime());


    if (timeDiff / 60000 >= 30) {
      this.schedule_time_error = false;
        if (day_diff > 0) {
            this.check_open(selected_date, false);
        } else {
            this.check_open(selected_date, true);
        }

    } else {
        this.schedule_time_error = true;
    }
  }

  tooglePickupOrders (){
    this.get_order_cart_invoice(this.google_distance,this.google_time);
  }

  check_delivery_time(selected_date,today){
    var date: any = JSON.parse(JSON.stringify(selected_date));
    date = new Date(date)
    let weekday = date.getDay();
    let current_time = date.getTime();
    var close = true;
    this.store.nextopentime = '';
    if (today) {
        this.store_open_day = 'Today';
    } else {
        this.store_open_day = WEEK_DAY[weekday];
    }
    let week_index = this.store.store_delivery_time.findIndex((x) => x.day == weekday)
    let day_time = this.store.store_delivery_time[week_index].day_time;
    if (this.store.store_delivery_time[week_index].is_store_open_full_time) {
      close = false;
    }
    else {
      if (this.store.store_delivery_time[week_index].is_store_open) {
          if (day_time.length == 0) {
              close = true;
          } else {
              day_time.forEach((store_delivery_time, index) => {
                  let x = date.setHours(Math.floor( store_delivery_time.store_open_time / 60), store_delivery_time.store_open_time % 60, 0, 0)
                  let x1 = new Date(x);
                  let x2 = x1.getTime();

                  let y = date.setHours(Math.floor( store_delivery_time.store_close_time / 60), store_delivery_time.store_close_time % 60, 0, 0)
                  let y1 = new Date(y);
                  let y2 = y1.getTime();

                  if (current_time > x2 && current_time < y2) {
                      close = false;
                  }

                  if (current_time < x2 && this.store.nextopentime == '') {
                      this.store.nextopentime = this.helper.pad2(Math.floor( store_delivery_time.store_open_time / 60)) + ':' +this.helper.pad2(store_delivery_time.store_open_time % 60);
                  }
              });
              if(this.store.nextopentime == '' && close)
              {
                  this.store.nextopentime = day_time[0].store_open_time
              }
          }
      } else {
          close = true;
      }
    }
    if(close){
      this._notifierService.showNotification('error', this.helper.trans.instant('label-title.invalid-slot-selected'));
      this.set_schedule(false);
    }

  }

  onIsContactless(event){
    console.log(event.target.checked)
    if(event.target.checked){
      this.selected_payment_gateway = 'card'
    }
  }

}
