import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Helper } from 'src/app/shared/helper';
import { LocationModel, LocationService } from 'src/app/services/location.service'
import { environment } from 'src/environments/environment';
import { CartService, DELIVERY_TYPE } from 'src/app/services/cart.service';
import { AuthService } from 'src/app/services/auth.service';
import { Cart, cartAddress, CartData } from 'src/app/models/cart.model';
import { PaymentService } from 'src/app/services/payment.service';
import { CourierService } from 'src/app/services/courier.service';
import { CONFIG, PAYMENT_GATEWAY } from 'src/app/constants/constants';
import * as moment from 'moment';
import { NotifiyService } from 'src/app/services/notifier.service';

declare var $;
declare var google;
declare var elements: any;
declare var stripe: any;

@Component({
  selector: 'app-courier-order',
  templateUrl: './courier-order.component.html',
  styleUrls: ['./courier-order.component.scss']
})
export class CourierOrderComponent implements OnInit {
  address: any = '';
  user_name: any = '';
  user_phone_number: any = '';
  pickup_instruction: any = '';
  deliveryDetailForm: FormGroup;
  order_delivery_address: cartAddress = new cartAddress('destination');
  userDetailForm: FormGroup;
  map: any;
  vehicle_list: any = [];
  IMAGE_BASE_URL = CONFIG.IMAGE_URL;
  marker: any;
  selected_vehicle: any;
  order_detail: any = null;
  imageFiles: any = [];
  courier_images: any = []
  is_cash_payment_mode: boolean = false
  is_card_payment_mode: boolean = false
  wallet: any = 0;
  wallet_currency_code: any = 'INR';
  payment_id: any;
  cards: any = [];
  is_use_wallet: boolean = false;
  is_login: boolean = false;
  cardHandler = this.onChange.bind(this);
  card;
  selectedcard: any;
  selected_payment_gateway = 'cash';
  card_error: string;
  isLoading = false;
  order_payment_details: any;
  moment = moment();
  timezone: "Asia/Kolkata";

  @ViewChild('autocomplete_address', { static: true }) autocomplete_address: ElementRef;
  @ViewChild('delivery_autocomplete_address', { static: true }) delivery_autocomplete_address: ElementRef;
  @ViewChild('cardInfo') cardInfo: ElementRef;

  constructor(public helper: Helper,
    private _authService: AuthService,
    private _paymentService: PaymentService,
    public _locationService: LocationService,
    public _cartService: CartService,
    private _notifierService:NotifiyService,
    public _courierService: CourierService,
    private cd: ChangeDetectorRef) {
      
    }

  ngOnInit(): void {
    if(!this._authService.isAuthenticated || this.helper.city_id === ''){
      this.helper._route.navigate(['/']);
    }
    this._cartService.clear_cart()
    this._initAutoComplete()
    this.validateUserData()
    this.deliveryDetailForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      phone_number: new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(12)]),
      address: new FormControl(null, Validators.required),
      instructions: new FormControl(null)
    })

    this.userDetailForm = new FormGroup({
      user_name: new FormControl(this._authService.user.first_name + ' ' + this._authService.user.last_name, Validators.required),
      user_phone_number: new FormControl(this._authService.user.phone, [Validators.required, Validators.minLength(8), Validators.maxLength(12)])
    })


    this.user_name = this._authService.user.first_name + ' ' + this._authService.user.last_name;
    this.user_phone_number = this._authService.user.phone
    this.address = this._locationService.current_location.address
    this._cartService.user_cart.cart_data.pickup_addresses[0].user_details.name = this._authService.user.first_name + ' ' + this._authService.user.last_name
    this._cartService.user_cart.cart_data.pickup_addresses[0].user_details.phone = this._authService.user.phone
    this._cartService.user_cart.cart_data.pickup_addresses[0].user_details.email = this._authService.user.email
    this._cartService.user_cart.cart_data.pickup_addresses[0].user_details.country_phone_code = this._authService.user.country_phone_code
    this.order_delivery_address.location[0] = this._locationService.current_location.latitude
    this.order_delivery_address.location[1] = this._locationService.current_location.longitude

  }

  validateUserData() {
    this.is_use_wallet = this._authService.user !== null ? this._authService.user.is_use_wallet : false;
    this.is_login = this._authService.user !== null;
  }

  onSelectImageFile(event) {
    let files = event.target.files;
    if (files.length === 0)
      return;
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      alert("Only images are supported.")
      files = [];
      return;
    }
    this.imageFiles.push(files[0])
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.courier_images.push(reader.result)
    }
  }

  onDeleteImage(index) {
    this.courier_images.splice(index, 1)
  }

  // this.imagefile.push(event);
  //   const reader = new FileReader();
  //   reader.readAsDataURL(event);
  //   reader.onload = (_event) => {
  //     this.upload_imageurl.push({
  //       image:reader.result,
  //       checked:false,
  //     });
  //   }

  onSubmitDeliveryDetails() {
    this.deliveryDetailForm.markAllAsTouched()
    if (this.deliveryDetailForm.valid) {
      this._cartService.user_cart.cart_data.destination_addresses[0].user_details.name = this.deliveryDetailForm.value.name
      this._cartService.user_cart.cart_data.destination_addresses[0].user_details.phone = this.deliveryDetailForm.value.phone_number
      this._cartService.user_cart.cart_data.destination_addresses[0].user_details.email = ''
      this._cartService.user_cart.cart_data.destination_addresses[0].user_details.country_phone_code = this._authService.user.country_phone_code
      this._cartService.user_cart.cart_data.destination_addresses[0].note = this.deliveryDetailForm.value.instructions
      this._courierService.get_vehicles_list(this.helper.city_id, 2).then(res_data => {
        this.vehicle_list = res_data.admin_vehicles
      })
    }
  }

  openUserChange() {
    this.userDetailForm.patchValue({
      user_name: this.user_name,
      user_phone_number: this.user_phone_number
    })
    $(".custom-model-main").removeClass('model-open');
    $("body").addClass('model-open');
    $('#user-details').addClass('model-open');
  }

  onChangeDeliveryAddress() {
    $(".custom-model-main").removeClass('model-open');
    $("body").addClass('model-open');
    $('#delivery-add-update').addClass('model-open');
  }

  onUserDetail() {
    this.userDetailForm.markAsTouched();
    if (this.userDetailForm.valid && this._cartService.user_cart.cart_data.pickup_addresses.length) {
      this.user_name = this.userDetailForm.value.user_name
      this.user_phone_number = this.userDetailForm.value.user_phone_number
    }
    this._cartService.user_cart.cart_data.pickup_addresses[0].user_details.name = this.user_name
    this._cartService.user_cart.cart_data.pickup_addresses[0].user_details.phone = this.user_phone_number
    this._cartService.user_cart.cart_data.pickup_addresses[0].note = this.pickup_instruction


    $(".custom-model-main").removeClass('model-open');
    $("body").removeClass('model-open');
  }

  closeupdateaddress() {
    this._courierService.check_delivery_available(this.helper.city_id, this.order_delivery_address.location[0], this.order_delivery_address.location[1]).then(res_data => {
      if (res_data) {
        this.address
        $(".custom-model-main").removeClass('model-open');
        $("body").removeClass('model-open');
      }
    })
  }

  onVehicleSelected(vehicle) {
    this.selected_vehicle = vehicle
    this._cartService.add_to_cart(DELIVERY_TYPE.COURIER).then(res_data => {
      if (res_data) {
        this.get_distnce_time()
      }
    })
  }

  get_distnce_time() {
    let google_distance = 0;
    let google_time = 0;
    let origin = { lat: this._locationService.current_location.latitude, lng: this._locationService.current_location.longitude };
    let destination = { lat: this._cartService.user_cart.cart_data.destination_addresses[0].location[0], lng: this._cartService.user_cart.cart_data.destination_addresses[0].location[1] };
    let service = new google.maps.DistanceMatrixService;
    service.getDistanceMatrix({
      origins: [origin],
      destinations: [destination],
      unitSystem: google.maps.UnitSystem.METRIC,
      avoidHighways: false,
      avoidTolls: false,
      travelMode: google.maps.TravelMode.DRIVING,
    }, (response, status) => {
      if (status == google.maps.DistanceMatrixStatus.OK) {
        google_distance = response.rows[0].elements[0].distance != undefined ? response.rows[0].elements[0].distance.value : 0;
        google_time = response.rows[0].elements[0].duration != undefined ? response.rows[0].elements[0].duration.value : 0;
        this._cartService.get_courier_order_invoice(google_distance, google_time, 0, this.selected_vehicle._id).then(res_data => {
            this.order_detail = res_data
            this.get_payment_gateway()
        })
      } else {
        this._cartService.get_courier_order_invoice(google_distance, google_time, 0, this.selected_vehicle._id).then(res_data => {
          this.order_detail = res_data
          this.timezone = this.order_detail.timezone
          this.get_payment_gateway()
        })
      }
    });
  }

  get_payment_gateway() {
    this._paymentService.get_payment_gateway(this._locationService.current_location, this._authService.user._id, this._authService.user.server_token, this._cartService.user_cart.cart_data.cart_id).then(res_data => {
      this.is_cash_payment_mode = res_data.is_cash_payment_mode;
      const idx = res_data.payment_gateway.findIndex(_p => _p.name.toLowerCase() === PAYMENT_GATEWAY.STRIPE)
      this.is_card_payment_mode = idx !== -1
      this.wallet = res_data.wallet
      this.wallet_currency_code = res_data.wallet_currency_code
      if (this.is_card_payment_mode) {
        this.payment_id = res_data.payment_gateway[idx]._id;
        this._initCards();
      }
    })
  }

  _initCards() {
    this._paymentService.get_card_list({ user_id: this._authService.user._id, server_token: this._authService.user.server_token }).then(cards => {
      this.helper.ngZone.run(() => {
        this.cards = cards;
        if (this.cards.length) {
          const idx = this.cards.findIndex(_c => _c.is_default);
          if (idx !== -1) {
            this.selectedcard = cards[idx];
          }
        }
      })
    })
  }

  onChange({ error }) {
    if (error) {
      this.card_error = error.message;
    } else {
      this.card_error = null;
    }
    this.cd.detectChanges();
  }

  onClickAddCard() {
    this.helper._loader.isLoading = true;
    setTimeout(() => {
      this.card = elements.create('card');
      this.card.mount(this.cardInfo.nativeElement);
      this.card.addEventListener('change', this.cardHandler);
      $(".custom-model-main").removeClass('model-open');
    $("body").addClass('model-open');
      $('#add-new-card').addClass('model-open')
      this.helper._loader.isLoading = false;
    }, 1000);
  }

  async addCard() {
    this.isLoading = true;
    const { token, error } = await stripe.createToken(this.card)
    if (error) {
      alert(error);
    } else {
      var expiry_month = token.card.exp_month;
      var expiry_year = token.card.exp_year;

      if (this.payment_id) {
        this._paymentService.get_stripe_add_card_intent({ payment_id: this.payment_id }).then(client_secret => {
          if (client_secret) {
            stripe.handleCardSetup(client_secret, this.card, {
              payment_method_data: {
                billing_details: {}
              }
            }
            ).then((result) => {
              if (result.error) {
                this.card.clear();
                this.card.removeEventListener('change', this.cardHandler);
                this.card.destroy();
                this.card_error = result.error.message;
                this._notifierService.showNotification('error', this.card_error);
                this.isLoading = false;
              } else {
                this._paymentService.add_card({
                  payment_id: this.payment_id,
                  payment_method: result.setupIntent.payment_method,
                  user_id: this._authService.user._id,
                  server_token: this._authService.user.server_token,
                  expiry_month: expiry_month,
                  expiry_year: expiry_year
                }).then(is_card_added => {
                  this.closeAddCardModel()
                  if (is_card_added) {
                    this._initCards()
                  } else {
                  }
                })
              }
            });
          }
        })
      }
    }
  }

  selectDefault(card_id) {
    this._paymentService.select_card({ card_id });
  }

  removeCard(card_id) {
    this._paymentService.delete_card({ card_id }).then(is_deleted => {
      if (is_deleted) {
        this._initCards();
      }
    })
  }

  change_user_wallet_status(event) {
    this._cartService.change_user_wallet_status(this.is_use_wallet)
  }

  closeAddCardModel() {
    $('.addcardclose').click()
    this.card.clear();
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();
    this.isLoading = false;
  }

  place_order() {
    this.helper._loader.isLoading = true;
    var payment_mode_cash = this.selected_payment_gateway === PAYMENT_GATEWAY.CASH;
    var payment_id = payment_mode_cash ? 'cash' : PAYMENT_GATEWAY.STRIPE;
    this._cartService.pay_order_payment(payment_mode_cash, this.order_detail._id, payment_id, DELIVERY_TYPE.COURIER).then((res_data) => {
      if (res_data.success) {
        var is_payment_paid = res_data.data.is_payment_paid;
        if (is_payment_paid) {
          this.create_order_service();
        } else {
          var client_secret = res_data.data.client_secret;
          var payment_method = res_data.data.payment_method;
          stripe.confirmCardPayment(client_secret, { payment_method: payment_method }).then((result) => {
            if (result.error) {
            } else {
              this.create_order_service();
            }
          });
        }
      }else{
        this.helper._loader.isLoading = false;
      }
    })

  }

  create_order_service() {
    let selected_date = this._cartService.user_cart.schedule_date;
    let milisecond = 0;
    let milisecond1 = 0;
    if (this._cartService.user_cart.is_schedule_order) {
        milisecond = (selected_date.getTime() - this.moment.tz(this.timezone).utcOffset() * 60000);
        if (this._cartService.user_cart.schedule_date1) {
            milisecond1 = (this._cartService.user_cart.schedule_date1.getTime() - this.moment.tz(this.timezone).utcOffset() * 60000);
        }
    }
    // this.user_helper.user_cart.cart_data.destination_addresses[0].note = this.delivery_note;
    console.log('create_order')
    this._cartService.create_order(this.order_detail._id, milisecond, milisecond1, this.courier_images, DELIVERY_TYPE.COURIER, '',false,this.selected_vehicle._id).then(is_created => {
      if (is_created) {
        this.helper._loader.isLoading = false;
        this.helper._route.navigate(['/']);
      }

    })
  }

  _initAutoComplete() {
    var autocompleteElm = this.autocomplete_address.nativeElement;
    let autocomplete = new google.maps.places.Autocomplete((autocompleteElm), { types: [] });
    autocomplete.addListener('place_changed', () => {
      var place = autocomplete.getPlace();

      var lat = place.geometry.location.lat();
      var lng = place.geometry.location.lng();
      var address = place['formatted_address'];
      var curlocation: LocationModel = new LocationModel();
      curlocation.latitude = place.geometry.location.lat();
      curlocation.longitude = place.geometry.location.lng();
      curlocation.address = place['formatted_address'];
      place['address_components'].forEach(element => {
        var type = element.types[0]
        switch (type) {
          case 'country':
            curlocation.country_name = element.long_name;
            curlocation.country_code = element.short_name;
            break;
          case 'administrative_area_level_1':
            curlocation.state_code = element.short_name;
            curlocation.state_name = element.long_name;
            break;
          case 'administrative_area_level_2':
            curlocation.city_name = element.short_name;
            break;
          case 'postal_code':
            break;
          default:
            break;
        }
      });
      this._locationService.current_location = curlocation;
      this.setMap()
      // this._cartService.update_address(lat, lng, address).then(is_update => {
      //   if (is_update) {
      //     // Display
      this.order_delivery_address.location[0] = place.geometry.location.lat();
      this.order_delivery_address.location[1] = place.geometry.location.lng();
      this.order_delivery_address.address = place['formatted_address'];
      //     // $('#close-update-address').click()
      this.address = place['formatted_address'];
      //     // this.get_distnce_time()
      //   }
      // })
    });
    var delivery_autocompleteElm = this.delivery_autocomplete_address.nativeElement;
    let delivery_autocomplete = new google.maps.places.Autocomplete((delivery_autocompleteElm), { types: [] });
    delivery_autocomplete.addListener('place_changed', () => {
      var place = delivery_autocomplete.getPlace();
      var destination_address: LocationModel = new LocationModel();
      var lat = place.geometry.location.lat();
      var lng = place.geometry.location.lng();
      var address = place['formatted_address'];
      place['address_components'].forEach(element => {
        var type = element.types[0]
        switch (type) {
          case 'country':
            destination_address.country_name = element.long_name;
            destination_address.country_code = element.short_name;
            break;
          case 'administrative_area_level_1':
            destination_address.state_code = element.short_name;
            destination_address.state_name = element.long_name;
            break;
          case 'administrative_area_level_2':
            destination_address.city_name = element.short_name;
            break;
          case 'postal_code':
            break;
          default:
            break;
        }
      });
      this._cartService.user_cart.cart_data.destination_addresses[0].address = address
      this._cartService.user_cart.cart_data.destination_addresses[0].city = destination_address.city_name
      this._cartService.user_cart.cart_data.destination_addresses[0].location = [lat, lng]
    })
  }

  setMap() {

    var lat = this.order_delivery_address.location[0];
    var lng = this.order_delivery_address.location[1];

    this.map = new google.maps.Map(document.getElementById('map'), {
      zoom: 15,
      center: { lat, lng },
      draggable: true,
      zoomControl: true,
      scrollwheel: false,
      disableDoubleClickZoom: false,
      fullscreenControl: true
    });
    this.marker = new google.maps.Marker({
      position: { lat, lng },
      map: this.map,
      draggable: true,
      icon: this.IMAGE_BASE_URL + 'map_pin_images/Admin/user_pin.png'
    });
    var user_location = new google.maps.LatLng(lat, lng);
    this.map.setCenter(user_location);
    // google.maps.event.addListener(this.store_marker, 'dragend', (marker) => {
    //   lat = marker.latLng.lat();
    //   lng = marker.latLng.lng();
    //   this.geocodePosition(marker.latLng.lat(), marker.latLng.lng());
    // });
  }

}
