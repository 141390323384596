import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SetttingModel } from 'src/app/models/setting.model';
import { CommonService, Lang } from 'src/app/services/common.service';
import { Helper } from 'src/app/shared/helper';

declare var $:any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  langs:Array<Lang> = [];
  selectedLang = 'en';
  setting:SetttingModel = new SetttingModel();

  constructor(public _helper:Helper,private _commonService:CommonService,public translateService:TranslateService) { }

  ngOnInit(): void {

    this.langs = this._commonService.langs;
    this.selectedLang = this.translateService.currentLang;
    this.setting = this._commonService.settings;
    
  }
  
  
  change_langauge(lang){
    this.selectedLang = lang;
    localStorage.setItem('current_lang',this.selectedLang);
    if(lang == 'ar'){
      $('body').addClass('rtl');
    }else{
      $('body').removeClass('rtl');
    }
    this.translateService.use(lang) 
    window.location.reload()   
  }

}
