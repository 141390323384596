<div class="checkout-page page-content">
  <div class="container">
    <div class="section-title page-title">
      <h2>{{'heading-title.courier' | translate}}</h2>
    </div>
    <div class="your-order-section mb-30">
      <div class="row">
        <div class="col-lg-4 col-sm-6 checkout-col1">
          <div class="row">
            <div class="col-12">
              <div class="order-title d-flex align-item-center">
                <h3>{{'heading-title.pickup-details' | translate}}</h3>
              </div>
              <div class="delivery-details mb-30">
                <div class="delivery-address-section">
                  <div class="form-checklist-item mb-0">
                    <p class="mb-1">{{address}}</p>
                    
                  </div>
                  <a class="mb-4" (click)="onChangeDeliveryAddress()">{{'button-title.change-pin' | translate}}</a>
                  <div class="customer-details d-flex align-item-center mt-30 mb-30">
                    <div class="customer-name mr-auto">
                      <span>{{user_name}}</span>
                      <a href="">{{user_phone_number}}</a>
                    </div>
                    <div class="change-link">
                      <a (click)="openUserChange()" class="custom-popup">{{'button-title.change-information' | translate}}</a>
                    </div>
                  </div>
                  <div class="form-filed mb-30">
                    <label for="">{{'label-title.any-special-instruction' | translate}}</label>
                    <textarea name="pickup_instruction" id="pickup_instruction" rows="3"
                      [(ngModel)]="pickup_instruction" class="form-control"></textarea>
                  </div>
                  <div class="form-field mb-30">
                    <label for="" class="font-weight-bold">{{'label-title.upload-images' | translate}}</label>
                    <div class="row">
                      <div class="col-4 mb-4">
                        <div class="inputBox " onclick="$('#fileLoader').trigger('click'); return false;">
                          <span>+</span>
                          <!-- <div class="file-remove-btn"><a  href="javascript:void(0)">×</a></div> -->
                        </div>

                      </div>
                      <div class="col-4" hidden>
                        <input type="file" name="" id="fileLoader" class="file" (change)="onSelectImageFile($event)">
                        <img id="blah">

                      </div>
                      <div class="col-4 mb-2" *ngFor="let images of courier_images; let i = index" >
                        <img src="{{images}}" alt="" style="height: 96px;">
                        <div class="file-remove-btn"><a  (click)="onDeleteImage(i)">×</a></div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-12 mb-4">
              <div class="order-title d-flex align-item-center">
                <h3>{{'heading-title.delivery-details' | translate}}</h3>
              </div>
              <div class="delivery-details mb-30">
                <form [formGroup]="deliveryDetailForm">
                  <div class="delivery-address-section">
                    <div class="row">
                      <div class="col-6 mb-2">
                        <label for="">{{'label-title.name' | translate}}</label>
                        <input type="text" formControlName="name" class="form-control">
                        <div *ngIf="deliveryDetailForm.get('name').errors?.required && deliveryDetailForm.get('name').touched" class="text-danger">{{'validation-title.username-is-required' | translate}}!!</div>
                      </div>
                      <div class="col-6 mb-2">
                        <label for="">{{'label-title.phone-no' | translate}}</label>
                        <input type="text" (keypress)="helper.phone_number_validation($event)" formControlName="phone_number" class="form-control">
                        <div *ngIf="deliveryDetailForm.get('phone_number').errors?.required && deliveryDetailForm.get('phone_number').touched" class="text-danger">{{'validation-title.phone-is-required' | translate}}</div>
                        <div *ngIf="deliveryDetailForm.get('phone_number').errors?.minlength && deliveryDetailForm.get('phone_number').touched" class="text-danger">{{'validation-title.min-required' | translate}}!!</div>
                      </div>
                      <div class="col-12 mb-2">
                        <label for="">{{'label-title.address' | translate}}</label>
                        <input type="text" id="delivery_autocomplete_address" formControlName="address" class="form-control" #delivery_autocomplete_address>
                        <div *ngIf="deliveryDetailForm.get('address').errors?.required && deliveryDetailForm.get('address').touched" class="text-danger">{{'validation-title.address-is-required' | translate}}!!</div>
                      </div>
                      <div class="col-12">
                        <label for="">{{'label-title.any-special-instruction' | translate}}</label>
                        <input type="text" formControlName="instructions" class="form-control">
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="add-to-cart-btn" (click)="onSubmitDeliveryDetails()"><a
                  class="primary-btn">{{'button-title.submit' | translate}}</a></div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 checkout-col2" *ngIf="vehicle_list.length">
          <div class="order-title d-flex align-item-center">
            <h3>{{'heading-title.select-delivery-vehicle' | translate}}</h3>
          </div>
          <div class="delivery-details mb-30">
            <div class="delivery-address-section">
              <div class="d-flex flex-row mb-3 pb-3 border-bottom justify-content-between align-items-center" *ngFor="let vehicle of vehicle_list; let i = index">
                <a href="#">
                  <img alt="Marble Cake" class="list-thumbnail border-0"
                    src="../../../assets/default_images/delivery.png">
                </a>
                <div class="pl-3 flex-fill">
                  <a href="#">
                    <p class="font-weight-medium mb-0">{{vehicle.vehicle_name}}</p>
                    <p class="text-muted mb-0 text-small">{{order_detail.order_currency_code}} {{vehicle.price_per_unit_distance}}/km</p>
                  </a>
                </div>
                <div>
                  <label for="case{{i}}" class="radio mr-auto"><input (click)="onVehicleSelected(vehicle)" type="radio" name="case" id="case{{i}}"
                      class="hidden"><span class="label"></span></label>
                </div>
              </div>
              <!-- <div class="d-flex flex-row mb-3 pb-3 border-bottom justify-content-between align-items-center">
                <a href="#">
                  <img alt="Marble Cake" class="list-thumbnail border-0"
                    src="../../../assets/default_images/delivery.png">
                </a>
                <div class="pl-3 flex-fill">
                  <a href="#">
                    <p class="font-weight-medium mb-0">Car</p>
                    <p class="text-muted mb-0 text-small">₹ 10/km</p>
                    <p class="text-muted mb-0 text-small">5 Kms</p>
                  </a>
                </div>
                <div>
                  <label for="case122" class="radio mr-auto"><input type="radio" name="" id="case122"
                      class="hidden"><span class="label"></span></label>
                </div>
              </div>
              <div class="d-flex flex-row mb-3 pb-3 justify-content-between align-items-center">
                <a href="#">
                  <img alt="Marble Cake" class="list-thumbnail border-0"
                    src="../../../assets/default_images/delivery.png">
                </a>
                <div class="pl-3 flex-fill">
                  <a href="#">
                    <p class="font-weight-medium mb-0">Van</p>
                    <p class="text-muted mb-0 text-small">₹ 20/km</p>
                    <p class="text-muted mb-0 text-small">5 Kms</p>
                  </a>
                </div>
                <div>
                  <label for="case231" class="radio mr-auto"><input type="radio" name="" id="case231"
                      class="hidden"><span class="label"></span></label>
                </div>
              </div> -->
            </div>

          </div>
        </div>
        <div class="col-lg-4 col-sm-6 checkout-col3" *ngIf="selected_vehicle">
          <div class="row">
            <div class="col-12 mb-4" [hidden]="!is_login">
              <div class="price-details-section mb-30">
                <div class="order-title d-flex align-item-center">
                  <h3>{{'heading-title.payments' | translate}}</h3>
                </div>
              </div>
              <div class="cart-total-wrp payment-wrp">
                <ul>
                  <li>
                    <div class="cart-total-list-wrp d-flex align-item-center mb-0">
                      <div class="cart-total-label form-checklist-item mr-auto">
                        <div class="form-field">
                          <label for="case" class="checkbox mr-auto">
                            <input type="checkbox" class="option-input checkbox"  (change)="change_user_wallet_status($event)" style="vertical-align: middle;"
                              checked>
                            <span class="label"></span><span>{{'label-title.wallet' | translate}}</span>
                            <p class="mb-0 ">Current Balance</p>
                            <p class="mb-0 ">{{wallet_currency_code}}{{wallet}}</p>
                          </label>
                        </div>
                      </div>
                      <!-- <div class="cart-total-value">
                        <div class="wallet box">
                          <i class="fas fa-check"></i>
                        </div>
                      </div> -->
                    </div>
                  </li>
                  <li>
                    <div class="cart-total-list-wrp d-flex mb-0">
                      <div class="cart-total-label form-checklist-item mr-auto" *ngIf="is_card_payment_mode">
                        <div class="form-field">
                          <label for="case" class="radio mr-auto">
                            <input type="radio" value="card" (change)="selected_payment_gateway = 'card'" [checked]="selected_payment_gateway === 'card'" name="colorRadio" id="case" class="hidden" checked />
                            <span class="label"></span><span>{{'label-title.card' | translate}}</span>
                          </label>
                          <ul [hidden]="selected_payment_gateway != 'card'">
                            <li class="mb-0"  *ngFor="let card of cards">
                              <div class="order-list-block">
                                <div class="order-product-content">
                                  <div class="order-product-title">
                                    <label for="case{{card._id}}" class="radio mr-auto mb-0 ">
                                      <input type="radio" id="case{{card._id}}" (change)="selectDefault(card._id)" [checked]="card._id === selectedcard._id" id="case2" class="hidden mb-0 mr-auto" />
                                      <span class="label mb-0"></span>
                                      <p class="mb-0 ">xxxx-xxxx-xxxx-{{card.last_four}}123 {{card.card_type | uppercase}}</p>
                                    </label>
                                    <span>
                                      <div class="remove-btn"><a (click)="removeCard(card._id)">×</a></div>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <div [hidden]="selected_payment_gateway != 'card'" class="add-card mt-2">
                            <a href="javascript:void(0);" (click)="onClickAddCard()" data-id="add-new-card"
                              class="custom-popup">{{'button-title.add-new-card' | translate}}</a>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="cart-total-value">
                        <div class="box1">
                          <i class="fas fa-check"></i>
                        </div>
                        <div class="green box">
                          <i class="fas fa-check"></i>
                        </div>
                      </div> -->
                    </div>
                  </li>
                  <li>
                    <div class="cart-total-list-wrp d-flex align-item-center mb-0" *ngIf="is_cash_payment_mode">
                      <div class="cart-total-label  form-checklist-item mr-auto">
                        <div class="form-field">
                          <label for="paypal" class="radio mr-auto">
                            <input type="radio" (change)="selected_payment_gateway = 'cash'" [checked]="selected_payment_gateway === 'cash'" value="cash" name="colorRadio" id="paypal" class="hidden" />
                            <span class="label"></span>{{'label-title.cash' | translate}}
                          </label>
                        </div>
                      </div>
                      <!-- <div class="cart-total-value">
                        <div class="cash box">
                          <i class="fas fa-check"></i>
                        </div>
                      </div> -->
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12" *ngIf="order_detail != null">
              <div class="price-details-section mb-30">
                <div class="order-title d-flex align-item-center">
                  <h3>{{'heading-title.invoice' | translate}}</h3>
                </div>
              </div>
              <div class="cart-total-wrp">
                <ul>
                  <li>
                    <div class="cart-total-list-wrp subtotal d-flex align-item-center">
                      <div class="cart-total-label mr-auto"><strong>{{'label-title.service-price' | translate}}</strong>
                      </div>
                      <div class="cart-total-value"><span>{{order_detail.order_currency_code}} {{order_detail.total_service_price}}</span></div>
                    </div>
                  </li>
                  <li>
                    <div class="cart-total-list-wrp d-flex align-item-center">
                      <div class="cart-total-label mr-auto"><span>{{'label-title.service-tax' | translate}}</span></div>
                      <div class="cart-total-value"><span>{{order_detail.order_currency_code}} {{order_detail.service_tax}}</span></div>
                    </div>
                  </li>
                </ul>
                <ul class="total-bottom">
                  <li>
                    <div class="cart-total-list-wrp subtotal d-flex align-item-center">
                      <div class="cart-total-label mr-auto"><strong>{{'label-title.total' | translate}}</strong></div>
                      <div class="cart-total-value"><strong>{{order_detail.order_currency_code}} {{order_detail.total}}</strong></div>
                    </div>
                  </li>
                </ul>
                <div class="total-btn text-center">
                  <button class="primary-btn" (click)="place_order()">{{'heading-title.pay' | translate}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Change user details popup start  -->

<div class="custom-model-main medium-popup" id="user-details">
  <div class="custom-model-inner">
    <div class="close-btn">×</div>
    <div class="custom-model-wrap">
      <div class="pop-up-content-wrap">
        <div class="dropdown-title without-subtitle" >
          <h2>{{'heading-title.change-details' | translate}}</h2>
        </div>
        <div class="dropdown-content" style="padding-top: 0 !important;">
          <form [formGroup]="userDetailForm" class="login-form">
            <div class="form-field">
              <label for="">{{'label-title.name' | translate}}</label>
              <input type="text" class="form-control" formControlName="user_name">
              <div *ngIf="userDetailForm.get('user_name').errors?.required && userDetailForm.get('user_name').touched">{{'validation-title.username-is-required' | translate}}!!!!</div>
            </div>
            <div class="form-field">
              <label for="">{{'label-title.phone-no' | translate}}</label>
              <input type="text" class="form-control" formControlName="user_phone_number">
              <div *ngIf="userDetailForm.get('user_phone_number').errors?.required && userDetailForm.get('user_phone_number').touched">{{'validation-title.phone-is-required' | translate}}</div>
              <div *ngIf="userDetailForm.get('user_phone_number').errors?.minlength && userDetailForm.get('user_phone_number').touched">{{'validation-title.min-required' | translate}}!!</div>
            </div>
            <div class="form-field">
              <button (click)="onUserDetail()" [disabled]="userDetailForm.invalid" class="primary-btn submit-btn mr-auto">{{'button-title.update' | translate}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-overlay"></div>
</div>

<!-- Change user details popup end  -->

<!-- Change delivery address popup start  -->

<div class="custom-model-main" id="delivery-add-update">
  <div class="custom-model-inner">
    <div class="close-btn" id="close-update-address">×</div>
    <div class="custom-model-wrap">
      <div class="pop-up-content-wrap">
        <div class="dropdown-title without-subtitle">
          <h2>{{'heading-title.change-delivery-address' | translate}}</h2>
        </div>
        <div class="dropdown-content">
          <form action="" class="login-form">
            <div class="form-field">
              <label for="">{{'label-title.search-address-here' | translate}}</label>
              <input type="text" class="form-control" id="autocomplete_address" name="autocomplete_address" #autocomplete_address>
            </div>
            <div class="form-field">
							<div id="map" style="width: 100%; min-height: 400px;margin-top:20px;"></div>
              <!-- <iframe
              src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d1884916.7075680098!2d70.5269932983835!3d22.673245189258935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3959cbcc3c9a5705%3A0xc4473b3507694530!2sElluminati%20Inc%2C%20Jala%20Ram%20Nagar%2C%20Rajkot%2C%20Gujarat%20360007!3m2!1d22.2901858!2d70.7747911!5e0!3m2!1sen!2sin!4v1602661037173!5m2!1sen!2sin"
              width="550" height="200" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
              tabindex="0"></iframe> -->
            </div>
            <!-- src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d1884916.7075680098!2d70.5269932983835!3d22.673245189258935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3959cbcc3c9a5705%3A0xc4473b3507694530!2sElluminati%20Inc%2C%20Jala%20Ram%20Nagar%2C%20Rajkot%2C%20Gujarat%20360007!3m2!1d22.2901858!2d70.7747911!5e0!3m2!1sen!2sin!4v1602661037173!5m2!1sen!2sin" -->
            <div class="form-field">
              <button (click)="closeupdateaddress()" class="primary-btn submit-btn mr-auto">{{'button-title.update' | translate}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-overlay"></div>
</div>

<!-- Change delivery address popup end  -->

<!-- Add Card Popup Start -->

<div class="custom-model-main" id="add-new-card">
  <div class="custom-model-inner ">
      <div class="close-btn addcardclose" (click)="closeAddCardModel()">x</div>
      <div class="custom-model-wrap ">
          <div class="pop-up-content-wrap ">
              <div class="dropdown-title without-subtitle ">
                  <h2>{{'heading-title.add-card-details' | translate}}</h2>
              </div>
              <div class="dropdown-content">
                  <form action=" " class="login-form ">
                      <div id="card-info" style="width:100%" #cardInfo></div>
                      <div class="form-field mt-4">
                        <a href="javascript:void(0)" *ngIf="!isLoading" (click)="addCard()" class="primary-btn submit-btn mr-auto ">{{'button-title.add-card' | translate}}</a>
                        <a href="javascript:void(0)" *ngIf="isLoading" style="background-color: grey;cursor: not-allowed;color: white;border-color: white;" [disabled]="true" class="primary-btn mr-auto ">{{'button-title.processing' | translate}}</a>
                    </div>
                  </form>
              </div>
          </div>
      </div>
  </div>
  <div class="bg-overlay "></div>
</div>

<!-- Add Card Popup End -->
