export var apiColletions = {

    //Common
    "get_languages":"/admin/get_languages",
    "get_countries":"/api/admin/get_country_list",
    "check_referral":"/api/admin/check_referral",
    "get_setting_detail":"/api/admin/get_setting_detail",
    "get_favourite_store_list": "/api/user/get_favourite_store_list",
    "add_favourite_store": "/api/user/add_favourite_store",
    "remove_favourite_store": "/api/user/remove_favourite_store",
    "get_promo_code_list": "/admin/get_promo_code_list",

    // Auth 
    "login":"/api/user/login",
    "register":"/api/user/register",
    "forgot_password":"/api/admin/forgot_password",
    "forgot_password_verify":"/api/admin/forgot_password_verify",
    "reset_password":"/api/admin/new_password",

    // Verification
    "generate_otp":"/api/admin/otp_verification",
    "verify_otp":"/api/user/otp_verification",

    // Profile
    "get_user_detail":"/api/user/get_detail",
    "get_detail": '/api/user/get_detail',
    "user_update":"/api/user/update",

    // HomePage
    "get_delivery_list_for_nearest_city":"/api/user/get_delivery_list_for_nearest_city",
    "get_store_list":"/api/user/get_delivery_store_list",
    "get_store_detail":"/api/store/get_store_detail",
    "search_store":"/api/user/store_search",
    

    // Store Detail
    "get_store_data": "/admin/get_store_data",
    "get_product_group_list": "/api/user/get_product_group_list",
    "user_get_store_product_item_list": "/api/user/user_get_store_product_item_list",

    // Order Flow
    "get_cart": "/api/user/get_cart",
    "add_item_in_cart": "/api/user/add_item_in_cart",
    "get_order_cart_invoice": "/api/user/get_order_cart_invoice",
    "apply_promo_code": "/api/user/apply_promo_code",
    "get_orders": "/api/user/get_orders",
    "order_history": "/api/user/order_history",
    "get_order_detail": "/api/user/get_order_detail",
    "order_history_detail":"/api/user/order_history_detail",
    "user_cancel_order": "/api/user/user_cancel_order",
    "clear_cart": "/api/user/clear_cart",
    "change_delivery_address":"/api/user/change_delivery_address",
    "pay_order_payment":"/api/user/pay_order_payment",
    "create_order":"/api/user/create_order",
    "change_user_wallet_status":"/api/user/change_user_wallet_status",
    "rating_to_provider": "/api/user/rating_to_provider",
    "rating_to_store":"/api/user/rating_to_store",
    "user_update_order": "/api/user/user_update_order",


    // Wallet History
    "get_wallet_history":"/api/admin/get_wallet_history",

    // Payment
    "get_payment_gateway":"/api/user/get_payment_gateway",
    "get_card_list":"/api/user/get_card_list",
    "delete_card":"/api/user/delete_card",
    "get_stripe_add_card_intent":"/api/user/get_stripe_add_card_intent",
    "add_card":"/api/user/add_card",
    "select_card":"/api/user/select_card",
    "get_stripe_payment_intent_wallet":"/api/user/get_stripe_payment_intent_wallet",
    "add_wallet_amount":"/api/user/add_wallet_amount",

    //courier
    "check_delivery_available": "/api/user/check_delivery_available",
    "get_vehicles_list": "/api/store/get_vehicles_list",
    "get_courier_order_invoice": "/api/user/get_courier_order_invoice"

}   