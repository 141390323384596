<div class="help page-content">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-12">
                <div class="section-title page-title">
                    <h2>{{'heading-title.help' | translate}}</h2>
                    <div class="mb-4">
                        <p class="text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed tempor and vitality, so that the labor and sorrow, some important things to do eiusmod. Over the years, I will come, who will nostrud aliquip out of her the advantage
                            of exercise, so that stimulus efforts if the school district and longevity. Want to be a pain in the cupidatat cillum has been criticized in the Duis et dolore magna flee produces no resultant pleasure. Excepteur cupidatat
                            blacks are not excepteur, is soothing to the soul, that is, they deserted the general duties of those who are to blame for your troubles.</p>
                    </div>
                    <section class="help2">
                        <div class="customer-details d-flex align-item-center row ">
                            <div class="col-md-8 col-12 customer-name mr-auto mt-4">
                                <div class="row">
                                    <div class="col-md-6">
                                        <h5 class="mb-3">{{'label-title.question1' | translate}}</h5>
                                        <p>{{'label-title.answer1' | translate}}</p>
                                    </div>
                                    <div class="col-md-6">
                                        <h5 class="mb-3">{{'label-title.question2' | translate}}</h5>
                                        <p>{{'label-title.answer2' | translate}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <h5 class="mb-3">{{'label-title.question3' | translate}}</h5>
                                        <p>{{'label-title.answer3' | translate}}</p>
                                    </div>
                                    <div class="col-md-6">
                                        <h5 class="mb-3">{{'label-title.question4' | translate}}</h5>
                                        <p>{{'label-title.answer4' | translate}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <h5 class="mb-3">{{'label-title.question5' | translate}}</h5>
                                        <p>{{'label-title.answer5' | translate}} </p>
                                    </div>
                                    <div class="col-md-6">
                                        <h5 class="mb-3">{{'label-title.question6' | translate}}</h5>
                                        <p>{{'label-title.answer6' | translate}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-12 change-link" id="first">
                                <div class="help_img"><img class="img-fluid img-responsive lazyloaded" data-src="assets/img/help.jpg" height="auto" width="auto" src="../../assets/images/help.jpg"></div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</div>
