import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable({ providedIn: 'root' })
export class NotifiyService{

    constructor(private toaster: ToastrService){}

    showNotification( type: string, message: string ): void {
      
      if(type === 'success'){
        this.toaster.success('success', message);
      }else{
        this.toaster.error('error', message);
      }

      
    }

}