import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import { Observable, of } from "rxjs";
import { AuthService } from '../services/auth.service';
import { catchError, tap } from 'rxjs/operators';
import { Helper } from '../shared/helper';
import { TranslateService } from '@ngx-translate/core';
import { NotifiyService } from '../services/notifier.service';

@Injectable()
export class ResInterceptInterceptor implements HttpInterceptor {

  constructor(private _auth:AuthService,private _helper:Helper,private _notifierService:NotifiyService ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {


      return next.handle(req).pipe(tap(evt => {
          if (evt instanceof HttpResponse && evt.body) {
            if (evt.body.success) {
              var successMessage = this._helper.trans.instant('success-code.' + evt.body.message);
              if(!successMessage.includes(evt.body.message)){
                this._notifierService.showNotification('success',successMessage);
              }
            } else {
              if(evt.body.error_code === 999){
                this._auth.user_logout();
              }
              if(evt.body.success != undefined && !evt.body.success){
                var errorMessage = this._helper.trans.instant('error-code.' + evt.body.error_code);
                if(!errorMessage.includes(evt.body.error_code)){
                  this._notifierService.showNotification('error',errorMessage);
                }
              }
            }
          }
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          this._notifierService.showNotification('error',err.message)
        }
        return of(err);
      }));

  }
}
