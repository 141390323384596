import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './views/landing/landing.component';
import { CheckoutComponent } from './views/checkout/checkout.component';
import { AccountDetailComponent } from './views/account-detail/account-detail.component';
import { OrderDetailComponent } from './views/order-detail/order-detail.component';
import { HomePageComponent } from './views/home-page/home-page.component';
import { WalletHistoryComponent } from './views/wallet-history/wallet-history.component';
import { FavouritesListComponent } from './views/favourites-list/favourites-list.component';
import { HelpComponent } from './views/help/help.component';
import { AuthGuard } from './authguards/auth.guard';
import { CourierOrderComponent } from './views/courier-order/courier-order.component';

const routes: Routes = [
  { path: '', component: HomePageComponent,pathMatch:'full' },
  { path: 'store/:id', component: LandingComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'help', component: HelpComponent },
  { path: 'courier-order', component: CourierOrderComponent},
  { path: 'account', component: AccountDetailComponent, canActivate:[AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'order', component: OrderDetailComponent, canActivate:[AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'w-history', component: WalletHistoryComponent, canActivate:[AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'f-list', component: FavouritesListComponent, canActivate:[AuthGuard],canActivateChild:[AuthGuard] },
  { path: '**', redirectTo:'homepage',pathMatch:'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
