<div class="favourites-list page-content">
    <div class="container">
        <div class="section-title page-title">
            <h2>{{'heading-title.favorites-stores' | translate}}</h2>
        </div>
        <div *ngIf="favourite_stores && favourite_stores.length === 0" style="display: flex;align-items: center;justify-content: center;flex-direction: column;">
            <img src="assets/not_found_images/empty_fav_store.png">
            <h3 class="text-muted">{{'heading-title.no-fav-stores-found' | translate}}</h3>
        </div>
        <div class="store-list">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-4 col-12" *ngFor="let store of favourite_stores">
                    <div class="store-list-wrp" id="card1" *ngIf="hide">
                        <div class="store-img">
                          <div class="heart-icon" (click)="justLike('card1')">
                            <i class="fas fa-heart"></i>
                        </div>
                          <div class="store-img-container store-img text-center img" ng-reflect-ng-class="">
                            <img (click)="onStoreClick(store)"  (error)="$event.target.src = DEFAULT_IMAGE"
                                [src]="IMAGE_URL + store.image_url" [alt]="store.name">
                          </div>
                        </div>
                        <div class="store-details">
                            <div class="store-title d-flex align-item-center">
                                <h3 class="mb-0 mr-auto">{{store.name}}</h3>
                                <div class="store-rating"><svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.9756 6.07562C16.917 5.8977 16.7612 5.76804 16.5736 5.74118L11.3035 4.98558L8.94661 0.273901C8.86273 0.106173 8.68958 0 8.50003 0C8.31044 0 8.13732 0.106173 8.05341 0.273901L5.6964 4.98558L0.426422 5.74118C0.238858 5.76804 0.0829699 5.8977 0.0243998 6.07558C-0.0342035 6.2535 0.0146713 6.44878 0.150438 6.57932L3.96373 10.2469L3.06367 15.4256C3.03159 15.6099 3.10843 15.7962 3.26179 15.9062C3.34855 15.9684 3.45131 16 3.55457 16C3.63386 16 3.71342 15.9814 3.78626 15.9436L8.49999 13.4985L13.2135 15.9435C13.3814 16.0306 13.5847 16.0161 13.738 15.9061C13.8914 15.7962 13.9683 15.6099 13.9362 15.4255L13.0359 10.2469L16.8496 6.57929C16.9853 6.44878 17.0342 6.2535 16.9756 6.07562Z"></path></svg>
                                    <span>{{store.user_rate | roundpipe:2}}<span>({{store.user_rate_count | roundpipe}})</span></span>
                                </div>
                            </div>
                            <span *ngFor="let famous_tag of store.famous_products_tags">{{famous_tag}} | </span>
                            <div class="order-product-tag store-tag">
                                <span *ngFor="let i of counter(store.price_rating)">₹</span> |
                                <span>{{store.delivery_time}}-{{store.delivery_time_max}} {{'label-title.mins' | translate}}</span>
                                <span>{{store.is_store_pay_delivery_fees ? ' | ₹0 ' + ('label-title.delivery-fee' | translate) : ''
                                  }}</span>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
