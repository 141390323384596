import { Component, OnInit, ɵCompiler_compileModuleSync__POST_R3__ } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ItemModel, cartProductsModel, cartAddress } from 'src/app/models/cart.model';
import { StoreModel } from 'src/app/models/store.model';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { LocationModel, LocationService } from 'src/app/services/location.service';
import { MenuService } from 'src/app/services/menu.service';
import { Helper } from 'src/app/shared/helper';
import { environment } from 'src/environments/environment';
import { CONFIG, DEFAULT_IMAGE } from 'src/app/constants/constants'
declare var $;
declare var google;

export interface ProductGroup {
  image_url: string;
  name: string;
  product_ids: Array<string>;
  sequence_number: number;
  store_id: string
  _id: string
}

export interface Menu {
  products: Array<{
    items: Array<{
      name: string;
      details: string;
      specification: Array<any>;
      specifications_unique_id_count: number;
      unique_id: number;
      is_item_in_stock: boolean;
      is_visible_in_store: boolean;
      price: number;
      item_price_without_offer: number;
    }>
    _id: any;
  }>
}

export interface Promo {
  Promo: Array<any>;
}

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})


export class LandingComponent implements OnInit {

  product_groups: Array<ProductGroup> = [];
  store: StoreModel = new StoreModel();
  products: any;
  paramSubscription;
  store_id = null;
  product_length: number = 0;
  selected_product: number = 0;
  selected_item: any = '';
  product_name: string = '';
  product_unique_id: number = 0;
  IMAGE_URL = CONFIG.IMAGE_URL;
  ITEM_DEFAULT_IMAGE = DEFAULT_IMAGE.PRODUCT
  DEFAULT_USER_PROFILE = DEFAULT_IMAGE.STORE_PROFILE;
  product_index: number = 0
  required_temp_count: number = 0;
  total: number = 0
  qty: number = 1
  required_count: number = 0
  note_for_item: any = '';
  private cartProducts: cartProductsModel = new cartProductsModel();
  private cartProductItems: ItemModel =  new ItemModel();
  current_main_item;
  map: any;
  store_marker: any;
  store_detail: any = {
    name: '',
    user_rate: 0,
    user_rate_count: 0,
    order_cancellation_charge_value: 0,
    address: '',
    location:[0,0],
    phone: '',
    country_phone_code: '',
    store_time: []
  };
  search_text: any = '';
  is_store_open: boolean = false;
  store_open_day: any;

  constructor(public _helper: Helper,
    private _menuService: MenuService,
    private _authService:AuthService,
    private route: ActivatedRoute,
    private _cartService: CartService,
    private _locationService: LocationService) { }

  ngOnInit(): void {


    this._helper._loader.isLoading = true
    //script
    $(".close-btn, .bg-overlay").click(function () {
      $(".custom-model-main").removeClass('model-open');
      $("body").removeClass('model-open');
    });

    this.store_id = this.route.snapshot.paramMap.get('id');
    this.get_store_details().then(()=>{
      this.setMap()
    })
    this._helper.store_id = this.store_id
    this.get_product_group_list(this.store_id)


  }

  setMap() {
    var lat = this.store_detail.location[0];
    var lng = this.store_detail.location[1];
    this.map = new google.maps.Map(document.getElementById('map'), {
      zoom: 15,
      center: { lat, lng },
      draggable: false,
      zoomControl: true,
      scrollwheel: false,
      disableDoubleClickZoom: false,
      fullscreenControl: true
    });
    if (lat && lng) {
      this.store_marker = new google.maps.Marker({
        position: { lat, lng },
        map: this.map,
        draggable: false,
        icon: this.IMAGE_URL + 'map_pin_images/Admin/user_pin.png'
      });
      var user_location = new google.maps.LatLng(lat, lng);
      this.map.setCenter(user_location);
      // google.maps.event.addListener(this.store_marker, 'dragend', (marker) => {
      //   lat = marker.latLng.lat();
      //   lng = marker.latLng.lng();
      //   this.geocodePosition(marker.latLng.lat(), marker.latLng.lng());
      // });
    } else {
      // if (!this.store_marker) {
      //   this.store_marker.setMap(null);
      // }
    }
  }

  get_store_details(){
    return new Promise((resolve,rejects)=>{
      this._menuService.get_store_data({store_id: this.store_id}).then(res_data => {
        if(res_data.success){
          this.store_detail = res_data.store_detail
          this._helper.timezone = this.store_detail.city_details.timezone
          this._helper.open_check(this.store_detail.store_time)
          this.store_detail.store_time.forEach((data)=>{
            data.day_time.forEach((day_time_data)=>{
              if(Math.floor(day_time_data.store_close_time / 60) >12){
                 var hour = Math.floor(day_time_data.store_close_time / 60) - 12;
                 day_time_data.store_close_time = this._helper.pad2(hour)+":"+this._helper.pad2(day_time_data.store_close_time % 60)+" PM";
              }else{
                 var hour = Math.floor(day_time_data.store_close_time / 60);
                 day_time_data.store_close_time = this._helper.pad2(hour)+":"+this._helper.pad2(day_time_data.store_close_time % 60)+" AM";
              }

              if(Math.floor(day_time_data.store_open_time / 60) >12){
                var hour = Math.floor(day_time_data.store_open_time / 60) - 12;
                day_time_data.store_open_time = this._helper.pad2(hour)+":"+this._helper.pad2(day_time_data.store_open_time % 60)+" PM";
              }else{
                var hour = Math.floor(day_time_data.store_open_time / 60);
                day_time_data.store_open_time = this._helper.pad2(hour)+":"+this._helper.pad2(day_time_data.store_open_time % 60)+" AM";
              }
            })
          })
          if(this.store_detail.store_time[new Date().getDay()].is_store_open){
            this.is_store_open = true
          } else {
            this.is_store_open = false

          }
        }
        resolve(true);
      })
    })
  }

  async get_product_group_list(store_id) {
    await this._menuService.get_product_group_list(store_id).then(res_data => {
      if(res_data.length){
        this.product_groups = res_data;
      }else{
        this._helper._loader.isLoading = false;
      }
      this.user_get_store_product_item_list()
    })
  }

  user_get_store_product_item_list() {

    if(!this.product_groups.length){
      this.product_groups.push({
        name:'Main',
        _id:null,
        image_url:"",
        product_ids:[],
        sequence_number:0,
        store_id:this.store_id
      })
      this.product_index = 0;
    }

    var product_ids = this.product_groups[this.product_index].product_ids.length ? this.product_groups[this.product_index].product_ids : null;


    this._menuService.user_get_store_product_item_list({ product_ids, server_token: "", user_id: "", store_id: this.store_id }).then(res_data => {
      if (res_data.success) {
        this.store = res_data.store
        this.products = res_data.products
        this.products.forEach(product => {
          product.items.forEach(item => {
            item.total_item_price = 0
            item.specifications.forEach(spec => {
              spec.list.forEach(list => {
                if (list.is_default_selected) {
                  item.total_item_price = item.total_item_price + list.price
                }
              });
            });
            item.total_item_price = item.total_item_price + item.price
          });
        });
        this.product_length = this.products.length

        this.product_groups.forEach((_product_group,i)=>{
          if(i === this.product_index){
            _product_group['is_show'] = !!!_product_group['is_show'];
          $('#'+this.product_index.toString()).slideDown('slow');

          }else{
            _product_group['is_show'] = false
          }
        })

        setTimeout(() => {
         try{
          var height = $('#' + this.product_index.toString()).offset().top - $('html,body').offset().top;
          $('html,body').animate({
            scrollTop: height - $('.main-banner').height()
          })
         }catch(err){}
        }, 100);
      }
    }).finally(() => {
      this._helper._loader.isLoading = false
    })
  }

  calculate_is_required() {
    this.required_count = 0
    this.selected_item.specifications.forEach((specification_group) => {
      if (specification_group.is_required) {
        this.required_count++;
      }
    })
  }

  onProductSelected(index) {
    this.selected_product = index
  }

  onModelOpen(item,product_id) {
    $(".custom-model-main").removeClass('model-open');
    $('.model-open').slideToggle('slow');
    $("body").addClass('model-open');
    $('#product-popup').addClass('model-open');
    setTimeout(() => {
      var header_height = $('#product-popup').find(".dropdown-title").outerHeight() + 25;
      $('#product-popup').find(".dropdown-content").css("padding-top", header_height);
    }, 100);
    this.product_name = this.products[this.selected_product]._id.name;
    this.product_unique_id = this.products[this.selected_product]._id.unique_id;
    this.current_main_item = item;
    this.selected_item = JSON.parse(JSON.stringify(item))
    this.calculateTotalAmount(false);
    this.calculate_is_required()
  }

  onProduct(index) {
    this.selected_product = 0;
    if(!this.product_groups[index]['is_show']){
      this._helper._loader.isLoading = true;
      this.product_index = index
      this.user_get_store_product_item_list()
    }else{
      this.product_groups[index]['is_show'] = false;
    }
  }

  changeradio(event, specification_group_index, specification_index) {
    var index = this.selected_item.specifications[specification_group_index].list.findIndex(x => x.is_default_selected == true)
    if (index !== -1) {
      this.selected_item.specifications[specification_group_index].list[index].is_default_selected = false;
    }
    this.selected_item.specifications[specification_group_index].list[specification_index].is_default_selected = true;
    this.calculateTotalAmount(true);
  }

  changecheckbox(event, specification_group_index, specification_index) {
    this.selected_item.specifications[specification_group_index].list[specification_index].is_default_selected = event.target.checked;
    this.calculateTotalAmount(true);
  }

  incerase_qty() {
    this.qty++;
    this.calculateTotalAmount(true);
  }

  decrease_qty() {
    if (this.qty > 1) {
      this.qty--;
      this.calculateTotalAmount(true);
    }
  }

  calculateTotalAmount(boolean) {
    this.total = this.selected_item.price;
    this.required_temp_count = 0;
    this.selected_item.specifications.forEach((specification_group, specification_group_index) => {
      var default_selected_count = 0
      specification_group.list.forEach((specification, specification_index) => {

        if (specification.is_default_selected) {
          // if(specification_group.type == 2 && boolean){
            this.total = this.total + specification.price;
          // }
          default_selected_count++;
        }
        specification_group.default_selected_count = default_selected_count;
      });


      if (specification_group.type == 1 && specification_group.is_required) {
        if (specification_group.range) {
          if (default_selected_count >= specification_group.range) {
            this.required_temp_count++;
          }
        } else {
          if (default_selected_count >= 1) {
            this.required_temp_count++;
          }
        }
      } else if (specification_group.type == 2 && specification_group.is_required) {
        if (specification_group.range) {
          if (default_selected_count >= specification_group.range) {
            this.required_temp_count++;
          }
        } else {
          if (default_selected_count >= 1) {
            this.required_temp_count++;
          }
        }
      }

    });

    this.total = this.total * this.qty;
  }

  async clearCart(){
    await this._cartService.clear_cart()
    $('#close-clear-cart').click()
    this.handleClickOnAddToCart()
  }

  async handleClickOnAddToCart() {

        if (this._cartService.user_cart.cart_data.cart.length > 0 && this._cartService.user_cart.cart_data.selectedStoreId !== this.store_id) {
          this._helper.openModel('clear-cart-content')
          return;
        }
        var location: LocationModel = this._locationService.current_location
        var user_details = {
          name:"",
          country_phone_code:"",
          phone:"",
          email:""
        }
        if(this._authService.user){
          user_details = {
            name: `${this._authService.user.first_name} ${this._authService.user.last_name}`,
            country_phone_code: `${this._authService.user.country_phone_code}`,
            phone: `${this._authService.user.phone}`,
            email: `${this._authService.user.email}`
          }
        }

        this._cartService.user_cart.cart_data.destination_addresses[0] = {
          address: location.address,
          address_type: 'destination',
          city: location.city_name,
          delivery_status: 1,
          location: [location.latitude, location.longitude],
          note: "",
          user_details: user_details,
          user_type: this._authService.user ? this._authService.user.user_type : 1
        }


        let specificationPriceTotal = 0;
        let specificationPrice = 0;
        let specificationList = [];
        var y_name = "";

        this.selected_item.specifications.forEach((specification_group, specification_group_index) => {
          let specificationItemCartList = [];
          specification_group.list.forEach((specification, specification_index) => {
              y_name = specification.name;
              if (typeof specification.name == "object") {
                  y_name = specification.name[0];
                  if (!y_name) {
                      y_name = specification.name[0];
                  }
              }
              specification.name = y_name;
              if (specification.is_default_selected) {
                  specification.name = y_name
                  specificationPrice = specificationPrice + specification.price;
                  specificationPriceTotal = specificationPriceTotal + specification.price;
                  specificationItemCartList.push(specification)
              }
          });
          y_name = specification_group.name;
          if (typeof specification_group.name == "object") {
              y_name = specification_group.name[0];
              if (!y_name) {
                  y_name = specification_group.name[0];
              }
          }
          specification_group.name = y_name;
          if (specificationItemCartList.length > 0) {
              let specificationsItem_json = {
                  list: specificationItemCartList,
                  unique_id: specification_group.unique_id,
                  name: y_name,
                  price: specificationPrice,
                  type: specification_group.type,
                  range: specification_group.range,
                  max_range: specification_group.max_range
              }
              specificationList.push(specificationsItem_json);
          }
          specificationPrice = 0;

      });

      this.cartProductItems.item_id = this.selected_item._id;
      this.cartProductItems.unique_id = this.selected_item.unique_id;
      this.cartProductItems.item_name = this.selected_item.name;
      this.cartProductItems.quantity = this.qty;
      this.cartProductItems.image_url = this.selected_item.image_url;
      this.cartProductItems.details = this.selected_item.details;
      this.cartProductItems.specifications = specificationList;
      this.cartProductItems.item_price = this.selected_item.price;
      this.cartProductItems.total_specification_price = specificationPriceTotal;
      this.cartProductItems.total_item_price = this.total;
      if (this.store.is_use_item_tax == true) {
          this.cartProductItems.tax = this.selected_item.tax;
      } else {
          this.cartProductItems.tax = this.store.item_tax;
      }
      this.cartProductItems.total_specification_tax = specificationPriceTotal * Number(this.cartProductItems.tax) * 0.01;
      this.cartProductItems.item_tax = (Number(this.cartProductItems.tax) * Number(this.cartProductItems.item_price) * 0.01);
      this.cartProductItems.total_tax = (Number(this.cartProductItems.item_tax) + Number(this.cartProductItems.total_specification_tax));
      this.cartProductItems.total_item_tax = (Number(this.cartProductItems.total_tax) * Number(this.cartProductItems.quantity));
      this.cartProductItems.note_for_item = this.note_for_item;

      this._cartService.user_cart.cart_main_item.push(this.current_main_item);

      this._cartService.user_cart.total_cart_amount = this._cartService.user_cart.total_cart_amount + this.total;
      this._cartService.user_cart.total_item_tax = Number(this._cartService.user_cart.total_item_tax) + Number(this.cartProductItems.total_item_tax);

      if (this.isProductExistInLocalCart(this.cartProductItems)) {

      } else {
          let cartProductItemsList = [];
          cartProductItemsList.push(this.cartProductItems)
          this.cartProducts.items = cartProductItemsList;
          this.cartProducts.product_id = this.selected_item.product_id;
          this.cartProducts.product_name = this.product_name
          this.cartProducts.unique_id = this.product_unique_id
          this.cartProducts.total_item_price = 0;
          this.cartProducts.total_item_tax = 0;
          this.cartProducts.items.forEach((item) => {
              this.cartProducts.total_item_price += item.total_item_price;
              this.cartProducts.total_item_tax += item.total_item_tax;
          });
          this._cartService.user_cart.cart_data.cart.push(this.cartProducts);
          localStorage.setItem('cartStoreId',this.store_id);
          this._cartService.user_cart.cart_data.selectedStoreId = this.store_id;
          this._cartService.user_cart.cart_data.max_item_quantity_add_by_user = this.store.max_item_quantity_add_by_user;
          this._cartService.user_cart.cart_data.pickup_addresses[0].location = this.store.location;
          this._cartService.user_cart.cart_data.pickup_addresses[0].address = this.store.address;
          this._cartService.user_cart.cart_data.pickup_addresses[0].user_type = this.store.user_type;
          this._cartService.user_cart.cart_data.pickup_addresses[0].user_details = {
              "name": this.store.name,
              "country_phone_code": this.store.country_phone_code,
              "phone": this.store.phone,
              "email": this.store.email
          }
          this._cartService.user_cart.cart_data.total_item++;
          this.addItemInServerCart();

      }
  }

  gotoDirection(){
    window.open(`https://www.google.com/maps/dir/${this._locationService.current_location.address}/${this.store.address}/`);
  }

  isProductExistInLocalCart(cartProductItems) {
    let bool = false;
    this._cartService.user_cart.cart_data.cart.forEach((cart_item) => {
        if (cart_item.product_id == this.products[this.selected_product].product_id && bool == false) {
            cart_item.items.push(cartProductItems);
            cart_item.total_item_price = cart_item.total_item_price + this.total;
            this.addItemInServerCart();
            this._cartService.user_cart.cart_data.total_item++;
            bool = true;
        }
    });
    return bool;
  }

  addItemInServerCart() {
    this.cartProducts = new cartProductsModel();
    this.cartProductItems =  new ItemModel();
    this._cartService.add_to_cart();
    this.note_for_item = '';
    $('#close_item_model').click();
  }

}
