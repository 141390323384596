<div class="container page-content">
    <div class="row">
        <div class="col-12">
            <div class="payment-detail">
                <div class="section-title page-title">
                    <h2>{{'heading-title.payment' | translate}}</h2>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-12">
                        <div class="order-title mb-4">
                            <h3>{{'heading-title.wallet_history' | translate}}</h3>
                        </div>
                        <div class="row">
                            <ng-container *ngIf="!walletHistory || !walletHistory.length">
                                <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;width: 100%;">
                                  <img src="assets/not_found_images/wallet_history_not_found.png" height="400px">
                                </div>
                              </ng-container>
                            <div class="col-lg-12 col-md-6 col-12 mb-3" *ngFor="let item of walletHistory">
                                <div class="order-list">
                                    <div class="order-list-block">
                                        <div class="order-product-content">
                                            <div class="order-number d-flex">
                                                <h4 class="mr-auto"><span>{{'heading-title.order' | translate}}</span> #{{item.unique_id}}</h4>
                                                <div [class.order-status-refuse]="item.wallet_status === 2" [class.order-status]="item.wallet_status === 1" class="wallet-link ">
                                                    <span>
                                                        <i [class.fa-info-circle]="item.wallet_status === 2" [class.fa-arrow-down]="item.wallet_status === 1" class="fas"></i> {{item.added_wallet}} {{item.to_currency_code}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="order-time d-flex mb-0">
                                                <span class="mr-auto mb-2">{{item.to_currency_code}} {{item.total_wallet_amount}} | {{item.updated_at | date: "dd MMM hh:mm a"}}</span>
                                            </div>
                                            <div [class.order-status-refuse]="item.wallet_status === 2" [class.order-status]="item.wallet_status === 1">
                                                <span>{{item.wallet_description}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-12">
                        <div class="row">
                            <div class="col-lg-12 col-md-6 col-12">
                                <form [formGroup]="walletFormGroup" (ngSubmit)="submit()">
                                    <div class="customer-details d-flex align-item-center">
                                        <div class="customer-name mr-auto mt-4">
                                            <h3>{{'heading-title.wallet' | translate}}</h3>
                                        </div>
                                        <div class="change-link">
                                            <div *ngIf="wAmount">
                                                <button [disabled]="walletFormGroup.invalid" type="submit" class="btn btn-outline-primary btn-xs">
                                                    {{'button-title.submit' | translate}}
                                                </button>
                                            </div>
                                            <div *ngIf="!wAmount">
                                                <a href="javascript:void(0);" class="btn btn-outline-primary btn-xs " (click)="add()">
                                                        {{'button-title.add' | translate}}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-30" *ngIf="wAmount">
                                        <input type="number" min="1" (keypress)="helper.number_validation($event)" class="form-control" formControlName="amount" placeholder="{{'label-title.enter-amount' | translate}}">
                                        <span *ngIf="amount_error" class="text-danger">{{amount_error}}</span>
                                    </div>
                                </form>
                                <div class="mb-30" *ngIf="!wAmount">
                                    <span class> {{'label-title.current_wallet_balance' | translate}} :</span><br>
                                    <span class> {{paymentGatewayData.wallet_currency_code}} {{paymentGatewayData.wallet}}</span>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6 col-12">

                                <div class="customer-details d-flex align-item-center">
                                    <div class="customer-name mr-auto mt-4">
                                        <h3>{{'heading-title.card' | translate}}</h3>
                                    </div>
                                    <div class="change-link">
                                        <a href="javascript:void(0);" (click)="onClickAddCard()" data-id="add-new-card" class="custom-popup btn btn-outline-primary btn-xs">
                                        {{'button-title.add' | translate}}
                                      </a>
                                    </div>
                                </div>
                                <div class="row">
                                    <ng-container *ngIf="!cards || !cards.length">
                                        <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;width: 100%;">
                                          <img src="assets/not_found_images/card_not_found.png" height="400px">
                                        </div>
                                      </ng-container>
                                    <div [class.theme-text]="card.is_default" (click)="selectDefault(card._id)" class="col-12 mb-30 c-pointer" *ngFor="let card of cards">
                                        <div class="order-list">
                                            <div class="order-list-block ">
                                                <div class="order-product-content ">
                                                    <div class="order-number d-flex ">
                                                        <h4 [class.theme-text]="card.is_default" class="mr-auto ">xxx xxx xxxx {{card.last_four}}</h4>
                                                    </div>
                                                    <div class="order-time mb-0 ">
                                                        <span class="mr-auto mb-2 ">{{card.card_type | titlecase}}</span>
                                                    </div>
                                                </div>
                                                <div class="remove-btn">
                                                    <a (click)="removeCard(card._id)" href="javascript:void(0)" alt="remove">×</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="custom-model-main " id="add-new-card">
    <div class="custom-model-inner ">
        <div class="close-btn addcardclose">×</div>
        <div class="custom-model-wrap ">
            <div class="pop-up-content-wrap ">
                <div class="dropdown-title without-subtitle ">
                    <h2>{{'heading-title.add-card-details' | translate}}</h2>
                </div>
                <div class="dropdown-content">
                    <form action=" " class="login-form ">
                        <div id="card-info" style="width:100%" #cardInfo></div>

                        <!-- <div class="form-field ">
                            <label for=" ">{{label.user_name | translate}}</label>
                            <input type="text " class="form-control ">
                        </div>
                        <div class="form-field ">
                            <label for=" ">{{label.card_number | translate}}</label>
                            <input type="text " class="form-control ">
                        </div>
                        <div class="">
                            <div class="row ">
                                <div class="col-sm-8 col-8 ">
                                    <div class="form-field ">
                                        <label for=" ">{{label.expiry_date | translate}}</label>
                                        <div class="expiry-form d-flex align-item-center ">
                                            <div class="row ">
                                                <div class="col-sm-6 col-6 ">
                                                    <select name="month " id="mm " class="form-control ">
                                                        <option value=" ">mm</option>
                                                        <option value="january ">1</option>
                                                        <option value="february ">2</option>
                                                        <option value="march ">3</option>
                                                        <option value="april ">4</option>
                                                        <option value="may ">5</option>
                                                        <option value="june ">6</option>
                                                        <option value="july ">7</option>
                                                        <option value="august ">8</option>
                                                        <option value="september ">9</option>
                                                        <option value="october ">10</option>
                                                        <option value="november ">11</option>
                                                        <option value="december ">12</option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-6 col-6 ">
                                                    <select name="year " id="yyyy " class="form-control ">
                                                        <option value=" ">yyyy</option>
                                                        <option value="1 ">1</option>
                                                        <option value="2 ">2</option>
                                                        <option value="3 ">3</option>
                                                        <option value="4 ">4</option>
                                                        <option value="5 ">5</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-4 ">
                                    <div class="form-field ">
                                        <label for=" ">{{label.cvv | translate}}</label>
                                        <input type="text " class="form-control ">
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="form-field mt-4">
                            <a href="javascript:void(0)" *ngIf="!isLoading" (click)="addCard()" class="primary-btn submit-btn mr-auto ">{{'button-title.add-card' | translate}}</a>
                            <a href="javascript:void(0)" *ngIf="isLoading" style="background-color: grey;cursor: not-allowed;color: white;border-color: white;" [disabled]="true" class="primary-btn mr-auto ">{{'button-title.processing' | translate}}</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-overlay "></div>
</div>
