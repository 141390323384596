import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { apiColletions } from '../constants/api_collection'

export interface Lang{
    code: string,
    name: string
    string_file_path:string
}

@Injectable({ providedIn: 'root' })
export class CourierService {

    public is_location_show = false;

    constructor(private _api:ApiService){}

    check_delivery_available(city_id, latitude, longitude): Promise<boolean>{
        return new Promise ((resolve, reject) => {
            try{
                var parameters = {
                    city_id,
                    latitude,
                    longitude
                }
                this._api.post({url: apiColletions.check_delivery_available, parameters}).then(response => {
                    if(response.success){
                        resolve(true)
                    } else {
                        resolve(false)
                    }
                })
            } catch {
                resolve(false)
            }
        })
    }

    get_vehicles_list(city_id, delivery_type): Promise<any>{
        return new Promise((resolve, reject) => {
            try {
                var parameters = {
                    city_id,
                    delivery_type
                }
                this._api.post({url: apiColletions.get_vehicles_list, parameters}).then(response => {
                    if(response.success){
                        resolve(response.data)
                    } else {
                        resolve([])
                    }
                })
            } catch {
                resolve([])
            }
        })
    }
}


