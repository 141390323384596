<!-- Main banner start -->

<div class="main-banner">
    <div class="container">
        <div class="b-wrp">
            <div class="banner-img">
                <div class="desktop-banner-img">
                    <img src="../../../assets/images/store-banner.jpg" alt="">
                </div>
                <div class="mob-banner-img">
                    <img src="../../../assets/images/store-banner.jpg" alt="">
                </div>
            </div>
            <!-- {{store | json}} -->

            <!-- <div class="banner-content-wrp"> -->
            <div class="banner-content d-flex align-item-center">
                <div class="store-logo-img" style="border: none;">
                    <img (error)="$event.target.src = DEFAULT_USER_PROFILE" [src]="IMAGE_URL + store_detail.image_url" alt="">
                </div>
                <div class="content-details">
                    <h1>{{ store_detail.name[0] }}</h1>
                    <div class="common-content-list-wrp">
                        <div class="common-content-list review-star">
                            <div class="details-block">
                                <h4>{{store_detail.user_rate}} <img src="../../../assets/images/star.svg" alt=""> </h4>
                                <span>{{store_detail.user_rate_count}} {{'label-title.reviews' | translate}}</span>
                            </div>
                        </div>
                        <div class="common-content-list open-now">
                            <div class="details-block">
                                <h4>{{'label-title.open-status' | translate}}</h4>
                                <span>{{_helper.open_at}}</span>
                                <!-- <span *ngIf="is_store_open">{{'label-title.open' | translate}}</span>
                                <span *ngIf="!is_store_open">{{'label-title.close-at-tomorrow' | translate}}</span> -->
                            </div>
                        </div>
                        <div class="clear">
                            <div class="common-content-list delivery">
                                <div class="details-block" *ngIf="store_detail.country_details">
                                    <h4 class="price-color">{{store_detail.country_details.currency_sign}}{{store_detail.order_cancellation_charge_value}}*</h4>
                                    <span>{{'label-title.delivery-fee' | translate}}</span>

                                    <!-- delivery-fee dropdown start  -->
                                    <div class="delivery-fee common-dropdown small-dropdown">
                                        <div class="dropdown-wrp">
                                            <div class="dropdown-title without-subtitle">
                                                <h2>{{'label-title.delivery-fee' | translate}}</h2>
                                            </div>
                                            <div class="dropdown-content">
                                                <p *ngIf="store_detail.is_store_pay_delivery_fees">
                                                    {{'label-title.user-get-free-delivery-if-order-price-more-than' |
                                                    translate}}
                                                    {{store_detail.country_details.currency_sign}}{{store_detail.free_delivery_for_above_order_price}}
                                                </p>
                                                <p *ngIf="store_detail.is_store_pay_delivery_fees">
                                                    {{'label-title.free-delivery-available-radius' | translate}}
                                                    {{store_detail.free_delivery_within_radius}}
                                                    {{store_detail.country_details.is_distance_unit_mile ? 'Mile' :
                                                    'Km'}}</p>
                                            </div>

                                        </div>
                                    </div>
                                    <!-- delivery-fee dropdown start  -->

                                </div>
                            </div>
                            <div class="common-content-list cancellation">
                                <div class="details-block" *ngIf="store_detail.country_details">
                                    <h4 class="price-color" *ngIf="store_detail.order_cancellation_charge_type === 1">{{store_detail.order_cancellation_charge_value}}%</h4>
                                    <h4 class="price-color" *ngIf="store_detail.order_cancellation_charge_type === 2">{{store_detail.country_details.currency_sign}}{{store_detail.order_cancellation_charge_value}}*</h4>
                                    <span>{{'label-title.cancellation' | translate}}</span>

                                    <!-- cancellation-fee dropdown start  -->
                                    <div class="cancellation-fee common-dropdown small-dropdown">
                                        <div class="dropdown-wrp">
                                            <div class="dropdown-title without-subtitle">
                                                <h2>{{'label-title.cancellation-fees' | translate}}</h2>
                                            </div>
                                            <div class="dropdown-content">
                                                <p
                                                    *ngIf="store_detail.is_order_cancellation_charge_apply && store_detail.order_cancellation_charge_type == 1">
                                                    {{store_detail.order_cancellation_charge_value}}%
                                                    {{'label-title.cancellation-charge-above' | translate}}
                                                    {{store_detail.country_details.currency_sign}}{{store_detail.order_cancellation_charge_for_above_order_price}}
                                                </p>
                                                <p
                                                    *ngIf="store_detail.is_order_cancellation_charge_apply && store_detail.order_cancellation_charge_type == 2">
                                                    {{store_detail.country_details.currency_sign}}{{store_detail.order_cancellation_charge_value}}
                                                    {{'label-title.cancellation-charge-above' | translate}}
                                                    {{store_detail.country_details.currency_sign}}{{store_detail.order_cancellation_charge_for_above_order_price}}
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                    <!-- cancellation-fee dropdown start  -->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- </div> -->
        </div>
    </div>


</div>

<div class="menu-sections mb-100">
    <div class="container">

        <div class="menu-title-wrp d-flex mb-30 flex-wrap">
            <div class="section-title mr-auto">
                <h2 class="mb-0">{{'heading-title.menu' | translate}}</h2>
            </div>
            <div class="menu-right d-flex align-item-center">

                <div class="product-search">
                    <input type="text" [(ngModel)]="search_text" placeholder="{{'heading-title.placeholder-search' | translate}}" class="form-control">
                    <img src="../../../assets/images/search-icon.svg" alt="">
                </div>
            </div>
        </div>


        <div class="product-type">
            <div id='product-type-wrp' *ngFor="let product_group of product_groups; let product_group_index = index" data-tag="tab{{i}}">

              <!-- product title section  -->
              <div class="product-block-title" (click)="onProduct(product_group_index)">
                  <div class="title-block">
                      <div class="title-block-wrp d-flex align-item-center">
                          <div class="title-block-img">
                              <img (error)="$event.target.src = ITEM_DEFAULT_IMAGE" [src]="IMAGE_URL + product_group.image_url" alt="">
                          </div>
                          <div class="title-block-details">
                              <h3>{{ product_group.name }}</h3>
                              <!-- <p>A classic delight with 100% Real mozzarella cheese</p> -->
                          </div>
                      </div>
                  </div>
              </div>
              <!-- product content section  -->
              <div class='product-content-wrp' style="display:block" id="{{product_group_index}}" [hidden]="!product_group.is_show"  *ngIf="products">
                  <ul class="tab d-flex">
                      <li class="tab-li" *ngFor="let product of products; let i = index">
                          <span *ngIf="product_group.name !== 'Main'">
                              <a *ngIf="product_group.product_ids.indexOf(product._id._id) != -1" (click)="onProductSelected(i)" [ngClass]="selected_product === i ? 'activelink' : '' ">{{product._id.name}}</a>
                          </span>
                          <span *ngIf="product_group.name === 'Main'">
                              <a (click)="onProductSelected(i)" [ngClass]="selected_product === i ? 'activelink' : '' ">{{product._id.name}}</a>
                          </span>
                    </li>
                  </ul>

                  <!-- product tab section  -->
                <div id="container-wrp">
                    <div class="list">
                        <div class="row">
                            <div class="col-lg-4 col-sm-6"
                                *ngFor="let item of products[selected_product].items | searchpipe:search_text:['name'];let i=index">
                                <div class="product-block product-tab">
                                    <div class="product-list d-flex align-item-center">
                                        <div class="product-img">
                                            <img (error)="$event.target.src = ITEM_DEFAULT_IMAGE" [src]="IMAGE_URL + item.image_url"
                                                alt="">
                                        </div>
                                        <div class="product-details">
                                            <a href="javascript:void(0)" data-id="product-popup" (click)="onModelOpen(item)"
                                                class="custom-popup">{{item.name}}</a>
                                            <p>{{item.details}}</p>
                                            <div class="product-bottom d-flex align-item-center">
                                                <div class="product-price mr-auto">{{store_detail.country_details.currency_sign}}{{item.total_item_price}}</div>
                                                <div class="add-to-cart-bttn"><a href="javascript:void(0)" (click)="onModelOpen(item)"
                                                        class="primary-btn secondary-btn">{{'button-title.add' | translate}}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

              <!-- product title section2  -->


                  </div>
              </div>

          </div>
        </div>

    </div>
</div>

<!-- Menu section end -->

<!-- About section start  -->

<div class="about-section mb-4">
    <div class="container">
        <div class="address-section">
            <div class="row">
                <div class="col-sm-6 time-list">
                    <div class="section-title section-title-with-text mb-10">
                        <h3 class="mb-0">{{'heading-title.address' | translate}}</h3>
                    </div>
                    <address>{{store_detail.address}} <br>{{store_detail.country_phone_code}} {{store_detail.phone}}</address>
                    <a href="javascript:void(0)" (click)="gotoDirection()">{{'button-title.get-directions' | translate}}</a>
                    <div class="store-hours">
                        <div class="section-title section-title-with-text mb-10">
                            <h3 class="mb-0">{{'label-title.hours' | translate}}</h3>
                            <p class="mb-0">{{'label-title.all-times-are-in' | translate}}  {{_helper.timezone}}</p>
                        </div>
                        <div class="time-list-block-wrp">
                            <div class="row time-list-block" *ngFor="let time of store_detail.store_time">
                                <div class="col-md-4 col-4 order2">
                                    <span>{{'days.'+time.day | translate}}</span>
                                </div>
                                <div class="col-md-8 col-8 order1" *ngIf="!time.is_store_open_full_time">
                                    <div class="time" *ngFor="let time of time.day_time"><span>{{time.store_open_time}} - {{time.store_close_time}}</span></div>
                                </div>
                                <div class="col-md-8 col-8 order1" *ngIf="time.is_store_open_full_time">
                                    <div class="time"><span>{{'label-title.open' | translate}} </span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="store-map">
						<div id="map" style="width: 100%; min-height: 400px;margin-top:20px;"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- About section end  -->

<!-- product card popup start  -->

<div class="custom-model-main product-popup my-scroll-area fix" id="product-popup">
    <div class="custom-model-inner">
        <div class="close-btn" id="close_item_model">×</div>
        <div class="custom-model-wrap">
            <div class="pop-up-content-wrap">
                <div class="dropdown-title">
                    <h2>{{ selected_item.name }}</h2>
                </div>
                <div class="dropdown-content">
                    <div class="pro-dec">
                        <p>{{ selected_item.details }}</p>
                    </div>
                    <div class="product-img text-center mb-30">
                        <img (error)="$event.target.src = ITEM_DEFAULT_IMAGE" [src]="IMAGE_URL + selected_item.image_url" alt="">
                    </div>

                    <div *ngFor="let specification of selected_item.specifications; let i = index">
                        <div class="product-options-block" *ngIf="specification.type === 1">
                            <div class="subtitle-wrp d-flex align-item-top">
                                <div class="choose-item-wrp d-flex flex-wrap">
                                    <h4>{{specification.name[0]}}</h4>
                                    <div class="choose">{{'label-title.choose1' | translate}}</div>
                                </div>
                                <div class="required-star" *ngIf="specification.is_required">{{'label-title.required' | translate}}</div>
                            </div>
                            <div class="options-list-block">
                                <form action="">
                                    <div class="options-list-wrp d-flex align-item-center" *ngFor="let spec of specification.list; let spec_index = index">
                                        <label for="opt{{spec_index}}{{i}}" class="radio mr-auto">
                                            <input type="radio" [checked]="spec.is_default_selected" id="opt{{spec_index}}{{i}}" (change)="changeradio($event, i, spec_index)" name="opt{{i}}" class="hidden"/>
                                            <span class="label"></span>{{spec.name[0]}}
                                        </label>
                                        <div class="options-price">
                                            <span *ngIf="spec.price > 0">{{spec.price | roundpipe:2}}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="product-options-block" *ngIf="specification.type === 2">
                            <div class="subtitle-wrp d-flex align-item-top">
                                <div class="choose-item-wrp d-flex flex-wrap">
                                    <h4>{{specification.name[0]}}</h4>
                                    <div class="choose">
                                        <span>{{'label-title.choose' | translate}} {{specification.range}}</span>
                                        <span *ngIf="specification.max_range !== 0">&nbsp;{{'label-title.to' | translate}}&nbsp;{{specification.max_range}}</span></div>
                                </div>
                                <div class="required-star" *ngIf="specification.is_required">{{'label-title.required' | translate}}</div>
                            </div>
                            <div class="options-list-block">
                                <form action="">
                                    <div class="options-list-wrp d-flex align-item-center" *ngFor="let spec of specification.list; let spec_index = index">
                                        <label class="d-flex checkbox align-item-center mr-auto" >
                                            <input type="checkbox" [checked]="spec.is_default_selected" *ngIf="specification.type==2 && (specification.max_range == 0 || specification.max_range == null)" [disabled]="specification.default_selected_count == specification.range && specification.range !== 0 && !spec.is_default_selected" (change)="changecheckbox($event, i, spec_index)" class="option-input checkbox">
                                            <input type="checkbox" [checked]="spec.is_default_selected" *ngIf="specification.type==2 && specification.max_range != 0 && specification.max_range != null" [disabled]="specification.default_selected_count == specification.max_range && !spec.is_default_selected" (change)="changecheckbox($event, i, spec_index)" class="option-input checkbox">{{spec.name[0]}}
                                        </label>
                                        <div class="options-price">
                                            <span *ngIf="spec.price > 0">{{spec.price}}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>



                    <div class="product-options-block">
                        <h4>{{'heading-title.preferences' | translate}}</h4>
                        <div class="options-list-block">
                            <h5 class="small-title">{{'label-title.any-special-instructions' | translate}}</h5>
                                <textarea class="form-control" [(ngModel)]="note_for_item"  cols="30" rows="5"></textarea>
                        </div>
                    </div>
                </div>
                <div class="popup-footer">
                    <div class="popup-footer-wrp d-flex align-item-center">
                        <div class="product-qty d-flex align-item-center">
                            <a href="javascript:void(0)" class="qty-btn qty-dec" (click)="decrease_qty()">-</a>
                            <input type="text" class="form-control" value="{{qty}}">
                            <a href="javascript:void(0)" class="qty-btn qty-inc" (click)="incerase_qty()">+</a>
                        </div>
                        <div class="add-to-cart-btn" *ngIf="store_detail.country_details">
                            <button class="primary-btn" (click)="handleClickOnAddToCart()" [disabled]="required_count !== required_temp_count">{{'button-title.add-to-order' | translate}} - {{store_detail.country_details.currency_sign}} {{total | roundpipe:2}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-overlay"></div>
</div>

<!-- product card popup end  -->

<!-- Read more review popup start  -->

<div class="custom-model-main" id="review-content">
    <div class="custom-model-inner">
        <div class="close-btn">×</div>
        <div class="custom-model-wrap">
            <div class="pop-up-content-wrap">
                <div class="dropdown-content">
                    <div class="customer-name">
                        <h3>Ella</h3>
                    </div>
                    <div class="customer-review-count">
                        <div class="d-flex align-item-center">
                            <span>3.0</span> <img src="../../../assets/images/star-pink.svg" alt="">
                        </div>
                    </div>
                    <div class="customer-text-popup">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type
                            specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
                            passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-overlay"></div>
</div>

<!-- Read more review popup start  -->

<!-- Clear Cart popup start  -->

<div class="custom-model-main" id="clear-cart-content">
    <div class="custom-model-inner">
        <div class="close-btn" id="close-clear-cart" hidden>×</div>
        <div class="custom-model-wrap">
            <div class="pop-up-content-wrap">
                <div class="dropdown-content">
                    <div class="customer-name">
                        <h3>{{'heading-title.attention' | translate}}</h3>
                    </div>
                    <div class="customer-text-popup">
                        <p>{{'heading-title.clear-cart-content' | translate}}</p>
                    </div>
                    <div>
                        <div class="primary-btn secondary-btn c-pointer" (click)="clearCart()">{{'label-title.ok' | translate}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-overlay"></div>
</div>

<!-- Clear Cart popup End  -->
