import { environment } from "src/environments/environment";
import { CONFIG } from "../constants/constants";

export class AdModel {
    ads_detail: string;
    expiry_date: Date;
    image_for_banner: string;
    image_for_mobile: string;
    private image_url: string;
    is_ads_have_expiry_date: boolean;
    store_detail: any;
    is_ads_redirect_to_store: boolean;
    store_id: string;
    _id: string;
  
    static _width:number = 500;
  
    constructor(ad:AdModel){
      if(ad){
        this.ads_detail = ad.ads_detail;
        this.expiry_date = ad.expiry_date;
        this.image_for_banner = ad.image_for_banner;
        this.image_url = ad.image_url;
        this.ads_detail = ad.ads_detail;
        this.is_ads_have_expiry_date = ad.is_ads_have_expiry_date;
        this.store_detail = ad.store_detail;
        this.is_ads_redirect_to_store = ad.is_ads_redirect_to_store;
        this.store_id = ad.store_id;
        this._id = ad._id;
      }
    }
  
    get imageUrl(){
      if(CONFIG.IS_USE_AWS){
        return `${CONFIG.API_URL}/resize_image?image=${CONFIG.IMAGE_URL}${this.image_url}&width=${AdModel._width}&format=jpg`;
      }else{
        return `${CONFIG.API_URL}/resize_image?image=/${this.image_url}&width=${AdModel._width}&format=jpg`;
      }
    }
  
    static convertArrayToObject(array:Array<any> = [],ad_width = 200){
      this._width = ad_width;
      var convertedArray:Array<AdModel> = [];
      array.forEach(element => {
        convertedArray.push(new AdModel(element))
      });
      return convertedArray;
    }
  
  }