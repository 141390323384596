import { resolve } from "@angular/compiler-cli/src/ngtsc/file_system";
import { Injectable } from "@angular/core";
import { rejects } from "assert";
import { BehaviorSubject, Observable } from "rxjs";
import { apiColletions } from "../constants/api_collection";
import { SetttingModel } from "../models/setting.model";
import { UserModel } from "../models/user.model";
import { ApiService } from "./api.service";
import { ProfileService } from "./profile.service";

@Injectable({ providedIn: 'root' })
export class AuthService {

    private logginUser:UserModel;
    private loginSubject = new BehaviorSubject<UserModel>(null);
    loginObservable:Observable<UserModel> = this.loginSubject.asObservable();
    private verificationSubject = new BehaviorSubject<boolean>(false);
    verificationObservable:Observable<boolean> = this.verificationSubject.asObservable();
    
    constructor(private _api:ApiService,private _profileService:ProfileService){}


    get isAuthenticated():boolean{
        return (!this.logginUser || this.logginUser === null) ? false : true;
    }

    get user(){
        return this.logginUser ? this.logginUser : new UserModel();
    }

    // Login

    user_login({uuid,username,password,social_id="",login_by="manual"}):Promise<boolean>{
        return new Promise((resolve,rejects)=>{
            try{
                var parameters = {
                    cart_unique_token: uuid,
                    email:username,
                    password,
                    login_by,
                    social_id
                };
                this._api.post({url:apiColletions.login,parameters}).then((response)=>{
                    if(response.success){
                        this.logginUser = response.data.user;
                        this.loginSubject.next(this.logginUser);
                        var localStorageData = {
                            _id:this.logginUser._id,
                            server_token:this.logginUser.server_token
                        }
                        localStorage.setItem('userData',JSON.stringify(localStorageData))
                        resolve(true);
                    }else{
                        resolve(false);
                    }
                })
            }catch(err){
                resolve(true);
            }
        })
    }

    user_logout():Promise<boolean>{
        return new Promise((resolve,rejects)=>{
            this.logginUser = null;
            this.loginSubject.next(this.logginUser);
            localStorage.removeItem('userData')
            resolve(true)
        })
    }


    user_autologin({user_id,server_token,setting = new SetttingModel()}):Promise<boolean>{
        return new Promise((resolve,rejects)=>{
            this._profileService.fetch_profile({user_id,server_token}).then(user=>{
                // this.check_verification(user,setting);
                this.loginSubject.next(user);
                resolve(true)
            })
        })
    }

    check_verification(user,setting){
        if(user === null){
            this.user_logout();
        }
        this.logginUser = user;
        var is_verified = false; 
        if(setting.is_user_mail_verification && setting.is_user_sms_verification){
            is_verified = user.is_email_verified && user.is_phone_number_verified;
        }else if(setting.is_user_mail_verification){
            is_verified = user.is_email_verified;
        }else if(setting.is_user_sms_verification){
            is_verified = user.is_phone_number_verified;
        }else{
            is_verified = true;
        }
        if(!is_verified){
            this.verificationSubject.next(true);
        }
    }
    
    // Register

    user_register(userForm):Promise<boolean>{
        return new Promise((resolve,rejects)=>{
            try{
                this._api.post({url:apiColletions.register,parameters:userForm}).then((response)=>{
                    if(response.success){
                        this.logginUser = response.data.user;
                        this.loginSubject.next(this.logginUser);
                        var localStorageData = {
                            _id:this.logginUser._id,
                            server_token:this.logginUser.server_token
                        }
                        localStorage.setItem('userData',JSON.stringify(localStorageData))
                        resolve(true);
                    }else{
                        resolve(false);
                    }
                })
            }catch(err){
                resolve(false);
            }
        })
    }

    // Forgot Password

    user_forgot_password({phone}):Promise<boolean>{
        return new Promise((resolve,rejects)=>{
            try{
                var parameters = {phone,type:7};
                this._api.post({url:apiColletions.forgot_password,parameters}).then((response)=>{
                    if(response.success){
                        resolve(true);
                    }else{
                        resolve(false);
                    }
                })
            }catch(err){
                resolve(false);
            }
        })
    }

    user_forgot_password_verify({phone,otp=''}):Promise<{success:boolean,id:string,server_token:string}>{
        return new Promise((resolve,rejects)=>{
            try{
                var parameters = {phone,otp,type:7};
                this._api.post({url:apiColletions.forgot_password_verify,parameters}).then((response)=>{
                    if(response.success){
                        resolve({
                            id:response.data.id,
                            server_token:response.data.server_token,
                            success:true
                        });
                    }else{
                        resolve({
                            id:'',
                            server_token:'',
                            success:false
                        });
                    }
                })
            }catch(err){
                resolve({
                    id:'',
                    server_token:'',
                    success:false
                });
            }
        })
    }

    user_reset_password({password='',id='',server_token=''}){
        return new Promise((resolve,rejects)=>{
            try{
                var parameters = {password,type:7,id,server_token};
                this._api.post({url:apiColletions.reset_password,parameters}).then((response)=>{
                    if(response.success){
                        resolve(true);
                    }else{
                        resolve(false);
                    }
                })
            }catch(err){
                resolve(false);
            }
        })
    }

    // Verification

    user_generate_otp({email='',phone='',country_phone_code=''}):Promise<{
        otp_for_email: string
        otp_for_sms: string
        success: boolean
    }>{
        return new Promise((resolve,rejects)=>{
            try{
                var parameters = { type: 7, email, phone, country_phone_code };
                this._api.post({url:apiColletions.generate_otp,parameters}).then((response)=>{
                    if(response.success){
                        resolve({
                            success:true,
                            otp_for_email:response.data.otp_for_email,
                            otp_for_sms:response.data.otp_for_sms
                        });
                    }else{
                        resolve({
                            success:false,
                            otp_for_email:'',
                            otp_for_sms:''
                        });
                    }
                })
            }catch(err){
                resolve({
                    success:false,
                    otp_for_email:'',
                    otp_for_sms:''                    
                });
            }
        })
    }

    user_verify_otp(parameters){
        return new Promise((resolve,rejects)=>{
            try{
                this._api.post({url:apiColletions.verify_otp,parameters}).then((response)=>{
                    if(response.success){
                        resolve(true);
                    }else{
                        resolve(false);
                    }
                })
            }catch(err){
                resolve(false);
            }
        })
    }

}