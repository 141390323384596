import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { LoaderService } from "../services/loader.service";
import { NotifiyService } from "../services/notifier.service";
import { BehaviorSubject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";

declare var swal: any;
declare var $: any;


export enum ALERT_TYPE{
    WARNING,SUCCESS,ERROR,INFO
}

@Injectable({
    providedIn: 'root'
})
export class Helper {

    CartChange = new BehaviorSubject<any>(null);
    store_id: any = '';
    country_id: any;
    city_id: any = '';
    interval: any;
    store_delivery_id: any;
    private _cart_unique_token;
    declined: Boolean = false;
    open_at: any = '';
    current_close: Boolean = true;
    timezone: any = '';


    constructor(public _loader:LoaderService,
        public _route:Router,
        public trans:TranslateService,
        public ngZone: NgZone,
        private _translateService: TranslateService) { }

    phone_number_validation(evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57) || charCode === 101) {
            return false;
        }
        return true;
    }

    number_validation(evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            if (charCode == 46) {
                return true;
            }
            else {
                return false;
            }
        }
        return true;
    }

    space_validation(evt) {
        if (evt.code == "Space" && evt.target.value.length < 1) {
            return false;
        }
        return true
    }

    openModel(id){
        console.log(id)
        $(".custom-model-main").removeClass('model-open');
        $("body").addClass('model-open');
        $('#'+id).addClass('model-open');
    }

    get cart_unique_token(): string {
        return this._cart_unique_token;
    }

    set cart_unique_token(token){
        this._cart_unique_token = token;
    }

    get generate_new_uuid():string{
        return 'xxxxxxxx-xxxx-xxx-xxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    open_check(storeTime){

        var local_time = new Date();
        let time1 = parseInt(moment().tz(this.timezone).format("ZZ"))
        let time2 = parseInt(moment(new Date()).format("ZZ"))
        var diff1 = String(parseInt(moment().tz(this.timezone).format("ZZ"))- parseInt(moment(new Date()).format("ZZ")));
        var hour = "0";
        var minute = "0";
        if(diff1[0]){
            hour = diff1[0];
        }
        if(diff1[1]){
          minute = diff1[1]+diff1[2];
        }
        
        
        var total_minute = ((Number(hour) * 60) + Number(minute)) * 60000;
        if( diff1[0] == "-"){
            total_minute = total_minute * -1;
        }
        var date = new Date(Number(new Date()) + total_minute);

        let weekday = date.getDay();
        let current_time = date.getTime();
        this.open_at = '';
        var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

        var day;
        let week_index = storeTime.findIndex((x) => x.day == weekday)
         
        let day_time = storeTime[week_index].day_time;
        if (storeTime[week_index].is_store_open) {

            if (storeTime[week_index].is_store_open_full_time) {
                this.open_at = this._translateService.instant('open-for-full-day');
            }else {

                if (day_time.length == 0) {
                }else{
                    day_time.forEach((store_time, index) => {
                        let x = date.setHours(Math.floor( store_time.store_open_time / 60), store_time.store_open_time % 60, 0, 0)
                        let x1 = new Date(x);
                        let x2 = x1.getTime();

                        let y = date.setHours(Math.floor( store_time.store_close_time / 60), store_time.store_close_time % 60, 0, 0)
                        let y1 = new Date(y);
                        let y2 = y1.getTime();

                        if (current_time > x2 && current_time < y2) {
                            this.current_close = false;
                            let y = date.setHours(Math.floor( store_time.store_close_time / 60), store_time.store_close_time % 60, 0, 0)
                            let y1 = new Date(y);
                            let y2 = y1.getTime();

                            if(((y2-current_time)/60000) < 30){
                                this.open_at = this._translateService.instant('close-in')+' '+Math.floor((y2-current_time)/60000)+' '+this._translateService.instant('mins');
                            }else{
                                var am_pm = 'AM';
                                var hour_12 = Math.floor( store_time.store_close_time / 60);
                                if(hour_12 > 12){
                                    hour_12 = hour_12 - 12;
                                    am_pm = 'PM';
                                }
                                this.open_at = this._translateService.instant('open-till')+' '+this.pad2(hour_12) + ':' +this.pad2(store_time.store_close_time % 60) + ' '+am_pm;
                            }
                        }

                        if (current_time < x2 && this.open_at == '') {
                            var am_pm = 'AM';
                            var hour_12 = Math.floor( store_time.store_open_time / 60);
                            if(hour_12 > 12){
                                hour_12 = hour_12 - 12;
                                am_pm = 'PM';
                            }
                            if(((x2-current_time)/60000) < 30){
                                this.open_at = this._translateService.instant('open_in')+ ' ' +Math.floor((x2-current_time)/60000)+' '+this._translateService.instant('mins');;
                            }else{
                                this.open_at = this._translateService.instant('open-at')+ ' ' +this.pad2(hour_12) + ':' +this.pad2(store_time.store_open_time % 60) + ' '+am_pm;
                            }
                        }
                    });
                    if(this.open_at == ''){

                        if(this.current_close == false){

                        }else{
                            for(let i =1;i< 7;i++){
                              day = week_index + i;
                              if(day > 6){
                                day = day - 7;
                              }

                              if(storeTime[day].is_store_open){

                                if (storeTime[day].is_store_open_full_time || storeTime[day].day_time.length != 0){

                                    if(day == (week_index + 1)){
                                      this.open_at = this._translateService.instant('open-at')+ ' '+this._translateService.instant('tomorrow');
                                    }else{

                                      this.open_at = this._translateService.instant('open-at')+ ' ' +this._translateService.instant(days[day]);
                                    }
                                    break;

                                }
                              }
                            }
                        }
                    }
                }
            }
        }else{

            for(let i =1;i< 7;i++){
              day = week_index + i;
              if(day > 6){
                day = day - 7;
              }

              if(storeTime[day].is_store_open){

                if (storeTime[day].is_store_open_full_time || storeTime[day].day_time.length != 0){

                    if(day == (week_index + 1)){
                      this.open_at = this._translateService.instant('open-at')+ ' ' +this._translateService.instant('tomorrow');
                    }else{
                      this.open_at = this._translateService.instant('open-at')+ ' ' +this._translateService.instant(days[day]);
                    }
                    break;

                }
              }
            }
        }

          if(this.open_at == '' && !this.current_close){
            let open_index = storeTime.findIndex((x) => (x.is_store_open_full_time == true) ||  (x.day_time.length > 0 && x.is_store_open == true))
            if(open_index == -1){
              this.open_at =this._translateService.instant('open-business-off');
            }
          }

    }


    open_dialog(alert_type:ALERT_TYPE,title,subtitle){
        swal(title, subtitle, ALERT_TYPE[alert_type].toLowerCase());
    }


    pad2(number) {
        return (number < 10 ? '0' : '') + number
    }

    loadScript(name: string):Promise<boolean> {
        return new Promise((resolve, reject) => {
            let script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = name;
            document.getElementsByTagName('head')[0].appendChild(script);
            resolve(true)
        });
    }

}
