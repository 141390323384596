export class cartProductsModel {
    items: Array<ItemModel> = [];
    product_id: string = null;
    product_name: string = null;
    unique_id: number = 0;
    total_item_tax: number = 0;
    total_item_price: number = 0;    
}

export class ItemModel{
    item_id = null;
    unique_id= 0;
    item_name= '';
    item_tax=0;
    quantity= 0;
    image_url= [];
    details= '';
    specifications:Array<cartSpecificationGroupsModel> = [];
    item_price= 0;
    total_price= 0;
    tax= 0;
    total_specification_price= 0;
    total_specification_tax= 0;
    total_item_price= 0;
    max_item_quantity= 0;
    total_tax= 0;
    total_item_tax= 0;
    note_for_item='';
}


export class cartSpecificationGroupsModel {
    list: any = [];
    name: string = null;
    price: number = 0;
    type: number = 0;
    unique_id: number = 0;
    range: number = 0;
    max_range: number = 0;
}

export class CartData{
    cart: Array<cartProductsModel> = [];
    cart_id: string = null;
    city_id: string = null;
    destination_addresses: Array<cartAddress> = [new cartAddress('destination')];
    pickup_addresses: Array<cartAddress> = [new cartAddress('pickup')];
    max_item_quantity_add_by_user:number = 0
    selectedStoreId:string = null;
    total_item: number = 0;
    option_for_delivery: number = 0;
    apartment: string = '';
    building_name: string = '';
    business_name: string = '';
}

export class Cart {
    cart_data : CartData = new CartData();
    total_item_tax: number = 0;
    total_cart_amount: number = 0;
    cart_main_item: any[] = [];                 // For Edit Order Get Main Item with Specifications
    busy_premium_time: any[] = [];                 // For Edit Order Get Main Item with Specifications
    store_time: any[] = [];                 // For Edit Order Get Main Item with Specifications
    store_delivery_time: any[] = [];                 // For Edit Order Get Main Item with Specifications
    is_user_pick_up_order: Boolean = false;
    is_schedule_order: Boolean = false;
    is_busy_period: Boolean = false;
    schedule_date: any = new Date();
    schedule_date1: any = new Date();
    clicked_date: string = '';
    time_zone: string = '';
    server_time: any = new Date();
    
    
    // store_location : any [];
    // minimum_phone_number_length: number = 8;
    // maximum_phone_number_length: number = 12;
    // delivery_note: string = '';
    // delivery_user_name: string = '';
    // delivery_user_phone: string = '';
    // cart_main_item: any[] = [];
    // user_profile_image_url: string = '';
    // myLoading: Boolean = false;
    // timezone: string = '';
    // after_login: string = "deliveries";
    // order_payment_id: Object = null;
    
}

export class cartAddress {
    delivery_status:number = 0;
    address_type:string = null;
    address:string = "";
    city:string = "";
    location:Array<number> = [0,0];
    note:string = "";
    user_type:number = 0;
    user_details:any  = {
        name:"",
        country_phone_code:"",
        phone:"",
        email:""
    }

    constructor(address_type){
        this.address_type = address_type;
    }

}