<div class="account-content page-content">
    <div class="container">
        <div class="profile-detail-wrp-heading d-flex align-item-top"><h2>{{'heading-title.account-page' | translate}}</h2></div>
        <div class="profile-detail-wrp d-flex align-item-top">

            <div class="profile-content">
                <div class="d-flex align-item-center mb-30">
                    <div class="profile-img">
                      <label for="file-input">
                        <img (error)="$event.target.src = DEFAULT_IMAGE" [src]="profile_image" [alt]="user.first_name">
                      </label>
                      <input (change)="onSelectImageFile($event)" id="file-input" type="file" />
                    </div>
                    <div class="profile-name">
                        <h3>{{user.first_name}} {{user.last_name}}</h3>
                    </div>
                </div>

                <div class="profile-form">
                    <form [formGroup]="userForm" (ngSubmit)="updateProfile()">
                        <div class="row row-list1">
                            <div class="col-sm-6 row-list">
                                <label for="">{{'label-title.first-name' | translate}}</label>
                                <input formControlName="first_name" type="text" class="form-control" required>
                                <ng-container *ngIf="userForm.get('first_name').errors && userForm.get('first_name').touched">
                                    <span *ngIf="userForm.get('first_name').errors.required" class="text-danger">{{'validation-title.first-name-is-required' | translate}}</span>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 row-list">
                                <label for="">{{'label-title.last-name' | translate}}</label>
                                <input formControlName="last_name" type="text" class="form-control" required>
                                <ng-container *ngIf="userForm.get('last_name').errors && userForm.get('last_name').touched">
                                    <span *ngIf="userForm.get('last_name').errors.required" class="text-danger">{{'validation-title.last-name-is-required' | translate}}</span>
                                </ng-container>
                            </div>
                        </div>
                        <div class="row row-list2">
                            <div class="col-sm-6 row-list">
                                <label for="">{{'label-title.email' | translate}}</label>
                                <input formControlName="email" type="text" class="form-control" required email>
                                <ng-container *ngIf="userForm.get('email').errors && userForm.get('email').touched">
                                    <span *ngIf="userForm.get('email').errors.required" class="text-danger">{{'validation-title.email-is-required' | translate}}</span>
                                    <span *ngIf="userForm.get('email').errors.email" class="text-danger">{{'validation-title.email-is-Invalid' | translate}}</span>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 row-list">
                                <label for="">{{'label-title.phone-no' | translate}}</label>
                                <input (keypress)="_helper.phone_number_validation($event)" formControlName="phone" type="text" class="form-control" minlength="9" maxlength="12" required>
                                <ng-container *ngIf="userForm.get('phone').errors && userForm.get('phone').touched">
                                    <span *ngIf="userForm.get('phone').errors.required" class="text-danger">{{'validation-title.phone-is-required' | translate}}</span>
                                    <span *ngIf="userForm.get('phone').errors.minlength" class="text-danger">{{'validation-title.phone-is-invalid' | translate}}</span>
                                </ng-container>
                            </div>
                        </div>
                        <div class="row row-list2" *ngIf="user.login_by === 'manual'">
                            <div class="col-sm-6 row-list">
                                <label for="">{{'label-title.current-password' | translate}}</label>
                                <input formControlName="password" type="password" class="form-control">
                                <ng-container *ngIf="userForm.get('password').errors && userForm.get('password').touched && userForm.get('password').touched">
                                    <span *ngIf="userForm.get('password').errors.required" class="text-danger">{{'validation-title.password-is-required' | translate}}</span>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 row-list">
                                <label for="">{{'label-title.new-password' | translate}}</label>
                                <input formControlName="new_password" type="password" class="form-control">
                            </div>
                        </div>
                        <button type="submit" [disabled]="userForm.invalid" class="primary-btn">{{'button-title.save' | translate}}</button>
                    </form>
                </div>
            </div>
        </div>

    </div>
</div>
