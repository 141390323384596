import { Injectable } from "@angular/core";
import { ProductGroup } from "../views/landing/landing.component"
import { apiColletions } from "../constants/api_collection";
import { Helper } from "../shared/helper";
import { ApiService } from "./api.service";

export interface get_product_group_list{
    product_groups:Array<ProductGroup>;
}

@Injectable({ providedIn: 'root' })
export class MenuService {


    constructor(private _api:ApiService,private helper:Helper){}

    async get_product_group_list(store_id:string): Promise<Array<any>>{
        return new Promise((resolve,rejects)=>{
            try{
                var parameters = {
                    store_id
                };
                this._api.post({url:apiColletions.get_product_group_list,parameters}).then((response)=>{
                    if(response.success){
                        resolve(response.data.product_groups);
                    }else{
                        resolve([]);
                    }
                })
            }catch(err){
                resolve([]);
            }
        })
    }

    async user_get_store_product_item_list({product_ids, server_token, user_id, store_id}):Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                var parameters = {
                    store_id,
                    product_ids,
                    server_token,
                    user_id
                }
                this._api.post({url: apiColletions.user_get_store_product_item_list, parameters}).then(response => {
                    if(response.success){
                        resolve(response.data)
                    } else {
                        resolve({})
                    }
                })
            } catch {
                resolve({})
            }
        })
    }

    async get_store_data(json): Promise<any>{
        return new Promise((resolve, reject) => {
            try {
                this._api.post({url: apiColletions.get_store_data, parameters: json}).then(response => {
                    if(response.success){
                        resolve(response.data)
                    } else {
                        resolve({})
                    }
                })
            } catch {
                resolve({})
            }
        })
    }
    
}


