import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {

  isLoading = false;
  loaderSubscription:Subscription;

  constructor(public _loaderService:LoaderService) {}

  ngOnInit(): void {
    this.loaderSubscription = this._loaderService.loaderObservable.subscribe((loader:boolean)=>{
      this.isLoading = loader;
    })

  }

}
