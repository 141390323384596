export class UserModel{
    _id:string = '';
    first_name:string = '';
    last_name:string = '';
    image_url:string = '';
    email:string = '';
    phone:string = '';
    is_approved:boolean = false;
    is_document_uploaded:boolean = false;
    is_email_verified:boolean = false;
    is_use_wallet:boolean = false;
    is_phone_number_verified:boolean = false;
    cart_id:string = '';
    login_by:string = '';
    server_token: string = '';
    social_id: string = '';
    social_ids:Array<string> = []
    country_code: string = '';
    country_id: string = '';
    country_phone_code: string = '';
    wallet_currency_code: string = '';
    user_type: number = 1;
    favourite_stores: Array<string> = [];
    
    // address: "Rajkot"
    // admin_type: 7
    // city: "Rajkot"
    // comments: "New Register"
    // created_at: "2018-04-27T10:41:46.990Z"
    // current_order: ''
    // customer_id: "cus_HGOOHZijn3l29a"
    // favourite_stores: ["5b45bfbb58568b4398f980fb"]
    // is_referral: false
    // is_use_wallet: false
    // is_user_type_approved: true
    // orders: []
    // otp: ""
    // password: "e10adc3949ba59abbe56e057f20f883e"
    // payment_intent_id: ""
    // promo_count: -11
    // provider_rate: 4.58
    // provider_rate_count: 101
    // referral_code: "IN965GV4AG"
    // referred_by: ''
    // store_rate: 4.94
    // store_rate_count: 15
    // total_referrals: 1
    // unique_id: 1149
    // updated_at: "2021-03-05T08:09:03.590Z"
    
    // user_type_id: ''
    // wallet: 12057.57
    // wallet_currency_code: "INR"
}