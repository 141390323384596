import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { ALERT_TYPE, Helper } from 'src/app/shared/helper';
import { environment } from 'src/environments/environment';
import { LocationService } from 'src/app/services/location.service';
import { StoreModel } from 'src/app/models/store.model';
import { AdModel } from 'src/app/models/ad.model';
import { Subscription } from 'rxjs';
import { CartService } from 'src/app/services/cart.service';
import { AuthService } from 'src/app/services/auth.service';
import * as moment from 'moment-timezone';
import { CONFIG, DEFAULT_IMAGE } from 'src/app/constants/constants';
import { TranslateService } from '@ngx-translate/core';
import { NotifiyService } from 'src/app/services/notifier.service';

declare var $:any;

export class Deliveries {
  delivery_name: string = "";
  delivery_type: number = 0;
  description: string = "";
  famous_products_tags: Array<string> = [];
  icon_url: string = "";
  image_url: string = "";
  is_business: boolean = false;
  is_store_can_create_group: boolean = false;
  map_pin_url: string = "";
  sequence_number: number = 0;
  unique_id: number = 0;
  _id: string = "";
}


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  search_store = '';
  search_stores = [];
  // DataSource
  stores: Array<StoreModel> = [];
  filter_stores: Array<StoreModel> = [];
  ads: Array<AdModel> = [];
  slider_ads: Array<any> = [];
  city_id: string;
  deliveries: Array<Deliveries> = [];
  selectedDelivery: Deliveries = new Deliveries();
  favourite_store: Array<any> = [];
  promos: Array<any> = []
  slider_promo: Array<any> = []

  // Config
  IMAGE_URL = CONFIG.IMAGE_URL;
  DEFAULT_STORE_IMAGE = DEFAULT_IMAGE.STORE_PROFILE;
  // DEFAULT_STORE_IMAGE = StoreModel.defaultImage;
  DEFAULT_IMAGE_DELIVERY = DEFAULT_IMAGE.DELIVERY;
  DEFAULT_IMAGE_ADS = DEFAULT_IMAGE.ADS;

  // Search
  search_text;

  // Pagination on Scroll
  page: number = 1;
  perpage: number = 15;
  total_stores: number = 0;
  throttle = 300;
  scrollDistance = 1;

  // Filter
  distance_filter_array = [
    {title:1,checked:false},
    {title:2,checked:false},
    {title:3,checked:false},
    {title:4,checked:false},
    {title:5,checked:false},
  ];
  time_filter_array = [
    {title:[0,10],checked:false},
    {title:[10,20],checked:false},
    {title:[20,30],checked:false},
    {title:[30,40],checked:false},
    {title:[40,50],checked:false},
    {title:[50,60],checked:false},
  ];
  price_filter_array = [
    {title:1,checked:false},
    {title:2,checked:false},
    {title:3,checked:false},
    {title:4,checked:false},
    {title:5,checked:false},
  ];
  tags_filter_array = [];
  locationSubscription:Subscription;

  // Schedule Order
  date_array: any[] = [];
  dateIndex: number = 0;
  time_array: any[] = [];
  slot1: Boolean = false;
  slot2: Boolean = false;
  slot3: Boolean = false;
  slot4: Boolean = false;
  clicked_date: any = null;
  selected_time: any = null;
  currency: string = '';
  schedule_time: string = '';
  schedule_date: any = '';
  timezone: "Asia/Kolkata";
  moment = moment();


  constructor(public _helper: Helper, 
    private _commonService: CommonService, 
    private _locationService: LocationService,
    public _cartService:CartService,
    private _notifierService:NotifiyService,
    private _authService:AuthService,
    private _translateService: TranslateService) { }

  ngOnInit(): void {


    this.lang_change_month_day();
    if(this._authService.isAuthenticated){
      this.favourite_store = this._authService.user.favourite_stores
    } else {
      this.favourite_store = [];
    }
    this.resetStoreData();
    this.locationSubscription = this._locationService.locationObservable.subscribe((locationChange=>{
      if(locationChange != null){
          this.fetchDeliveryandAds()
        }
    }));
  }

  onScrollDown() {
    if (this.stores.length < this.total_stores) {
      this.page = this.page + 1;
      this.fetchStores()
    }
  }

  adRedirect(ad:AdModel){
    if(ad.is_ads_redirect_to_store){
      this.onStoreClick(ad.store_id)
    }
  }
  remove_value(){
    this.search_store = '';
  }
  fetchDeliveryandAds() {
    this._helper._loader.isLoading = true;
    this._commonService.get_delivery_list_for_nearest_city(this._locationService.current_location, this._helper.cart_unique_token).then(response => {
      this._helper.ngZone.run(()=>{
        this.deliveries = response.deliveries;
        this.ads = AdModel.convertArrayToObject(response.ads,370);
        var array = [];
        var count = 3;
        if(!window.matchMedia("(max-width:992px)").matches){
            count = 3;
        }else if(!window.matchMedia("(max-width:576px)").matches){
            count = 2;
        }else{
            count = 1;
        }
        this.ads.forEach((promo_code)=>{
          array.push(promo_code);
          if(array.length == count){
            this.slider_ads.push(array);
            array = [];
          }
        });
        if(array.length > 0){
          this.slider_ads.push(array);
        }


        this.city_id = response.city_id;
        this._helper.city_id = this.city_id
        this._helper.country_id = response.country_id
        if (this.deliveries.length) {
          this.onChangeDelivery(this.deliveries[this.deliveries.findIndex(_d=>_d.delivery_type === 1)]);
        }else{
          this.resetStoreData();
        }
        setTimeout(() => {
          this._helper._loader.isLoading = false;
        }, 1000);
      })
    })
  }

  async fetchStores() {
    var response = await this._commonService.get_store_list(this._locationService.current_location, this._helper.cart_unique_token, this.selectedDelivery._id, this.city_id, this.page, this.perpage);
    this.total_stores = response.count;
    this.currency = response.currency;
    this.stores.push(...StoreModel.convertArrayToObject(response.results,370));
    this.onFilter(null);
    this.stores.forEach(store => {
      this.check_open(new Date, true,store)
    })
    // response.results.forEach(async _store=>{
    //   this.check_valid_time(JSON.parse(JSON.stringify(_store))).then(is_store_time_valid=>{
    //     if(is_store_time_valid && this.filterData(_store)){
    //         this.filter_stores.push(_store);
    //     }
    //   })
    // })
  }

  onSearch(){
    this._commonService.search_store(this.city_id,this.search_store).then((data)=>{
      if(data){
        this.search_stores = data['stores'];
      }else{
        this.search_stores = [];
      }
    })
  }

  onChangeDelivery(delivery: Deliveries) {
    if(delivery.delivery_type === 2){
      if(this._authService.isAuthenticated){
        this._helper._route.navigate(['/courier-order'])
        this._helper.store_delivery_id = delivery._id
      }else{
        this._notifierService.showNotification('error','Login First')
      }
    }else{
      this.selectedDelivery = delivery;
      this.tags_filter_array = [];
      this.selectedDelivery.famous_products_tags.forEach(element => {
        this.tags_filter_array.push({ title: element, check: false });
      });
      this.resetStoreData();
      this.fetchStores();
      this.get_promo_code_list()

      setTimeout(() => {
        var height = $('#store-list').offset().top - $('html,body').offset().top;
        $('html,body').animate({scrollTop: height})
      }, 500);
      // - $('.homepage-main').height()


    }
  }

  get_promo_code_list(){
    this._commonService.get_promo_code_list(this.selectedDelivery._id, this.city_id).then(res_data => {
      this.promos = res_data.promo_codes
      var array = [];
        var count = 4;
        if(!window.matchMedia("(max-width:992px)").matches){
            count = 4;
        }else if(!window.matchMedia("(max-width:576px)").matches){
            count = 2;
        }else{
            count = 1;
        }
        this.promos.forEach((promo_code)=>{
          array.push(promo_code);
          if(array.length == count){
            this.slider_promo.push(array);
            array = [];
          }
        });
        if(array.length > 0){
          this.slider_promo.push(array);
        }
    })
  }

  counter(i) {
    return Array(Number(i));
  }

  onStoreClick(store_id: string) {
    this._helper._route.navigate([`/store/${store_id}`]);
  }

  onFilter(value){
    if(value){
      value.checked = !value.checked
    }
    var filter_stores = [];
    this.stores.forEach(async _store=>{
      this.check_valid_time(JSON.parse(JSON.stringify(_store))).then(is_store_time_valid=>{
        if(is_store_time_valid && this.filterData(_store)){
            filter_stores.push(_store);
        }
      })
    })
    this.filter_stores = filter_stores;
  }

  filterData(_store:StoreModel):Boolean{
    var distance_filter = this.distance_filter_array.filter(_d=>_d.checked);
    var time_filter = this.time_filter_array.filter(_t=>_t.checked);
    var price_filter = this.price_filter_array.filter(_t=>_t.checked);
    var tags_filter = this.tags_filter_array.filter(_t=>_t.checked);

    var distance_flag = false;
    var time_flag = false;
    var price_flag = false;
    var tag_flag = false;
    var pickup_flag = false;

    // Distance Filter
    if(distance_filter.length > 0){
      distance_filter.forEach(_d=>{
          if(_store.distance < _d.title && _store.distance > (_d.title - 1)){
            distance_flag = true;
          }
      })
    }else{
      distance_flag = true;
    }

    // Time Filter
    if(time_filter.length > 0){
      time_filter.forEach(_t=>{
          if(_store.delivery_time_max >= _t.title[0] && _store.delivery_time_max < _t.title[1]){
            time_flag = true;
          }
      })
    }else{
      time_flag = true;
    }

     // Price Filter
     if(price_filter.length > 0){
      price_filter.forEach(_p=>{
          if(_store.price_rating === _p.title){
            price_flag = true;
          }
      })
    }else{
      price_flag = true;
    }


    // Tag Filter
    if(tags_filter.length > 0){
      tags_filter.forEach(_tag=>{
        if(_store.famous_products_tags.toString().includes(_tag.title)){
          tag_flag = true;
        }
      })
    }else{
      tag_flag = true;
    }

    //Pickup Filter
    if(this._cartService.user_cart.is_user_pick_up_order){
      pickup_flag = _store.is_provide_pickup_delivery == true;
    }else{
      pickup_flag = true;
    }

    return distance_flag && time_flag && price_flag && tag_flag && pickup_flag;
  }

  resetStoreData() {
    this.page = 1;
    this.total_stores = 0;
    this.stores = [];
    this.filter_stores = [];
  }

  justLike(id): void{
    var index = this.favourite_store.indexOf(id)
    if(index != -1){
      this._commonService.remove_favourite_store(this._authService.user.server_token, [id], this._authService.user._id).then(res_data => {
        this._authService.user.favourite_stores.splice(index, 1)
      })
    } else {
      this._commonService.add_favourite_stores(id, this._authService.user._id).then(res_data => {
        if(res_data.success){
          this._authService.user.favourite_stores.push(id)
        }
      })
    }
  }

  tooglePickupOrders (){
    this._cartService.user_cart.is_user_pick_up_order = !this._cartService.user_cart.is_user_pick_up_order;
    this.onFilter(null);
  }

  lang_change_month_day() {
    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let time_date = new Date();
    time_date.setHours(0, 0, 0, 0);
    for (var i = 0; i < 7; i++) {
      let date = new Date();
      date.setDate(date.getDate() + i);
      date = new Date(date);
      var day = this._helper.trans.instant(days[date.getDay()]);
      var month = this._helper.trans.instant(months[date.getMonth()]);
      var date_format = day + ', ' + month + ' ' + date.getDate();
      this.date_array[i] = { day: date.getDay(), date_format: date_format, isActive: false, date: date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() };
    }
    if (this.date_array.length > 0) {
      this.set_date(this.date_array[0]);
    }
  }

  set_date(date) {
    this.slot1 = false;
    this.slot2 = false;
    this.slot3 = false;
    this.slot4 = false;
    this.timezone = "Asia/Kolkata";
    var diff1 = String(parseInt(moment().tz(this.timezone).format("ZZ")) - parseInt(moment(new Date()).format("ZZ")));
    var hour = "0";
    var minute = "0";
    if (diff1[1]) {
      hour = diff1[1];
    }
    if (diff1[2]) {
      minute = diff1[2] + diff1[3];
    }
    var total_minute = ((Number(hour) * 60) + Number(minute)) * 60000;
    if (diff1[0] == "-") {
      total_minute = total_minute * -1;
    }

    if (date == this.date_array[0]) {
      this.schedule_date = date.date;
    }
    this.clicked_date = date.date_format;
    let date_with_Delivery_time = new Date(new Date(Number(new Date()) + total_minute).getTime() + (30 * 60000))
    this.date_array.forEach((date_detail) => {
      date_detail.isActive = false;
      if (date_detail.date == date.date) {
        date_detail.isActive = true;
      }
    });
    let checkSelectedDate = new Date(date.date);
    checkSelectedDate.setHours(23, 59, 59, 0);
    var diff = (checkSelectedDate.getTime() - new Date(Number(new Date()) + total_minute).getTime()) / 1000;
    diff /= 60;
    diff = Math.abs(Math.round(diff));

    let time_date = new Date(date.date);
    let time_date_close = new Date(date.date);
    var date1 = new Date(date.date);
    this.time_array = [];
    var i = 0;

        let x = date1.setHours(0, 0, 0, 0)
        let x1 = new Date(x);

        time_date_close.setHours(0, 0, 0, 0);
        time_date_close = new Date(time_date_close.setMinutes(time_date_close.getMinutes() + 30));
        time_date.setHours(0, 0, 0, 0);
        time_date = new Date(time_date);

        let y = date1.setHours(23, 59, 0, 0)
        let y1 = new Date(y);

        for (; time_date < y1;) {
          var hours = time_date.getHours();
          var am_pm = "AM";
          if (hours > 12) {
            hours -= 12;
            am_pm = "PM";
          } else if (hours === 0) {
            hours = 12;
          }
          var hours_close = time_date_close.getHours();
          var am_pm_close = "AM";
          if (hours_close > 12) {
            hours_close -= 12;
            am_pm_close = "PM";
          } else if (hours_close === 0) {
            hours_close = 12;
          }

          if (date_with_Delivery_time < time_date) {
            if (time_date.getHours() < 6) {
              var type = 1;
              this.slot1 = true;
            } else if (time_date.getHours() < 12) {
              var type = 2;
              this.slot2 = true;
            } else if (time_date.getHours() < 18) {
              var type = 3;
              this.slot3 = true;
            } else {
              var type = 4;
              this.slot4 = true;
            }
            this.time_array[i] = { type: type, date: date, time_format: this._helper.pad2(time_date.getHours()) + ':' + this._helper.pad2(time_date.getMinutes()) + '-' + this._helper.pad2(time_date_close.getHours()) + ':' + this._helper.pad2(time_date_close.getMinutes()), time: this._helper.pad2(hours) + ':' + this._helper.pad2(time_date.getMinutes()) + " " + am_pm + ' - ' + this._helper.pad2(hours_close) + ':' + this._helper.pad2(time_date_close.getMinutes()) + " " + am_pm_close }
            i++;
          }
          time_date.setMinutes(time_date.getMinutes() + 30);
          time_date_close.setMinutes(time_date_close.getMinutes() + 30);
    }
    ;
  }

  onSlide(e) {
    this.dateIndex = parseInt(e.current.replace("slideId_", ""), 10);
    this.set_date(this.date_array[this.dateIndex]);
  }

  set_time(time) {
    this.selected_time = time;
    this.schedule_time = time.time_format;
    this.schedule_date = time.date.date;
    this.clicked_date = time.date.date_format;
    this.set_order_time(true);
  }

  set_order_time(boolean) {

    if (boolean) {
        if (this.schedule_date !== '' && this.schedule_time !== '') {

            let server_date: any = new Date(this._cartService.user_cart.server_time);
            server_date = new Date(server_date).toLocaleString("en-US", { timeZone: this.timezone })
            server_date = new Date(server_date);

            let date = JSON.parse(JSON.stringify(this.schedule_date.split('-')));
            var schedule_time = this.schedule_time.split('-');
            let time = schedule_time[0].split(':')
            var selected_date1 = null;

            if (schedule_time[1] && schedule_time[1] != "") {
                let time1 = schedule_time[1].split(':')
                selected_date1 = new Date(Date.now());
                selected_date1 = new Date(selected_date1).toLocaleString("en-US", { timeZone: this.timezone })
                selected_date1 = new Date(selected_date1);
                selected_date1.setDate(date[2])
                selected_date1.setMonth(date[1] - 1)
                selected_date1.setFullYear(date[0])
                selected_date1.setHours(time1[0], time1[1], 0, 0);
            }
            let selected_date: any = new Date(Date.now());
            selected_date = new Date(selected_date).toLocaleString("en-US", { timeZone: this.timezone })
            selected_date = new Date(selected_date);
            selected_date.setDate(date[2])
            selected_date.setMonth(date[1] - 1)
            selected_date.setFullYear(date[0])
            selected_date.setHours(time[0], time[1], 0, 0);
            let timeDiff = Math.round(selected_date.getTime() - server_date.getTime());
            
            if (timeDiff / 60000 >= 30) {
                this._cartService.user_cart.schedule_date = selected_date;
                this._cartService.user_cart.schedule_date1 = selected_date1;
                if(Number(schedule_time[0].split(":")[0]) > 12){
                    schedule_time[0] = this._helper.pad2(Number(schedule_time[0].split(":")[0]) -12)+':'+schedule_time[0].split(":")[1]+' PM';
                }else{
                    schedule_time[0] = schedule_time[0] +' AM';
                }

                if(Number(schedule_time[1].split(":")[0]) > 12){
                    schedule_time[1] = this._helper.pad2(Number(schedule_time[1].split(":")[0]) -12)+':'+schedule_time[1].split(":")[1]+' PM';
                }else{
                    schedule_time[1] = schedule_time[1] +' AM';
                }
                this._cartService.user_cart.clicked_date = this.clicked_date + ' ' + schedule_time;
            } else {
                // this._helper.trans.instant('store_not_provide_service_at_this_time');
            }
        } else {
          // store_not_provide_service_at_this_time
        }

    } else {
        this._cartService.user_cart.schedule_date = null;
        this.schedule_date = '';
        this.schedule_time = '';
    }
  }

  set_schedule(bool) {
    if (bool) {
      if (this._cartService.user_cart.schedule_date) {
        this._cartService.user_cart.is_schedule_order = true;
        this._helper.open_dialog(ALERT_TYPE.SUCCESS,'Success',this._translateService.instant('schedule-time-select-successfully'));
        $('.schedule-form').css('display', 'none');
        setTimeout(() => {
          $('.schedule-form').css('display', '');
        }, 100);
        this.onFilter(null);
      } else {
        this._helper.open_dialog(ALERT_TYPE.ERROR,'Error',this._translateService.instant('please-select-time-for-schedule-order'));
      }
    } else {
      this._cartService.user_cart.is_schedule_order = false;
      this._cartService.user_cart.schedule_date = null;
      this.selected_time = null;
      this.onFilter(null);
    }
  }

  // check store time (open/close)
  check_valid_time(store):Promise<boolean> {
    return new Promise((resolve,rejects)=>{

        if(!this._cartService.user_cart.is_schedule_order){
          resolve(true);
        }

        let server_date: any = new Date(this._cartService.user_cart.server_time);
        server_date = new Date(server_date).toLocaleString("en-US", { timeZone: this.timezone })
        server_date = new Date(server_date);

        let selected_date: any = this._cartService.user_cart.schedule_date

        let day_diff = selected_date.getDay() - server_date.getDay();
        let timeDiff = Math.round(selected_date.getTime() - server_date.getTime());
        if (timeDiff / 60000 >= 30) {
            if (day_diff > 0) {
                this.check_open(selected_date, false,store).then(res=>{
                  resolve(res)
                })
            } else {
                this.check_open(selected_date, true,store).then(res=>{
                  resolve(res)
                })
            }
        } else {
            resolve(false)
        }
    })
  }

  check_open(selected_date, today,store):Promise<boolean> {
    return new Promise((resolve,rejects)=>{
      var date: any = JSON.parse(JSON.stringify(selected_date));
      date = new Date(date)

      let weekday = date.getDay();
      let current_time = date.getTime();
      store.close = true;
      store.nextopentime = '';
      let week_index = store.store_time.findIndex((x) => x.day == weekday)
      let day_time = store.store_time[week_index].day_time;
      if (store.store_time[week_index].is_store_open_full_time) {
        store.close = false;
      }
      else {
        if (store.store_time[week_index].is_store_open) {
            if (day_time.length == 0) {
                store.close = true;
            } else {
                day_time.forEach((store_time, index) => {
                    let x = date.setHours(Math.floor( store_time.store_open_time / 60), store_time.store_open_time % 60, 0, 0)
                    let x1 = new Date(x);
                    let x2 = x1.getTime();

                    let y = date.setHours(Math.floor( store_time.store_close_time / 60), store_time.store_close_time % 60, 0, 0)
                    let y1 = new Date(y);
                    let y2 = y1.getTime();

                    if (current_time > x2 && current_time < y2) {
                        store.close = false;
                    }

                    if (current_time < x2 && store.nextopentime == '') {
                        store.nextopentime = this._helper.pad2(Math.floor( store_time.store_open_time / 60)) + ':' +this._helper.pad2(store_time.store_open_time % 60);
                    }
                });
                if(store.nextopentime == '' && store.close)
                {
                    store.nextopentime = day_time[0].store_open_time
                }
            }
        } else {
            store.close = true;
        }
      }

      if(store.close){
        resolve(false)
      }else{
        if(!this._cartService.user_cart.is_user_pick_up_order && this._cartService.user_cart.is_schedule_order){
          this.check_delivery_time(selected_date,today,store).then(res=>{
            resolve(res);
          })
        }else{
          resolve(true);
        }
      }

    })
  }

  check_delivery_time(selected_date,today,store):Promise<boolean>{
    return new Promise((resolve,rejects)=>{
      var date: any = JSON.parse(JSON.stringify(selected_date));
      date = new Date(date)
      let weekday = date.getDay();
      let current_time = date.getTime();
      var close = true;
      store.nextopentime = '';
      let week_index = store.store_delivery_time.findIndex((x) => x.day == weekday)
      let day_time = store.store_delivery_time[week_index].day_time;



      if (store.store_delivery_time[week_index].is_store_open_full_time) {
        close = false;
      }
      else {
        if (store.store_delivery_time[week_index].is_store_open) {
            if (day_time.length == 0) {
                close = true;
            } else {
                day_time.forEach((store_delivery_time, index) => {

                    let x = date.setHours(Math.floor( store_delivery_time.store_open_time / 60), store_delivery_time.store_open_time % 60, 0, 0)
                    let x1 = new Date(x);
                    let x2 = x1.getTime();

                    let y = date.setHours(Math.floor( store_delivery_time.store_close_time / 60), store_delivery_time.store_close_time % 60, 0, 0)
                    let y1 = new Date(y);
                    let y2 = y1.getTime();

                    if (current_time >= x2 && current_time < y2) {
                        close = false;
                    }

                    if (current_time < x2 && store.nextopentime == '') {
                        store.nextopentime = this._helper.pad2(Math.floor( store_delivery_time.store_open_time / 60)) + ':' +this._helper.pad2(store_delivery_time.store_open_time % 60);
                    }
                });
                if(store.nextopentime == '' && close)
                {
                    store.nextopentime = day_time[0].store_open_time
                }
            }
        } else {
            close = true;
        }
      }



      if(close){
        resolve(false)
      }else{
        resolve(true)
      }

    })

  }


}
