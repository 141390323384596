import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CONFIG, DEFAULT_IMAGE } from 'src/app/constants/constants';
import { UserModel } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { ProfileService } from 'src/app/services/profile.service';
import { Helper } from 'src/app/shared/helper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-account-detail',
  templateUrl: './account-detail.component.html',
  styleUrls: ['./account-detail.component.scss'],
})
export class AccountDetailComponent implements OnInit {

  user:UserModel = new UserModel();
  userForm:FormGroup;
  IMAGE_URL = CONFIG.IMAGE_URL;
  DEFAULT_IMAGE = DEFAULT_IMAGE.USER_PROFILE;
  profile_image:any = this.DEFAULT_IMAGE;
  imagefile;

  constructor(public _helper:Helper,private _profileService:ProfileService,private _authService:AuthService) { }

  ngOnInit(): void {
    this._initForm();
    this.fetchProfile();
  }

  
  _initForm(){
    this.userForm = new FormGroup({
      first_name:new FormControl(null,Validators.required),
      last_name:new FormControl(null,Validators.required),
      email:new FormControl(null,[Validators.required,Validators.email]),
      phone:new FormControl(null,Validators.required),
      password:new FormControl(this._authService.user.login_by === 'social' ? '' : null,this._authService.user.login_by === 'social' ? null : Validators.required),
      new_password:new FormControl("")
    })
  }

  fetchProfile(){
    this._profileService.fetch_profile({server_token:this._authService.user.server_token,user_id:this._authService.user._id}).then((user_data)=>{
      this.user = user_data;
      this.profile_image = this.IMAGE_URL + this.user.image_url;
      this.userForm.patchValue({first_name:this.user.first_name,last_name:this.user.last_name,email:this.user.email,phone:this.user.phone});
    })
  }

  onSelectImageFile(event) {
    let files = event.target.files;
    if (files.length === 0)
      return;
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      alert("Only images are supported.")
      files = [];
      return;
    }
    this.imagefile = files[0]
    const reader = new FileReader();
    reader.readAsDataURL(this.imagefile);
    reader.onload = (_event) => {
      this.profile_image = reader.result;
    }
  }

  updateProfile(){

    if(this.userForm.invalid){
      this.userForm.markAllAsTouched();
      return;
    }
    var profileForm = new FormData();
    profileForm.append('user_id',this._authService.user._id);
    profileForm.append('server_token',this._authService.user.server_token);
    profileForm.append('phone',this.userForm.value.phone);
    profileForm.append('email',this.userForm.value.email);
    profileForm.append('first_name',this.userForm.value.first_name);
    profileForm.append('last_name',this.userForm.value.last_name);
    profileForm.append('social_id',this._authService.user.social_id);
    profileForm.append('login_by',this._authService.user.login_by);
    profileForm.append('old_password',this.userForm.value.password);
    
    
    
    if(this.userForm.value.new_password !== ""){
      profileForm.append('new_password',this.userForm.value.new_password);
    }else{
      profileForm.append('new_password',"");
    }
    if(this.imagefile){
      profileForm.append('image_url',this.imagefile);
    }
    this._profileService.update_profile(profileForm).then(is_update=>{
      if(is_update){
        this.resetForm();
        this.fetchProfile();
      }
    })

  }

  resetForm(){
    this.userForm.reset();
    this.imagefile = undefined;
    this.profile_image = this.DEFAULT_IMAGE;
  }

}
