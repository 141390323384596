import { environment } from "src/environments/environment";
import { CONFIG, DEFAULT_IMAGE } from "../constants/constants";

export class StoreModel {
    _id:string = null;
    private image_url:string = null;
    name:string = null;
    famous_products_tags:Array<string> [];
    price_rating:number = null;
    delivery_time:number = null;
    delivery_time_max:number = null;
    is_store_pay_delivery_fees:boolean = null;
    user_rate:number = null;
    user_rate_count:number = null;
    distance:number = null;
    is_use_item_tax:boolean = false ;
    item_tax:number = 0 ;
    default_prep_time:number = 0 ;
    country_phone_code:string = '';
    currency:string = '';
    phone:string = '';
    email:string = '';
    max_item_quantity_add_by_user:number = 0;
    location:Array<number> = [0,0];
    address:string = '';
    user_type:number = 2;
    store_delivery_time:any = [];
    busy_premium_time:any = [];
    prep_time:any = [];
    store_time:any = [];
    schedule_order_create_after_minute:number = 0;
    is_store_set_schedule_delivery_time:boolean = false;
    is_provide_pickup_delivery:boolean = false;
    is_busy_period:Boolean = false;
    close:boolean = false;
    nextopentime:string = '';
    is_store_busy: boolean = false;

    static _width:number = 370;

    constructor(store:StoreModel = null){
      if(store){
        this._id = store._id;
        this.image_url = store.image_url;
        this.name = store.name;
        this.famous_products_tags = store.famous_products_tags;
        this.price_rating = store.price_rating;
        this.delivery_time = store.delivery_time;
        this.delivery_time_max = store.delivery_time_max;
        this.is_store_pay_delivery_fees = store.is_store_pay_delivery_fees;
        this.user_rate = store.user_rate;
        this.user_rate_count = store.user_rate_count;
        this.distance = store.distance;
        this.is_use_item_tax = store.is_use_item_tax;
        this.item_tax = store.item_tax;
        this.country_phone_code = store.country_phone_code;
        this.default_prep_time = store.default_prep_time;
        this.phone = store.phone;
        this.currency = store.currency;
        this.email = store.email;
        this.max_item_quantity_add_by_user = store.max_item_quantity_add_by_user;
        this.location = store.location;
        this.address = store.address;
        this.user_type = store.user_type;
        this.store_delivery_time = store.store_delivery_time;
        this.is_busy_period = store.is_busy_period;
        this.busy_premium_time = store.busy_premium_time;
        this.prep_time = store.prep_time;
        this.store_time = store.store_time;
        this.schedule_order_create_after_minute = store.schedule_order_create_after_minute;
        this.is_store_set_schedule_delivery_time = store.is_store_set_schedule_delivery_time;
        this.is_provide_pickup_delivery = store.is_provide_pickup_delivery;
        this.close = store.close;
        this.nextopentime = store.nextopentime;
        this.is_store_busy = store.is_store_busy
      }
    }

    get imageUrl(){
      if(CONFIG.IS_USE_AWS){
      return `${CONFIG.API_URL}/resize_image?image=${CONFIG.IMAGE_URL}${this.image_url}&width=${StoreModel._width}&format=jpg`;
      }else{
      return `${CONFIG.API_URL}/resize_image?image=/${this.image_url}&width=${StoreModel._width}&format=jpg`;
      }
      }

      static get defaultImage(){
      var image_path = window.location.origin + '/' + DEFAULT_IMAGE.STORE_PROFILE;
      return `${CONFIG.API_URL}/resize_image?image=${image_path}&width=${StoreModel._width}&format=webp`;
      }

    static convertArrayToObject(array:Array<any> = [],ad_width = 200){
      this._width = ad_width;
      var convertedArray:Array<StoreModel> = [];
      array.forEach(element => {
        convertedArray.push(new StoreModel(element))
      });
      return convertedArray;
    }

  }
