export class OrderPayment {

    user_pay_payment: number = 0;
    _id: string = null;
    currency_code: string = '';
    total_service_price: number = 0;
    total_delivery_price: number = 0;
    service_tax: number = 0;
    total_order_price: number = 0;
    total_cart_price: number = 0;
    tip_amount: number = 0;
    total: number = 0;
    total_customer_service_fee: number = 0;
    promo_payment: number = 0;
    total_store_tax_price: number = 0;
    total_admin_tax_price: number = 0;
    busy_period_amount: number = 0;
    is_store_pay_delivery_fees:boolean = false;
    busy_period_to_customer:boolean = false;
    // admin_currency_code: ""
    // admin_profit_mode_on_delivery: 1
    // admin_profit_mode_on_store: 1
    // admin_profit_value_on_delivery: 20
    // admin_profit_value_on_store: 0
    // capture_amount: 0
    // card_payment: 0
    // cart_id: "6049da01ca8ef11ab8634e31"
    // cash_payment: 0
    // city_id: "59a7de493e05c20f39e9726c"
    // completed_date_in_city_timezone: null
    // completed_date_tag: ""
    // country_id: "59a7de153e05c20f39e9726b"
    // created_at: "2021-03-11T08:57:16.514Z"
    // current_rate: 1
    // delivered_at: null
    // delivery_price_used_type: 1
    // delivery_price_used_type_id: null
    // is_cancellation_fee: false
    // is_distance_unit_mile: false
    // is_min_fare_applied: false
    // is_order_payment_refund: false
    // is_order_payment_status_set_by_store: false
    // is_order_price_paid_by_store: true
    // is_paid_from_wallet: false
    // is_payment_mode_cash: true
    // is_payment_paid: false
    // is_promo_for_delivery_service: true
    // is_provider_income_set_in_wallet: false
    // is_store_income_set_in_wallet: false
    // is_store_pay_delivery_fees: false
    // is_transfered_to_provider: false
    // is_transfered_to_store: false
    // is_user_pick_up_order: false
    // item_tax: 0
    // order_cancellation_charge: 0
    // order_currency_code: "INR"
    // order_id: null
    // order_unique_id: 0
    // other_promo_payment_loyalty: 0
    // pay_to_provider: 0
    // pay_to_store: 0
    // payment_intent_id: ""
    // promo_id: null
    // promo_payment: 0
    // provider_id: null
    // provider_income_set_in_wallet: 0
    // refund_amount: 0
    // remaining_payment: 0
    // service_tax: 0
    // store_id: "5ec7bfe5c9c5df38ef249980"
    // store_income_set_in_wallet: 0
    // tip_value: 0
    // total: 230
    // total_admin_profit_on_delivery: 10
    // total_admin_profit_on_store: 0
    // total_admin_tax_price: 0
    // total_after_wallet_payment: 0
    // total_cart_price: 180
    // total_delivery_price: 50
    // total_distance: 0.892
    // total_item_count: 1
    // total_order_price: 180
    // total_provider_income: 40
    // total_service_price: 50
    // total_store_income: 180
    // total_store_tax_price: 0
    // total_time: 2.85
    // unique_id: 9066
    // updated_at: "2021-03-11T09:06:02.606Z"
    // user_id: "5ae2fe6a15ec517b2ec6964c"
    
    // wallet_payment: 0
    // wallet_to_admin_current_rate: 1
    // wallet_to_order_current_rate: 1
}