<div class="footer-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-3 f-block1">
                <div class="footer-logo-section footer-block">
                    <div class="footer-logo">
                        <a href="javascript:void(0)"><img src="../../../assets/images/footer-logo.png" alt=""></a>
                    </div>
                    <div class="phone-number">
                        <span>{{setting.admin_contact_phone_number}}</span>
                        <span>{{setting.admin_contact_email}}</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 f-block2 hide">
                <div class="footer-link-list footer-block">
                    <ul>
                        <li><a href="javascript:void(0)">{{'label-title.explore' | translate}}</a></li>
                        <li><a href="javascript:void(0)">{{'label-title.about' | translate}}</a></li>
                        <li><a href="javascript:void(0)">{{'label-title.contact' | translate}}</a></li>
                        <li><a href="javascript:void(0)">{{'label-title.faq' | translate}}</a></li>
                        <li><a href="javascript:void(0)">{{'label-title.privacy-policy' | translate}}</a></li>
                        <li><a href="javascript:void(0)">{{'label-title.terms-conditions' | translate}}</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-3 f-block4">
                <div class="footer-social footer-block">
                    <div class="app-link d-flex align-item-center">
                        <div class="google-app mr-30">
                            <a href="https://play.google.com/store/apps/details?id=com.deliveryangel.user"><img src="../../../assets/images/g-play.png" alt="eDelivery"></a>
                        </div>
                        <!--<div class="ios-app">
                            <a href="https://apps.apple.com/us/app/edelivery-user/id1276529954?ls=1"><img src="../../../assets/images/ios-store.png" alt="eDelivery"></a>
                        </div>-->
                    </div>
                    <div class="social-link-list hide">
                        <ul class="d-flex align-item-center flex-wrap">
                            <li>
                                <a href="javascript:void(0)"><img src="../../../assets/images/fb-icon.svg" alt=""></a>
                            </li>
                            <li>
                                <a href="javascript:void(0)"><img src="../../../assets/images/fb-icon.svg" alt=""></a>
                            </li>
                            <li>
                                <a href="javascript:void(0)"><img src="../../../assets/images/fb-icon.svg" alt=""></a>
                            </li>
                            <li>
                                <a href="javascript:void(0)"><img src="../../../assets/images/fb-icon.svg" alt=""></a>
                            </li>
                        </ul>
                    </div>
                    <!-- lang button start  -->

                    <div class="lang-btn hide">
                        <form action="">
                            <select name="month" id="lang" class="form-control">
                                <option value="">{{'label-title.lang' | translate}}</option>
                                <option value="january">1</option>
                                <option value="february">2</option>
                            </select>
                        </form>
                    </div>

                    <!-- lang button end  -->
                </div>

            </div>
        </div>
        <div class="lang-f">
            <ul>
              <div class="row">
                <div class="col-xl-1 col-md-2 col-3" *ngFor="let item of langs">
                  <li (click)="change_langauge(item.code)" class="mb-1"><a [class.theme-text]="item.code === selectedLang" href="javascript:void(0)">{{item.name}}</a></li>
                </div>
              </div>
            </ul>
        </div>
    </div>
</div>
