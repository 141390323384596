import { Injectable } from "@angular/core";
import { apiColletions } from "../constants/api_collection";
import { Helper } from "../shared/helper";
import { ApiService } from "./api.service";
import { AuthService } from "./auth.service";

@Injectable({ providedIn: 'root' })
export class OrderService {
    constructor(private _api: ApiService,private _authService:AuthService, private _helper: Helper){}


    get_orders(json): Promise<any>{
        return new Promise((resolve, reject) => {
            try{
                this._api.post({url: apiColletions.get_orders, parameters: json}).then((response) => {
                    if(response.success){
                        resolve(response.data.order_list)
                    } else {
                        resolve([])
                    }
                })
            } catch {
                resolve({success: false})
            }
        })
    }

    order_history(json): Promise<any>{
        return new Promise((resolve, reject) => {
            try {
                this._api.post({url: apiColletions.order_history, parameters: json}).then(response => {
                    if(response.success){
                        resolve(response.data.order_list)
                    } else {
                        resolve([])
                    }
                })
            } catch {
                resolve([])
            }
        })
    }

    get_order_details(json): Promise<any>{
        return new Promise((resolve, reject) => {
            try{
                this._api.post({url: apiColletions.get_order_detail, parameters: json}).then(response => {
                    if(response.success){
                        resolve(response.data)
                    } else {
                        resolve(response)
                    }
                })
            } catch {
                resolve({})
            }
        })
    }

    get_history_order_details(json): Promise<any>{
        return new Promise((resolve, reject) => {
            try{
                this._api.post({url: apiColletions.order_history_detail, parameters: json}).then(response => {
                    if(response.success){
                        resolve(response.data)
                    } else {
                        resolve({})
                    }
                })
            } catch {
                resolve({})
            }
        })
    }

    user_cancle_order(json): Promise<any>{
        return new Promise((resolve, reject) => {
            try{
                this._api.post({url: apiColletions.user_cancel_order, parameters: json}).then(response => {
                    if(response.success){
                        resolve(response.data)
                    } else {
                        resolve({})
                    }
                })
            } catch {
                resolve({})
            }
        })
    }

    rate_to_provider(json): Promise<any>{
        return new Promise((resolve, reject) => {
            try {
                this._api.post({url: apiColletions.rating_to_provider, parameters: json}).then(response => {
                    if(response.success){
                        resolve(response.data)
                    } else {
                        resolve(response)
                    }
                })
            } catch {
                resolve(false)
            }
        })
    }

    rate_to_store(json): Promise<any>{
        return new Promise((resolve, reject) => {
            try {
                this._api.post({url: apiColletions.rating_to_store, parameters: json}).then(response => {
                    if(response.success){
                        resolve(response.data)
                    } else {
                        resolve(response)
                    }
                })
            } catch {
                resolve(false)
            }
        })
    }

}