import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { Helper } from 'src/app/shared/helper';
import { StoreModel } from 'src/app/models/store.model';
import { environment } from 'src/environments/environment';
import { CONFIG, DEFAULT_IMAGE } from 'src/app/constants/constants';

@Component({
  selector: 'app-favourites-list',
  templateUrl: './favourites-list.component.html',
  styleUrls: ['./favourites-list.component.scss'],
})
export class FavouritesListComponent implements OnInit {
  liked = true;
  hide = true;
  favourite_stores: any = []

  IMAGE_URL = CONFIG.IMAGE_URL;
  DEFAULT_IMAGE = DEFAULT_IMAGE.STORE_PROFILE;

  constructor(public helper:Helper, public _commonService: CommonService, public _authService: AuthService) { }

  ngOnInit(): void {
    this.get_favourite_stores()
  }

  get_favourite_stores(){
    this._commonService.get_favourite_stores(this._authService.user.server_token, this._authService.user._id).then(res_data => {
      if(res_data.favourite_stores){
        this.favourite_stores = res_data.favourite_stores
      }else{
        this.favourite_stores = []
      }
    })
  }

  justLike(id): void{
    var index = this.favourite_stores.indexOf(id)
    this._commonService.remove_favourite_store(this._authService.user.server_token, [id], this._authService.user._id).then(res_data => {
      this._authService.user.favourite_stores.splice(index, 1)
      this.get_favourite_stores()
    })
  }

  onStoreClick(store: StoreModel) {
    this.helper._route.navigate([`/store/${store._id}`]);
  }

  counter(i) {
    return Array(Number(i));
  }
}
