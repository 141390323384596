import { Injectable } from "@angular/core";
import { apiColletions } from "../constants/api_collection";
import { Card } from "../models/card.model";
import { PaymentGateway } from "../models/payment_gatewat.model";
import { ApiService } from "./api.service";
import { AuthService } from "./auth.service";
import { LocationModel } from "./location.service";

@Injectable({ providedIn: 'root' })
export class PaymentService {


    constructor(private _api:ApiService,private _authService:AuthService){}

    get_payment_gateway(location:LocationModel,user_id,server_token,cart_unique_token):Promise<{is_cash_payment_mode:boolean,wallet:number,wallet_currency_code:string,payment_gateway:Array<PaymentGateway>}>{
        return new Promise((resolve,rejects)=>{
            try{
                var parameters = {
                    cart_unique_token,
                    address: location.address,
                    city1: location.city_name,
                    city2: location.state_name,
                    city3: location.city_name,
                    city_code: location.state_code,
                    country: location.country_name,
                    country_code: location.country_code,
                    country_code_2: location.country_code,
                    latitude: location.latitude,
                    longitude: location.longitude,
                    server_token,
                    user_id,
                    city_id:""
                }
                this._api.post({url:apiColletions.get_payment_gateway,parameters}).then((response)=>{
                    if(response.success){
                        resolve({...response.data});
                    }else{
                        resolve({
                            is_cash_payment_mode:false,
                            payment_gateway:[],
                            wallet:0,
                            wallet_currency_code:null
                        });
                    }
                })
            }catch(err){
                resolve({
                    is_cash_payment_mode:false,
                    payment_gateway:[],
                    wallet:0,
                    wallet_currency_code:null
                });
            }
        })
    }

    get_card_list({user_id,server_token}):Promise<Array<Card>>{
        return new Promise((resolve,rejects)=>{
            try{
                var parameters = {user_id: user_id,server_token: server_token}
                this._api.post({url:apiColletions.get_card_list,parameters}).then((response)=>{
                    if(response.success){
                        resolve(response.data.cards);
                    }else{
                        resolve([]);
                    }
                })
            }catch(err){
                resolve([]);
            }
        })
    }

    delete_card({card_id}):Promise<boolean>{
        return new Promise((resolve,rejects)=>{
            try{
                var parameters = {
                    user_id:this._authService.user._id,
                    server_token:this._authService.user.server_token,
                    card_id
                }
                this._api.post({url:apiColletions.delete_card,parameters}).then((response)=>{
                    if(response.success){
                        resolve(true);
                    }else{
                        resolve(false);
                    }
                })
            }catch(err){
                resolve(false);
            }
        })
    }

    get_stripe_add_card_intent({payment_id}):Promise<string>{
        return new Promise((resolve,rejects)=>{
            try{
                var parameters = {payment_id}
                this._api.post({url:apiColletions.get_stripe_add_card_intent,parameters}).then((response)=>{
                    if(response.success){
                        resolve(response.data.client_secret);
                    }else{
                        resolve(null);
                    }
                })
            }catch(err){
                resolve(null);
            }
        })
    }

    add_card({payment_method,user_id,server_token,payment_id,expiry_month,expiry_year}):Promise<boolean>{
        return new Promise((resolve,rejects)=>{
            try{
                var parameters = {
                    type:7,user_id,server_token,payment_id,payment_method,
                    card_expiry_date : expiry_month+"/"+expiry_year,
                }
                this._api.post({url:apiColletions.add_card,parameters}).then((response)=>{
                    if(response.success){
                        resolve(true);
                    }else{
                        resolve(false);
                    }
                })
            }catch(err){
                resolve(false);
            }
        })
    }

    select_card({card_id}):Promise<boolean>{
        return new Promise((resolve,rejects)=>{
            try{
                var parameters = {
                    user_id:this._authService.user._id,server_token:this._authService.user.server_token,card_id}
                this._api.post({url:apiColletions.select_card,parameters}).then((response)=>{
                    if(response.success){
                        resolve(true);
                    }else{
                        resolve(false);
                    }
                })
            }catch(err){
                resolve(false);
            }
        })
    }

    get_stripe_payment_intent_wallet(card_id,user_id,server_token,wallet:number,payment_id):Promise<{client_secret: string,last_four: string,payment_method:string,error:string}>{
        return new Promise((resolve,rejects)=>{
            try{
                var parameters = {user_id,server_token,wallet,payment_id,card_id,amount: wallet,type: 7}
                this._api.post({url:apiColletions.get_stripe_payment_intent_wallet,parameters}).then((response)=>{
                    if(response.success){
                        resolve({...response.data,error:''});
                    }else{
                        resolve({
                            client_secret:null,
                            last_four:null,
                            payment_method:null,
                            error:response.code
                        });
                    }
                })
            }catch(err){
                resolve({
                    client_secret:null,
                    last_four:null,
                    payment_method:null,
                    error:err.message
                });
            }
        })
    }

    add_wallet_amount({user_id,server_token,wallet,payment_id,payment_intent_id,last_four,card_id}):Promise<boolean>{
        return new Promise((resolve,rejects)=>{
            try{
                let parameters = {
                    user_id,
                    server_token,
                    wallet: Number(wallet),
                    payment_id,
                    payment_intent_id,
                    last_four,
                    card_id,
                    type: 7
                }

                this._api.post({url:apiColletions.add_wallet_amount,parameters}).then((response)=>{
                    if(response.success){
                        resolve(true);
                    }else{
                        resolve(false);
                    }
                })
            }catch(err){
                resolve(false);
            }
        })
    }

}