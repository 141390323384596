import { Injectable } from "@angular/core";
import { apiColletions } from "../constants/api_collection";
import { AdModel } from "../models/ad.model";
import { CountryModel } from "../models/country.model";
import { SetttingModel } from "../models/setting.model";
import { StoreModel } from "../models/store.model";
import { Deliveries } from "../views/home-page/home-page.component";
import { ApiService } from "./api.service";
import { LocationModel } from "./location.service";

export interface Lang{
    code: string,
    name: string
    string_file_path:string
}

@Injectable({ providedIn: 'root' })
export class CommonService {

    public is_location_show = false;
    private setting_data:SetttingModel = new SetttingModel();
    private lang_data:Array<Lang> = [];


    constructor(private _api:ApiService){}


    get settings():SetttingModel{
        return this.setting_data;
    }

    get langs(){
        return this.lang_data;
    }

    set langs(langs_data){
        this.lang_data = langs_data;
    }

    get_delivery_list_for_nearest_city(location:LocationModel,uuid:string):Promise<{deliveries:Array<Deliveries>,ads:Array<AdModel>,city_id:string, country_id:string}>{

        return new Promise((resolve,rejects)=>{
            try{
                var parameters = {
                    address: location.address,
                    city1: location.city_name,
                    city3: location.city_name,
                    city_code: location.state_code,
                    country: location.country_name,
                    country_code: location.country_code,
                    country_code_2: location.country_code,
                    latitude: location.latitude,
                    longitude: location.longitude,
                    city2: location.state_name,
                    cart_unique_token:uuid,
                    delivery_type: "1", //useless
                    server_token:"", //useless
                    user_id:"" //useless
                  };
                this._api.post({url:apiColletions.get_delivery_list_for_nearest_city,parameters}).then((response)=>{
                    if(response.success){
                        resolve({ads:response.data.ads,deliveries:response.data.deliveries,city_id:response.data.city._id, country_id: response.data.country_id})
                    }else{
                        resolve({ads:[],deliveries:[],city_id:null, country_id:null});
                    }
                })
            }catch(err){
                resolve({
                    ads:[],
                    deliveries:[],
                    city_id:null,
                    country_id: null
                });
            }
        })
    }

    get_store_list(location:LocationModel,uuid:string,delivery_id:string,city_id:string,page:number,perPage:number):Promise<{
        count:number,
        currency:string,
        results:Array<StoreModel>
    }>{
        return new Promise((resolve,rejects)=>{
            try{
                var parameters = {
                "city_id": city_id,
                "store_delivery_id": delivery_id,
                "cart_unique_token": uuid,
                "latitude": location.latitude,
                "longitude": location.longitude,
                "user_id": "",
                "server_token": "",
                page:page,
                per_page:perPage
                };
                this._api.post({url:apiColletions.get_store_list,parameters}).then((response)=>{
                    if(response.success){
                        resolve({
                            results:response.data.stores.results,
                            count:response.data.stores.count,
                            currency:response.data.currency_sign
                        });
                    }else{
                        resolve({count:0,results:[],currency:''});
                    }
                })
            }catch(err){
                resolve({
                    count:0,
                    results:[],
                    currency:""
                });
            }
        })

    }

    search_store(city_id:string,search_text:string):Promise<Array<{_id:string,name:Array<string>}>>{
        return new Promise((resolve,rejects)=>{
            try{
                this._api.post({url:apiColletions.search_store,parameters:{city_id,search_text}}).then(response=>{
                    if(response.success){
                        resolve(response.data)
                    }else{
                        resolve([])
                    }
                })
            }catch(err){
                resolve([])
            }
        })
    }

    get_languages():Promise<Array<Lang>>{
        return new Promise((resolve,rejects)=>{
            try{
                this._api.get({url:apiColletions.get_languages,parameters:{}}).then(response=>{
                    if(response && response.success){
                        resolve(response.data.lang)
                    }else{
                        resolve([])
                    }
                })
            }catch(err){
                resolve([])
            }
        })
    }

    get_country_list():Promise<Array<CountryModel>>{
        return new Promise((resolve,rejects)=>{
            try{
                this._api.get({url:apiColletions.get_countries,parameters:{}}).then(response=>{
                    if(response.success){
                        resolve(response.data.countries)
                    }else{
                        resolve([])
                    }
                })
            }catch(err){
                resolve([])
            }
        })
        '/api/admin/get_country_list'
    }
    
    check_referral(country_code,referral_code):Promise<boolean>{
        return new Promise((resolve,rejects)=>{
            try{
                var parameters = {country_code,referral_code,type: 7};
                this._api.post({url:apiColletions.check_referral,parameters}).then(response=>{
                    if(response.success){
                        resolve(true)
                    }else{
                        resolve(false)
                    }
                })
            }catch(err){
                resolve(false)
            }
        })
    }

    get_setting_detail():Promise<SetttingModel>{
        return new Promise((resolve,rejects)=>{
            try{
                var parameters = {};
                this._api.post({url:apiColletions.get_setting_detail,parameters}).then(response=>{
                    if(response.success){
                        this.setting_data = response.data.setting;
                        resolve(response.data.setting)
                    }else{
                        resolve(null)
                    }
                })
            }catch(err){
                resolve(null)
            }
        })
    }

    get_store_detail(store_id):Promise<any>{
        return new Promise((resolve,rejects)=>{
            try{
                var parameters = {store_id};
                this._api.post({url:apiColletions.get_store_detail,parameters}).then(response=>{
                    if(response.success){
                        resolve(response.data)
                    }else{
                        resolve(null)
                    }
                })
            }catch(err){
                resolve(null)
            }
        })
    }

    get_favourite_stores(server_token, user_id):Promise<any>{
        return new Promise ((resolve, reject) => {
            try{
                var parameters = {
                    server_token,
                    user_id
                }
                this._api.post({url: apiColletions.get_favourite_store_list, parameters}).then(response => {
                    if(response.success){
                        resolve(response.data)
                    } else {
                        resolve([])
                    }
                })
            } catch {
                resolve([])
            }
        })
    }

    add_favourite_stores(store_id, user_id): Promise<any>{
        return new Promise ((resolve, reject) => {
            try {
                var parameters = {
                    store_id, user_id
                }
                this._api.post({url: apiColletions.add_favourite_store, parameters}).then(response => {
                    if(response.success){
                        resolve(response.data)
                    } else {
                        resolve(false)
                    }
                })
            } catch {
                resolve(false)
            }
        })
    }

    remove_favourite_store(server_token, store_id, user_id):Promise<any>{
        return new Promise((resolve, reject) => {
            try {
                var parameters = {
                    server_token, store_id, user_id
                }
                this._api.post({url: apiColletions.remove_favourite_store, parameters}).then(response => {
                    if(response.success){
                        resolve(response.data)
                    } else {
                        resolve(false)
                    }
                })
            } catch {
                resolve(false)
            }
        })
    }

    get_promo_code_list(delivery_id, city_id):Promise<any>{
        return new Promise((resolve, reject) => {
            try {
                var parameters = {
                    delivery_id,
                    city_id
                }

                this._api.post({url: apiColletions.get_promo_code_list, parameters}).then(response => {
                    if(response.success){
                        resolve(response.data)
                    } else {
                        resolve([])
                    }
                })
            } catch {
                resolve([])
            }
        })
    }

}


