import { Component, OnInit, NgZone, ViewChild, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { OrderService } from 'src/app/services/order.service';
import { ItemModel, cartProductsModel, cartAddress } from 'src/app/models/cart.model';
import { Helper } from 'src/app/shared/helper';
import { ORDER_STATE, DEFAULT_IMAGE, CONFIG } from 'src/app/constants/constants'
import { environment } from 'src/environments/environment';
import { MenuService } from 'src/app/services/menu.service';
import { CartService } from 'src/app/services/cart.service';
import { LocationService } from 'src/app/services/location.service';
import { ChatService, ChatType } from 'src/app/services/chat.service';
import { Subscription } from 'rxjs';
import { TabsetComponent } from 'ngx-tabset'

declare var google;
declare var $

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss'],
})
export class OrderDetailComponent implements OnInit, OnDestroy {
  chatDialog;
  constructor(public helper: Helper,
    public _authService: AuthService,
    public _orderService: OrderService,
    public ngZone: NgZone,
    public _menuService: MenuService,
    public _cartService: CartService,
    private _locationService: LocationService,
    private _chatService: ChatService) { }

  user_detail: any
  current_orders: any = [];
  order_history: any = [];
  selected_order: any = '';
  search_text = ''
  isshow = false;
  order_details: any = {
    order: {
      status: 0
    }
  };
  cart_order_details: any = [];
  ORDER_STATE = ORDER_STATE;
  order_payments: any = [];
  provider_details: any = [{
    first_name: '',
    last_name: ''
  }];
  review_details: any;
  store_details: any = {}
  destination_address: any;
  pickup_address: any;
  is_user_pick_up_order = false;
  map_loaded: Boolean = false;
  old_lat_lng: any[];
  new_lat_lng: any[];
  map: any;
  bounds: any;
  IMAGE_BASE_URL = CONFIG.IMAGE_URL;
  cancellation_reason: any = '';
  public directionsDisplay = new google.maps.DirectionsRenderer;
  rating_rate: any;
  rating_comment: any;
  type: number = 1;
  store_id: any = '';
  cancellation_reason_error: any = '';
  cancellation_type: any = '';
  product_groups: any;
  store: any;
  products: any;
  product_index: number = 0;
  product_length: any = 0;
  selected_item: any;
  selected_product: any = 0;
  required_count: number = 0;
  product_name: any;
  current_main_item: any;
  product_unique_id: any;
  required_temp_count: number;
  total: number = 0;
  qty: number = 1;
  is_edit_item: boolean = false;
  is_edit_order: boolean = false;
  order_index: number;
  item_index: number;
  private cartProducts: cartProductsModel = new cartProductsModel();
  private cartProductItems: ItemModel = new ItemModel();
  chatSubscription: Subscription;
  chats: any = [];
  message: any = '';
  chat_type = ChatType.ADMIN
  ITEM_DEFAULT_IMAGE = DEFAULT_IMAGE.PRODUCT
  USER_DEFAULT_IMAGE = DEFAULT_IMAGE.USER_PROFILE
  is_user_pickup_order: boolean = false
  IMAGE_URL = CONFIG.IMAGE_URL;
  is_show_confirmation_code: boolean = false

  @ViewChild(TabsetComponent) tabset: TabsetComponent;


  ngOnInit(): void {
    
    this.user_detail = this._authService.user
    this.get_orders()
    this.get_order_history()    
  }

 

  get_orders() {
    this.helper._loader.isLoading = true;
    var json = {
      user_id: this.user_detail._id,
      server_token: this.user_detail.server_token,
      start_date: "",
      end_date: ""
    }
    this._orderService.get_orders(json).then(res_data => {
      this.current_orders = res_data
      if(this.current_orders.length){
        this.get_order_details(this.current_orders[0]._id)
      }
      this.helper._loader.isLoading = false;
    })
  }

  get_order_history() {
    var json = {
      user_id: this.user_detail._id,
      server_token: this.user_detail.server_token,
      start_date: "",
      end_date: ""
    }
    this._orderService.order_history(json).then(res_data => {
      this.order_history = res_data
    })
  }


  onSelectOrder(order, index) {
    this.selected_order = order
    this.get_order_details(order._id)
  }

  get_order_details(order_id) {
    var json = {
      user_id: this.user_detail._id,
      server_token: this.user_detail.server_token,
      order_id
    }
    this._orderService.get_order_details(json).then(res_data => {
      if(res_data.success){
        this.isshow = true;
        this.order_details = res_data
        this.order_details.create_date = null;
        this.order_details.accept_date = null;
        this.order_details.prepare_date = null;
        this.order_details.on_way_date = null;
        this.order_details.order_received_date = null;

          
          var on_way_idx = this.order_details.order.date_time.findIndex(_date=>_date.status === 7)
          if(on_way_idx != -1){
            this.order_details.prepare_date = this.order_details.order.date_time[on_way_idx].date;
          }
        if (res_data.order.request_id) {
          this.order_details.request_status = res_data.order.request_detail.delivery_status
          var on_way_idx = this.order_details.order.request_detail.date_time.findIndex(_date=>_date.status === 13)
          if(on_way_idx != -1){
            this.order_details.on_way_date = this.order_details.order.request_detail.date_time[on_way_idx].date;
          }

          var order_received_idx = this.order_details.order.request_detail.date_time.findIndex(_date=>_date.status === 25)
          if(order_received_idx != -1){
            this.order_details.order_received_date = this.order_details.order.request_detail.date_time[order_received_idx].date;
          }

        } else {
          this.order_details.request_status = 0;
        }
        var create_idx = this.order_details.order.date_time.findIndex(_date=>_date.status === 1)
        if(create_idx != -1){
          this.order_details.create_date = this.order_details.order.date_time[create_idx].date;
        }
        var accept_idx = this.order_details.order.date_time.findIndex(_date=>_date.status === 3)
        if(accept_idx != -1){
          this.order_details.accept_date = this.order_details.order.date_time[accept_idx].date;
        }

        var prepare_idx = this.order_details.order.date_time.findIndex(_date=>_date.status === 5)
        if(prepare_idx != -1){
          this.order_details.prepare_date = this.order_details.order.date_time[prepare_idx].date;
        }

        this.selected_order = res_data.order
        this.cart_order_details = res_data.order.cart_detail.order_details
        this.order_payments = res_data.order.order_payment_detail
        this.provider_details = res_data.order.provider_detail[0]
        this.store_details = res_data.order.store_detail
        this.pickup_address = res_data.order.cart_detail.pickup_addresses[0];
        this.destination_address = res_data.order.cart_detail.destination_addresses[0]
        if (this.order_details.order.reviews_detail.length) {
          this.review_details = this.order_details.order.reviews_detail[0]
        }
        this._cartService.user_cart.cart_data.cart = this.cart_order_details
        this.is_user_pick_up_order = this.order_details.order.order_payment_detail.is_user_pick_up_order
        this._cartService.user_cart.cart_data.cart_id = res_data.order.cart_id
        this._cartService.user_cart.cart_data.city_id = res_data.order.city_id
        this._cartService.user_cart.total_cart_amount = res_data.order.cart_detail.total_cart_price
        this._cartService.user_cart.total_item_tax = res_data.order.cart_detail.total_item_tax
        this.store = res_data.order.store_detail
        this._cartService.user_cart.cart_data.selectedStoreId = this.store._id
        if (res_data.order.provider_detail.length && !this.map_loaded) {
          this.provider_details = res_data.order.provider_detail[0];
          this.map_loaded = true;
          this.old_lat_lng = this.provider_details.location;
          this.new_lat_lng = this.provider_details.location;
          this.ngZone.run(() => {
            this.map = new google.maps.Map(document.getElementById('map'), {
              zoom: 10,
              center: { lat: 22.290268653053918, lng: 70.7748018100878 },
              draggable: false,
              zoomControl: true,
              scrollwheel: false,
              disableDoubleClickZoom: false,
              fullscreenControl: true
            });
            this.bounds = new google.maps.LatLngBounds();
            this.bounds.extend(new google.maps.LatLng(this.provider_details.location[0], this.provider_details.location[1]));
            this.directionsDisplay.setMap(this.map);
            var map_pin = document.getElementById('map_pin');
            this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(map_pin);
            this.bounds.extend(new google.maps.LatLng(this.pickup_address.location[0], this.pickup_address.location[1]));
            var store_marker = new google.maps.Marker({
              position: { lat: this.pickup_address.location[0], lng: this.pickup_address.location[1] },
              map: this.map,
              icon: this.IMAGE_BASE_URL + 'map_pin_images/Store/store_open.png'
            });
            var store_contentString =
              '<b>Name</b>: ' + this.pickup_address.user_details.name +
              '<br><b>Email</b>: ' + this.pickup_address.user_details.email +
              '<br><b>Phone</b>: ' + this.pickup_address.user_details.country_phone_code + this.pickup_address.user_details.phone;
            var store_message = new google.maps.InfoWindow({
              content: store_contentString,
              maxWidth: 320
            });
            google.maps.event.addListener(store_marker, 'click', (e) => {
              store_message.open(this.map, store_marker);
              setTimeout(function () { store_message.close(); }, 5000);
            });
            this.bounds.extend(new google.maps.LatLng(this.destination_address.location[0], this.destination_address.location[1]));

            var user_marker = new google.maps.Marker({
              position: { lat: this.destination_address.location[0], lng: this.destination_address.location[1] },
              map: this.map,
              icon: this.IMAGE_BASE_URL + 'map_pin_images/Store/store_open.png'
            });
            var user_contentString =
              '<b>Address</b>: ' + this.destination_address.address;
            var user_message = new google.maps.InfoWindow({
              content: user_contentString,
              maxWidth: 320
            });
            google.maps.event.addListener(user_marker, 'click', (e) => {
              user_message.open(this.map, user_marker);
              setTimeout(function () { user_message.close(); }, 5000);
            });
            this.map.fitBounds(this.bounds);
          });
        }
      }else{
        this.isshow = false;
        this.order_details = {
          order: {
            status: 0
          }
        };;
      }

    })
  }

  // Rating

  give_rate_modal(type) {
    this.rating_rate = 3;
    this.rating_comment = '';
    this.type = type;
    $(".review-model").removeClass('model-open');
    $("body").addClass('model-open');
    $('#add-review-popup').addClass('model-open');
  }
  
  give_rate() {
    if (this.type == 1) {
      let json = {
        user_id: this._authService.user._id,
        order_id: this.selected_order._id,
        server_token: this._authService.user.server_token,
        user_rating_to_provider: parseFloat(this.rating_rate),
        user_review_to_provider: this.rating_comment,
      }
      this._orderService.rate_to_provider(json).then((res_data) => {
        $('#close-add-review-popup').click();
        this.rating_rate = 1
        this.rating_comment = ""
        this.get_order_details(this.selected_order._id);
      })
    } else {
      let json = {
        user_id: this._authService.user._id,
        order_id: this.selected_order._id,
        server_token: this._authService.user.server_token,
        user_rating_to_store: parseFloat(this.rating_rate),
        user_review_to_store: this.rating_comment
      }
      this._orderService.rate_to_store(json).then(res_data => {
        $('#close-add-review-popup').click();
        this.rating_rate = 1
        this.rating_comment = ""
        this.get_order_details(this.selected_order._id);
      })
    }
  }


  // Cancel Order

  openCancelModel(){
    this.cancellation_reason = this.helper.trans.instant('label-title.change-my-mind')
    this.cancellation_type = this.helper.trans.instant('label-title.change-my-mind')
    this.helper.openModel('cancel-order')
  }

  user_cancle_order() {
    this.cancellation_reason_error = "";
    if(this.cancellation_reason === "" && this.cancellation_type === this.helper.trans.instant('label-title.other')){
      this.cancellation_reason_error = this.helper.trans.instant('validation-title.reason-is-required');
      return;
    }

    var json = {
      cancel_reason: this.cancellation_reason,
      order_id: this.selected_order._id,
      order_status: this.selected_order.order_status,
      server_token: this._authService.user.server_token,
      user_id: this._authService.user._id
    }
    this._orderService.user_cancle_order(json).then(res_data => {
      $(".custom-model-main").removeClass('model-open');
      this.ngOnInit()
    })
  }


  counter(i) {
    return Array(Number(i));
  }

 
  // Edit Order

  onSelectReason(event) {
    this.cancellation_type = event.target.value;
    this.cancellation_reason = event.target.value;    
  }



  onAddMoreItems() {
    this.get_product_group_list(this.selected_order.store_id)    
  }

  async get_product_group_list(store_id) {
    await this._menuService.get_product_group_list(store_id).then(res_data => {
      this.product_groups = res_data;
      this.user_get_store_product_item_list()
      this.helper.openModel('order-add-more-items')  

    })
  }

  user_get_store_product_item_list() {
    if(!this.product_groups.length){
      this.product_groups.push({
        name:'Main',
        _id:null,
        image_url:"",
        product_ids:[],
        sequence_number:0,
        store_id:this.selected_order.store_id
      })
      this.product_index = 0;
    }


    this._menuService.user_get_store_product_item_list({ product_ids:null, server_token: "", user_id: "", store_id: this.selected_order.store_id }).then(res_data => {
      if (res_data.success) {
        this.store = res_data.store
        this.products = res_data.products
        this.products.forEach(product => {
          product.items.forEach(item => {
            item.total_item_price = 0
            item.specifications.forEach(spec => {
              spec.list.forEach(list => {
                if (list.is_default_selected) {
                  item.total_item_price = item.total_item_price + list.price
                }
              });
            });
            item.total_item_price = item.total_item_price + item.price

          });
        });
        this.product_length = this.products.length

        this.product_groups.forEach((_product_group,i)=>{
          
          if(i === this.product_index){
            _product_group['is_show'] = !!!_product_group['is_show'];
          $('#'+this.product_index.toString()).slideDown('slow');

          }else{
            _product_group['is_show'] = false
          }
        })

        setTimeout(() => {
         try{
          var height = $('#' + this.product_index.toString()).offset().top - $('html,body').offset().top;
          $('html,body').animate({
            scrollTop: height - $('.main-banner').height()
          })
         }catch(err){}
        }, 100);
      }
    })
  }


  calculate_is_required() {
    this.required_count = 0
    this.selected_item.specifications.forEach((specification_group) => {
      if (specification_group.is_required) {
        this.required_count++;
      }
    })
  }

  onProductSelected(index) {
    this.selected_product = index
  }
  onTrackOrder(){
    $("#thankyou-popup").removeClass('model-open')
    $("body").removeClass('model-open')
    this.get_order_details(this.selected_order._id);
  }
  onClose() {
    $("#product-popup").removeClass('model-open')
    $("body").removeClass('model-open')
  }

  onModelOpen(item, product_id) {
    //script
    // const product_index = this._cartService.user_cart.cart_data.cart.findIndex(_p => _p.product_id === product_id);
    // if(product_index != -1){
    $("#order-add-more-items").removeClass('model-open')
    setTimeout(() => {
      $('#product-popup').addClass('model-open');
      var header_height = $('#product-popup').find(".dropdown-title").outerHeight() + 25;
      $('#product-popup').find(".dropdown-content").css("padding-top", header_height);
    }, 10);
    this.product_name = this.products[this.selected_product]._id.name;
    this.product_unique_id = this.products[this.selected_product]._id.unique_id;
    this.current_main_item = item;
    this.selected_item = JSON.parse(JSON.stringify(item))
    this.calculateTotalAmount();
    this.calculate_is_required()
    // }
  }

  onEditModelOpen(item, order, order_index, item_index) {
    this.is_edit_item = true;
    this.order_index = order_index;
    this.item_index = item_index;
    /*this._orderService.get_item_data({item_id: item.item_id}).then(res_data => {
      console.log(res_data);
      
    })*/
    $("#order-add-more-items").removeClass('model-open')
    setTimeout(() => {
      $('#product-popup').addClass('model-open');
      var header_height = $('#product-popup').find(".dropdown-title").outerHeight() + 25;
      $('#product-popup').find(".dropdown-content").css("padding-top", header_height);
    }, 10);
    
    
    item.name = item.item_name
    item.price = item.item_price
    this.qty = item.quantity;
    this.product_name = order.product_name;
    this.product_unique_id = order.unique_id;
    this.current_main_item = item;
    this.selected_item = JSON.parse(JSON.stringify(item))
    this.selected_item.name = this.selected_item.item_name
    this.calculateTotalAmount();
    this.calculate_is_required()
  }


  onProduct(index) {
    this.selected_product = 0;
    if(!this.product_groups[index]['is_show']){
      this.product_index = index
      this.user_get_store_product_item_list()
    }else{
      this.product_groups[index]['is_show'] = false;
    }
  }


  changeradio(event, specification_group_index, specification_index) {
    var index = this.selected_item.specifications[specification_group_index].list.findIndex(x => x.is_default_selected == true)
    if (index !== -1) {
      this.selected_item.specifications[specification_group_index].list[index].is_default_selected = false;
    }
    this.selected_item.specifications[specification_group_index].list[specification_index].is_default_selected = true;
    this.calculateTotalAmount();
  }

  changecheckbox(event, specification_group_index, specification_index) {
    this.selected_item.specifications[specification_group_index].list[specification_index].is_default_selected = event.target.checked;
    this.calculateTotalAmount();
  }

  incerase_qty() {
    this.qty++;
    this.calculateTotalAmount();
  }

  decrease_qty() {
    if (this.qty > 1) {
      this.qty--;
      this.calculateTotalAmount();
    }
  }

  calculateTotalAmount() {
    if(this.selected_item){
      this.total = this.selected_item.price;
    this.required_temp_count = 0;
    this.selected_item.specifications.forEach((specification_group, specification_group_index) => {
      var default_selected_count = 0
      specification_group.list.forEach((specification, specification_index) => {

        if (specification.is_default_selected) {
          // if(specification_group.type == 2 && boolean){
            this.total = this.total + specification.price;
          // }
          default_selected_count++;
        }
        specification_group.default_selected_count = default_selected_count;
      });


      if (specification_group.type == 1 && specification_group.is_required) {
        if (specification_group.range) {
          if (default_selected_count >= specification_group.range) {
            this.required_temp_count++;
          }
        } else {
          if (default_selected_count >= 1) {
            this.required_temp_count++;
          }
        }
      } else if (specification_group.type == 2 && specification_group.is_required) {
        if (specification_group.range) {
          if (default_selected_count >= specification_group.range) {
            this.required_temp_count++;
          }
        } else {
          if (default_selected_count >= 1) {
            this.required_temp_count++;
          }
        }
      }

    });

    this.total = this.total * this.qty;
    }
  }


  async handleClickOnAddToCart() {

    this.is_edit_order = true

    let specificationPriceTotal = 0;
    let specificationPrice = 0;
    let specificationList = [];
    var y_name = "";

    this.selected_item.specifications.forEach((specification_group, specification_group_index) => {
      let specificationItemCartList = [];
      specification_group.list.forEach((specification, specification_index) => {
        y_name = specification.name;
        if (typeof specification.name == "object") {
          y_name = specification.name[0];
          if (!y_name) {
            y_name = specification.name[0];
          }
        }
        specification.name = y_name;
        if (specification.is_default_selected) {
          specification.name = y_name
          specificationPrice = specificationPrice + specification.price;
          specificationPriceTotal = specificationPriceTotal + specification.price;
          specificationItemCartList.push(specification)
        }
      });
      y_name = specification_group.name;
      if (typeof specification_group.name == "object") {
        y_name = specification_group.name[0];
        if (!y_name) {
          y_name = specification_group.name[0];
        }
      }
      specification_group.name = y_name;
      if (specificationItemCartList.length > 0) {
        let specificationsItem_json = {
          list: specificationItemCartList,
          unique_id: specification_group.unique_id,
          name: y_name,
          price: specificationPrice,
          type: specification_group.type,
          range: specification_group.range,
          max_range: specification_group.max_range
        }
        specificationList.push(specificationsItem_json);
      }
      specificationPrice = 0;

    });

    this.cartProductItems.item_id = this.selected_item._id;
    this.cartProductItems.unique_id = this.selected_item.unique_id;
    this.cartProductItems.item_name = this.selected_item.name;
    this.cartProductItems.quantity = this.qty;
    this.cartProductItems.image_url = this.selected_item.image_url;
    this.cartProductItems.details = this.selected_item.details;
    this.cartProductItems.specifications = specificationList;
    this.cartProductItems.item_price = this.selected_item.price;
    this.cartProductItems.total_specification_price = specificationPriceTotal;
    this.cartProductItems.total_item_price = this.total;
    if (this.store.is_use_item_tax == true) {
      this.cartProductItems.tax = this.selected_item.tax;
    } else {
      this.cartProductItems.tax = this.store.item_tax;
    }
    this.cartProductItems.total_specification_tax = specificationPriceTotal * Number(this.cartProductItems.tax) * 0.01;
    this.cartProductItems.item_tax = (Number(this.cartProductItems.tax) * Number(this.cartProductItems.item_price) * 0.01);
    this.cartProductItems.total_tax = (Number(this.cartProductItems.item_tax) + Number(this.cartProductItems.total_specification_tax));
    this.cartProductItems.total_item_tax = (Number(this.cartProductItems.total_tax) * Number(this.cartProductItems.quantity));
    this._cartService.user_cart.cart_main_item.push(this.current_main_item);
    this._cartService.user_cart.total_cart_amount = this._cartService.user_cart.total_cart_amount + this.total;
    this._cartService.user_cart.total_item_tax = Number(this._cartService.user_cart.total_item_tax) + Number(this.cartProductItems.total_item_tax);

    if (this.isProductExistInLocalCart(this.cartProductItems)) {
      console.log('if');
      
    }
    else if (this.is_edit_item) {
      console.log('else if'); 
      this._cartService.user_cart.cart_data.cart[this.order_index].items.forEach((item) => {
        this._cartService.user_cart.cart_data.cart[this.order_index].total_item_price += item.total_item_price;
        this._cartService.user_cart.cart_data.cart[this.order_index].total_item_tax += item.total_item_tax;
      })
      this._cartService.user_cart.cart_data.cart[this.order_index].items[this.item_index].quantity = this.qty
      this._cartService.user_cart.cart_data.cart[this.order_index].items[this.item_index].total_item_price = this.total
      this.addItemInServerCart();
      
    }
    else {
      console.log('else'); 
      let cartProductItemsList = [];
      cartProductItemsList.push(this.cartProductItems)
      this.cartProducts.items = cartProductItemsList;
      this.cartProducts.product_id = this.selected_item.product_id;
      this.cartProducts.product_name = this.product_name
      this.cartProducts.unique_id = this.product_unique_id
      this.cartProducts.total_item_price = 0;
      this.cartProducts.total_item_tax = 0;
      this.cartProducts.items.forEach((item) => {
        this.cartProducts.total_item_price += item.total_item_price;
        this.cartProducts.total_item_tax += item.total_item_tax;
      });
      this._cartService.user_cart.cart_data.cart.push(this.cartProducts);
      this._cartService.user_cart.cart_data.selectedStoreId = this.store_id;
      this._cartService.user_cart.cart_data.max_item_quantity_add_by_user = this.store.max_item_quantity_add_by_user;
      this._cartService.user_cart.cart_data.pickup_addresses[0].user_type = this.store.user_type;
      this._cartService.user_cart.cart_data.pickup_addresses[0].user_details = {
        "name": this.store.name,
        "country_phone_code": this.store.country_phone_code,
        "phone": this.store.phone,
        "email": this.store.email
      }
      this._cartService.user_cart.cart_data.total_item++;
      this.addItemInServerCart();
    }
  }


  isProductExistInLocalCart(cartProductItems) {
    // let bool = false;
    // this._cartService.user_cart.cart_data.cart.forEach((cart_item) => {
    //   if (cart_item.product_id == this.products[this.selected_product].product_id && bool == false) {
    //     cart_item.items.push(cartProductItems);
    //     cart_item.total_item_price = cart_item.total_item_price + this.total;
    //     this.addItemInServerCart();
    //     this._cartService.user_cart.cart_data.total_item++;
    //     bool = true;
    //   }
    // });

    return false;
  }

  addItemInServerCart() {
    this.cartProducts = new cartProductsModel();
    this.cartProductItems = new ItemModel();
    // this._cartService.add_to_cart();

    $('#close_item_model').click();
  }

  onRemoveFromCart(product_id, item_index, order) {
    this.is_edit_order = true
    var index = this._cartService.user_cart.cart_data.cart.findIndex(x => x.product_id === product_id)
    this._cartService.user_cart.cart_data.cart[index].items.splice(item_index, 1)
    if (this._cartService.user_cart.cart_data.cart[index].items.length <= 0) {
      this._cartService.user_cart.cart_data.cart.splice(index,1)
    }
    
    // this._cartService.remove_from_cart(product_id, item_id)
    this.calculateTotalAmount()
  }

  // User Chat

  onEditOrder() {
    var json = {
      user_id: this._authService.user._id,
      server_token: this._authService.user.server_token,
      store_id: this.selected_order.store_id,
      order_id: this.selected_order._id,
      cart_id: this._cartService.user_cart.cart_data.cart_id,

      order_details: this._cartService.user_cart.cart_data.cart,
      total_item_count: this._cartService.user_cart.cart_data.total_item,
      total_cart_price: this._cartService.user_cart.total_cart_amount,
      total_item_tax: this._cartService.user_cart.total_item_tax
    }
    this._cartService.edit_order(json).then(res_data => {
      this.is_edit_order = false
    })
  }

  getActiveTab() {
    this.chats = [];
    this._chatService.clearSubscription()
    const activeTab = this.tabset.tabs.filter(tab => tab.active);

    switch (activeTab[0].tabTitle.toLowerCase()) {
      case 'store':
        this.chat_type = ChatType.STORE
        break;
      case 'admin':
        this.chat_type = ChatType.ADMIN
        break;
      case 'deliveryman':
        this.chat_type = ChatType.DELIVERYMAN
        break;
      default:
        break;
    }
    this.onReadMessage()
  }

  onReadMessage() {
    this._chatService.readChat(this.selected_order._id, this.chat_type)
    this.chatSubscription = this._chatService._chatObservable.subscribe(data => {
      if (data) {
        var chats = [];
        Object.keys(data).forEach(ele => {
          chats.push(data[ele]);
        })
        this.chats = chats;
      } else {
        this.chats = [];
      }
    })
    // this.onSendMessage()
  }

  onSendMessage() {
    console.log(this.selected_order)
    var receiver_id = '000000000000000000000000'
    if (this.chat_type === ChatType.ADMIN) {
      receiver_id = '000000000000000000000000'
    } else if (this.chat_type === ChatType.STORE) {
      receiver_id = this.selected_order.store_detail._id
    } else {
      receiver_id = this.selected_order.provider_detail[0]._id
    }
    if (this.message != '') {
      this._chatService.sendMessage(this.selected_order._id, this.chat_type, receiver_id, this.message);
      this.message = '';
      this.onReadMessage();
    }
  }

  onChatClose() {
    if (this.chatSubscription) {
      this.chatSubscription.unsubscribe();
      this._chatService.clearSubscription();
    }
    // this.modalRef.hide();
  }

  showChat(): void {
    this.chatDialog = !this.chatDialog;
  }

  resetSelection(){
    this.order_details = null;
    this.selected_order = ''        
  }

  ngOnDestroy(): void {
    if(this.chatSubscription){
      this.chatSubscription.unsubscribe()
      this._chatService.clearSubscription()
    }
  }
  onConfim() {
    this.calculateTotalAmount()
    this.helper.openModel('order-confirm')
  }

  onConfirmationCode(){
    this.is_show_confirmation_code = !this.is_show_confirmation_code
  }


}
