<div class="order-detail page-content">
    <div class="container">
        <div class="section-title page-title">
            <h2>{{'heading-title.order-page' | translate}}</h2>
        </div>
        <div class="order-detail-wrp">
            <div class="row">
                <div class="col-lg-4 order-col1">
                    <div class='product-content-wrp' style="display:block">
                        <ul class="tab d-flex">
                            <li (click)="resetSelection()" class="tab-li"><a data-tag="order-list1" class="activelink">{{'label-title.current-order' | translate}}</a></li>
                            <li (click)="resetSelection()" class="tab-li"><a data-tag="order-list2">{{'label-title.order-history' | translate}}</a>
                            </li>
                        </ul>

                        <!-- product tab section  -->
                        <div id="container-wrp">
                            <div class="list" id="order-list1">
                                <div class="order-list custom-popup1"
                                    *ngFor="let order of current_orders; let current_order_index = index"
                                    data-id="order-list-popup1">
                                    <div class="order-list-block"
                                        [ngClass]="selected_order._id === order._id ? 'selected': '' ">
                                        <div class="order-product-content"
                                            (click)="onSelectOrder(order, current_order_index)">
                                            <div class="order-number d-flex">
                                                <h4 class="mr-auto"><span>{{'label-title.order' | translate}}</span>
                                                    #{{order.unique_id}}</h4>
                                                <div class="cancel-link">
                                                    <a class="cancel-link" (click)="helper.openModel('cancel-order')" *ngIf="order.order_status <= ORDER_STATE.DELIVERY_MAN_PICKED_ORDER">{{'button-title.cancel-order' | translate}}</a>
                                                </div>
                                            </div>
                                            <div class="order-time">
                                                <span>{{order.created_at | date:'shortTime'}}</span>
                                            </div>
                                            <div class="order-time order-status" >
                                                <a class="order-time order_status">{{ 'status-title.'+order.order_status | translate}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="list hide" id="order-list2">
                                <div class="order-list"
                                    *ngFor="let order of order_history; let order_history_index = index">
                                    <div class="order-list-block"
                                        [ngClass]="selected_order._id === order._id ? 'selected': '' ">
                                        <div class="order-product-content"
                                            (click)="onSelectOrder(order, current_order_index)">
                                            <div class="order-number d-flex">
                                                <h4 class="mr-auto"><span>{{'label-title.order' | translate}}</span>
                                                    #{{order.unique_id}}</h4>
                                                <div class="cancel-link" *ngIf="order.order_status <= DELIVERY_MAN_PICKED_ORDER">
                                                    <a class="cancel-link">{{'button-title.cancel-order' | translate}}</a>
                                                </div>
                                            </div>
                                            <div class="order-time">
                                                <span>{{order.created_at | date:'shortTime'}}</span>
                                            </div>
                                            <div class="order-time order-status" >
                                                <a class="order-time order_status">{{ 'status-title.'+order.order_status | translate}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-lg-8 order-col2 order-detail-desktop" [hidden]="!order_details || !isshow || helper._loader.isLoading">
                    <div class="order-progess-bar">
                        <ul class="progress-list d-flex align-item-center justify-content-center">
                            <li data-step="1" class="one"
                                [ngClass]="order_details.order.order_status >= ORDER_STATE.WAITING_FOR_ACCEPT_STORE ? 'active': '' ">
                                <a href="javascript:void(0)" class="res1 m1">1</a><span class="status">{{'label-title.order-in-progress' | translate}} </span>
                                <span class="time mt-1" style="min-height: 12px;">{{order_details.create_date | date:'dd MMM hh:mm a'}}</span>
                            </li>
                            <li data-step="2" class="two"
                                [ngClass]="order_details.order.order_status > ORDER_STATE.WAITING_FOR_ACCEPT_STORE ? 'active': '' ">
                                <a href="javascript:void(0)" class="res1 m1">2</a><span class="status">{{'label-title.order-accepted' | translate}} </span>
                                <span class="time mt-1" style="min-height: 12px;">{{order_details.accept_date | date:'dd MMM hh:mm a'}}</span>
                            </li>
                            <li data-step="3" class="three"
                                [ngClass]="order_details.order.order_status >= ORDER_STATE.ORDER_READY ? 'active': '' ">
                                <a href="javascript:void(0)" class="res">3</a><span class="status">{{'label-title.order-is-prepared' | translate}}  </span>
                                <span class="time mt-1" style="min-height: 12px;">{{order_details.prepare_date | date:'dd MMM hh:mm a'}}</span>
                            </li>
                            <li data-step="4" class="three" *ngIf="!order_payments.is_user_pick_up_order"
                                [ngClass]="order_details.request_status >= ORDER_STATE.DELIVERY_MAN_STARTED_DELIVERY && order_details.request_status <= ORDER_STATE.ORDER_COMPLETED ? 'active': '' ">
                                <a href="javascript:void(0)"
                                    class="res">4</a><span class="status">{{'label-title.deliveryman-on-his-way' | translate}}  </span>
                                    <span class="time mt-1" style="min-height: 12px;">{{order_details.on_way_date | date:'dd MMM hh:mm a'}}</span>
                            </li>
                            <li data-step="5" class="four" *ngIf="!order_payments.is_user_pick_up_order"
                                [ngClass]="order_details.order.order_status >= ORDER_STATE.DELIVERY_MAN_ARRIVED_AT_DESTINATION&& order_details.request_status != 0 && order_details.request_status <= ORDER_STATE.ORDER_COMPLETED ? 'active': '' ">

                                <a href="javascript:void(0)" class="res1 confirmation-code">5</a><span class="status">{{'label-title.order-received' | translate}}</span>
                                <span class="time mt-1" style="min-height: 12px;">{{order_details.order_received_date | date:'dd MMM hh:mm a'}}</span>
                                <span>
                                    <span class="link" *ngIf="!is_show_confirmation_code" (click)="onConfirmationCode()">{{ 'label-title.confirmation-code' | translate }}</span>
                                    <span class="link" *ngIf="is_show_confirmation_code" (click)="onConfirmationCode()">{{order_details.order.confirmation_code_for_complete_delivery}}</span>
                                </span>
                            </li>
                            <li data-step="4" class="four" *ngIf="order_payments.is_user_pick_up_order"
                                [ngClass]="order_details.order.order_status >= ORDER_STATE.DELIVERY_MAN_ARRIVED_AT_DESTINATION&& order_details.request_status != 0 && order_details.request_status <= ORDER_STATE.ORDER_COMPLETED ? 'active': '' ">

                                <a href="javascript:void(0)" class="res1 confirmation-code">4</a><span class="status">{{'label-title.order-received' | translate}}</span>
                                <span class="time mt-1" style="min-height: 12px;">{{order_details.order_received_date | date:'dd MMM hh:mm a'}}</span>
                                <span>
                                    <span class="link" *ngIf="!is_show_confirmation_code" (click)="onConfirmationCode()">{{ 'label-title.confirmation-code' | translate }}</span>
                                    <span class="link" *ngIf="is_show_confirmation_code" (click)="onConfirmationCode()">{{order_details.order.confirmation_code_for_complete_delivery}}</span>
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 detail-col1">
                            <div class="order-detail-col mb-30">
                                <div class="order-number-title d-flex align-item-center flex-wrap d-flex-none-mob">
                                    <div class="order-title mr-auto">
                                        <h4>{{'heading-title.order' | translate}} #{{order_details.order.unique_id}}</h4>
                                    </div>
                                    <div
                                        class="btn-list d-flex align-item-center justify-content-end justify-content-start-mob" *ngIf="selected_order.order_change">
                                        <a href="javascript:void(0)" class="edit hide">{{'button-title.edit' | translate}}</a>
                                        <a href="javascript:void(0)" (click)="onAddMoreItems()" *ngIf="selected_order.order_status <= ORDER_STATE.STORE_ACCEPTED">{{'button-title.add-more-items' | translate}}</a>
                                        <button *ngIf="selected_order.order_status <= ORDER_STATE.STORE_ACCEPTED" (click)="onConfim()" class="primary-btn secondary-btn custom-popup"
                                            data-id="order-confirm">{{'button-title.confirm' | translate}}</button>
                                    </div>
                                </div>
                                <div class="order-number-title d-flex align-item-center flex-wrap d-flex-none-mob" *ngIf="order_details.order.order_status == 5 && order_details.order.estimated_time_for_ready_order">
                                    <div class="order-title mr-auto">
                                        <h4>{{'label-title.estimated-order-ready-time' | translate}} : {{order_details.order.estimated_time_for_ready_order | date:'dd MMM hh:mm a'}}</h4>
                                    </div>
                                </div>
                                <div class="order-list-wrp mt-2" *ngFor="let order of cart_order_details; let order_index = index">
                                    <div class="order-border" *ngFor="let item of order.items; let item_index = index">
                                        <div class="order-list-block">
                                            <div class="order-product-content">
                                                <div class="order-product-title">
                                                    <a href="javascript:void(0)" class="mr-auto">{{item.item_name}}</a>
                                                    <div class="remove-btn" *ngIf="cart_order_details.length > 1 || (cart_order_details.length === 1 && order.items.length > 1)"><a (click)="onRemoveFromCart(order.product_id,item.item_id, order)">×</a></div>
                                                </div>
                                                <div class="order-product-details">
                                                    <p>{{item.details}}</p>
                                                </div>
                                                <div class="order-product-price d-flex align-item-center">
                                                    <div class="price mr-auto">{{order_payments.order_currency_code}} {{item.total_item_price | roundpipe:2}}</div>
                                                    <div class="order-qty"><label for="">{{'heading-title.quantity' |
                                                            translate}}</label><span>{{item.quantity}}</span></div>
                                                </div>
                                                <div class="order-product-edit d-flex align-item-center"
                                                    *ngFor="let specification of item.specifications">
                                                    <div class="edit-content mr-auto">
                                                        <p><span>{{specification.name}}:&emsp;</span>
                                                            <span class="toppings"
                                                                *ngFor="let spec of specification.list">{{spec.name}} ,&emsp; </span>

                                                        </p>
                                                    </div>
                                                    <div class="topping-price">
                                                        <span>{{order_payments.order_currency_code}} {{specification.price | roundpipe:2}}</span>
                                                    </div>
                                                </div>
                                                <div class="text-right font-14">
                                                    <a *ngIf="selected_order.order_change && selected_order.order_status <= ORDER_STATE.STORE_ACCEPTED" (click)="onEditModelOpen(item, order, order_index, item_index)" class="color-primary">{{'button-title.edit' | translate}}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                <div class="order-list mb-4" >
                                    <div class="order-list-block">
                                      
                                        {{'label-title.isCutlery' | translate}}
                                        <input type="checkbox" [checked]="order_details.order.isCutlery" class="option-input" style="float:right;vertical-align: middle;">
                                     
                                    </div>
                                </div>
                        </div>
                        <div class="col-sm-6 detail-col2">

                            <div class="price-details-section order-detail-col order-invoice mb-30">
                                <div class="order-title d-flex align-item-center">
                                    <h4>{{'heading-title.invoice' | translate}}</h4>
                                </div>
                                <div class="cart-total-wrp">
                                    <ul>
                                        <li [hidden]="!order_payments.is_store_pay_delivery_fees" class="text-right">
                                          <img src="../../assets/images/free_shipping.png" alt="">
                                        </li>
                                        <li>
                                            <div class="cart-total-list-wrp subtotal d-flex align-item-center">
                                                <div class="cart-total-label mr-auto">
                                                    <strong>{{'label-title.total-service-price' | translate}}</strong>
                                                </div>
                                                <div class="cart-total-value">
                                                    <strong *ngIf="order_payments.busy_period_amount > 0 && order_payments.busy_period_to_customer">{{order_payments.order_currency_code}} {{order_payments.total_delivery_price + order_payments.busy_period_amount | roundpipe:2}}</strong>
                                                    <strong *ngIf="order_payments.busy_period_amount <= 0 && !order_payments.busy_period_to_customer">{{order_payments.order_currency_code}} {{order_payments.total_delivery_price | roundpipe:2}}</strong>
                                                </div>
                                            </div>
                                        </li>
                                        <!--<li>
                                            <div class="cart-total-list-wrp d-flex align-item-center">
                                                <div class="cart-total-label mr-auto"><span>{{'label-title.service-price' | translate}}</span></div>
                                                <div class="cart-total-value">
                                                    <span *ngIf="order_payments.busy_period_amount > 0 && order_payments.busy_period_to_customer">{{order_payments.order_currency_code}} {{order_payments.total_service_price + order_payments.busy_period_amount | roundpipe:2}}</span>
                                                    <span *ngIf="order_payments.busy_period_amount <= 0 && !order_payments.busy_period_to_customer">{{order_payments.order_currency_code}} {{order_payments.total_service_price | roundpipe:2}}</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="cart-total-list-wrp d-flex align-item-center">
                                                <div class="cart-total-label mr-auto"><span>{{'label-title.service-tax' | translate}}</span></div>
                                                <div class="cart-total-value">
                                                    <span>{{order_payments.order_currency_code}} {{order_payments.service_tax | roundpipe:2}}</span>
                                                </div>
                                            </div>
                                        </li>-->
                                        <li>
                                            <div class="cart-total-list-wrp subtotal d-flex align-item-center">
                                                <div class="cart-total-label mr-auto">
                                                    <strong>{{'label-title.total-item-price' | translate}}</strong>
                                                </div>
                                                <div class="cart-total-value">
                                                    <strong>{{order_payments.order_currency_code}} {{order_payments.total_order_price | roundpipe:2}}</strong>
                                                </div>
                                            </div>
                                        </li>
                                        <!--<li>
                                            <div class="cart-total-list-wrp d-flex align-item-center">
                                                <div class="cart-total-label mr-auto"><span>{{'label-title.item-price' | translate}}</span></div>
                                                <div class="cart-total-value">
                                                    <span>{{order_payments.order_currency_code}} {{order_payments.total_cart_price | roundpipe:2}}</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="cart-total-list-wrp d-flex align-item-center">
                                                <div class="cart-total-label mr-auto"><span>{{'label-title.item-tax' | translate}}</span></div>
                                                <div class="cart-total-value">
                                                    <span>{{order_payments.order_currency_code}} {{order_payments.total_store_tax_price | roundpipe:2}}</span>
                                                </div>
                                            </div>
                                        </li>-->
                                        <li>
                                            <div class="cart-total-list-wrp d-flex align-item-center" *ngIf="order_payments.tip_amount > 0">
                                              <div class="cart-total-label mr-auto"><span>{{'label-title.tip-amount' | translate}}</span></div>
                                              <div class="cart-total-value"><span>{{order_currency_sign}} {{order_payments.tip_amount | roundpipe:2}}</span></div>
                                            </div>
                                        </li>

                                        <!--<li>
                                            <div class="cart-total-list-wrp subtotal d-flex align-item-center" *ngIf="order_payments.busy_period_amount > 0 && order_payments.busy_period_to_customer">
                                                <div class="cart-total-label mr-auto">
                                                    <strong>{{'label-title.busy-period-premium' | translate}}</strong>
                                                </div>
                                                <div class="cart-total-value">
                                                    <strong>{{order_payments.order_currency_code}} {{order_payments.busy_period_amount | roundpipe:2}}</strong>
                                                </div>
                                            </div>
                                        </li>-->
                                        <li>
                                            <div class="cart-total-list-wrp subtotal d-flex align-item-center">
                                                <div class="cart-total-label mr-auto">
                                                    <strong>{{'label-title.customer-service-fee' | translate}}</strong>
                                                </div>
                                                <div class="cart-total-value">
                                                    <strong>{{order_payments.order_currency_code}} {{order_payments.total_customer_service_fee | roundpipe:2}}</strong>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="cart-total-list-wrp subtotal d-flex align-item-center" *ngIf="order_payments.promo_payment > 0">
                                                <div class="cart-total-label mr-auto">
                                                    <strong>{{'label-title.subtotal' | translate}}</strong>
                                                </div>
                                                <div class="cart-total-value">
                                                    <strong>{{order_currency_sign}} {{order_payments.total | roundpipe:2}}</strong>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="cart-total-list-wrp d-flex align-item-center" *ngIf="order_payments.promo_payment > 0">
                                              <div class="cart-total-label mr-auto"><span>{{'label-title.discount' | translate}}</span></div>
                                              <div class="cart-total-value"><span>- {{order_currency_sign}} {{order_payments.promo_payment | roundpipe:2}}</span></div>
                                            </div>
                                        </li>

                                    </ul>
                                    <ul class="total-bottom">
                                        <li>
                                            <div class="cart-total-list-wrp subtotal d-flex align-item-center">
                                                <div class="cart-total-label mr-auto"><strong>{{'label-title.total' | translate}}</strong></div>
                                                <div class="cart-total-value">
                                                    <strong>{{order_payments.order_currency_code}} {{order_payments.user_pay_payment | roundpipe:2}}</strong>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul class="total-bottom" *ngIf="order_payments.store_refund_amount > 0">
                                        <li>
                                            <div class="cart-total-list-wrp subtotal d-flex align-item-center">
                                                <div class="cart-total-label mr-auto text-danger"><strong>{{'label-title.refund' | translate}}</strong></div>
                                                <div class="cart-total-value text-danger">
                                                    <strong>{{order_payments.order_currency_code}} {{order_payments.store_refund_amount | roundpipe:2}}</strong>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="order-delivery-details" [hidden]="!provider_details">
                                <div class="order-title d-flex align-item-center">
                                    <h4>{{'heading-title.delivery-details' | translate}}</h4>
                                </div>
                                <div class="delivery-details mb-30">
                                    <div class="delivery-address-section">
                                        <div class="delivery-person-detail d-flex align-item-center"
                                            *ngIf="provider_details">
                                            <div class="person-img">
                                                <img src="../../assets/images/product-img1.jpg" alt="">
                                            </div>
                                            <div class="person-detail">
                                                <div class="person-name">
                                                    <h5>{{provider_details.first_name}}&nbsp;{{provider_details.last_name}}
                                                    </h5>
                                                    <span class="distance">{{'label-title.distance-time' | translate}}</span>
                                                    <span class="distance-count">20km & 30mins</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="user-review mt-30 d-flex align-item-center">
                                            <div class="customer-review-count mr-auto">
                                                <div class="d-flex align-item-center"
                                                    *ngIf="review_details && review_details.user_rating_to_provider">
                                                    <span class="star">{{review_details.user_rating_to_provider}}</span>
                                                    <span
                                                        *ngFor="let i of counter(review_details.user_rating_to_provider)">
                                                        <img src="../../../assets/images/star-pink.svg" alt="">
                                                    </span>
                                                    <!-- <img src="../../../assets/images/star-pink.svg" alt="">
                                                    <img src="../../../assets/images/star-pink.svg" alt="">
                                                    <img src="../../../assets/images/star-pink.svg" alt="">
                                                    <img src="../../../assets/images/star-pink.svg" alt=""> -->
                                                </div>
                                            </div>
                                            <a (click)="give_rate_modal(1)" class="custom-popup"
                                                *ngIf="order_details.order.order_status === ORDER_STATE.ORDER_COMPLETED && !order_details.order.is_user_rated_to_provider"
                                                data-id="add-review-popup">{{'button-title.add-review' | translate}}</a>
                                        </div>
                                        <div class="map mt-30">
                                            <div id="map" style="height: 400px; width: 100%;"></div>

                                            <!-- <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d1884916.7075680098!2d70.5269932983835!3d22.673245189258935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3959cbcc3c9a5705%3A0xc4473b3507694530!2sElluminati%20Inc%2C%20Jala%20Ram%20Nagar%2C%20Rajkot%2C%20Gujarat%20360007!3m2!1d22.2901858!2d70.7747911!5e0!3m2!1sen!2sin!4v1602661037173!5m2!1sen!2sin"
                                                frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
                                                tabindex="0"></iframe> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="order-delivery-details" *ngIf="store_details">
                                <div class="delivery-details mb-30">
                                    <div class="delivery-address-section person-review-section">
                                        <div class="delivery-person-detail d-flex align-item-center">
                                            <div class="person-img">
                                                <img src="../../assets/images/product-img1.jpg" alt="">
                                            </div>
                                            <div class="person-detail">
                                                <div class="person-name">
                                                    <h5>{{store_details.name}}</h5>
                                                    <span class="distance">{{'label-title.distance-time' | translate}}</span>
                                                    <span class="distance">{{order_payments.total_distance | roundpipe:2}} {{order_details.order.country_detail ? (order_details.order.country_detail.is_distance_unit_mile ? 'Mile' : 'Km') : ''}} & {{order_payments.total_time | roundpipe:2}}mins</span>
                                                    <span class="distance" *ngIf="order_details.order.cart_detail">{{'label-title.apartment' | translate}} : {{order_details.order.cart_detail.apartment}}</span>
                                                    <span class="distance" *ngIf="order_details.order.cart_detail">{{'label-title.building_name' | translate}} : {{order_details.order.cart_detail.building_name}}</span>
                                                    <span class="distance" *ngIf="order_details.order.cart_detail">{{'label-title.business_name' | translate}} : {{order_details.order.cart_detail.business_name}}</span>
                                                    <span class="distance" *ngIf="order_details.order.cart_detail">{{'label-title.option_for_delivery' | translate}} : 
                                                      <ng-container *ngIf="order_details.order.cart_detail.option_for_delivery == 0">{{'label-title.leave-at-door-ring' | translate}}</ng-container>
                                                      <ng-container *ngIf="order_details.order.cart_detail.option_for_delivery == 1">{{'label-title.knock-on-door' | translate}}</ng-container>
                                                      <ng-container *ngIf="order_details.order.cart_detail.option_for_delivery == 2">{{'label-title.leave-at-door' | translate}}</ng-container>
                                                      <ng-container *ngIf="order_details.order.cart_detail.option_for_delivery == 3">{{'label-title.meet-outside' | translate}}</ng-container>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="user-review mt-4 d-flex align-item-center">
                                            <div class="customer-review-count mr-auto">
                                                <div class="d-flex align-item-center"
                                                    *ngIf="review_details && review_details.user_rating_to_store">
                                                    <span class="star">{{review_details.user_rating_to_store}}</span>
                                                    <span
                                                        *ngFor="let i of counter(review_details.user_rating_to_store)">
                                                        <img src="../../../assets/images/star-pink.svg" alt="">
                                                    </span>
                                                    <!-- <img src="../../../assets/images/star-pink.svg" alt="">
                                                    <img src="../../../assets/images/star-pink.svg" alt="">
                                                    <img src="../../../assets/images/star-pink.svg" alt="">
                                                    <img src="../../../assets/images/star-pink.svg" alt=""> -->
                                                </div>
                                            </div>
                                            <a (click)="give_rate_modal(2)" class="custom-popup"
                                                *ngIf="order_details.order.order_status === ORDER_STATE.ORDER_COMPLETED && !order_details.order.is_user_rated_to_store"
                                                data-id="add-review-popup">{{'button-title.add-review' | translate}}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div [hidden]="isshow">
                    <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;width: 100%;">
                      <img src="assets/not_found_images/orders_not_found.png" height="400px">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Add Review Popup start  -->

<div class="custom-model-main medium-popup review-model" id="add-review-popup">
    <div class="custom-model-inner">
        <div class="close-btn" id="close-add-review-popup">×</div>
        <div class="custom-model-wrap">
            <div class="pop-up-content-wrap">
                <div class="dropdown-title without-subtitle">
                    <h2>{{'heading-title.add-review' | translate}}</h2>
                </div>
                <div class="dropdown-content">
                    <form action="" class="login-form">
                        <div class="form-field">
                            <label for="rating_rate">{{'label-title.rating' | translate}}</label>
                            <select name="review" [(ngModel)]="rating_rate" name="rating_rate" id="rating_rate"
                                class="form-control">
                                <option value="">{{'label-title.select-rating' | translate}}</option>
                                <option value="1">1 {{'label-title.star-worst' | translate}}</option>
                                <option value="2">2 {{'label-title.stars' | translate}}</option>
                                <option value="3">3 {{'label-title.stars-average' | translate}}</option>
                                <option value="4">4 {{'label-title.stars' | translate}}</option>
                                <option value="5">5 {{'label-title.stars-best' | translate}}</option>
                            </select>
                        </div>
                        <div class="form-field">
                            <label for="rating_comment">{{'label-title.comments' | translate}}</label>
                            <textarea [(ngModel)]="rating_comment" name="rating_comment" id="rating_comment" cols="30"
                                rows="3" class="form-control"></textarea>
                        </div>
                        <div class="form-field">
                            <a (click)="give_rate()"
                                class="primary-btn submit-btn mr-auto">{{'button-title.submit-review' | translate}}</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-overlay"></div>
</div>

<!-- Add Review Popup end  -->

<!-- Order list popup for mobile View start  -->

<div class="custom-model-main order-detail-popup-mob fix" id="order-list-popup1">
    <div class="custom-model-inner">
        <div class="close-btn">×</div>
        <div class="custom-model-wrap">
            <div class="pop-up-content-wrap">
                <div class="dropdown-title without-subtitle">
                    <h2>{{'heading-title.order' | translate}} #1234567890</h2>
                </div>
                <div class="dropdown-content">
                    <div class="order-detail-mob row">

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-overlay"></div>
</div>

<!-- Order list popup for mobile View start  -->

<!-- Cancel order popup start  -->

<div class="custom-model-main order-detail-popup-mob" id="cancel-order">
    <div class="custom-model-inner">
        <div class="close-btn">×</div>
        <div class="custom-model-wrap">
            <div class="pop-up-content-wrap">
                <div class="dropdown-title without-subtitle">
                    <h2>{{'heading-title.cancel-your-order' | translate}}</h2>
                </div>
                <div class="dropdown-content cancel-order">
                    <div class="cancel-order-wrp" *ngIf="order_details">
                        <div class="mb-30" *ngIf="order_details.order.order_status >= 7 && store_details && store_details.order_cancellation_charge_value > 0 && order_payments.total_order_price > store_details.order_cancellation_charge_for_above_order_price">
                            <h5 class="mb-2">{{'heading-title.cancellation-charge' | translate}}</h5>
                            <!-- <p *ngIf="store_details.order_cancellation_charge_type === 2">{{'label-title.apply-above-order-price-charge-15-or-100' | translate}} {{store_details ? (store_details.order_cancellation_charge_for_above_order_price | roundpipe:0) : ''}} - {{order_details.order.country_detail ? order_details.order.country_detail.currency_sign : '' }} {{store_details.order_cancellation_charge_value}}</p> -->
                            <p *ngIf="store_details.order_cancellation_charge_type === 2">{{order_details.order.country_detail ? order_details.order.country_detail.currency_sign : '' }} {{store_details.order_cancellation_charge_value}}</p>
                            <p *ngIf="store_details.order_cancellation_charge_type === 1">{{order_details.order.country_detail ? order_details.order.country_detail.currency_sign : '' }} {{order_payments.total_order_price * (store_details.order_cancellation_charge_value / 100)}}</p>
                        </div>
                        <h5>{{'heading-title.reasons-for-cancel' | translate}}</h5>
                        <div class="cancel-list">
                            <form action="">
                                <div class="form-field">
                                    <label for="list1" class="radio mr-auto">
                                        <input type="radio" [checked]="cancellation_type === 'Change My Mind'" name="rdo" (change)="onSelectReason($event)" id="list1"
                                            class="hidden" value="{{'label-title.change-my-mind' | translate}}" />
                                        <span class="label"></span>{{'label-title.change-my-mind' | translate}}
                                    </label>
                                </div>
                                <div class="form-field">
                                    <label for="list2" class="radio mr-auto">
                                        <input type="radio" name="rdo" (change)="onSelectReason($event)" id="list2"
                                            class="hidden"
                                            value="{{'label-title.wrong-shipping-address' | translate}}" />
                                        <span class="label"></span>{{'label-title.wrong-shipping-address' | translate}}
                                    </label>
                                </div>
                                <div class="form-field">
                                    <label for="list3" class="radio mr-auto">
                                        <input type="radio" name="rdo" (change)="onSelectReason($event)" id="list3"
                                            class="hidden" value="{{'label-title.duplicate-order' | translate}}" />
                                        <span class="label"></span>{{'label-title.duplicate-order' | translate}}
                                    </label>
                                </div>
                                <div class="form-field">
                                    <label for="list4" class="radio mr-auto">
                                        <input type="radio" name="rdo" (change)="onSelectReason($event)" id="list4"
                                            class="hidden" value="{{'label-title.other' | translate}}" />
                                        <span class="label"></span>{{'label-title.other' | translate}}
                                    </label>
                                    <br>
                                    <input type="text" *ngIf="cancellation_type === ('label-title.other' | translate)" [(ngModel)]="cancellation_reason" [ngModelOptions]="{standalone: true}" (keypress)="helper.space_validation($event)"  />
                                    <div class="text-danger">{{cancellation_reason_error}}</div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="popup-footer">
                    <div class="popup-footer-wrp d-flex align-item-center">
                        <div class="add-to-cart-btn">
                            <button class="primary-btn" (click)="user_cancle_order()">{{'button-title.cancel-order' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-overlay"></div>
</div>


<!-- Cancel order popup start  -->

<!-- Order menu popup start  -->

<div class="custom-model-main order-menu fix" id="order-add-more-items">
    <div class="custom-model-inner">
        <div id="close-order-item" class="close-btn modal-close">×</div>
        <div class="custom-model-wrap p-4">
            <div class="menu-title-wrp d-flex mb-30 flex-wrap">
                <div class="section-title mr-auto">
                    <h2 class="mb-0">{{'heading-title.menu' | translate}}</h2>
                </div>
                <div class="menu-right d-flex align-item-center">

                    <div class="product-search">
                        <input type="text" [(ngModel)]="search_text" placeholder="{{'heading-title.placeholder-search' | translate}}" class="form-control">
                        <img src="../../../assets/images/search-icon.svg" alt="">
                    </div>
                </div>
            </div>


            <div class="product-type">
                <div id='product-type-wrp' *ngFor="let product_group of product_groups; let product_group_index = index" data-tag="tab{{i}}">

                  <!-- product title section  -->
                  <div class="product-block-title" (click)="onProduct(product_group_index)">
                      <div class="title-block">
                          <div class="title-block-wrp d-flex align-item-center">
                              <div class="title-block-img">
                                  <img (error)="$event.target.src = ITEM_DEFAULT_IMAGE" [src]="IMAGE_URL + product_group.image_url" alt="">
                              </div>
                              <div class="title-block-details">
                                  <h3>{{ product_group.name }}</h3>
                                  <!-- <p>A classic delight with 100% Real mozzarella cheese</p> -->
                              </div>
                          </div>
                      </div>
                  </div>
                  <!-- product content section  -->
                  <div class='product-content-wrp' style="display:block" id="{{product_group_index}}" [hidden]="!product_group.is_show"  *ngIf="products">
                      <ul class="tab d-flex">
                          <li class="tab-li" *ngFor="let product of products; let i = index">
                              <span *ngIf="product_group.name !== 'Main'">
                                  <a *ngIf="product_group.product_ids.indexOf(product._id._id) != -1" (click)="onProductSelected(i)" [ngClass]="selected_product === i ? 'activelink' : '' ">{{product._id.name}}</a>
                              </span>
                              <span *ngIf="product_group.name === 'Main'">
                                  <a (click)="onProductSelected(i)" [ngClass]="selected_product === i ? 'activelink' : '' ">{{product._id.name}}</a>
                              </span>
                        </li>
                      </ul>

                      <!-- product tab section  -->
                    <div id="container-wrp">
                        <div class="list">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6"
                                    *ngFor="let item of products[selected_product].items | searchpipe:search_text:['name'];let i=index">
                                    <div class="product-block product-tab">
                                        <div class="product-list d-flex align-item-center">
                                            <div class="product-img">
                                                <img (error)="$event.target.src = ITEM_DEFAULT_IMAGE" [src]="IMAGE_URL + item.image_url"
                                                    alt="">
                                            </div>
                                            <div class="product-details">
                                                <a href="javascript:void(0)" data-id="product-popup" (click)="onModelOpen(item)"
                                                    class="custom-popup">{{item.name}}</a>
                                                <p>{{item.details}}</p>
                                                <div class="product-bottom d-flex align-item-center">
                                                    <div class="product-price mr-auto">{{_cartService.currency}} {{item.total_item_price | roundpipe:2}}</div>
                                                    <div class="add-to-cart-bttn"><a href="javascript:void(0)" (click)="onModelOpen(item)"
                                                            class="primary-btn secondary-btn">{{'button-title.add' | translate}}</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                  <!-- product title section2  -->


                      </div>
                  </div>

              </div>
            </div>
        </div>
    </div>
    <div class="bg-overlay"></div>
</div>



<!-- Order menu popup start  -->

<!-- Order confirm popup start  -->

<div class="custom-model-main order-confirm-popup medium-popup fix" id="order-confirm">
    <div class="custom-model-inner">
        <div class="close-btn">×</div>
        <div class="custom-model-wrap">
            <div class="pop-up-content-wrap">
                <div class="dropdown-title without-subtitle">
                    <h2 class="mr-auto">{{'heading-title.confirm-order' | translate}}</h2>
                </div>
                <div class="dropdown-content">
                    <div class="cart-total-wrp">
                        <ul>
                            <li>
                                <div class="cart-total-list-wrp subtotal d-flex align-item-center">
                                    <div class="cart-total-label mr-auto"><strong>{{'label-title.subtotal' | translate}}</strong></div>
                                    <div class="cart-total-value"><strong>{{_cartService.currency}} {{_cartService.user_cart.total_cart_amount | roundpipe:2}}</strong></div>
                                </div>
                            </li>
                            <li>
                                <div class="cart-total-list-wrp d-flex align-item-center">
                                    <div class="cart-total-label mr-auto"><span>{{'label-title.delivery-charges' | translate}}</span></div>
                                    <div class="cart-total-value"><span>{{_cartService.currency}} {{_cartService.user_cart.total_item_tax | roundpipe:2}}</span></div>
                                </div>
                            </li>
                        </ul>
                        <ul class="total-bottom">
                            <li>
                                <div class="cart-total-list-wrp subtotal d-flex align-item-center">
                                    <div class="cart-total-label mr-auto"><strong>{{'label-title.total' | translate}}</strong></div>
                                    <div class="cart-total-value"><strong>{{_cartService.currency}} {{_cartService.user_cart.total_cart_amount | roundpipe:2}}</strong></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="popup-footer">
                    <div class="popup-footer-wrp d-flex align-item-center">
                        <div class="add-to-cart-btn">
                            <a (click)="onEditOrder()" class="primary-btn custom-popup"
                                data-id="thankyou-popup">{{'button-title.confirm' | translate}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-overlay"></div>
</div>

<!-- Order confirm popup end  -->

<!-- product card popup start  -->

<div class="custom-model-main product-popup my-scroll-area fix" id="product-popup" *ngIf="selected_item">
    <div class="custom-model-inner">
        <div class="close-btn modal-close" id="close_item_model" (click)="onClose()">×</div>
        <div class="custom-model-wrap">
            <div class="pop-up-content-wrap">
                <div class="dropdown-title">
                    <h2>{{ selected_item.name }}</h2>
                </div>
                <div class="dropdown-content">
                    <div class="pro-dec">
                        <p>{{ selected_item.details }}</p>
                    </div>
                    <div class="product-img text-center mb-30">
                        <img [src]="IMAGE_URL + selected_item.image_url" alt="">
                    </div>

                    <div *ngFor="let specification of selected_item.specifications; let i = index">
                        <div class="product-options-block" *ngIf="specification.type === 1">
                            <div class="subtitle-wrp d-flex align-item-top">
                                <div class="choose-item-wrp d-flex flex-wrap">
                                    <h4>{{specification.name}}</h4>
                                    <div class="choose">{{'label-title.choose1' | translate}}</div>
                                </div>
                                <div class="required-star" *ngIf="specification.is_required">{{'label-title.required' | translate}}</div>
                            </div>
                            <div class="options-list-block">
                                <form action="">
                                    <div class="options-list-wrp d-flex align-item-center" *ngFor="let spec of specification.list; let spec_index = index">
                                        <label for="opt{{spec_index}}{{i}}" class="radio mr-auto">
                                            <input type="radio" [checked]="spec.is_default_selected" id="opt{{spec_index}}{{i}}" (change)="changeradio($event, i, spec_index)" name="opt{{i}}" class="hidden"/>
                                            <span class="label"></span>{{spec.name}}
                                        </label>
                                        <div class="options-price">
                                            <span *ngIf="spec.price > 0">{{spec.price}}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="product-options-block" *ngIf="specification.type === 2">
                            <div class="subtitle-wrp d-flex align-item-top">
                                <div class="choose-item-wrp d-flex flex-wrap">
                                    <h4>{{specification.name}}</h4>
                                </div>
                                <div class="required-star" *ngIf="specification.is_required">{{'label-title.required' | translate}}</div>
                            </div>
                            <div class="options-list-block">
                                <form action="">
                                    <div class="options-list-wrp d-flex align-item-center" *ngFor="let spec of specification.list; let spec_index = index">
                                        <label class="d-flex align-item-center mr-auto" >
                                            <input type="checkbox" [checked]="spec.is_default_selected" *ngIf="specification.type==2 && (specification.max_range == 0 || specification.max_range == null)" [disabled]="specification.default_selected_count == specification.range && specification.range !== 0 && !spec.is_default_selected" (change)="changecheckbox($event, i, spec_index)" class="option-input checkbox">
                                            <input type="checkbox" [checked]="spec.is_default_selected" *ngIf="specification.type==2 && specification.max_range != 0 && specification.max_range != null" [disabled]="specification.default_selected_count == specification.max_range && !spec.is_default_selected" (change)="changecheckbox($event, i, spec_index)" class="option-input checkbox">{{spec.name}}
                                        </label>
                                        <div class="options-price">
                                            <span *ngIf="spec.price > 0">{{spec.total_item_price}}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="popup-footer">
                    <div class="popup-footer-wrp d-flex align-item-center">
                        <div class="product-qty d-flex align-item-center">
                            <a href="javascript:void(0)" class="qty-btn qty-dec" (click)="decrease_qty()">-</a>
                            <input type="text" class="form-control" value="{{qty}}">
                            <a href="javascript:void(0)" class="qty-btn qty-inc" (click)="incerase_qty()">+</a>
                        </div>
                        <div class="add-to-cart-btn">
                            <button class="primary-btn" (click)="handleClickOnAddToCart()" [disabled]="required_count !== required_temp_count">{{'button-title.add-to-order' | translate}} - ₹ {{total}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-overlay"></div>
</div>

<!-- product card popup end  -->

<!-- Thank You popup start  -->

<div class="custom-model-main thankyou-popup fix small-popup" id="thankyou-popup">
    <div class="custom-model-inner">
        <div class="close-btn" (click)="onTrackOrder()">×</div>
        <div class="custom-model-wrap">
            <div class="pop-up-content-wrap">
                <div class="dropdown-title">
                    <h2 class="mr-auto">{{'heading-title.thank-you' | translate}}</h2>
                    <p>{{'label-title.order-success' | translate}}</p>
                </div>
                <div class="dropdown-content">
                    <div class="thank-you-img text-center">
                        <img src="../../assets/images/thank_you.png" alt="">
                    </div>
                </div>
                <div class="popup-footer">
                    <div class="popup-footer-wrp d-flex align-item-center">
                        <div class="add-to-cart-btn">
                            <a href="javascript:void(0)" (click)="onTrackOrder()" class="primary-btn">{{'button-title.track-order' | translate}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-overlay"></div>
</div>
<div class="chatIcon" (click)="showChat()">
 <span>
     <img src="../../../assets/images/chat_icon.png" alt="USER">
</span>
</div>
<!-- Thank You popup end  -->

<div class="chat-dialog " *ngIf="chatDialog">
  <div class="chat-dialog-wrp">
      <div class="chat-title d-flex align-item-center">
          <div class="user-d mr-auto d-flex align-item-center">
              <div class="user-img">
                  <img (error)="$event.target.src = USER_DEFAULT_IMAGE" height="30" width="40" [src]="IMAGE_BASE_URL  + _authService.user.image_url" alt="">
              </div>
              <div class="user-name">
                  <h4>ORDER ID - {{this.selected_order.unique_id}}<br>{{_authService.user.first_name}} {{_authService.user.last_name}}</h4>
              </div>
          </div>
          <div class="chat-open">
              <ul class="d-flex align-item-center">
                  <li><a href="javascript:void(0)" (click)="showChat()">×</a></li>
              </ul>
          </div>
      </div>
      <ngx-tabset customNavClass="nav-test-class" customTabsClass="container" (click)="getActiveTab()">
        <ngx-tab tabTitle="{{'label-title.admin' | translate}}">
          <div class="chat-content">
            <ul>
                <li [class.bot-output]="chat.receiver_id !== '000000000000000000000000'" [class.user-output]="chat.receiver_id === '000000000000000000000000'" *ngFor="let chat of chats">{{chat.message}}</li>
            </ul>
        </div>
        <div class="typing-field">
            <form action="" (submit)="onSendMessage()">
                <input type="text" class="form-control" name="message" [(ngModel)]="message" (keypress)="helper.space_validation($event)" placeholder="{{'label-title.type-here' | translate}}">
            </form>
        </div>
        </ngx-tab>
        <ngx-tab *ngIf="selected_order.store_id" tabTitle="{{'label-title.store' | translate}}">
          <div class="chat-content">
            <ul>
                <li [class.bot-output]="chat.receiver_id !== selected_order.store_detail._id" [class.user-output]="chat.receiver_id === selected_order.store_detail._id" *ngFor="let chat of chats">{{chat.message}}</li>
            </ul>
        </div>
        <div class="typing-field">
            <form action=""  (submit)="onSendMessage()">
                <input type="text" class="form-control" name="message" (keypress)="helper.space_validation($event)" [(ngModel)]="message" placeholder="{{'label-title.type-here' | translate}}">
            </form>
        </div>
        </ngx-tab>
        <ngx-tab *ngIf="selected_order.order_status > 7" tabTitle="{{'label-title.deliveryman' | translate}}" >
          <div class="chat-content">
            <ul>
                <li [class.bot-output]="chat.receiver_id !== selected_order.provider_detail[0]._id" [class.user-output]="chat.receiver_id === selected_order.provider_detail[0]._id" *ngFor="let chat of chats">{{chat.message}}</li>

                <!-- <li class="bot-output">Hi!</li>
                <li class="bot-output">How are You?</li>
                <li class="user-output">Hello!</li>
                <li class="user-output">I am Fine. Thanks :)</li> -->
            </ul>
        </div>
        <div class="typing-field">
            <form action=""  (submit)="onSendMessage()">
                <input type="text" class="form-control" name="message" [(ngModel)]="message" (keypress)="helper.space_validation($event)" placeholder="{{'label-title.type-here' | translate}}">
            </form>
        </div>
        </ngx-tab>
      </ngx-tabset>

  </div>
</div>
