import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { BehaviorSubject, Observable } from 'rxjs';

export enum ChatType {
    "DELIVERYMAN",
    "ADMIN",
    "STORE"
}

@Injectable({ providedIn: 'root' })
export class ChatService {

    sender_type = '2';
    _chatObservable = new BehaviorSubject<any>(null);
    chatRef;

    constructor(private fireDB: AngularFireDatabase) { }

    readChat(order_id,chat_type:ChatType) {
        return new Promise((resolve,rejects)=>{
            var chatTypeCode = '12';
        switch (chat_type) {
            case ChatType.STORE:
                chatTypeCode = '24'
                break;
            case ChatType.ADMIN:
                chatTypeCode = '12'
                break;
            case ChatType.DELIVERYMAN:
                chatTypeCode = '23'
                break;
            default:
                break;
        }
            var chatRef = this.fireDB.database.ref(order_id).child(chatTypeCode);

            chatRef.on("value", snapshot => {
                if(snapshot){
                    var chat_object = snapshot.val()
                    if(chat_object != null){
                        var keys = Object.keys(chat_object)
                        keys.forEach((element,index) => {
                            if(chat_object[element].sender_type != this.sender_type && chat_object[element].is_read == false){
                                chatRef.child(element).child("is_read").set(true)
                            }
                        });
                    }
                    this._chatObservable.next(snapshot.val());
                }else{
                    this._chatObservable.next({});
                }
            });

        })
    }


    sendMessage(order_id,chat_type:ChatType,receiver_id,message){
        var chatType = '12';
        switch (chat_type) {
            case ChatType.STORE:
                chatType = '24'
                break;
            case ChatType.ADMIN:
                chatType = '12'
                break;
            case ChatType.DELIVERYMAN:
                chatType = '23'
                break;
            default:
                chatType = '12'
                break;
        }
        var ref = this.fireDB.database.ref(order_id).child(chatType);
        var key = ref.push().key;
        ref.child(key).set({
            "id":key,
            "message": message,
            "chat_type":Number(chatType),
            "sender_type": Number(this.sender_type),
            "receiver_id": receiver_id,
            "time":new Date().toISOString(),
            "is_read": false,
            "is_notify": false
        }, function(error) {
            if (error) {
                return false;
            } else {
                return true;
            }
        });
    }
    clearSubscription(){
        if(this.chatRef){
            this.chatRef.off();
        }
    }


}