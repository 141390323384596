<div class="homepage-main">
  <div class="container">
    <div class="delivering-section">
      <div class="section-title">
        <h2>{{'heading-title.we-are-delivering' | translate}}</h2>
      </div>
      <div class="delivering-section-wrp">
        <div class="delivering-list">
          <ul class="d-flex align-item-center flex-wrap p-0">
            <ng-container *ngIf="!deliveries || !deliveries.length">
              <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;width: 100%;">
                <img src="assets/not_found_images/deliveries_not_found.png" height="300px">
            </div>
            </ng-container>
            <li class="c-pointer" (click)="onChangeDelivery(delivery)" *ngFor="let delivery of deliveries">
              <div class="d-list-content">
                <div class="d-list-img">
                  <img (error)="$event.target.src = DEFAULT_IMAGE_DELIVERY" class="delivery_image"
                    [src]="IMAGE_URL + delivery.image_url" [alt]="delivery.delivery_name">
                </div>
                <div class="d-list-title">
                  <h5 [class.theme-text]="selectedDelivery._id === delivery._id">{{delivery.delivery_name}}</h5>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

  <ngb-carousel [showNavigationArrows]="false" >
    <ng-template ngbSlide *ngFor="let ads_array of slider_ads">
        <div class="row">
            <div class="col-lg-4 col-sm-6" *ngFor="let ad of ads_array" (click)="adRedirect(ad)">
                <div class="offer-banner">
                  <img (error)="$event.target.src = DEFAULT_IMAGE_ADS" [src]="ad.imageUrl" [alt]="ad.ads_detail">
                  <h4>{{ad.ads_detail}}</h4>
                </div>
            </div>
        </div>
    </ng-template>
  </ngb-carousel>



    <div class="store-near-you-section" id="store-list" >
      <div class="menu-title-wrp d-flex mb-30 flex-wrap">
        <div class="section-title mr-auto">
          <h2 class="mb-0">{{'heading-title.store-near-you' | translate}}</h2>
        </div>
        <div class="menu-right d-flex align-item-center mt-2 ">
          <div class="header-right-block toggle-btn schedule">
            <form class="form">
              <div class="switch-field">
                  <input type="radio" id="asap" class="no-border" name="switch-one" (click)="set_schedule(false)" value="ASAP" [checked]="!_cartService.user_cart.is_schedule_order" value="ASAP"/>
                  <label for="asap"> {{'label-title.asap' | translate}}</label>
                  <div class="h-schedule">
                      <input type="radio" disabled id="schedule" class="no-border" name="switch-one" disabled="" value="Schedule" [checked]="_cartService.user_cart.is_schedule_order" />
                      <label for="schedule">{{'label-title.schedule' | translate}} </label>
                      <div class="schedule-form common-dropdown">
                        <div class="dropdown-wrp">
                          <div class="dropdown-title">
                            <h2>{{'label-title.schedule-for-later' | translate}}</h2>
                            <p>{{'label-title.select-delivery-slot' | translate}}</p>
                          </div>
                          <div class="dropdown-content">
                            <div class="date-slider">

                              <div class='date-slider-wrp'>
                                <div class=''>
                                  <ngb-carousel [interval]="false" (slide)="onSlide($event)">
                                    <ng-template ngbSlide *ngFor="let date of date_array; let i = index;" [id]="'slideId_' + i">
                                      <div class="date-list">
                                        <span>{{date.date_format}}</span>
                                      </div>
                                      <div class="slots-list">
                                        <ng-container *ngIf="!slot1 && !slot2 && !slot3 && !slot4">{{'label-title.no-slots-available' | translate}}</ng-container>
                                        <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" *ngIf="slot1">
                                          <ngb-panel title="{{'label-title.night-time-slot' | translate}}">
                                          <ng-template ngbPanelContent>
                                            <ul class="d-flex align-item-center flex-wrap">
                                              <ng-container *ngFor="let time of time_array;">
                                                <li *ngIf="time.type == 1"><a href="javascript:void(0)" (click)="set_time(time)" [ngStyle]="{'background-color': time == selected_time ? '#0eb6cc' : '','color': time == selected_time ? '#fff' : '' }" class="primary-btn secondary-btn">{{time.time}} </a></li>
                                              </ng-container>
                                            </ul>
                                          </ng-template>
                                          </ngb-panel>
                                        </ngb-accordion>
                                        <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" *ngIf="slot2">
                                          <ngb-panel title="{{'label-title.morning-time-slot' | translate}}">
                                          <ng-template ngbPanelContent>
                                            <ul class="d-flex align-item-center flex-wrap">
                                              <ng-container *ngFor="let time of time_array;">
                                                <li *ngIf="time.type == 2"><a href="javascript:void(0)" (click)="set_time(time)" [ngStyle]="{'background-color': time == selected_time ? '#0eb6cc' : '','color': time == selected_time ? '#fff' : '' }" class="primary-btn secondary-btn">{{time.time}} </a></li>
                                              </ng-container>
                                            </ul>
                                          </ng-template>
                                          </ngb-panel>
                                        </ngb-accordion>
                                        <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" *ngIf="slot3">
                                          <ngb-panel title="{{'label-title.afternoon-time-slot' | translate}}">
                                          <ng-template ngbPanelContent>
                                            <ul class="d-flex align-item-center flex-wrap">
                                              <ng-container *ngFor="let time of time_array;">
                                                <li *ngIf="time.type == 3"><a href="javascript:void(0)" (click)="set_time(time)" [ngStyle]="{'background-color': time == selected_time ? '#0eb6cc' : '','color': time == selected_time ? '#fff' : '' }" class="primary-btn secondary-btn">{{time.time}} </a></li>
                                              </ng-container>
                                            </ul>
                                          </ng-template>
                                          </ngb-panel>
                                        </ngb-accordion>
                                        <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" *ngIf="slot4">
                                          <ngb-panel title="{{'label-title.evening-time-slot' | translate}}">
                                          <ng-template ngbPanelContent>
                                            <ul class="d-flex align-item-center flex-wrap">
                                              <ng-container *ngFor="let time of time_array;">
                                                <li *ngIf="time.type == 4"><a href="javascript:void(0)" (click)="set_time(time)" [ngStyle]="{'background-color': time == selected_time ? '#0eb6cc' : '','color': time == selected_time ? '#fff' : '' }" class="primary-btn secondary-btn">{{time.time}} </a></li>
                                              </ng-container>
                                            </ul>
                                          </ng-template>
                                          </ngb-panel>
                                        </ngb-accordion>
                                      </div>
                                    </ng-template>
                                  </ngb-carousel>

                                </div>

                              </div>

                            </div>
                          </div>
                          <div class="popup-footer">
                            <div class="popup-footer-wrp d-flex align-item-center">
                              <div class="add-to-cart-btn">
                                <a href="javascript:void(0)" class="primary-btn" (click)="set_schedule(true)">{{'button-title.schedule-delivery' | translate}}</a>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                  </div>
              </div>
          </form>
          </div>
          <div class="header-right-block toggle-btn delivery">
              <form class="form">
                  <div class="switch-field">
                      <div class="h-takeway">
                          <input (change)="tooglePickupOrders()"  type="checkbox" id="takeway" class="no-border" name="switch-one" value="Takeaway" />
                          <label for="takeway">{{'label-title.takeaway' | translate}}</label>
                      </div>
              </div>
            </form>
          </div>
          <div class="menu-filter ">
            <a href="javascript:void(0)" class="filter-btn">
              <img src="../../../assets/images/filter.svg" alt="" class="mr-2">{{'label-title.filters' | translate}}</a>
          </div>
          <!-- <div class="product-search ">
            <input type="text" [(ngModel)]="search_text" placeholder="{{'heading-title.placeholder-search' | translate}}"
              class="form-control store_search field">
              <img src="../../../assets/images/search-icon.svg" alt="">


          </div> -->
          <div class="wrapper">
            <div class="search-input">
              <a href="" target="_blank" hidden></a>
              <input type="text" class="field" (keyup)="onSearch()" placeholder="{{'label-title.search' | translate}}.." [(ngModel)]="search_store">
              <div class="autocom-box">
               <li *ngFor="let item of search_stores" >
                <div class="d-flex autocom-list" (click)="onStoreClick(item._id)">
                  <div class="storeimg">
                    <img (error)="$event.target.src = DEFAULT_STORE_IMAGE" [src]="IMAGE_URL+item.image_url" [alt]="item.name">
                    </div>
                    <div class="store-info">
                      <h5 class="m-0">{{item.name | langpipe}}</h5>
                      <!-- <p class="m-0">{{item.name}}</p> -->
                    </div>
                </div>
               </li>
              </div>
              <div class="icon"><img src="../../../assets/images/search-icon.svg" alt=""></div>
              <div *ngIf="search_store!=''" (click)="remove_value()" class="remove-store"><a href="javascript:void(0)" alt="remove">×</a></div>
            </div>
          </div>
        </div>
        <div class="header-sd-section  d-flex align-item-center justify-content-center">

        </div>
      </div>
      <div class="filter-list">
        <div class="content">
          <div class="acc d-flex flex-wrap">
            <div class="acc__card">
              <div class="acc__title">{{'label-title.tag' | translate}}</div>
              <div class="acc__panel">
                <h4>{{'label-title.choose-tag' | translate}}</h4>
                <form>
                  <label class="d-flex align-item-center" *ngFor="let tag of tags_filter_array">
                    <input type="checkbox" (change)="onFilter(tag)" class="option-input checkbox" />{{tag.title}}
                  </label>
                </form>
              </div>
            </div>
            <div class="acc__card">
              <div class="acc__title">{{'label-title.price' | translate}}</div>
              <div class="acc__panel">
                <h4>{{'label-title.price' | translate}}</h4>
                <form>
                  <label class="d-flex align-item-center" *ngFor="let price of price_filter_array">
                    <input type="checkbox" (change)="onFilter(price)" class="option-input checkbox" /><span
                      *ngFor="let i of counter(price.title)">{{currency}}</span>
                  </label>
                </form>
              </div>
            </div>
            <div class="acc__card">
              <div class="acc__title">{{'label-title.distance' | translate}}</div>
              <div class="acc__panel">
                <h4>{{'label-title.distance' | translate}}</h4>
                <form>
                  <label class="d-flex align-item-center" *ngFor="let distance of distance_filter_array">
                    <input type="checkbox" (change)="onFilter(distance)"
                      class="option-input checkbox" />{{distance.title}}
                  </label>
                </form>
              </div>
            </div>
            <div class="acc__card">
              <div class="acc__title">{{'label-title.time' | translate}}</div>
              <div class="acc__panel">
                <h4>{{'label-title.time' | translate}}</h4>
                <form>
                  <label class="d-flex align-item-center" *ngFor="let time of time_filter_array">
                    <input type="checkbox" (change)="onFilter(time)" class="option-input checkbox" />{{time.title[0]}} -
                    {{time.title[1]}}
                  </label>
                </form>
              </div>
            </div>
          </div>
          <!-- <div class="inline-list">
            <ul class="d-flex align-item-center">
              <li><a href="javascript:void(0)">tag1</a></li>
              <li><a href="javascript:void(0)">tag2</a></li>
              <li><a href="javascript:void(0)">test</a></li>
              <li><a href="javascript:void(0)">test</a></li>
              <li><a href="javascript:void(0)">test</a></li>
              <li><a href="javascript:void(0)">test</a></li>
            </ul>
          </div> -->
        </div>
      </div>
      <div class="store-list-advertisement mb-30" *ngIf="slider_promo.length">
        <h3>{{'heading-title.promo-ads' | translate}}</h3>
      </div>
      <ngb-carousel [showNavigationArrows]="false" class="mb-4">
        <ng-template ngbSlide *ngFor="let ads_array of slider_promo">
            <div class="row">
                <div class="col-lg-3 col-sm-6" *ngFor="let ad of ads_array" (click)="adRedirect(ad)">
                    <div class="offer-banner">
                      <img (error)="$event.target.src = DEFAULT_IMAGE_ADS" [src]="IMAGE_URL + ad.image_url" [alt]="ad.ads_detail">
                      <!-- <h4>{{ad.ads_detail}}</h4> -->
                    </div>
                </div>
            </div>
        </ng-template>
      </ngb-carousel>

      <div class="store-list search-results" infinite-scroll [infiniteScrollDistance]="scrollDistance" [infiniteScrollThrottle]="throttle" (scrolled)="onScrollDown()">
        <div class="row">
          <ng-container *ngIf="!filter_stores || !filter_stores.length">
            <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;width: 100%;">
              <img src="assets/not_found_images/no_store_found.png" height="400px">
            </div>
          </ng-container>
          <div class="fade-in col-lg-4 col-md-6 col-sm-4 col-12 c-pointer" *ngFor="let store of filter_stores | searchpipe:search_text:['name']">
            <div class="store-list-wrp">
              <div class="store-img">


                <div class="heart-icon" (click)="justLike(store._id)" *ngIf="_authService.isAuthenticated">
                  <i class="fas fa-heart" [ngStyle]="{'color': favourite_store.indexOf(store._id) != -1 ? '#0eb6cc' : '#7f7f7f' }"></i>
                </div>
                <div class="store-img-container store-img text-center" [ngClass]="store.is_store_busy || store.close ? 'store-background' : '' ">
                  <img [ngClass]="store.is_store_busy || store.close ? 'busy-store' : '' " (click)="onStoreClick(store._id)" (error)="$event.target.src = DEFAULT_STORE_IMAGE"
                  [src]="store.imageUrl" [alt]="store.name">
                  <span class="store-busy" *ngIf="store.is_store_busy && !store.close">{{'label-title.busy' | translate}}</span>
                  <span class="store-closed" *ngIf="store.close">
											{{'label-title.store-closed-today' | translate}}
                  </span>
                  <!-- <span class="store-busy" *ngIf="store.is_store_busy">{{ 'label-title.busy' | uppercase }}</span> -->
                </div>
                <!-- <div *ngIf="store.close">

                </div> -->
              </div>
              <div class="store-details">
                <div class="store-title d-flex align-item-center">
                  <h3 class="mb-0 mr-auto">{{store.name}}</h3>
                  <div class="store-rating">
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M16.9756 6.07562C16.917 5.8977 16.7612 5.76804 16.5736 5.74118L11.3035 4.98558L8.94661 0.273901C8.86273 0.106173 8.68958 0 8.50003 0C8.31044 0 8.13732 0.106173 8.05341 0.273901L5.6964 4.98558L0.426422 5.74118C0.238858 5.76804 0.0829699 5.8977 0.0243998 6.07558C-0.0342035 6.2535 0.0146713 6.44878 0.150438 6.57932L3.96373 10.2469L3.06367 15.4256C3.03159 15.6099 3.10843 15.7962 3.26179 15.9062C3.34855 15.9684 3.45131 16 3.55457 16C3.63386 16 3.71342 15.9814 3.78626 15.9436L8.49999 13.4985L13.2135 15.9435C13.3814 16.0306 13.5847 16.0161 13.738 15.9061C13.8914 15.7962 13.9683 15.6099 13.9362 15.4255L13.0359 10.2469L16.8496 6.57929C16.9853 6.44878 17.0342 6.2535 16.9756 6.07562Z" />
                    </svg>
                    <span>{{store.user_rate | roundpipe:2}}<span>({{store.user_rate_count | roundpipe}})</span></span>
                  </div>
                </div>
                <div class="order-product-tag store-tag d-inline-block">
                  <span *ngFor="let famous_tag of store.famous_products_tags">{{famous_tag}} | &nbsp;</span>
                </div>
                <div class="order-product-tag store-tag d-inline-block">
                  <span *ngFor="let i of counter(store.price_rating)">{{currency}}</span> |
                  <span>{{store.delivery_time_max}} {{'label-title.mins' | translate}}</span>
                  <span>{{store.is_store_pay_delivery_fees ? ' | ' + ('label-title.delivery-fee' | translate) : ''
                    }}</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</div>
