import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PAYMENT_GATEWAY } from 'src/app/constants/constants';
import { Card } from 'src/app/models/card.model';
import { PaymentGateway } from 'src/app/models/payment_gatewat.model';
import { WalletHistory } from 'src/app/models/wallet_history.model';
import { AuthService } from 'src/app/services/auth.service';
import { LocationService } from 'src/app/services/location.service';
import { NotifiyService } from 'src/app/services/notifier.service';
import { PaymentService } from 'src/app/services/payment.service';
import { ProfileService } from 'src/app/services/profile.service';
import { Helper } from 'src/app/shared/helper';

declare var stripe:any;
declare var elements: any;
declare var $: any;

export class PaymentGatewayData{
  is_cash_payment_mode:boolean = false;
  wallet:number = 0;
  wallet_currency_code:string = null;
  payment_gateway:Array<PaymentGateway> = [];
}

@Component({
  selector: 'app-wallet-history',
  templateUrl: './wallet-history.component.html',
  styleUrls: ['./wallet-history.component.scss'],
})
export class WalletHistoryComponent implements OnInit,OnDestroy {
  wAmount = false;
  walletHistory:Array<WalletHistory> = [];
  cards:Array<Card> = [];
  paymentGatewayData:PaymentGatewayData= new PaymentGatewayData();
  payment_id;
  card;
  cardHandler = this.onChange.bind(this);
  card_error: string;
  amount_error: string;
  isLoading = false;
  walletFormGroup:FormGroup;
  @ViewChild('cardInfo') cardInfo: ElementRef;


  constructor(public helper:Helper,
    private _profileService:ProfileService,
    private _locationService:LocationService,
    private _notifierService:NotifiyService,
    private cd: ChangeDetectorRef,
    private _authService:AuthService,private _paymentService:PaymentService) { }


  ngOnDestroy(): void {
    this.card.destroy();
  }

  ngOnInit(): void {
    this.walletFormGroup = new FormGroup({
      amount:new FormControl(0,Validators.required)
    });
    this.fetchPaymentGateway();
    this.fetchCardList();
    this.fetchWalletHistory();
  }

  ngAfterViewInit(){
    if(elements._elements.length){
      elements._elements = [];
    }
    this.card = elements.create('card');
  }

  onChange({ error }) {
    if (error) {
      this.card_error = error.message;
    } else {
      this.card_error = null;
    }
    this.cd.detectChanges();
  }

  async fetchWalletHistory(){
    this.walletHistory = await this._profileService.get_wallet_history({user_id:this._authService.user._id,server_token:this._authService.user.server_token})
  }

  async fetchCardList(){
    this.cards = await this._paymentService.get_card_list({user_id:this._authService.user._id,server_token:this._authService.user.server_token});
    if (this.cards.length) {
      const idx = this.cards.findIndex(_c => _c.is_default);
      if (idx === -1) {
        this.selectDefault(this.cards[0]._id);
      }
    }
  }

  async fetchPaymentGateway(){
    this.paymentGatewayData = await this._paymentService.get_payment_gateway(this._locationService.current_location,this._authService.user._id,this._authService.user.server_token,this.helper.cart_unique_token);
    const idx = this.paymentGatewayData.payment_gateway.findIndex(_p=>_p.name.toLowerCase() === PAYMENT_GATEWAY.STRIPE)
    if(idx !== -1){
      this.payment_id = this.paymentGatewayData.payment_gateway[idx]._id;
    }
  }

  removeCard(card_id){
    this._paymentService.delete_card({card_id}).then(is_deleted=>{
      if(is_deleted){
        this.fetchCardList();
      }
    })
  }

  onClickAddCard(){
    this.helper._loader.isLoading = true;
    try{
      if(this.card){
        this.card.clear();
        this.card.removeEventListener('change', this.cardHandler);
      }
    }catch(err){

    }
    setTimeout(() => {
      this.card.mount(this.cardInfo.nativeElement);
      this.card.addEventListener('change', this.cardHandler);
      this.helper._loader.isLoading = false;
    }, 1000);
  }

  async addCard(){
    this.isLoading = true;
    const { token, error } = await stripe.createToken(this.card);
    if (error) {
      alert(error);
    } else {
      var expiry_month = token.card.exp_month;
      var expiry_year = token.card.exp_year;

      if(this.payment_id){
        this._paymentService.get_stripe_add_card_intent({payment_id:this.payment_id}).then(client_secret=>{
          if(client_secret){
                stripe.handleCardSetup(client_secret, this.card, {
                    payment_method_data: {
                      billing_details: {}
                    }
                  }
              ).then((result)=> {
                if (result.error) {
                    this.card.clear();
                    this.card.removeEventListener('change', this.cardHandler);
                    this.card_error = result.error.message;
                    this._notifierService.showNotification('error',this.card_error);
                    this.isLoading = false;
                  } else {
                    this._paymentService.add_card({
                      payment_id:this.payment_id,
                      payment_method:result.setupIntent.payment_method,
                      user_id:this._authService.user._id,
                      server_token:this._authService.user.server_token,
                      expiry_month:expiry_month,
                      expiry_year:expiry_year
                    }).then(is_card_added=>{
                      if(is_card_added){
                        $('.addcardclose').click()
                        this.card.clear();
                        this.card.removeEventListener('change', this.cardHandler);
                        this.isLoading = false;
                        this.fetchCardList()
                      }else{
                        this.isLoading = false;
                      }
                    })
                  }
              });
          }
        })
      }
    }
  }

  selectDefault(card_id){
      this._paymentService.select_card({card_id}).then(is_selected=>{
        if(is_selected){
          this.fetchCardList()
        }
      })
  }

  submit(): void{
    this.amount_error = '';

    if(!this.cards.length){
      this.amount_error = this.helper.trans.instant('validation-title.card-not-found');
      return;
    }

    const amount = Number(this.walletFormGroup.value.amount);
    if(!this.walletFormGroup.valid){
      this.walletFormGroup.markAllAsTouched();
      return;
    }
    if(amount <= 0){
      this.amount_error = this.helper.trans.instant('validation-title.invalid-amount');
      return;
    }
    this.helper._loader.isLoading = true;
    const idx = this.cards.findIndex(_c=>_c.is_default);
    if(idx != -1){
      var card_id = this.cards[idx]._id;
      this._paymentService.get_stripe_payment_intent_wallet(card_id,this._authService.user._id,this._authService.user.server_token,amount,this.payment_id).then((response)=>{
        if(!response.client_secret || !response.payment_method){
          this.amount_error = 'error.'+response.error;
          this.helper._loader.isLoading = false;
          return;
        }
        stripe.confirmCardPayment(response.client_secret, {payment_method: response.payment_method}).then((result)=> {
          if(result.paymentIntent){
            this._paymentService.add_wallet_amount({
              user_id:this._authService.user._id,
              server_token:this._authService.user.server_token,
              card_id,
              last_four:response.last_four,
              payment_id:this.payment_id,
              payment_intent_id:result.paymentIntent.id,
              wallet:amount
            }).then(is_added=>{
              if(is_added){
                this.fetchPaymentGateway();
                this.fetchWalletHistory();
              }
              this.wAmount = false;
              this.helper._loader.isLoading = false;
            })
          } else {
            this.helper._loader.isLoading = false;
            this._notifierService.showNotification('error',result.error.message);
          }
        });
      })
    }

  }

  add(): void{
    this.wAmount = true;
  }
}
