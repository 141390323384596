<div class="header">
    <div class="container">
        <div class="row">
            <div class="header-wrp d-flex align-items-center flex-wrap">
                <div class="col-md-4 col-5">
                    <div class="logo">
                        <a href="javascript:void(0)" routerLink="/">
                            <img src="https://api.deliveryangel.com/web_images/user_logo.png" alt="eDelivery" height="50px" width="50px">
                        </a>
                    </div>
                </div>
                <div class="col-md-8 col-7">
                    <div class="header-right-list d-flex align-items-center justify-content-end">
                        <div class="header-right-block search-location" [hidden]="!_commanService.is_location_show">
                            <form>
                                <div class="input-wrp">
                                    <input type="text" autocomplete="false" [value]="_locationService.current_location.address" class="form-control" style="padding-right: 40px;" id="address" name="address" placeholder="{{'heading-title.placeholder-search-location' | translate}}" #address>
                                    <img (click)="set_current_location()" class="c-pointer" src="../../../assets/images/gps.svg" alt="">
                                </div>
                            </form>
                        </div>

                        <div class="header-right-block cart-section">
                            <div class="cart-btn">
                                <a href="javascript:void(0)" class="">
                                    <img src="../assets/images/shopping-cart.svg" alt="">
                                    <span *ngIf="total_items_in_user_cart" class="cart-count">{{total_items_in_user_cart}}</span>
                                </a>
                            </div>
                            <div class="cart-dropdown common-dropdown small-dropdown">
                                <div class="signin-box dropdown-wrp">
                                    <div class="signin-title without-subtitle">
                                        <h2 class="m-0">{{'heading-title.your-order' | translate}}</h2>
                                    </div>
                                    <div class="pl-4 pr-4 pt-4 d-content" [hidden]="!items_in_user_cart.length">
                                        <div class="previewcart-wrp">
                                            <ul class="previewcart-list">
                                                <ng-container *ngFor="let product of items_in_user_cart;let product_index = index">
                                                <li class="previewcart-item d-flex w-100" *ngFor="let item of product.items;let item_index = index">
                                                    <div class="previewcart-img">
                                                        <img (error)="$event.target.src = DEFAULT_ITEM_IMAGE" [src]="IMAGE_URL + item.image_url" alt="item.item_name">
                                                    </div>
                                                    <div class="previewcart-content">
                                                        <a href="javascript:void(0)" class="previewcart-product-name">{{item.item_name}}</a>
                                                        <!-- <p class="previewcart-product-content">A classic delight with 100% Real mozzarella cheese</p> -->
                                                        <div class="previewcart-product-qty-wrp d-flex align-item-center">
                                                            <div class="previewcart-product-qty product-qty d-flex align-item-center mr-auto">
                                                                <a href="javascript:void(0)" class="qty-btn qty-dec" (click)="decreaseValue(product.product_id,item.item_id)">-</a>
                                                                <input type="text" class="form-control" value="{{item.quantity}}">
                                                                <a href="javascript:void(0)" class="qty-btn qty-inc" (click)="increaseValue(product.product_id,item.item_id)">+</a>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="remove-btn">
                                                      <a (click)="_cartService.remove_from_cart(product.product_id,item.item_id)" href="javascript:void(0)" alt="remove">×</a>
                                                      <span class="previewcart-product-price ">{{user ? user.wallet_currency_code : ''}} {{item.total_item_price | roundpipe:2}}</span>
                                                      <!-- <span class="previewcart-product-price ">{{user ? user.wallet_currency_code : ''}} {{item.item_price + item.total_specification_price}}</span> -->
                                                    </div>
                                                </li>
                                                </ng-container>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="popup-footer">
                                        <div class="popup-footer-wrp">
                                            <div class="add-to-cart-btn">
                                                <button [disabled]="!items_in_user_cart.length" (click)="goToCheckout()" class="primary-btn">{{'button-title.checkout' | translate}}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="header-right-block signin-btn" >
                            <div class="toggle-btn">
                                <a [hidden]="user" href="javascript:void(0);" class="primary-btn custom-popup" data-id="sign-in-popup">{{'button-title.sign-in' | translate}}</a>
                                <div class="dropdown" [hidden]="!user">
                                    <ng-container *ngIf="user">
                                        <a href="javascript:void(0);" class="primary-btn secondary-btn customer-account-name d-flex align-item-center">
                                            <img (error)="$event.target.src = DEFAULT_USER_PROFILE"  [src]="IMAGE_URL + user.image_url" [alt]="user.first_name">
                                            <span>{{user.first_name}}</span>
                                        </a>
                                    </ng-container>
                                    <div class="menu-dropdown-content">
                                        <a routerLink="/account">{{'label-title.account' | translate}}</a>
                                        <a routerLink="/w-history">{{'label-title.wallet-history' | translate}}</a>
                                        <a routerLink="/f-list">{{'label-title.favorite-list' | translate}}</a>
                                        <a routerLink="/order">{{'label-title.orders' | translate}}</a>
                                        <a (click)="signOut()">{{'label-title.log-out' | translate}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="header-mob-section d-flex align-item-center justify-content-center">

                </div>
            </div>
        </div>
    </div>
</div>

<!-- Sign in popup start  -->

<div class="custom-model-main medium-popup fix no-footer" id="sign-in-popup">
    <div class="custom-model-inner">
        <div class="custom-model-wrap">
            <div class="signin-box pop-up-content-wrap">
                <div class="signin-title without-subtitle">
                    <h2 class="m-0">{{'label-title.sign-in' | translate}}</h2>
                    <div class="signinclose close-btn">×</div>
                </div>

                <div class="signin-container">
                  <div class="row social-menu">
                    <div class="col-6 mb-3 fb">
                     <a href="javascript:void(0)" (click)="signInWithFB()" class="social"><img src="../../../../assets/images/fb.png" alt=""></a>
                    </div>
                    <div class="col-6 mb-3 google" >
                      <a href="javascript:void(0)" (click)="signInWithGoogle()" class="social"> <img src="../../../../assets/images/google.png" alt=""></a>
                    </div>
                    <!-- <div class="col-4 mb-3 twitter">
                      <a href="javascript:void(0)" class="social">  <img src="../../../../assets/images/twitter.png" alt=""></a>
                    </div> -->
                  </div>
                  <div class="hr">
                    <div></div>
                    <div>{{'label-title.or' | translate}}</div>
                    <div></div>
                  </div>
                    <form [formGroup]="loginForm" (ngSubmit)="signIn()">
                        <div class="form-field form-field--inputEmail">
                            <label for="">{{'label-title.email-or-phone-number' | translate}}</label>
                            <input type="text" (keypress)="helper.space_validation($event)" formControlName="username" class="form-control" placeholder="{{'label-title.email-or-phone-number' | translate}}">
                            <div class="text-danger" *ngIf="loginForm.get('username').errors && this.loginForm.get('username').touched">
                                <span *ngIf="loginForm.get('username').errors.required">{{'validation-title.this-is-required' | translate}}</span>
                            </div>
                        </div>
                        <div class="form-field form-field--inputPassword mt-4">
                            <label for="">{{'label-title.password' | translate}}</label>
                            <input type="password" formControlName="password" class="form-control" placeholder="{{'label-title.password' | translate}}">
                            <div class="text-danger" *ngIf="loginForm.get('password').errors && this.loginForm.get('password').touched">
                                <span *ngIf="loginForm.get('password').errors.required">{{'validation-title.password-is-required' | translate}}</span>
                            </div>
                        </div>
                        <div class="form-field d-flex align-item-center mt-4">
                            <button type="submit" class="primary-btn submit-btn mr-auto">{{'button-title.submit' | translate}}</button>
                            <button type="submit" class="link forgot-pass-link custom-popup" data-id="forgot-password-popup">{{'button-title.forgot-password' | translate}}</button>
                        </div>
                        <div class="form-field text-center signup-btn mt-4">
                            <span>{{'label-title.do-not-have-an-account' | translate}} <button type="submit" class="link custom-popup" data-id="sign-up-popup">{{'button-title.sign-up-now' | translate}}</button></span>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
    <div class="bg-overlay"></div>
</div>

<!-- Sign in popup end  -->

<!-- Sign up popup start  -->

<div class="custom-model-main medium-popup fix no-footer" id="sign-up-popup">
    <div class="custom-model-inner">
        <div class="custom-model-wrap">
            <div class="signin-box pop-up-content-wrap">
                <div class="signin-title without-subtitle">
                    <h2 class="m-0">{{'label-title.sign-up' | translate}}</h2>
                    <div class="close-btn" id="signup-close">×</div>
                </div>
                <div class="signin-container">
                    <div class="row social-menu">
                      <div class="col-6 mb-3 fb">
                       <a href="javascript:void(0)" (click)="registerWithFB()" class="social"><img src="../../../../assets/images/fb.png" alt=""></a>
                      </div>
                      <div class="col-6 mb-3 google" >
                        <a href="javascript:void(0)" (click)="registerWithGoogle()    " class="social"> <img src="../../../../assets/images/google.png" alt=""></a>
                      </div>
                      <!-- <div class="col-4 mb-3 twitter">
                        <a href="javascript:void(0)" class="social">  <img src="../../../../assets/images/twitter.png" alt=""></a>
                      </div> -->
                    </div>
                  <div class="hr">
                    <div></div>
                    <div>{{'label-title.or' | translate}}</div>
                    <div></div>
                  </div>
                    <form [formGroup]="signupForm" (ngSubmit)="signUp()">
                        <div class="form-field form-field--inputFirstName">
                            <label for="">{{'label-title.first-name' | translate}}</label>
                            <input (keypress)="helper.space_validation($event)" type="text" formControlName="first_name" class="form-control" placeholder="{{'label-title.first-name' | translate}}" required>
                            <div class="text-danger" *ngIf="signupForm.get('first_name').errors && this.signupForm.get('first_name').touched">
                                <span *ngIf="signupForm.get('first_name').errors.required">{{'validation-title.first-name-is-required' | translate}}</span>
                            </div>
                        </div>
                        <div class="form-field form-field--inputLastName mt-2">
                            <label for="">{{'label-title.last-name' | translate}}</label>
                            <input (keypress)="helper.space_validation($event)" type="text" formControlName="last_name" class="form-control" placeholder="{{'label-title.last-name' | translate}}" required>
                            <div class="text-danger" *ngIf="signupForm.get('last_name').errors && this.signupForm.get('last_name').touched">
                                <span *ngIf="signupForm.get('last_name').errors.required">{{'validation-title.last-name-is-required' | translate}}</span>
                            </div>
                        </div>
                        <div class="form-field form-field--inputLastName mt-2">
                          <label for="">{{'label-title.email' | translate}}</label>
                          <input type="email" formControlName="email" autocomplete="off" class="form-control" placeholder="{{'label-title.email' | translate}}" email>
                          <div class="text-danger" *ngIf="signupForm.get('email').errors && this.signupForm.get('email').touched">
                            <span *ngIf="signupForm.get('email').errors.required">{{'validation-title.email-is-required' | translate}}</span>
                            <span *ngIf="signupForm.get('email').errors.email">{{'validation-title.email-is-invalid' | translate}}</span>
                        </div>
                        </div>
                        <div class="form-field form-field--country mt-2">
                            <label for="">{{'label-title.country' | translate}}</label>
                            <ng-select (change)="changeCountry($event)" placeholder="{{'label-title.country' | translate}}" [clearable]="false" autocomplete="off" formControlName="country_code">
                                <ng-option *ngFor="let item of countries" [value]="item.country_code">{{item.country_name}}</ng-option>
                            </ng-select>
                            <div class="text-danger" *ngIf="signupForm.get('country_code').errors && this.signupForm.get('country_code').touched">
                                <span *ngIf="signupForm.get('country_code').errors.required">{{'validation-title.country-is-required' | translate}}</span>
                            </div>
                        </div>
                        <div class="form-field form-field--inputPhone mt-2">
                            <div class="row">
                                <div class="col-sm-5">
                                    <label for="">{{'label-title.country-code' | translate}}</label>
                                    <input type="text" formControlName="country_phone_code" class="form-control" readonly>
                                </div>
                                <div class="col-sm-7">
                                    <label for="">{{'label-title.phone-number' | translate}}</label>
                                    <input type="text" (keypress)="helper.number_validation($event)" minlength="8" maxlength="12" formControlName="phone" autocomplete="off" class="form-control" placeholder="{{'label-title.phone-number' | translate}}">
                                    <div class="text-danger" *ngIf="signupForm.get('phone').errors && this.signupForm.get('phone').touched">
                                        <span *ngIf="signupForm.get('phone').errors.required">{{'validation-title.phone-is-required' | translate}}</span>
                                        <span *ngIf="signupForm.get('phone').errors.minlength">{{'validation-title.phone-is-too-short' | translate}}</span>
                                        <span *ngIf="signupForm.get('phone').errors.maxlength">{{'validation-title.phone-is-too-long' | translate}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-field form-field--inputAddress mt-2">
                            <label for="">{{'label-title.password' | translate}}</label>
                            <input type="password" formControlName="password" class="form-control" placeholder="{{'label-title.password' | translate}}" required>
                            <div class="text-danger" *ngIf="signupForm.get('password').errors && this.signupForm.get('password').touched">
                                <span *ngIf="signupForm.get('password').errors.required">{{'validation-title.password-is-required' | translate}}</span>
                            </div>
                        </div>
                        <div class="form-field form-field--inputPhone mt-2">
                          <div class="offer-label-wrp d-flex align-item-center">
                            <label for="referral_code" class="mr-auto">{{'label-title.referral-code' | translate}}</label>
                          </div>
                          <div class="form-prefixPostfix d-flex align-item-center justify-content-space-between flex-wrap" *ngIf="!is_referral_applied">
                            <input type="text" formControlName="referral_code" class="form-control" placeholder="{{'label-title.referral-code' | translate}}" >
                            <button type="button" (click)="checkReferral()" [disabled]="!signupForm.get('referral_code').value" class="primary-btn">{{'button-title.apply' | translate}}</button>
                          </div>
                          <div [hidden]="!is_referral_applied">
                            <span>{{signupForm.get('referral_code').value}}</span>
                          </div>
                      </div>
                        <div class="form-field form-field--inputCheckbox d-flex align-item-center mt-2 mb-2">
                            <label class="d-flex align-item-center">
								<input type="checkbox" formControlName="is_term_checked" class="option-input"><span>{{'label-title.agree-text' | translate}} <a href="https://api.deliveryangel.com/agreements/Delivery_Angel_TC_s.html" target="_blank">{{'button-title.terms-conditions' | translate}}</a> {{'label-title.and' | translate}} <a href="https://api.deliveryangel.com/agreements/Delivery_Angel_Privacy_Policy.html" target="_blank">{{'button-title.privacy-policy' | translate}}</a></span>
							</label>
                        </div>
                        <div class="text-danger" *ngIf="!signupForm.get('is_term_checked').value">
                            <span>{{'validation-title.privacy-policy-is-required' | translate}}</span>
                        </div>
                        <div class="form-field form-field--inputCheckbox d-flex align-item-center mt-2 mb-2">
                            <label class="d-flex align-item-center">
                                <input type="checkbox" style="width: 3.8rem;" formControlName="is_collection_checked" class="option-input">
                                <span>I have read and understood the 
                                    <a href="https://api.deliveryangel.com/agreements/CustomerCollectionNotice.html" target="_blank">Collection Notice</a> 
                                    and understand my personal information will be handled in accordance with this Collection Notice and the 
                                    <a href="https://api.deliveryangel.com/agreements/Delivery_Angel_Privacy_Policy.html" target="_blank">Privacy Policy</a>.
                                </span>
                            </label>
                        </div>
                        <div class="text-danger" *ngIf="!signupForm.get('is_collection_checked').value">
                            <span>Collection Notice is required</span>
                        </div>
                        <div class="form-field form-field--inputSignup text-center mb-2">
                            <button type="submit" class="primary-btn mr-auto">{{'button-title.sign-up' | translate}}</button>
                            <!-- submit-btn -->
                        </div>
                        <div class="form-field text-center signup-btn">
                            <span>{{'label-title.have-an-account' | translate}} <button type="submit" class="link custom-popup" data-id="sign-in-popup">{{'button-title.sign-in-now' | translate}}</button></span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-overlay"></div>
</div>

<!-- Sign up popup end  -->

<!-- Forgot Password start  -->

<div class="custom-model-main medium-popup fix no-footer" id="forgot-password-popup">
    <div class="custom-model-inner">
        <div class="forgotclose close-btn">×</div>
        <div class="custom-model-wrap">
            <div class="signin-box pop-up-content-wrap">
                <div class="signin-title without-subtitle">
                    <h2>{{'heading-title.forgot-password' | translate}}</h2>
                </div>
                <div class="signin-container">
                    <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()">
                        <div class="form-field form-field--inputEmail">
                            <label for="">{{'label-title.phone' | translate}}</label>
                            <input formControlName="phone" type="text" min="0" minlength="8" maxlength="12" class="form-control" (keypress)="helper.number_validation($event)" placeholder="{{'label-title.phone' | translate}}" >
                            <div class="text-danger" *ngIf="forgotPasswordForm.get('phone').errors && this.forgotPasswordForm.get('phone').touched">
                                <span *ngIf="forgotPasswordForm.get('phone').errors.required">{{'validation-title.phone-is-required' | translate}}</span>
                                <span *ngIf="forgotPasswordForm.get('phone').errors.minlength">{{'validation-title.phone-minlength-is-required' | translate}}</span>
                                <span *ngIf="forgotPasswordForm.get('phone').errors.maxlength">{{'validation-title.phone-maxlength-is-required' | translate}}</span>
                            </div>
                        </div>
                        <div class="form-field text-center mt-4">
                            <button type="submit" class="primary-btn submit-btn mr-auto">{{'button-title.submit' | translate}}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-overlay"></div>
</div>

<!-- Forgot Password end  -->

<!-- OTP Verification popup start  -->

<div class="custom-model-main medium-popup" id="otp-verification-popup">
    <div class="custom-model-inner">
        <div id="otpclose" class="close-btn">×</div>
        <div class="custom-model-wrap">
            <div class="pop-up-content-wrap">
                <div class="dropdown-title without-subtitle">
                    <h2>{{'heading-title.otp-verification' | translate}}</h2>
                </div>
                <div class="dropdown-content otp-verification">
                    <form class="login-form" [formGroup]="otpForm" (ngSubmit)="forgotPasswordVerify()">
                        <div class="form-field form-field--inputEmail">
                            <label for="">{{'label-title.enter-otp' | translate}}</label>
                            <input formControlName="otp" type="text" min="0" minlength="6" maxlength="6" class="form-control" (keypress)="helper.number_validation($event)">
                            <div class="text-danger" *ngIf="otpForm.get('otp').errors && this.otpForm.get('otp').touched">
                                <span *ngIf="otpForm.get('otp').errors.required">{{'validation-title.otp-is-required' | translate}}</span>
                                <span *ngIf="otpForm.get('otp').errors.minlength">{{'validation-title.otp-is-invalid' | translate}}</span>
                                <span *ngIf="otpForm.get('otp').errors.maxlength">{{'validation-title.otp-is-invalid' | translate}}</span>
                            </div>
                        </div>
                        <div class="form-field">
                            <button type="button" (click)="resendPassword()" class="link">{{'button-title.resend-code' | translate}}</button>
                        </div>
                        <div class="form-field form-field--inputSignup text-center">
                            <button type="submit" class="primary-btn submit-btn mr-auto">{{'button-title.verify' | translate}}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-overlay"></div>
</div>

<!-- OTP Verification popup end  -->

<!-- Reset Password end  -->

<div class="custom-model-main medium-popup fix no-footer" id="set-password-popup">
  <div class="custom-model-inner">
  <div class="close-btn" id="set-password-popup-close">×</div>
      <div class="custom-model-wrap">
          <div class="pop-up-content-wrap">
              <div class="dropdown-title without-subtitle">
                  <h2>{{'heading-title.new-password' | translate}}</h2>
              </div>
              <div class="dropdown-content">
        <form class="login-form" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
            <div class="form-field form-field--inputEmail">
                <label for="">{{'label-title.new-password' | translate}}</label>
                <input formControlName="password" type="text" minlength="6" class="form-control" required>
                <div class="text-danger" *ngIf="resetPasswordForm.get('password').errors && this.resetPasswordForm.get('password').touched">
                    <span *ngIf="resetPasswordForm.get('password').errors.required">{{'validation-title.password-is-required' | translate}}</span>
                    <span *ngIf="resetPasswordForm.get('password').errors.minlength">{{'validation-title.password-is-invalid' | translate}}</span>
                </div>
            </div>
            <div class="form-field form-field--inputEmail">
                <label for="">{{'label-title.confirm-password' | translate}}</label>
                <input formControlName="confirm_password" type="text" minlength="6" class="form-control" required>
                <div class="text-danger" *ngIf="resetPasswordForm.get('confirm_password').errors && this.resetPasswordForm.get('confirm_password').touched">
                    <span *ngIf="resetPasswordForm.get('confirm_password').errors.required">{{'validation-title.password-is-required' | translate}}</span>
                    <span *ngIf="resetPasswordForm.get('confirm_password').errors.minlength">{{'validation-title.password-is-invalid' | translate}}</span>
                </div>
            </div>
            <span *ngIf="password_error != ''" class="text-danger">{{password_error}}</span>
          <div class="form-field form-field--inputSignup text-center">
            <button type="submit" class="primary-btn submit-btn mr-auto">{{'button-title.submit' | translate}}</button>
          </div>
        </form>
      </div>
          </div>
      </div>
  </div>
  <div class="bg-overlay"></div>
</div>

<!-- Reset Password Popup end  -->


<!-- Verification Popup Start -->

<div class="custom-model-main medium-popup" id="verification-popup">
    <div class="custom-model-inner">
        <!-- <div id="verificationclose" [hidden]="true" class="close-btn">×</div> -->
        <div class="custom-model-wrap">
            <div class="pop-up-content-wrap">
                <div class="dropdown-title without-subtitle">
                    <h2>{{'heading-title.otp-verification' | translate}}</h2>
                </div>
                <div class="dropdown-content otp-verification">
                    <form class="login-form" [formGroup]="verificationForm" (ngSubmit)="verifyUser()">
                        <div class="form-field form-field--inputEmail" [hidden]="!setting_data.is_user_sms_verification || (_authService.user && _authService.user.is_phone_number_verified)">
                            <label for="">{{'label-title.enter-sms-otp' | translate}}</label>
                            <input formControlName="sms_otp" type="text" min="0" minlength="6" maxlength="6" class="form-control" (keypress)="helper.number_validation($event)">
                            <div class="text-danger" *ngIf="verificationForm.get('sms_otp').errors && this.verificationForm.get('sms_otp').touched">
                                <span *ngIf="verificationForm.get('sms_otp').errors.required">{{'validation-title.otp-is-required' | translate}}</span>
                                <span *ngIf="verificationForm.get('sms_otp').errors.minlength">{{'validation-title.otp-is-invalid' | translate}}</span>
                                <span *ngIf="verificationForm.get('sms_otp').errors.maxlength">{{'validation-title.otp-is-invalid' | translate}}</span>
                            </div>
                        </div>
                        <div class="form-field form-field--inputEmail" [hidden]="!setting_data.is_user_mail_verification || (_authService.user && _authService.user.is_email_verified)">
                            <label for="">{{'label-title.enter-email-otp' | translate}}</label>
                            <input formControlName="email_otp" type="text" min="0" minlength="6" maxlength="6" class="form-control" (keypress)="helper.number_validation($event)" >
                            <div class="text-danger" *ngIf="verificationForm.get('email_otp').errors && this.verificationForm.get('email_otp').touched">
                                <span *ngIf="verificationForm.get('email_otp').errors.required">{{'validation-title.otp-is-required' | translate}}</span>
                                <span *ngIf="verificationForm.get('email_otp').errors.minlength">{{'validation-title.otp-is-invalid' | translate}}</span>
                                <span *ngIf="verificationForm.get('email_otp').errors.maxlength">{{'validation-title.otp-is-invalid' | translate}}</span>
                            </div>
                        </div>
                        <span class="text-danger">{{verification_error}}</span>
                        <div class="form-field">
                            <button type="button" (click)="resendOtp()" class="link">{{'button-title.resend-code' | translate}}</button>
                        </div>
                        <div class="form-field form-field--inputSignup text-center">
                            <button type="submit" class="primary-btn submit-btn mr-auto">{{'button-title.verify' | translate}}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-overlay"></div>
</div>

<!-- Verification Popup Start -->
