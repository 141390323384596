<div class="checkout-page page-content">
  <div class="container">
    <div class="section-title page-title">
        <h2>{{'heading-title.checkout' | translate}} </h2>
    </div>
    <div class="your-order-section mb-30">
      <div class="row">
        <div class="col-xl-4 col-sm-6 checkout-col1">
          <div class="order-title d-flex align-item-center">
            <h3>{{'heading-title.your-order' | translate}}</h3> <span>({{total_items_in_user_cart}} {{'label-title.item' | translate}})</span>
          </div>
          <ng-container *ngFor="let product of items_in_user_cart">
            <div class="order-list mb-4" *ngFor="let item of product.items">
              <div class="order-list-block">
                <div class="order-product-content delivery-address-section">
                  <div class="order-product-title">
                    <a href="javascript:void(0)">{{item.item_name}}</a>
                  </div>
                  <div class="order-product-details">
                    <!-- <p>1 kg</p> -->
                    <p></p>
                  </div>
                  <div class="order-product-price d-flex align-item-center">
                    <div class="price mr-auto">{{wallet_currency_code}} {{item.total_item_price | roundpipe:2}}</div>
                    <div class="order-product-qty product-qty d-flex align-item-center">
                      <a href="javascript:void(0)" (click)="decreaseValue(product.product_id,item.item_id)" class="qty-btn qty-dec">-</a>
                      <input type="text" class="form-control" value="{{item.quantity}}">
                      <a href="javascript:void(0)" (click)="increaseValue(product.product_id,item.item_id)" class="qty-btn qty-inc">+</a>
                    </div>
                  </div>
                </div>
                <div class="remove-btn"><a href="javascript:void(0)" (click)="_cartService.remove_from_cart(product.product_id,item.item_id)" alt="remove">×</a></div>
              </div>
            </div>
          </ng-container>
           <div class="order-list mb-4" >
            <div class="order-list-block">
              <div class="order-product-content delivery-address-section">
                {{'label-title.isCutlery' | translate}}
                <input type="checkbox" [(ngModel)]="isCutlery" class="option-input" style="float:right;vertical-align: middle;">
              </div>
            </div>
           </div>
        </div>
        <div class="col-xl-4 col-sm-6 checkout-col2">
          <div class="order-title d-flex align-item-center">
            <h3>{{'heading-title.delivery-address' | translate}}</h3>
          </div>
          <div class="delivery-details mb-30">
            <div class="delivery-address-section">
              <div class="form-checklist-item ">
                <div class="form-field  rtl-asap d-inline-block">
                  <label for="">{{'label-title.delivery-asap' | translate}} </label> <label class="asap">|</label>
                </div>
                <div class="header-right-block toggle-btn schedule d-inline-block ml-1">
                  <form class="form">
                      <div class="switch-field">
                          <input type="radio" id="asap" class="no-border" name="switch-one" (click)="set_schedule(false)" value="ASAP" [checked]="!_cartService.user_cart.is_schedule_order" value="ASAP"/>
                          <label for="asap"> {{'label-title.asap' | translate}}</label>
                          <!-- <div class="h-schedule">
                            <input type="radio" disabled id="schedule" class="no-border" name="switch-one" disabled="" value="Schedule" [checked]="_cartService.user_cart.is_schedule_order" />
                              <label for="schedule">{{'label-title.schedule' | translate}} </label>
                              <div class="schedule-form common-dropdown small-dropdown" *ngIf="!is_taking_schedule_order">
                                <div class="dropdown-wrp">
                                  <div class="dropdown-title without-subtitle">
                                    <h2>{{'label-title.schedule' | translate}}</h2>
                                  </div>
                                  <div class="dropdown-content">
                                    <p>{{'label-title.we-are-not-providing-schedule-order' | translate}}</p>
                                  </div>
                                </div>
                              </div>
                              <div class="schedule-form common-dropdown">
                                <div class="dropdown-wrp">
                                  <div class="dropdown-title">
                                    <h2>{{'label-title.schedule-for-later' | translate}}</h2>
                                    <p>{{'label-title.select-delivery-slot' | translate}}</p>
                                  </div>
                                  <div class="dropdown-content">
                                    <div class="date-slider">

                                      <div class='date-slider-wrp'>
                                        <div class=''>
                                          <ngb-carousel [interval]="false" (slide)="onSlide($event)">

                                            <ng-template ngbSlide *ngFor="let date of date_array; let i = index;" [id]="'slideId_' + i">
                                              <div class="date-list">
                                                <span>{{date.date_format}}</span>
                                              </div>
                                              <div class="slots-list">
                                                <ng-container *ngIf="!slot1 && !slot2 && !slot3 && !slot4">{{'label-title.no-slots-available' | translate}}</ng-container>
                                                <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" *ngIf="slot1">
                                                  <ngb-panel title="{{'label-title.night-time-slot' | translate}}">
                                                  <ng-template ngbPanelContent>
                                                    <ul class="d-flex align-item-center flex-wrap">
                                                      <ng-container *ngFor="let time of time_array;">
                                                        <li *ngIf="time.type == 1"><a href="javascript:void(0)" (click)="set_time(time)" [ngStyle]="{'background-color': time == selected_time ? '#0eb6cc' : '','color': time == selected_time ? '#fff' : '' }" class="primary-btn secondary-btn">{{time.time}} </a></li>
                                                      </ng-container>
                                                    </ul>
                                                  </ng-template>
                                                  </ngb-panel>
                                                </ngb-accordion>
                                                <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" *ngIf="slot2">
                                                  <ngb-panel title="{{'label-title.morning-time-slot' | translate}}">
                                                  <ng-template ngbPanelContent>
                                                    <ul class="d-flex align-item-center flex-wrap">
                                                      <ng-container *ngFor="let time of time_array;">
                                                        <li *ngIf="time.type == 2"><a href="javascript:void(0)" (click)="set_time(time)" [ngStyle]="{'background-color': time == selected_time ? '#0eb6cc' : '','color': time == selected_time ? '#fff' : '' }" class="primary-btn secondary-btn">{{time.time}} </a></li>
                                                      </ng-container>
                                                    </ul>
                                                  </ng-template>
                                                  </ngb-panel>
                                                </ngb-accordion>
                                                <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" *ngIf="slot3">
                                                  <ngb-panel title="{{'label-title.afternoon-time-slot' | translate}}">
                                                  <ng-template ngbPanelContent>
                                                    <ul class="d-flex align-item-center flex-wrap">
                                                      <ng-container *ngFor="let time of time_array;">
                                                        <li *ngIf="time.type == 3"><a href="javascript:void(0)" (click)="set_time(time)" [ngStyle]="{'background-color': time == selected_time ? '#0eb6cc' : '','color': time == selected_time ? '#fff' : '' }" class="primary-btn secondary-btn">{{time.time}} </a></li>
                                                      </ng-container>
                                                    </ul>
                                                  </ng-template>
                                                  </ngb-panel>
                                                </ngb-accordion>
                                                <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" *ngIf="slot4">
                                                  <ngb-panel title="{{'label-title.evening-time-slot' | translate}}">
                                                  <ng-template ngbPanelContent>
                                                    <ul class="d-flex align-item-center flex-wrap">
                                                      <ng-container *ngFor="let time of time_array;">
                                                        <li *ngIf="time.type == 4"><a href="javascript:void(0)" (click)="set_time(time)" [ngStyle]="{'background-color': time == selected_time ? '#0eb6cc' : '','color': time == selected_time ? '#fff' : '' }" class="primary-btn secondary-btn">{{time.time}} </a></li>
                                                      </ng-container>
                                                    </ul>
                                                  </ng-template>
                                                  </ngb-panel>
                                                </ngb-accordion>
                                              </div>
                                            </ng-template>
                                          </ngb-carousel>

                                        </div>

                                      </div>

                                    </div>
                                  </div>
                                  <div class="popup-footer">
                                    <div class="popup-footer-wrp d-flex align-item-center">
                                      <div class="add-to-cart-btn">
                                        <a href="javascript:void(0)" class="primary-btn" (click)="set_schedule(true)">{{'button-title.schedule-delivery' | translate}}</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                          </div> -->
                          <div class="h-schedule">
                            <input type="radio" disabled id="schedule" class="no-border" name="switch-one" disabled="" value="Schedule" [checked]="_cartService.user_cart.is_schedule_order" />
                            <label for="schedule">{{'label-title.schedule' | translate}} </label>
                            <div class="schedule-form common-dropdown">
                              <div class="dropdown-wrp">
                                <div class="dropdown-title">
                                  <h2>{{'label-title.schedule-for-later' | translate}}</h2>
                                  <p>{{'label-title.select-delivery-slot' | translate}}</p>
                                </div>
                                <div class="dropdown-content">
                                  <div class="date-slider">

                                    <div class='date-slider-wrp'>
                                      <div class=''>
                                        <ngb-carousel [interval]="false" (slide)="onSlide($event)">
                                          <ng-template ngbSlide *ngFor="let date of date_array; let i = index;" [id]="'slideId_' + i">
                                            <div class="date-list">
                                              <span>{{date.date_format}}</span>
                                            </div>
                                            <div class="slots-list">
                                              <ng-container *ngIf="!slot1 && !slot2 && !slot3 && !slot4">{{'label-title.no-slots-available' | translate}}</ng-container>
                                              <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" *ngIf="slot1">
                                                <ngb-panel title="{{'label-title.night-time-slot' | translate}}">
                                                <ng-template ngbPanelContent>
                                                  <ul class="d-flex align-item-center flex-wrap">
                                                    <ng-container *ngFor="let time of time_array;">
                                                      <li *ngIf="time.type == 1"><a href="javascript:void(0)" (click)="set_time(time)" [ngStyle]="{'background-color': time == selected_time ? '#0eb6cc' : '','color': time == selected_time ? '#fff' : '' }" class="primary-btn secondary-btn">{{time.time}} </a></li>
                                                    </ng-container>
                                                  </ul>
                                                </ng-template>
                                                </ngb-panel>
                                              </ngb-accordion>
                                              <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" *ngIf="slot2">
                                                <ngb-panel title="{{'label-title.morning-time-slot' | translate}}">
                                                <ng-template ngbPanelContent>
                                                  <ul class="d-flex align-item-center flex-wrap">
                                                    <ng-container *ngFor="let time of time_array;">
                                                      <li *ngIf="time.type == 2"><a href="javascript:void(0)" (click)="set_time(time)" [ngStyle]="{'background-color': time == selected_time ? '#0eb6cc' : '','color': time == selected_time ? '#fff' : '' }" class="primary-btn secondary-btn">{{time.time}} </a></li>
                                                    </ng-container>
                                                  </ul>
                                                </ng-template>
                                                </ngb-panel>
                                              </ngb-accordion>
                                              <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" *ngIf="slot3">
                                                <ngb-panel title="{{'label-title.afternoon-time-slot' | translate}}">
                                                <ng-template ngbPanelContent>
                                                  <ul class="d-flex align-item-center flex-wrap">
                                                    <ng-container *ngFor="let time of time_array;">
                                                      <li *ngIf="time.type == 3"><a href="javascript:void(0)" (click)="set_time(time)" [ngStyle]="{'background-color': time == selected_time ? '#0eb6cc' : '','color': time == selected_time ? '#fff' : '' }" class="primary-btn secondary-btn">{{time.time}} </a></li>
                                                    </ng-container>
                                                  </ul>
                                                </ng-template>
                                                </ngb-panel>
                                              </ngb-accordion>
                                              <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" *ngIf="slot4">
                                                <ngb-panel title="{{'label-title.evening-time-slot' | translate}}">
                                                <ng-template ngbPanelContent>
                                                  <ul class="d-flex align-item-center flex-wrap">
                                                    <ng-container *ngFor="let time of time_array;">
                                                      <li *ngIf="time.type == 4"><a href="javascript:void(0)" (click)="set_time(time)" [ngStyle]="{'background-color': time == selected_time ? '#0eb6cc' : '','color': time == selected_time ? '#fff' : '' }" class="primary-btn secondary-btn">{{time.time}} </a></li>
                                                    </ng-container>
                                                  </ul>
                                                </ng-template>
                                                </ngb-panel>
                                              </ngb-accordion>
                                            </div>
                                          </ng-template>
                                        </ngb-carousel>

                                      </div>

                                    </div>

                                  </div>
                                </div>
                                <div class="popup-footer">
                                  <div class="popup-footer-wrp d-flex align-item-center">
                                    <div class="add-to-cart-btn">
                                      <a href="javascript:void(0)" class="primary-btn" (click)="set_schedule(true)">{{'button-title.schedule-delivery' | translate}}</a>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                        </div>
                      </div>
                  </form>

                </div>
                <div class="header-right-block toggle-btn delivery d-inline-block switch-field">
                    <div class="h-takeway">
                        <input (change)="tooglePickupOrders()" [value]="_cartService.user_cart.is_user_pick_up_order" [(ngModel)]="_cartService.user_cart.is_user_pick_up_order" type="checkbox" id="takeway" class="no-border" name="switch-one" value="Takeaway" />
                        <label for="takeway">{{'heading-title.takeaway' | translate}}</label>
                    </div>
                </div>
              </div>


              <div class="d-flex align-item-center justify-content-center mb-3">

              </div>
              <div class="add-details">
                <div class="add-content">
                  <address>
                    {{_cartService.user_cart.cart_data.destination_addresses[0].address}}
                  </address>
                  <a href="javascript:void(0)" class="change-pin custom-popup"
                    data-id="delivery-add-update">{{'button-title.change-pin' | translate}}</a>
                </div>
                <div class="customer-details d-flex align-item-center mt-30">
                  <div class="customer-name mr-auto">
                    <span style="font-size: 12px">{{'label-title.apartment' | translate}} : {{userDetailForm.value.apartment}}</span>
                    <span style="font-size: 12px">{{'label-title.building_name' | translate}} : {{userDetailForm.value.building_name}}</span>
                    <span style="font-size: 12px">{{'label-title.business_name' | translate}} : {{userDetailForm.value.business_name}}</span>
                    <span style="font-size: 12px">{{'label-title.option_for_delivery' | translate}} : 
                      <ng-container *ngIf="userDetailForm.value.option_for_delivery == 0">{{'label-title.leave-at-door-ring' | translate}}</ng-container>
                      <ng-container *ngIf="userDetailForm.value.option_for_delivery == 1">{{'label-title.knock-on-door' | translate}}</ng-container>
                      <ng-container *ngIf="userDetailForm.value.option_for_delivery == 2">{{'label-title.leave-at-door' | translate}}</ng-container>
                      <ng-container *ngIf="userDetailForm.value.option_for_delivery == 3">{{'label-title.meet-outside' | translate}}</ng-container>
                    </span>
                  </div>
                </div>
                <div class="customer-details d-flex align-item-center mt-30">
                  <div class="customer-name mr-auto">
                    <span>{{_cartService.user_cart.cart_data.destination_addresses[0].user_details.name}}</span>
                    <a href="tel:{{_cartService.user_cart.cart_data.destination_addresses[0].user_details.phone}}">{{_cartService.user_cart.cart_data.destination_addresses[0].user_details.phone}}</a>
                  </div>
                </div>
                <div class="customer-details d-flex align-item-center mt-30">
                  <div class="change-link">
                    <a href="javascript:void(0);" data-id="user-details" class="custom-popup" (click)="openUserChange()">{{'button-title.change' | translate}}</a>
                  </div>
                </div>
              </div>
              <div class="apartment-no mt-30" *ngIf="_commonService.settings.is_allow_contactless_delivery">
                <div class="form-field">
                  <label for="contactless_delivery" class="checkbox mr-auto">
                    <input type="checkbox" id="contactless_delivery" [(ngModel)]="is_allow_contactless_delivery" (change)="onIsContactless($event)" class="option-input" style="vertical-align: middle;">
                    <span class="label"></span><span>{{'label-title.is-allow-contact-less-delivery' | translate}}</span>
                  </label>
                </div>
              </div>
              <div class="apartment-no mt-30">
                  <label for="">{{'heading-title.delivery-note' | translate}}</label>
                  <textarea [(ngModel)]="delivery_note" cols="30" rows="3" class="form-control"></textarea>
              </div>

            </div>
          </div>
          <div class="order-offer-section mt-30" *ngIf="!promo_applied">
            <div class="order-title d-flex align-item-center">
              <h3>{{'heading-title.offers' | translate}}</h3>
            </div>
            <div class="order-offer-wrp">
                <div class="offer-label-wrp d-flex align-item-center">
                  <label for="" class="mr-auto">{{'label-title.coupon-code' | translate}}</label>
                  <a href="javascript:void(0)" (click)="openModel('view-offers')">{{'label-title.view-offers' | translate}}</a>
                </div>
                <div class="form-prefixPostfix d-flex align-item-center flex-wrap">
                  <input type="text" [(ngModel)]="promocode" class="form-control">
                  <button (click)="apply_promo_code()" class="primary-btn" style="text-align: right;">{{'button-title.apply' | translate}}</button>
                </div>
            </div>
          </div>
          <div class="order-offer-section mt-30">
            <div class="order-title d-flex align-item-center">
              <h3>{{'heading-title.tip' | translate}}</h3>
            </div>
            <div class="order-offer-wrp">
              <form action="">
                <div class="offer-label-wrp d-flex align-item-center">
                  <label for="" class="mr-auto">{{'label-title.tip-code' | translate}}</label>
                </div>
                <div class="inline-list w-100">
                  <ul class="d-flex align-item-center">
                    <li><a class="no_tip" (click)="add_tip(0)" href="javascript:void(0)" [class.active]="tip_amount === 0">{{'button-title.no-tip' | translate}}</a></li>
                    <li><a (click)="add_tip(5)" href="javascript:void(0)" [class.active]="tip_amount === 5">5 {{tip_type === 0 ? wallet_currency_code : '%' }}</a></li>
                    <li><a (click)="add_tip(10)" href="javascript:void(0)"[class.active]="tip_amount === 10">10 {{tip_type === 0 ? wallet_currency_code : '%' }}</a></li>
                    <li><a (click)="add_tip(15)" href="javascript:void(0)"[class.active]="tip_amount === 15">15 {{tip_type === 0 ? wallet_currency_code : '%' }}</a></li>
                    <li>
                      <input class="tip_button" (keypress)="helper.number_validation($event)" type="text" maxlength="7" (change)="add_tip1(tip)" style="width:60px" [(ngModel)]="tip" min="0" name="tip" placeholder="{{tip_type === 0 ? wallet_currency_code : '%' }}" />
                    </li>
                  </ul>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="col-xl-4 col-sm-6 checkout-col3">
          <div class="price-details-section mb-30" [hidden]="!is_login">
            <div class="order-title d-flex align-item-center">
              <h3>{{'heading-title.payment' | translate}}</h3>
            </div>
            <div class="cart-total-wrp payment-wrp">
              <ul>
                <li>
                  <div class="cart-total-list-wrp d-flex align-item-center mb-0">
                    <div class="cart-total-label form-checklist-item mr-auto">
                      <div class="form-field">
                        <label for="case" class="checkbox mr-auto">
                          <input type="checkbox" [(ngModel)]="is_use_wallet" (change)="change_user_wallet_status($event)" class="option-input" style="vertical-align: middle;">
                          <span class="label"></span><span>{{'label-title.wallet' | translate}}</span>
                          <p class="mb-0 ">{{'label-title.current-balance' | translate}}</p>
                          <p class="mb-0 ">{{wallet_currency_code}} {{wallet | roundpipe:2}}</p>
                        </label>
                      </div>
                    </div>
                    <div class="cart-total-value">
                      <div class="wallet box">
                        <i class="fas fa-check"></i>
                      </div>
                    </div>
                  </div>
                </li>
                <li *ngIf="is_card_payment_mode">
                  <div class="cart-total-list-wrp d-flex mb-0">
                    <div class="cart-total-label form-checklist-item mr-auto">
                      <div class="form-field">
                        <label for="case" class="radio mr-auto">
                          <input type="radio" (change)="selected_payment_gateway = 'card'" [checked]="selected_payment_gateway === 'card'" name="colorRadio" id="case" class="hidden" />
                          <span class="label"></span><span>{{'label-title.card' | translate}}</span>
                        </label>
                        <ul [hidden]="selected_payment_gateway != 'card'">
                          <li class="mb-0" *ngFor="let card of cards">
                            <div class="order-list-block">
                              <div class="order-product-content">
                                  <div class="order-product-title">
                                    <div class="">
                                      <label for="case{{card._id}}" class="radio mr-auto mb-0 ">
                                        <input type="radio" id="case{{card._id}}" (change)="selectDefault(card._id)" [checked]="selectedcard && card._id === selectedcard._id"   name="card" class="hidden mb-0 mr-auto" />
                                        <span class="label mb-0"></span>
                                        <p class="mb-0 ">xxxx-xxxx-xxxx-{{card.last_four}} {{card.card_type | uppercase}}</p>
                                      </label>
                                      <div class="remove-btn"><a (click)="removeCard(card._id)" href="javascript:void(0)">×</a></div>
                                    </div>

                                  </div>
                              </div>

                            </div>

                          </li>
                        </ul>
                        <div [hidden]="selected_payment_gateway != 'card'" class="add-card mt-2">
                          <a href="javascript:void(0);" (click)="onClickAddCard()" data-id="add-new-card" class="custom-popup btn btn-outline-primary btn-xs">{{'button-title.add-new-card' | translate}}</a>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="cart-total-value">
                      <div class="box1">
                        <i class="fas fa-check"></i>
                      </div>
                      <div class="green box">
                        <i class="fas fa-check"></i>
                      </div>
                    </div> -->
                  </div>
                </li>
                <li *ngIf="is_cash_payment_mode && !is_allow_contactless_delivery">
                  <div class="cart-total-list-wrp d-flex align-item-center mb-0">
                    <div class="cart-total-label  form-checklist-item mr-auto">
                      <div class="form-field">
                        <label for="paypal" class="radio mr-auto">
                          <input type="radio" (change)="selected_payment_gateway = 'cash'" [checked]="selected_payment_gateway === 'cash'" value="cash" name="colorRadio" id="paypal" class="hidden" />
                          <span class="label"></span>{{'label-title.cash' | translate}}
                        </label>
                      </div>
                    </div>
                    <div class="cart-total-value">
                      <div class="cash box">
                        <i class="fas fa-check"></i>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="price-details-section">
            <div class="order-title d-flex align-item-center">
              <h3>{{'label-title.price-details' | translate}}</h3>
            </div>
            <div class="cart-total-wrp">
              <ul>
                <li [hidden]="!order_payment_details.is_store_pay_delivery_fees" class="text-right">
                  <img src="../../assets/images/free_shipping.png" alt="">
                </li>
                <li [hidden]="order_payment_details.total_service_price <= 0 || order_payment_details.is_store_pay_delivery_fees">
                  <div class="cart-total-list-wrp subtotal d-flex align-item-center">
                    <div class="cart-total-label mr-auto"><strong>{{'label-title.total-service-price' | translate}}</strong></div>
                    <div class="cart-total-value" *ngIf="order_payment_details.busy_period_amount > 0 && order_payment_details.busy_period_to_customer"><strong>{{order_payment_details.currency_code}} {{order_payment_details.is_store_pay_delivery_fees ? '0.00' : (order_payment_details.total_delivery_price + order_payment_details.busy_period_amount | roundpipe:2)}}</strong></div>
                    <div class="cart-total-value" *ngIf="order_payment_details.busy_period_amount <= 0 || !order_payment_details.busy_period_to_customer"><strong>{{order_payment_details.currency_code}} {{order_payment_details.is_store_pay_delivery_fees ? '0.00' : (order_payment_details.total_delivery_price | roundpipe:2)}}</strong></div>
                  </div>
                </li>
                <!--<li [hidden]="order_payment_details.total_delivery_price <= 0">
                  <div class="cart-total-list-wrp d-flex align-item-center">
                    <div class="cart-total-label mr-auto"><span>{{'label-title.service-price' | translate}}</span></div>
                    <div class="cart-total-value" *ngIf="order_payment_details.busy_period_amount > 0 && order_payment_details.busy_period_to_customer"><span>{{order_payment_details.currency_code}} {{order_payment_details.is_store_pay_delivery_fees ? '0.00' : (order_payment_details.total_service_price + order_payment_details.busy_period_amount | roundpipe:2)}}</span></div>
                    <div class="cart-total-value" *ngIf="order_payment_details.busy_period_amount <= 0 || !order_payment_details.busy_period_to_customer"><span>{{order_payment_details.currency_code}} {{order_payment_details.is_store_pay_delivery_fees ? '0.00' : (order_payment_details.total_service_price | roundpipe:2)}}</span></div>
                  </div>
                </li>
                <li [hidden]="order_payment_details.service_tax <= 0">
                  <div class="cart-total-list-wrp d-flex align-item-center">
                    <div class="cart-total-label mr-auto"><span>{{'label-title.service-tax' | translate}}</span></div>
                    <div class="cart-total-value"><span>{{order_payment_details.currency_code}} {{order_payment_details.is_store_pay_delivery_fees ? '0.00' : (order_payment_details.total_admin_tax_price | roundpipe:2)}}</span></div>
                  </div>
                </li>-->
                <li>
                  <div class="cart-total-list-wrp subtotal d-flex align-item-center">
                    <div class="cart-total-label mr-auto"><strong>{{'label-title.total-item-price' | translate}}</strong>
                    </div>
                    <div class="cart-total-value"><strong>{{order_payment_details.currency_code}} {{order_payment_details.total_order_price | roundpipe:2}}</strong></div>
                  </div>
                </li>
                <!--<li>
                  <div class="cart-total-list-wrp d-flex align-item-center">
                    <div class="cart-total-label mr-auto"><span>{{'label-title.vat-tax' | translate}}</span>
                    </div>
                    <div class="cart-total-value"><span>{{order_payment_details.currency_code}} {{order_payment_details.total_store_tax_price | roundpipe:2}}</span></div>
                  </div>
                </li>
                <li>
                  <div class="cart-total-list-wrp d-flex align-item-center">
                    <div class="cart-total-label mr-auto"><span>{{'label-title.item-price' | translate}}</span></div>
                    <div class="cart-total-value"><span>{{order_payment_details.currency_code}} {{order_payment_details.total_cart_price | roundpipe:2}}</span></div>
                  </div>
                </li>-->
                <li>
                  <div class="cart-total-list-wrp d-flex align-item-center" *ngIf="order_payment_details.tip_amount > 0">
                    <div class="cart-total-label mr-auto"><span>{{'label-title.tip-amount' | translate}}</span></div>
                    <div class="cart-total-value"><span>{{order_payment_details.currency_code}} {{order_payment_details.tip_amount | roundpipe:2}}</span></div>
                  </div>
                </li>
                <!--<li>
                  <div class="cart-total-list-wrp subtotal d-flex align-item-center" *ngIf="order_payment_details.busy_period_amount > 0 && order_payment_details.busy_period_to_customer">
                    <div class="cart-total-label mr-auto"><strong>{{'label-title.busy-period-premium' | translate}}</strong>
                    </div>
                    <div class="cart-total-value"><strong>{{order_payment_details.currency_code}} {{order_payment_details.busy_period_amount | roundpipe:2}}</strong></div>
                  </div>
                </li>-->
                <li>
                  <div class="cart-total-list-wrp subtotal d-flex align-item-center" *ngIf="order_payment_details.total_customer_service_fee > 0">
                    <div class="cart-total-label mr-auto"><strong>{{'label-title.customer-service-fee' | translate}}</strong>
                    </div>
                    <div class="cart-total-value"><strong>{{order_payment_details.currency_code}} {{order_payment_details.total_customer_service_fee | roundpipe:2}}</strong></div>
                  </div>
                </li>
                <!--<li>
                  <div class="cart-total-list-wrp d-flex align-item-center" *ngIf="promo_applied">
                    <div class="cart-total-label mr-auto"><span>{{'label-title.promo-bonus' | translate}}</span></div>
                    <div class="cart-total-value"><span>{{order_payment_details.currency_code}} {{promo_bonus | roundpipe:2}}</span></div>
                  </div>
                </li>-->
                <li>
                  <div class="cart-total-list-wrp subtotal d-flex align-item-center" *ngIf="order_payment_details.promo_payment > 0">
                    <div class="cart-total-label mr-auto"><strong>{{'label-title.subtotal' | translate}}</strong></div>
                    <div class="cart-total-value"><strong>{{order_payment_details.currency_code}} {{order_payment_details.total | roundpipe:2}}</strong></div>
                  </div>
                </li>
                <!-- <li>
                  <div class="cart-total-list-wrp d-flex align-item-center">
                    <div class="cart-total-label mr-auto"><span>{{'label-title.discount' | translate}}</span>
                    </div>
                    <div class="cart-total-value"><span>{{order_payment_details.currency_code}} {{order_payment_details.promo_payment | roundpipe:2}}</span></div>
                  </div>
                </li> -->
                <li>
                  <div class="cart-total-list-wrp d-flex align-item-center" *ngIf="order_payment_details.promo_payment > 0">
                    <div class="cart-total-label mr-auto"><span>{{'label-title.discount' | translate}}</span>
                    </div>
                    <div class="cart-total-value"><span> - {{order_payment_details.currency_code}} {{order_payment_details.promo_payment | roundpipe:2}}</span></div>
                  </div>
                </li>
              </ul>
              <ul class="total-bottom">
                <li>
                  <div class="cart-total-list-wrp subtotal d-flex align-item-center">
                    <div class="cart-total-label mr-auto"><strong>{{'label-title.total' | translate}}</strong></div>
                    <div class="cart-total-value"><strong>{{order_payment_details.currency_code}} {{order_payment_details.user_pay_payment | roundpipe:2}}</strong></div>
                  </div>
                </li>
              </ul>
              <div *ngIf="store && store.close" class="mb-4">
								<div *ngIf="store.nextopentime!==''" style="font-size: 13px;"
									class="text-danger">
                 {{'label-title.store-closed-today' | translate}}
                 <!-- {{'label-title.store-closed-reopen-at'}}  {{store.nextopentime}} -->
								</div>
								<!-- <div *ngIf="store.nextopentime==''" style="font-size: 13px;" class="text-danger">
                 {{'label-title.store-closed-today' | translate}}
								</div> -->
							</div>
              <div class="total-btn text-center">
                <button [hidden]="is_login && order_payment_details.user_pay_payment > 0 && !store.close" [disabled]="true" class="primary-btn">{{'button-title.place-order' | translate}} {{order_payment_details.currency_code}} {{order_payment_details.user_pay_payment | roundpipe:2}}</button>
                <button [hidden]="!is_login || order_payment_details.user_pay_payment === 0 || store.close" (click)="place_order()" class="primary-btn">{{'button-title.place-order' | translate}}  {{order_payment_details.currency_code}} {{order_payment_details.user_pay_payment | roundpipe:2}}</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- Change user details popup start  -->

<div class="custom-model-main medium-popup" id="user-details">
  <div class="custom-model-inner">
    <div class="close-btn">×</div>
    <div class="custom-model-wrap">
      <div class="pop-up-content-wrap">
        <div class="dropdown-title without-subtitle" >
          <h2>{{'heading-title.change-details' | translate}} </h2>
        </div>
        <div class="dropdown-content" style="padding-top: 0 !important;">
          <form [formGroup]="userDetailForm" class="login-form">
            <div class="form-field">
              <label for="">{{'label-title.name' | translate}}</label>
              <input type="text" class="form-control" formControlName="user_name">
              <div *ngIf="userDetailForm.get('user_name').errors?.required && userDetailForm.get('user_name').touched">{{'validation-title.username-is-required' | translate}}</div>
            </div>
            <div class="form-field">
              <label for="">{{'label-title.phone-number' | translate}}</label>
              <input type="text" class="form-control" formControlName="user_phone_number">
              <div *ngIf="userDetailForm.get('user_phone_number').errors?.required && userDetailForm.get('user_phone_number').touched">{{'validation-title.phone-is-required' | translate}}</div>
              <div *ngIf="userDetailForm.get('user_phone_number').errors?.minlength && userDetailForm.get('user_phone_number').touched">{{'validation-title.phone-is-invalid' | translate}}</div>
            </div>
            <div class="form-field">
              <label for="">{{'label-title.apartment' | translate}}</label>
              <input type="text" class="form-control" formControlName="apartment">
            </div>
            <div class="form-field">
              <label for="">{{'label-title.building_name' | translate}}</label>
              <input type="text" class="form-control" formControlName="building_name">
            </div>
            <div class="form-field">
              <label for="">{{'label-title.business_name' | translate}}</label>
              <input type="text" class="form-control" formControlName="business_name">
            </div>
            <div class="form-field">
              <label for="">{{'label-title.option_for_delivery' | translate}}</label>
              <select type="text" class="form-control" formControlName="option_for_delivery">
                <option value="0">{{'label-title.leave-at-door-ring' | translate}}</option>
                <option value="1">{{'label-title.knock-on-door' | translate}}</option>
                <option value="2">{{'label-title.leave-at-door' | translate}}</option>
                <option value="3">{{'label-title.meet-outside' | translate}}</option>
              </select>
            </div>
            <div class="form-field">
              <button (click)="onUserDetail()" [disabled]="userDetailForm.invalid" class="primary-btn submit-btn mr-auto">{{'button-title.update' | translate}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-overlay"></div>
</div>

<!-- Change user details popup end  -->

<!-- Change delivery address popup start  -->

<div class="custom-model-main" id="delivery-add-update">
  <div class="custom-model-inner">
    <div class="close-btn" (click)="closeupdateaddress()" id="close-update-address">×</div>
    <div class="custom-model-wrap">
      <div class="pop-up-content-wrap">
        <div class="dropdown-title without-subtitle">
          <h2>{{'heading-title.change-delivery-address' | translate}}</h2>
        </div>
        <div class="dropdown-content">
          <form action="" class="login-form">
            <div class="form-field">
              <label for="">{{'heading-title.search-address-here' | translate}}</label>
              <input type="text" class="form-control" id="address" name="address" #address>
            </div>
            <div class="form-field">
							<div id="map" style="width: 100%; min-height: 400px;margin-top:20px;"></div>
              <!-- <iframe
              src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d1884916.7075680098!2d70.5269932983835!3d22.673245189258935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3959cbcc3c9a5705%3A0xc4473b3507694530!2sElluminati%20Inc%2C%20Jala%20Ram%20Nagar%2C%20Rajkot%2C%20Gujarat%20360007!3m2!1d22.2901858!2d70.7747911!5e0!3m2!1sen!2sin!4v1602661037173!5m2!1sen!2sin"
              width="550" height="200" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
              tabindex="0"></iframe> -->
            </div>
            <div>
              <span class="text-danger">{{address_error}}</span>
            </div>
            <!-- src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d1884916.7075680098!2d70.5269932983835!3d22.673245189258935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3959cbcc3c9a5705%3A0xc4473b3507694530!2sElluminati%20Inc%2C%20Jala%20Ram%20Nagar%2C%20Rajkot%2C%20Gujarat%20360007!3m2!1d22.2901858!2d70.7747911!5e0!3m2!1sen!2sin!4v1602661037173!5m2!1sen!2sin" -->
            <div class="form-field">
              <a href="javascript:void(0)" *ngIf="address_error === ''" (click)="closeupdateaddress()" class="primary-btn submit-btn mr-auto">{{'button-title.update' | translate}}</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-overlay"></div>
</div>

<!-- Change delivery address popup end  -->


<!-- View Offer popup start  -->

<div class="custom-model-main fix no-footer" id="view-offers">
  <div class="custom-model-inner">
      <div class="close-btn" (click)="closeModel('view-offers-close')" id="view-offers-close">×</div>
      <div class="custom-model-wrap">
          <div class="pop-up-content-wrap signin-box">
              <div class="dropdown-title without-subtitle">
                  <h2>{{'heading-title.offers' | translate}}</h2>
              </div>
              <div class="signin-content no-footer p-4">
                <ul class="offers-list" *ngIf="promo_codes.length">
                    <li *ngFor="let offer of promo_codes">
                        <div class="offer-main-content d-flex align-item-center">
                            <div class="image">
                                <img (error)="$event.target.src = DEFAULT_PROMO_IMAGE" [src]="IMAGE_BASE_URL + offer.image_url"  alt="">
                            </div>
                            <div class="content" style="width: 100%;">
                                <div class="d-flex align-item-center">
                                    <h4 class="mr-auto">{{offer.promo_code_name}}</h4>
                                    <button type="button" class="primary-btn submit-btn" *ngIf="is_login" (click)="apply_promo_code(offer.promo_code_name,true)">{{'button-title.apply' | translate}}</button>
                                    <button type="button" class="primary-btn submit-btn" *ngIf="!is_login" disabled>{{'button-title.apply' | translate}}</button>
                                </div>
                                <p>{{offer.promo_details}}</p>
                            </div>
                        </div>
                        <div class="offer-condition" *ngIf="offer.is_promo_apply_on_completed_order || offer.is_promo_required_uses || offer.is_promo_have_max_discount_limit || offer.is_promo_have_date || offer.is_promo_have_minimum_amount_limit || offer.is_promo_have_item_count_limit">
                            <strong>{{'label-title.terms-conditions' | translate}}</strong>
                            <ul>
                                <li *ngIf="offer.is_promo_apply_on_completed_order">{{'label-title.applied-after-completing' | translate}} {{offer.promo_apply_after_completed_order}} </li>
                                <li *ngIf="offer.is_promo_required_uses">{{'label-title.offer-apply-first' | translate}} {{offer.promo_apply_after_completed_order || 0}} </li>
                                <li *ngIf="offer.is_promo_have_max_discount_limit">{{'label-title.up_to' | translate}} {{order_payment_details.currency_code}} {{offer.promo_code_max_discount_amount}} </li>
                                <li *ngIf="offer.is_promo_have_date">
                                    <div *ngIf="offer.promo_start_date">{{'label-title.apply-date' | translate}} : {{'label-title.from' | translate}}  {{offer.promo_start_date | date: "dd MMM"}} {{'label-title.to' | translate}} {{offer.promo_expire_date | date: "dd MMM"}}</div>
                                    <div *ngIf="offer.promo_start_time && offer.promo_start_time != ''">{{'label-title.apply-time' | translate}} : {{'label-title.from' | translate}}  {{offer.promo_start_time}} {{'label-title.to' | translate}} {{offer.promo_end_time}}</div>
                                    <div *ngIf="offer.days && offer.days.length > 0">{{'label-title.apply-day' | translate}} : {{offer.days}} &nbsp;</div>
                                    <div *ngIf="offer.weeks && offer.weeks.length > 0">{{'label-title.apply-week' | translate}} : {{offer.weeks}} &nbsp;</div>
                                    <div *ngIf="offer.weeks && offer.weeks.months > 0">{{'label-title.apply-month' | translate}} : {{offer.weeks}} &nbsp;</div>
                                </li>
                                <li *ngIf="offer.is_promo_have_minimum_amount_limit">{{'label-title.minimum' | translate}} {{order_payment_details.currency_code}} {{offer.promo_code_apply_on_minimum_amount}} {{'label-title.order-price' | translate}}</li>
                                <li *ngIf="offer.is_promo_have_item_count_limit">{{'label-title.minimum' | translate}} {{offer.promo_code_apply_on_minimum_item_count}} {{'label-title.item_limit' | translate}}</li>
                            </ul>
                        </div>
                    </li>
                </ul>
              </div>
          </div>
      </div>
  </div>
  <div class="bg-overlay"></div>
</div>
<!-- View Offer popup end  -->

<!-- Add Card Popup Start -->

<div class="custom-model-main" id="add-new-card">
  <div class="custom-model-inner ">
      <div class="close-btn addcardclose" (click)="closeAddCardModel()">x</div>
      <div class="custom-model-wrap ">
          <div class="pop-up-content-wrap ">
              <div class="dropdown-title without-subtitle ">
                  <h2>{{'heading-title.add-card-details' | translate}}</h2>
              </div>
              <div class="dropdown-content">
                  <form action=" " class="login-form ">
                      <div id="card-info" style="width:100%" #cardInfo></div>

                      <!-- <div class="form-field ">
                          <label for=" ">{{'label.user_name' | translate}}</label>
                          <input type="text " class="form-control ">
                      </div>
                      <div class="form-field ">
                          <label for=" ">{{'label.card_number' | translate}}</label>
                          <input type="text " class="form-control ">
                      </div>
                      <div class="">
                          <div class="row ">
                              <div class="col-sm-8 col-8 ">
                                  <div class="form-field ">
                                      <label for=" ">{{'label.expiry_date' | translate}}</label>
                                      <div class="expiry-form d-flex align-item-center ">
                                          <div class="row ">
                                              <div class="col-sm-6 col-6 ">
                                                  <select name="month " id="mm " class="form-control ">
                                                      <option value=" ">mm</option>
                                                      <option value="january ">1</option>
                                                      <option value="february ">2</option>
                                                      <option value="march ">3</option>
                                                      <option value="april ">4</option>
                                                      <option value="may ">5</option>
                                                      <option value="june ">6</option>
                                                      <option value="july ">7</option>
                                                      <option value="august ">8</option>
                                                      <option value="september ">9</option>
                                                      <option value="october ">10</option>
                                                      <option value="november ">11</option>
                                                      <option value="december ">12</option>
                                                  </select>
                                              </div>
                                              <div class="col-sm-6 col-6 ">
                                                  <select name="year " id="yyyy " class="form-control ">
                                                      <option value=" ">yyyy</option>
                                                      <option value="1 ">1</option>
                                                      <option value="2 ">2</option>
                                                      <option value="3 ">3</option>
                                                      <option value="4 ">4</option>
                                                      <option value="5 ">5</option>
                                                  </select>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-sm-4 col-4 ">
                                  <div class="form-field ">
                                      <label for=" ">{{'label.cvv' | translate}}</label>
                                      <input type="text " class="form-control ">
                                  </div>
                              </div>
                          </div>
                      </div> -->
                      <div class="form-field mt-4">
                        <a href="javascript:void(0)" *ngIf="!isLoading" (click)="addCard()" class="primary-btn submit-btn mr-auto ">{{'button-title.add-card' | translate}}</a>
                        <a href="javascript:void(0)" *ngIf="isLoading" style="background-color: grey;cursor: not-allowed;color: white;border-color: white;" [disabled]="true" class="primary-btn mr-auto ">{{'button-title.processing' | translate}}</a>
                    </div>
                  </form>
              </div>
          </div>
      </div>
  </div>
  <div class="bg-overlay "></div>
</div>

<!-- Add Card Popup End -->


<div class="custom-model-main thankyou-popup fix small-popup" id="thankyou-popup">
  <div class="custom-model-inner">
      <div class="close-btn">×</div>
      <div class="custom-model-wrap">
          <div class="pop-up-content-wrap">
              <div class="dropdown-title">
                  <h2 class="mr-auto">{{'heading-title.thank-you' | translate}}</h2>
                  <p>{{'label-title.order-success' | translate}}</p>
              </div>
              <div class="dropdown-content">
                  <div class="thank-you-img text-center">
                      <img src="../../assets/images/thank_you.png" alt="">
                  </div>
              </div>
              <div class="popup-footer">
                  <div class="popup-footer-wrp d-flex align-item-center">
                      <div class="add-to-cart-btn">
                          <a href="javascript:void(0)" routerLink="/order" class="primary-btn">{{'button-title.track-order' | translate}}</a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="bg-overlay"></div>
</div>
